import { nextAvailableKey, sortByName } from 'helpers/helperFunctions';
import { useContext, useState, useEffect, useRef } from 'react';
import { IInputEvent, INumberProps, IStringProps } from 'iApp';
import AddLicense from '../files/AddLicense';
import FolderButtons from './FolderButtons';
import MoveFolder from './MoveFolder';
import UpdateFolder from './UpdateFolder';
import Files from '../files/Files';
import {
  IUserContext,
  ISitesContext,
  ICanvasContext,
  IDocumentContext,
} from 'state/iContext';
import {
  UserContext,
  SitesContext,
  CanvasContext,
  DocumentContext,
} from 'state/context';
import {
  ITower,
  ITowerList,
  IDocumentProps,
  IEditFileProps,
} from 'state/iState';

const Folder = ({
  name,
  files,
  folders,
  folderID,
  subIndex,
  onFolderClick,
  parentFolderID,
}) => {
  const { activeTowerID } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;
  const { towerList, setTowerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;
  const { activeSort, updateFolders, setUpdateFolders, setEditFileProps } =
    useContext<IDocumentContext | undefined>(DocumentContext)!;
  const { searchBar, searchDate, searchRange, rangeSearchActive } = useContext<
    IUserContext | undefined
  >(UserContext)!;

  const [openFolder, setOpenFolder] = useState<boolean>(false);
  const [uploadLicense, setUploadLicense] = useState<boolean>(false);

  const storedFiles = towerList[activeTowerID].documents.files;
  const storedFolders = towerList[activeTowerID].documents.folders;

  const { [folderID]: toBeRemoved, ...rest } = storedFolders;

  const fileInputRef = useRef<HTMLInputElement>(null);
  const nextKey: number = nextAvailableKey(storedFiles, 0)!;

  const handleFolderClick = () => {
    if (name !== 'root') {
      setOpenFolder((prev: boolean) => !prev);
    }
  };

  const fileList: IDocumentProps = storedFiles;
  const fileIds: string[] = files
    .sort((a: string, b: string): number => {
      const sort: INumberProps = {
        upload: activeSort.decend ? +b - +a : +a - +b,
        name: activeSort.decend
          ? sortByName(fileList[a].name, fileList[b].name)
          : sortByName(fileList[b].name, fileList[a].name),
      };
      return sort[activeSort.target];
    })
    .filter(
      (id): boolean =>
        fileList[id].name
          .toLowerCase()
          .includes(searchBar.toLowerCase().trim()) &&
        (rangeSearchActive && searchDate && searchRange
          ? fileList[id].upload >= Date.parse(searchDate) &&
            fileList[id].upload <= Date.parse(searchRange)
          : new Date(fileList[id].upload)
              .toString()
              .slice(0, 15)
              .includes(searchDate.slice(0, 15)))
    );

  const classes: IStringProps = {
    empty:
      'flex w-full border border-stone-400 justify-center font-bold text-stone-400 even:bg-slate-100',
    open: `fa-regular fa-folder-open text-stone-500 mr-1`,
    closed: `fa-solid fa-folder-closed text-stone-600 mr-1`,
    container:
      'group flex w-full border border-stone-400 items-center even:bg-slate-100',
    header: 'flex w-full items-center font-semibold text-stone-500',
    remove:
      'hidden fa-solid fa-folder-minus opacity-70 mr-2 text-red-600 hover:opacity-100 group-hover:flex',
  };

  const displayFiles =
    files?.length > 0 || folders.length > 0 ? (
      <Files
        fileIds={fileIds}
        fileList={fileList}
      />
    ) : (
      <span className={classes.empty}>Empty Folder</span>
    );

  const folderIconStyle = {
    marginLeft: `${(subIndex + 2) * 8}px`,
  };

  const folderIcon: string = openFolder ? classes.open : classes.closed;

  const displayedFolders = Object.keys(storedFolders)
    .filter((folder: string) => folders.includes(+folder))
    .map((id) => ({ ...storedFolders[id], id }));

  const folderElements = Object.keys(displayedFolders).map((id) => (
    <Folder
      key={displayedFolders[id].name}
      name={displayedFolders[id].name}
      folders={displayedFolders[id].folders}
      files={displayedFolders[id].files}
      onFolderClick={onFolderClick}
      subIndex={subIndex + 2}
      folderID={displayedFolders[id].id}
      parentFolderID={folderID}
    />
  ));

  const getChildrenFolderIds = (parentID, folders) => {
    const childrenIds: number[] = [];
    const findChildrenIds = (id) => {
      if (folders.length > 0) {
        storedFolders[id].folders.forEach((childId) => {
          childrenIds.push(+childId);
          findChildrenIds(+childId);
        });
      }
    };

    findChildrenIds(parentID);
    return childrenIds;
  };

  const childrenFolderIds = getChildrenFolderIds(
    folderID,
    storedFolders[folderID].folders
  );

  useEffect(() => {
    if (
      folderID > 0 &&
      updateFolders.active &&
      !updateFolders.move &&
      updateFolders.folder !== folderID
    ) {
      setOpenFolder(false);
    }
  }, [updateFolders]);

  useEffect(
    () =>
      setOpenFolder(
        name === 'root' ||
          +updateFolders.folder === +folderID ||
          childrenFolderIds.some((id) => +id === +updateFolders.folder)
      ),
    [updateFolders, folderID]
  );

  const buttonHandlers = {
    move: (e) => {
      if (openFolder) {
        e.stopPropagation();
      }
      setUpdateFolders({
        active: true,
        move: true,
        folder: folderID,
        parent: parentFolderID,
        name,
      });
    },
    edit: (e) => {
      if (openFolder) {
        e.stopPropagation();
      }
      setUpdateFolders({
        active: true,
        edit: true,
        folder: folderID,
        parent: parentFolderID,
        name,
      });
    },
    folder: (e) => {
      if (openFolder) {
        e.stopPropagation();
      }
      setUpdateFolders({
        active: true,
        folder: folderID,
        parent: parentFolderID,
        name: '',
      });
    },
    upload: (e) => {
      setOpenFolder(true);
      e.stopPropagation();
      storedFolders[folderID].datesRequired
        ? setUploadLicense(true)
        : fileInputRef.current!.click();
    },
    build: (e) => {
      setOpenFolder(true);
      e.stopPropagation();
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        type: 'doc',
        id: nextKey,
        folder: folderID,
      }));
    },
    input: (e: IInputEvent) => {
      const file: File = e.target.files![0];
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          documents: {
            ...prev[activeTowerID].documents,
            folders: {
              ...prev[activeTowerID].documents.folders,
              [folderID]: {
                ...prev[activeTowerID].documents.folders[folderID],
                files: [
                  ...prev[activeTowerID].documents.folders[folderID].files,
                  `${nextKey}`,
                ],
              },
            },
            files: {
              ...prev[activeTowerID].documents.files,
              [nextKey]: {
                name: file.name.replace(/.pdf/i, '').trim(),
                file,
                upload: Date.now(),
                isPDF: true,
                folder: +folderID,
              },
            },
          },
        },
      }));
      setOpenFolder(true);
    },
    remove: () =>
      setTowerList((prev: ITower) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          documents: {
            ...prev[activeTowerID].documents,
            folders: {
              ...rest,
              [parentFolderID]: {
                ...prev[activeTowerID].documents.folders[parentFolderID],
                folders: prev[activeTowerID].documents.folders[
                  parentFolderID
                ].folders.filter((id: string) => +id !== +folderID),
              },
            },
          },
        },
      })),
  };

  return (
    <>
      <div
        className={classes.container}
        onClick={handleFolderClick}>
        <>
          <i
            className={folderIcon}
            style={folderIconStyle}
          />
          <div className={classes.header}>
            {name !== 'root' ? name : ''}
            {updateFolders.folder === folderID &&
            folderID > 0 &&
            updateFolders.move ? (
              <MoveFolder type='folder' />
            ) : (
              <FolderButtons
                name={name}
                fileInputRef={fileInputRef}
                handlers={buttonHandlers}
              />
            )}
          </div>
          {files.length === 0 && folders.length === 0 && folderID > 4 && (
            <i
              id='upload-file-icon'
              className={classes.remove}
              onClick={buttonHandlers.remove}
            />
          )}
        </>
      </div>
      {openFolder && (
        <>
          {uploadLicense && (
            <AddLicense
              folderID={folderID}
              close={() => setUploadLicense(false)}
            />
          )}
          {updateFolders.folder === folderID &&
            updateFolders.active &&
            !updateFolders.move && <UpdateFolder />}
          <>{folderElements}</>
          <>{displayFiles}</>
        </>
      )}
    </>
  );
};

export default Folder;
