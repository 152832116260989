import DocumentInputField from '../../../DocumentInputField';
import { IDocumentTowerInfo } from 'state/iState';
import { IDocumentContext } from 'state/iContext';
import { IInputEvent, INumberProps } from 'iApp';
import { DocumentContext } from 'state/context';
import { ReactNode, useContext } from 'react';

const DocShelterInputs = ({ shelterID }: INumberProps) => {
  const { documentTowerInfo, setDocumentTowerInfo } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;

  const inputs: ReactNode[] = Object.keys(
    documentTowerInfo['Shelter Details'].shelters[shelterID]
  ).map((line) => (
    <DocumentInputField
      key={line}
      name={line}
      value={documentTowerInfo['Shelter Details'].shelters[shelterID][line]}
      onChange={(e: IInputEvent) =>
        setDocumentTowerInfo((prev: IDocumentTowerInfo) => ({
          ...prev,
          'Shelter Details': {
            ...prev['Shelter Details'],
            shelters: {
              ...prev['Shelter Details'].shelters,
              [shelterID]: {
                ...prev['Shelter Details'].shelters[shelterID],
                [line]: e.target.value,
              },
            },
          },
        }))
      }
    />
  ));

  return (
    <div
      key={shelterID}
      className='flex flex-col w-full my-2 p-0.5 justify-between'>
      <h1 className='w-full'>Shelter #{shelterID + 1}</h1>
      {inputs}
    </div>
  );
};

export default DocShelterInputs;
