import { ICanvasContext } from 'state/iContext';
import { IActiveToolBar } from 'state/iState';
import { CanvasContext } from 'state/context';
import Close from 'components/buttons/Close';
import { useContext } from 'react';

const HistoryPanel = () => {
  const { setActiveToolBar } = useContext<ICanvasContext | undefined>(CanvasContext)!;

  return (
    <div className='absolute flex right-1 top-0 w-[50vw] md:w-[300px] md:h-[300px] bg-red-100 border border-2 border-slate-500 rouned rounded-md'>
      <Close
        handler={() =>
          setActiveToolBar((prev: IActiveToolBar) => ({
            ...prev,
            select: true,
            history: false,
          }))
        }
      />
      HISTORY PANEL
    </div>
  );
};

export default HistoryPanel;
