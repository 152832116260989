import { ILibraryContext, IUserContext } from 'state/iContext';
import { LibraryContext, UserContext } from 'state/context';
import Icons from 'components/icons/Icons';
import { useContext } from 'react';
import { IStringProps } from 'iApp';

const IconsPanel = () => {
  const { libraries, activeLibrary, setEditLibrary } = useContext<
    ILibraryContext | undefined
  >(LibraryContext)!;
  const { user } = useContext<IUserContext | undefined>(UserContext)!;

  const editableLibrary: boolean = Object.keys(user.library).includes(
    activeLibrary
  );

  const classes: IStringProps = {
    container:
      'h-2/3 w-full border border-stone-300 overflow-y-auto bg-slate-100 sm:w-2/3 sm:h-full',
    header: 'm-1 font-medium text-lg text-stone-600',
    icon: 'fa-solid fa-pen-to-square mx-3 text-stone-400 text-sm hover:text-red-600',
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.header}>
        {activeLibrary}
        {editableLibrary && (
          <i
            className={classes.icon}
            onClick={() => {
              setEditLibrary({
                active: true,
                name: activeLibrary,
                prevName: activeLibrary,
              });
            }}
          />
        )}
      </h1>
      {activeLibrary !== '' && <Icons library={libraries[activeLibrary]} />}
    </div>
  );
};

export default IconsPanel;
