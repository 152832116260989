import { CanvasContext, HeaderContext, SettingsContext } from 'state/context';
import DataToolBarIcons from './DataToolbarIcons';
import { useContext, ReactNode } from 'react';
import { IActiveToolBar } from 'state/iState';
import { IStringProps } from 'iApp';
import {
  ICanvasContext,
  IHeaderContext,
  ISettingsContext,
} from 'state/iContext';

const ToolBarLeft = ({ zoomIn, zoomOut, resetTransform, full }) => {
  const {
    activeToolBar,
    setActiveToolBar,
    setAddItemProps,
    clearAddProps,
    setScale,
  } = useContext<ICanvasContext | undefined>(CanvasContext)!;
  const { setMinimize } = useContext<ISettingsContext | undefined>(
    SettingsContext
  )!;
  const { activeCanvasTab } = useContext<IHeaderContext | undefined>(
    HeaderContext
  )!;
  const { left } = DataToolBarIcons;

  const classes: IStringProps = {
    container: 'flex flex-wrap justify-center max-w-1/2',
    active:
      'relative flex flex-col w-[32px] h-[28px] justify-center text-center self-center border border-stone-300 py-1 px-2 bg-red-700 text-slate-200 cursor-pointer transition ease-in-out delay-100 hover:bg-red-800',
    inactive:
      'relative flex flex-col w-[32px] h-[28px] justify-center text-center self-center bg-stone-100 border border-stone-300 py-1 px-2 cursor-pointer transition ease-in-out delay-100 hover:bg-red-700 hover:text-slate-200',
  };

  const iconList: string[] = Object.keys(left);
  const buttons: ReactNode[] = iconList
    .filter((_, i) =>
      full ? (activeCanvasTab === 'site' ? i !== 4 : true) : i > 5
    )
    .map((icon): ReactNode => {
      const id: string = `data-${icon}-button`;
      const activeClass: string = activeToolBar[
        icon as keyof typeof activeToolBar
      ]
        ? classes.active
        : classes.inactive;

      // Tool bar button behaviors
      const clickHandler = (icon: string) => {
        const handlers = {
          select: (prev: IActiveToolBar) => ({
            ...prev,
            select: true,
            image: false,
            text: false,
            drawings: false,
            sections: false,
            edit: false,
          }),
          image: (prev: IActiveToolBar) => ({
            ...prev,
            image: !prev.image,
            select: prev.image,
            text: false,
            drawings: false,
            sections: false,
            edit: false,
          }),
          text: (prev: IActiveToolBar) => ({
            ...prev,
            text: !prev.text,
            select: prev.text,
            image: false,
            drawings: false,
            sections: false,
            edit: false,
          }),
          drawings: (prev: IActiveToolBar) => ({
            ...prev,
            drawings: !prev.drawings,
            select: prev.drawings,
            image: false,
            text: false,
            sections: false,
            edit: false,
          }),
          sections: (prev: IActiveToolBar) => ({
            ...prev,
            sections: !prev.sections,
            select: prev.sections,
            image: false,
            text: false,
            drawings: false,
            edit: false,
          }),
          edit: (prev: IActiveToolBar) => ({
            ...prev,
            edit: !prev.edit,
            select: prev.edit,
            image: false,
            text: false,
            drawings: false,
            sections: false,
          }),
        };
        setAddItemProps(clearAddProps);
        if (handlers[icon]) {
          setMinimize(false);
          setActiveToolBar(handlers[icon as keyof typeof handlers]);
        }
        if (icon === 'text' || icon === 'image') {
          resetTransform();
        }
      };
      const zoomHandlers = {
        zoomIn: () => {
          zoomOut();
          setScale((prev: number) => prev - 0.5);
        },
        zoomOut: () => {
          zoomIn();
          setScale((prev: number) => prev + 0.5);
        },
        resetTransform: () => {
          setScale(1);
          resetTransform();
        },
      };

      const zoomControl = (icon: string) => {
        if (zoomHandlers[icon]) {
          zoomHandlers[icon]();
        }
      };

      return (
        <div
          id={id}
          key={icon}
          onClick={() => {
            clickHandler(icon);
            zoomControl(icon);
          }}
          className={activeClass}>
          {left[icon as keyof typeof left]}
        </div>
      );
    });

  return (
    <div
      id='data-toolbar-left'
      className={classes.container}>
      {buttons}
    </div>
  );
};

export default ToolBarLeft;
