import { IHeaderContext } from 'state/iContext';
import { HeaderContext } from 'state/context';
import logo from 'assets/header/logo.svg';
import { useContext } from 'react';

const Logo = () => {
  const { setActiveHeaderButton } = useContext<IHeaderContext | undefined>(HeaderContext)!;

  // Returns to home page
  const clickHandler = () =>
    setActiveHeaderButton({
      siteTypes: false,
      account: false,
      settings: false,
      tower: false,
      drawings: false,
      site: false,
    });

  return (
    <img
      id='logo'
      src={logo}
      sizes='150px'
      alt='rigstar logo'
      onClick={clickHandler}
      className='cursor-pointer'
      srcSet='https://mlemukba83pq.i.optimole.com/w:300/h:93/q:mauto/https://www.rigstar.ca/wp-content/uploads/2017/01/Rigstar_Horizontal_Main_industrial_telecom300.png 300w, https://mlemukba83pq.i.optimole.com/w:auto/h:auto/q:mauto/https://www.rigstar.ca/wp-content/uploads/2017/01/Rigstar_Horizontal_Main_industrial_telecom600.png 600w'
    />
  );
};

export default Logo;