import { IBlockResize, IBlockResizeClasses } from './iPorts';

const BlockResize = ({ name, handlers, ports }: IBlockResize) => {
  const classes: IBlockResizeClasses = {
    container: 'flex w-full justify-around items-center p-0.5',
    name: 'flex w-1/2 justify-center',
    resize: 'flex w-1/2 justify-around',
    button:
      'flex min-w-[20px] h-[20px] m-1 bg-white justify-center items-center border border-stone-200 rounded rounded-md',
  };

  return (
    <div className={classes.container}>
      <span className={classes.name}>{name}</span>
      <div className={classes.resize}>
        <div
          onClick={handlers.minus}
          className={classes.button}>
          -
        </div>
        {ports}
        <div
          onClick={handlers.plus}
          className={classes.button}>
          +
        </div>
      </div>
    </div>
  );
};

export default BlockResize;
