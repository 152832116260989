import { ICanvasContext, ISitesContext, IDocumentContext } from 'state/iContext';
import { CanvasContext, SitesContext, DocumentContext } from 'state/context';
import { IDocumentTowerInfo, ITower } from 'state/iState';
import DocumentInputField from '../../DocumentInputField';
import { IInputEvent } from 'iApp';
import { useContext } from 'react';

const DocStructureInfo = () => {
  const { towerList } = useContext<ISitesContext | undefined>(SitesContext)!;
  const { activeTowerID } = useContext<ICanvasContext | undefined>(CanvasContext)!;
  const { setDocumentTowerInfo } = useContext<IDocumentContext | undefined>(
    DocumentContext
  )!;

  const tower: ITower = towerList[activeTowerID];

  return (
    <div className='w-full p-2'>
      <h1 className='font-semibold text-stone-500'>Structure Information</h1>
      <div className='flex flex-col w-full my-2 p-0.5 justify-between'>
        <DocumentInputField
          name={'Structure Height'}
          value={tower.height}
          onChange={(e: IInputEvent) =>
            setDocumentTowerInfo((prev: IDocumentTowerInfo) => ({
              ...prev,
              'Structure Information': {
                ...prev['Structure Information'],
                'Structure Height': e.target.value,
              },
            }))
          }
        />
        <DocumentInputField
          value={tower.type.site}
          name={'Structure Type'}
          onChange={(e: IInputEvent) =>
            setDocumentTowerInfo((prev: IDocumentTowerInfo) => ({
              ...prev,
              'Structure Information': {
                ...prev['Structure Information'],
                'Structure Type': e.target.value,
              },
            }))
          }
        />
        <DocumentInputField
          name={'Construction Type'}
          value={tower.type.tower}
          onChange={(e: IInputEvent) =>
            setDocumentTowerInfo((prev: IDocumentTowerInfo) => ({
              ...prev,
              'Structure Information': {
                ...prev['Structure Information'],
                'Construction Type': e.target.value,
              },
            }))
          }
        />
        <DocumentInputField
          name={'Manufacturer'}
          onChange={(e: IInputEvent) =>
            setDocumentTowerInfo((prev: IDocumentTowerInfo) => ({
              ...prev,
              'Structure Information': {
                ...prev['Structure Information'],
                Manufacturer: e.target.value,
              },
            }))
          }
        />
      </div>
    </div>
  );
};

export default DocStructureInfo;
