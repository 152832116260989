import { ISiteInfoShortLong } from '../components/home/map/infoWindowDetails/iInfoWindow';
import { IMinMaxClasses } from '../components/home/sitesPanel/iSites';
import { ReactElement, ReactNode } from 'react';
import { ISearchMatch } from './iHelpers';
import { IStringProps } from 'iApp';
import {
  ITower,
  IMeasurements,
  LatLngLiteral,
  IMeasurelength,
  IGridLayoutProps,
  ITowerLayoutProps,
  IDocumentProps,
  IVerticalityProps,
  IVerticality,
  IVerticalityContent,
  IDocumentImageProps,
  IDocumentDeficiencies,
  IFolders,
} from 'state/iState';

// Returns red/blue class if 'all-sites-tab' is provided
export const isAllType = (id: string): string =>
  id === 'all-sites-tab' ? 'text-blue-500' : 'text-red-600';

// Returns classes based on minimize state of side panel
export const isMinClass = (state: boolean, classes: IMinMaxClasses): string =>
  state ? classes.min : classes.max;

// Converts 'white spaced' strings 'to-kebab-format'
export const kebabString = (string: string): string =>
  string.trim().toLowerCase().split(' ').join('-');

// Converts number 'telephone-kebab-format'
export const kebabNumber = (num: number): string => {
  const numStr = num.toString();
  return numStr.replace(/(\d{3})(\d{4})/, '$1-$2-');
};

// Highlights parts of provided string that match the search bar
export const highlightSearch = (
  str: string,
  search: string
): ReactNode[] | string => {
  const searchFor: string = search.toLowerCase().trim();
  const searchIn: string = str.toLowerCase();
  const searchMatch: boolean = searchIn.includes(searchFor);
  const index: number = searchIn.indexOf(searchFor);
  const splitStr: string[] = [
    str.slice(0, index),
    str.slice(index, index + searchFor.length),
    str.slice(index + searchFor.length),
  ];

  const splitSearchMatch = (arr: string[]): string[] => {
    const lastItem: string = arr[arr.length - 1];
    if (lastItem.toLowerCase().includes(searchFor) && search !== '') {
      const nextIndex: number = lastItem.toLowerCase().indexOf(searchFor);
      const spliced: string[] = [
        ...arr.slice(0, -1),
        lastItem.slice(0, nextIndex),
        lastItem.slice(nextIndex, nextIndex + searchFor.length),
        lastItem.slice(nextIndex + searchFor.length),
      ];
      return splitSearchMatch(spliced);
    } else {
      return arr;
    }
  };
  const output: string[] = splitSearchMatch(splitStr);

  if (searchMatch && search !== '') {
    const highlightOutput: ReactNode[] = output.map((str, i): ReactNode => {
      const highlight: string =
        str.toLowerCase() === searchFor ? 'bg-yellow-200' : '';
      return str.toLowerCase() === searchFor ? (
        <span
          key={`highlight-${str}-${i}`}
          className={highlight}>
          {str}
        </span>
      ) : (
        <span key={`highlight-${str}-${i}`}>{str}</span>
      );
    });
    return highlightOutput;
  } else {
    return str;
  }
};

// Converts provided info to a string if it is an array of JSX components
export const infoObjectToString = (info: string | ReactElement[]): string => {
  let infoString: string = '';

  if (typeof info === 'object') {
    info.forEach(
      (obj: ReactElement, i: number) => (infoString += info[i].props.children)
    );
  } else {
    infoString = info;
  }
  return infoString;
};

// Returns short/long classes based on max length provided
export const isInfoLong = (
  data: string | ISiteInfoShortLong,
  isMaxLength: boolean
): string => {
  if (typeof data === 'string' || !isMaxLength) {
    return data as string;
  } else {
    return data.short;
  }
};

// Returns mile distance between two lat/long coordinates
export const haversineDistance = (
  tower1: ITower,
  tower2: ITower,
  measurement: string
): number => {
  const earthRadius: number = measurement === 'kms' ? 6371 : 3958.8;

  // Converts degrees to radians
  const radianlat1: number = tower1.location.lat * (Math.PI / 180);
  const radianlat2: number = tower2.location.lat * (Math.PI / 180);

  // Radian differences in lat/long
  const difflat: number = radianlat2 - radianlat1;
  const difflng: number =
    (tower2.location.lng - tower1.location.lng) * (Math.PI / 180);

  const distance: number =
    2 *
    earthRadius *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(radianlat1) *
            Math.cos(radianlat2) *
            Math.sin(difflng / 2) *
            Math.sin(difflng / 2)
      )
    );
  return distance;
};

// Returns mid coordinate between two lat/long coodinates
export const midPoint = (tower1: ITower, tower2: ITower): LatLngLiteral => {
  const { atan2, sin, cos, sqrt, PI } = Math;
  const { location: loc1 } = tower1;
  const { location: loc2 } = tower2;

  // Convert all coordinates into radians
  const radLat1: number = loc1.lat * (PI / 180);
  const radLng1: number = loc1.lng * (PI / 180);
  const radLat2: number = loc2.lat * (PI / 180);
  const radLng2: number = loc2.lng * (PI / 180);

  // Convert lat/long to cartesian (x,y,z) coordinates
  const cartX1: number = cos(radLat1) * cos(radLng1);
  const cartY1: number = cos(radLat1) * sin(radLng1);
  const cartZ1: number = sin(radLat1);
  const cartX2: number = cos(radLat2) * cos(radLng2);
  const cartY2: number = cos(radLat2) * sin(radLng2);
  const cartZ2: number = sin(radLat2);

  // Compute combined weighted cartesian coordinate
  const weightedCartX: number = (cartX1 + cartX2) / 2;
  const weightedCartY: number = (cartY1 + cartY2) / 2;
  const weightedCartZ: number = (cartZ1 + cartZ2) / 2;

  // Convert cartesian coordinate to latitude and longitude for the midpoint
  const cartRadLng: number = atan2(weightedCartY, weightedCartX);
  const cartRadHyp: number = sqrt(
    weightedCartX * weightedCartX + weightedCartY * weightedCartY
  );
  const cartRadLat: number = atan2(weightedCartZ, cartRadHyp);

  // Convert midpoint lat and long from radians to decimal degrees
  const lat: number = cartRadLat * (180 / PI);
  const lng: number = cartRadLng * (180 / PI);

  return { lat, lng };
};

// Returns measurement unit type
export const measurementType = (
  metricActive: boolean,
  measurements: IMeasurements
): IMeasurelength =>
  metricActive ? measurements.metric : measurements.imperial;

// Converts between Metric/Imperial
export const towerHeight = (metric: boolean, height: number): number =>
  metric ? +(height / 3.28084).toFixed(2) : height;

// Checks if provided search is found in data
export const searchMatch = ({
  searchInList,
  lowCaseSearch,
}: ISearchMatch): boolean =>
  lowCaseSearch === '' ||
  searchInList.some((name) => name.toLowerCase().includes(lowCaseSearch));

// Returns next available key in object
export const nextAvailableKey = (
  obj:
    | IGridLayoutProps
    | ITowerLayoutProps
    | IDocumentProps
    | IVerticalityContent
    | IDocumentDeficiencies
    | { [key: number]: IStringProps }
    | { [key: number]: IDocumentImageProps },
  offset: number
): number | void => {
  if (!obj) {
    return 0;
  }

  const sortedKeys = Object.keys(obj)
    .map((str) => Number(str))
    .filter((num) => num >= 0)
    .sort((a, b) => a - b);

  // Finds next largest available key
  let availableKey = Math.max(...sortedKeys, 0) + offset;

  // Finds first smallest available key
  for (let i = 0; i < sortedKeys.length; i++) {
    if (availableKey < sortedKeys[i]) {
      return availableKey;
    } else if (availableKey === sortedKeys[i]) {
      // Increment availableKey if it matches an existing key
      availableKey++;
    }
  }

  return availableKey;
};

// Sorting logic for string variables
export const sortByName = (a: string, b: string): number => b.localeCompare(a);

export const inspectionAlert = (date: number): string => {
  const days: IStringProps = {
    due: 'text-red-600 font-bold',
    within30: 'text-yellow-600 font-bold',
    notDue: 'font-bold',
  };

  // Converts dates to UTC strings
  const now: Date = new Date();
  const dueDate: Date = new Date(date);
  const within30Date: Date = new Date(
    new Date(dueDate).setDate(new Date(dueDate).getDate() - 30)
  );

  // Converts UTC strings to UTC number values
  const nowUTC: number = Date.parse(now.toString());
  const within30UTC: number = Date.parse(within30Date.toString());

  if (nowUTC > within30UTC && nowUTC < date) {
    return days.within30;
  } else if (nowUTC > date) {
    return days.due;
  } else {
    return days.notDue;
  }
};

export const getDescendants = (
  folderId: string,
  folders: IFolders
): number[] => {
  const folder = folders[folderId];
  let descendants: number[] = [];

  folder.folders.forEach((childId: string) => {
    descendants.push(+childId);
    descendants.push(...getDescendants(childId, folders));
  });

  return descendants;
};
