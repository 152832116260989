import { IUserDropdownClasses, IUserDropdown } from './iUserDropdown';
import { IHeaderContext, ISettingsContext } from 'state/iContext';
import { HeaderContext, SettingsContext } from 'state/context';
import UserDropdownMenu from './UserDropdownMenu';
import profile from 'assets/header/profile.png';
import { IHeaderButtons } from 'state/iState';
import MenuArrow from '../MenuArrow';
import { useContext } from 'react';

const UserDropdown = () => {
  const   { setIsSettingsOpen } = useContext<ISettingsContext | undefined>(SettingsContext)!;
  const   { activeHeaderButton, setActiveHeaderButton } = useContext<IHeaderContext | undefined>(HeaderContext)!;

  // Component Classes
  const classes: IUserDropdownClasses = {
    main: 'flex inline-block text-left w-15 cursor-pointer',
    button:
      'inline-flex w-full justify-center items-center bg-stone-100 px-2 py-3 text-sm font-medium text-gray-700',
    img: 'w-5 rounded-full bg-slate-400 invert',
  };

  const { account } = activeHeaderButton!;

  // Toggle account menu dropdown
  const clickHandler = () => {
    if (!activeHeaderButton.settings) {
      setIsSettingsOpen(false);
      setActiveHeaderButton(
        (prev: IHeaderButtons): IHeaderButtons => ({
          ...prev,
          account: !prev.account,
          siteTypes: false,
        })
      );
    }
  };

  // Props to pass to their respective elements
  const props: IUserDropdown = {
    buttonProps: {
      type: 'button',
      onClick: clickHandler,
      className: classes.button,
      id: 'account-menu-button',
    },
    imgProps: {
      src: profile,
      className: classes.img,
    },
  };

  return (
    <>
      <div className={classes.main}>
        <div {...props.buttonProps}>
          <img
            alt='user profile icon'
            {...props.imgProps}
          />
          <MenuArrow name={'account'} />
        </div>
      </div>
      {account && <UserDropdownMenu />}
    </>
  );
};

export default UserDropdown;
