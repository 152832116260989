import { useContext, useEffect, useState, ChangeEvent, useMemo } from 'react';
import { IChangeImageStates, IFileSelectComplete } from 'helpers/iHelpers';
import { IDocumentContext, ISettingsContext } from 'state/iContext';
import { IDocumentImageProps, IEditFileProps } from 'state/iState';
import { DocumentContext, SettingsContext } from 'state/context';
import { IInputEvent, INumberProps, IStringProps } from 'iApp';
import { nextAvailableKey } from 'helpers/helperFunctions';
import ImageCropHelpers from 'helpers/imageCropHelpers';
import DocumentTextarea from '../DocumentTextarea';
import DocImagesList from './DocImageList';
import ToggleOption from '../ToggleOption';
import DocImageSize from './DocImageSize';
import noImage from 'assets/no-image.jpg';

const DocImages = () => {
  const { fileTypes } = useContext<ISettingsContext | undefined>(
    SettingsContext
  )!;
  const { editFileProps, setEditFileProps, setActiveFilePanel } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;

  const {
    imgSrc,
    setImgSrc,
    setImgSrcExt,
    fileInputRef,
    onFileSelect,
    setFileReader,
    clearToDefault,
    getImageFileExtension,
  } = ImageCropHelpers(noImage);

  const { acceptedImageTypes } = fileTypes;
  const clearImageProps: IDocumentImageProps = {
    image: '',
    customSize: false,
    size: {
      w: 300,
      h: 300,
    },
    label: '',
  };

  const [imageProps, setImageProps] =
    useState<IDocumentImageProps>(clearImageProps);
  const [editID, setEditID] = useState<string>('');

  // Changes/saves the state of the image and file extension
  const changeImageStates = ({ image }: IChangeImageStates): void => {
    setImgSrc(image);
    setImgSrcExt(getImageFileExtension(image));
    setImageProps((prev: IDocumentImageProps) => ({
      ...prev,
      image,
    }));
  };

  // Additional methods to envoke once a file is selected
  const onFileSelectComplete = ({ e, file }: IFileSelectComplete) => {
    setFileReader({ file, changeImageStates });
    clearToDefault(e);
  };

  const list = Object.keys(editFileProps.contents.images.content).map((id) => {
    const { [+id]: toBeRemoved, ...rest } =
      editFileProps.contents.images.content;

    const handler = {
      edit: () => {
        setEditID(id);
        setImageProps({
          image: editFileProps.contents.images.content[id].image,
          customSize: true,
          size: editFileProps.contents.images.content[id].size,
          label: editFileProps.contents.images.content[id].label,
        });
      },
      remove: () =>
        setEditFileProps((prev: IEditFileProps) => ({
          ...prev,
          contents: {
            ...prev.contents,
            images: {
              ...prev.contents.images,
              content: rest,
            },
          },
        })),
    };

    return (
      <DocImagesList
        key={id}
        handler={handler}
        isEditActive={editID === id}
        content={editFileProps.contents.images.content[id]}
      />
    );
  });

  const classes: IStringProps = useMemo(
    () => ({
      panel: 'relative flex flex-col w-full h-full p-0.5',
      title: 'w-full text-center text-lg text-stone-400 font-bold',
      displayed:
        'max-w-[208px] max-h-[208px] bg-stone-100 object-contain self-center items-center justify-center border border-stone-300',
      content:
        'flex w-full bg-stone-100 object-contain self-center items-center justify-around border border-stone-300',
      uploaded: 'p-5 text-center font-bold text-stone-400',
      list: 'flex flex-col w-full h-[250px] items-center overflow-y-auto border',
      folder: 'p-3 text-sm text-blue-500 self-start',
      remove: 'flex w-[250px] justify-center',
      buttonBox: 'relative flex w-full h-1/6 p-2 justify-between',
      buttonsRight: 'flex w-1/2 justify-end',
      button:
        'w-1/6 h-fit p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600 disabled:bg-stone-400',
    }),
    []
  );

  const nextKey: number = nextAvailableKey(
    editFileProps.contents.images.content,
    0
  )!;
  const selectSize: INumberProps = {
    xs: 100,
    sm: 200,
    md: 300,
    lg: 400,
    xl: 500,
  };

  const props = {
    active: {
      name: 'Active Page',
      value: editFileProps.contents.images.active,
      onChange: () =>
        setEditFileProps((prev: IEditFileProps) => ({
          ...prev,
          contents: {
            ...prev.contents,
            images: {
              ...prev.contents.images,
              active: !prev.contents.images.active,
            },
            pages: [...prev.contents.pages, 'images'],
          },
        })),
    },
    img: {
      id: 'uploaded-thumbnail',
      className: classes.displayed,
      src: imgSrc,
    },
    input: {
      type: 'file',
      multiple: false,
      ref: fileInputRef!,
      name: 'photo-input',
      id: 'doc-photo-input',
      accept: acceptedImageTypes,
      className: classes.folder,
      onChange: (e: IInputEvent) => onFileSelect({ e, onFileSelectComplete }),
    },
    size: {
      name: 'Custom Size',
      defaultValue: imageProps.customSize!,
      onChange: () =>
        setImageProps((prev: IDocumentImageProps) => ({
          ...prev,
          customSize: !prev.customSize,
        })),
    },
  };

  useEffect(() => setImageProps(clearImageProps), []);
  useEffect(() => {
    if (!editFileProps.contents.images.active) {
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          pages: prev.contents.pages.filter((str: string) => str !== 'images'),
        },
      }));
    }
  }, [editFileProps.contents.images.active]);

  return (
    <div className={classes.panel}>
      <h1 className={classes.title}>Add Images</h1>
      <ToggleOption {...props.active} />
      <div className={classes.content}>
        {imgSrc && (
          <div className={classes.uploaded}>
            Image to Upload
            <img
              alt='thumbnail'
              {...props.img}
            />
          </div>
        )}
        <div className={classes.list}>
          {list.length === 0 ? <span>No Images Uploaded</span> : <>{list}</>}
        </div>
      </div>
      {imgSrc ? (
        <div className={classes.remove}>
          <button
            className={classes.folder}
            onClick={() => setImgSrc('')}>
            Remove
          </button>
        </div>
      ) : (
        <input {...props.input} />
      )}
      <ToggleOption
        name={'Custom Size'}
        value={imageProps.customSize!}
        onChange={() =>
          setImageProps((prev: IDocumentImageProps) => ({
            ...prev,
            customSize: !prev.customSize,
          }))
        }
      />
      <DocImageSize
        isCustomActive={imageProps.customSize}
        size={imageProps.size}
        handler={{
          w: (e: IInputEvent) =>
            setImageProps((prev: IDocumentImageProps) => ({
              ...prev,
              size: {
                ...prev.size,
                w:
                  e.target.value < 5
                    ? 5
                    : e.target.value > 600
                    ? 600
                    : e.target.value,
              },
            })),
          h: (e: IInputEvent) =>
            setImageProps((prev: IDocumentImageProps) => ({
              ...prev,
              size: {
                ...prev.size,
                h:
                  e.target.value < 5
                    ? 5
                    : e.target.value > 600
                    ? 600
                    : e.target.value,
              },
            })),
          select: (e: ChangeEvent<HTMLSelectElement>) => {
            setImageProps((prev: IDocumentImageProps) => ({
              ...prev,
              size: {
                w: selectSize[e.target.value],
                h: selectSize[e.target.value],
              },
            }));
          },
        }}
      />
      <DocumentTextarea
        name='Image Details'
        value={imageProps.label}
        onChange={(e: IInputEvent) =>
          setImageProps((prev: IDocumentImageProps) => ({
            ...prev,
            label: e.target.value,
          }))
        }
      />
      <div className={classes.buttonBox}>
        <button
          className={classes.button}
          onClick={() => setActiveFilePanel('')}>
          Back
        </button>
        <div className={classes.buttonsRight}>
          {editID && (
            <button
              className={classes.button}
              onClick={() => {
                setImageProps(clearImageProps);
                setEditID('');
              }}>
              Cancel
            </button>
          )}
          <button
            disabled={!imageProps.image || !imageProps.label}
            className={classes.button}
            onClick={() => {
              setEditFileProps((prev: IEditFileProps) => ({
                ...prev,
                contents: {
                  ...prev.contents,
                  images: {
                    ...prev.contents.images,
                    content: {
                      ...prev.contents.images.content,
                      [editID ? editID : nextKey]: {
                        image: imageProps.image,
                        size: imageProps.size,
                        label: imageProps.label,
                      },
                    },
                  },
                },
              }));
              setImageProps(clearImageProps);
              setEditID('');
              setImgSrc('');
            }}>
            {editID ? 'Update' : 'Add'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DocImages;
