import { IHeaderContext, ISettingsContext } from 'state/iContext';
import { HeaderContext, SettingsContext } from 'state/context';
import { IHeaderButtons } from 'state/iState';
import { useAuth0 } from '@auth0/auth0-react';
import { ICallbackProps } from 'iApp';
import { useContext } from 'react';
import {
  IUserDropdownProps,
  IUserDropdownOptionClasses,
} from './iUserDropdown';

const UserDropdownOption = ({ icon, name, id }: IUserDropdownProps) => {
  const { logout } = useAuth0();

  const { setIsSettingsOpen } = useContext<ISettingsContext | undefined>(
    SettingsContext
  )!;
  const { clearActiveButtons, setActiveHeaderButton } = useContext<
    IHeaderContext | undefined
  >(HeaderContext)!;

  // Component classes
  const classes: IUserDropdownOptionClasses = {
    mainClass:
      'border border-slate-200 text-gray-700 block px-4 py-2 text-sm flex transition ease-in-out delay-100 hover:bg-stone-200',
    iconClass: 'menu-icon mr-2',
    nameClass: 'menu-item',
  };

  // Menu button actions
  const clickHandler: ICallbackProps = {
    'My Account': (): void => {
      clearActiveButtons();
      setActiveHeaderButton((prev: IHeaderButtons) => ({
        ...prev,
        settings: true,
      }));
    },
    Settings: (): void => {
      setIsSettingsOpen(true);
      clearActiveButtons();
    },
    Logout: (): void => logout(),
  };

  return (
    <div
      id={id}
      className={classes.mainClass}
      onClick={clickHandler[name as keyof typeof clickHandler]}>
      <div className={classes.iconClass}>{icon}</div>
      <div className={classes.nameClass}>{name}</div>
    </div>
  );
};

export default UserDropdownOption;
