import { ReactNode, useContext, useMemo } from 'react';
import { IDocumentTowerInfo } from 'state/iState';
import { IDocumentContext } from 'state/iContext';
import DocShelterInputs from './DocShelterInputs';
import { IInputEvent, IStringProps } from 'iApp';
import { DocumentContext } from 'state/context';

const DocStructureDetails = () => {
  const { documentTowerInfo, setDocumentTowerInfo } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;
  const { totalShelters } = documentTowerInfo['Shelter Details'];

  const classes: IStringProps = {
    container: 'w-full p-2',
    title: 'font-semibold text-stone-500',
    total: 'flex w-full my-0.5 p-0.5 justify-between',
    label: 'mx-1 text-stone-500 font-bold',
    input: 'p-0 w-1/2 placeholder:px-2',
  };

  const shelters: ReactNode[] = useMemo((): ReactNode[] => {
    let inputsToRender: JSX.Element[] = [];
    for (let rendered = 0; rendered < totalShelters; rendered++) {
      const inputFields: ReactNode = (
        <DocShelterInputs
          shelterID={rendered}
          key={rendered}
        />
      );
      inputsToRender = [...inputsToRender, inputFields];
    }
    return inputsToRender;
  }, [totalShelters]);

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Shelter Details</h1>
      <div className={classes.total}>
        <label
          className={classes.label}
          htmlFor='file-name'>
          Number of Shelters
        </label>
        <input
          min={1}
          type='number'
          defaultValue={1}
          name='shelter-total'
          id='input-shelter-total'
          className={classes.input}
          onChange={(e: IInputEvent) => {
            const newTotalShelters = +e.target.value;
            const newShelters = {
              ...documentTowerInfo['Shelter Details'].shelters,
            };
            if (newTotalShelters > totalShelters) {
              for (let i = totalShelters; i < newTotalShelters; i++) {
                newShelters[i] = {};
              }
            } else {
              for (let i = newTotalShelters; i < totalShelters; i++) {
                delete newShelters[i];
              }
            }
            setDocumentTowerInfo((prev: IDocumentTowerInfo) => ({
              ...prev,
              ['Shelter Details']: {
                ...prev['Shelter Details'],
                totalShelters: newTotalShelters,
                shelters: newShelters,
              },
            }));
          }}
        />
      </div>
      {shelters}
    </div>
  );
};

export default DocStructureDetails;
