import { ICustomIconButtons, ICustomIconButtonsProps } from './iCustomIcon';
import Button from 'components/buttons/Button';
import { ILibrary } from 'state/iState';
import { IStringProps } from 'iApp';
import { useContext } from 'react';
import {
  IUserContext,
  ICropContext,
  IModalContext,
  ILibraryContext,
} from 'state/iContext';
import {
  CropContext,
  UserContext,
  ModalContext,
  LibraryContext,
} from 'state/context';

const CustomIconButtons = ({
  stage,
  setStage,
  editIcon,
}: ICustomIconButtons) => {
  const { activeLibrary, activeIcon, setActiveIcon, setLibraries, iconIndex } =
    useContext<ILibraryContext | undefined>(LibraryContext)!;
  const { setSearchBar } = useContext<IUserContext | undefined>(UserContext)!;
  const { isCropActive } = useContext<ICropContext | undefined>(CropContext)!;
  const { setActiveModal, clearModal, setEditIcon, clearEditIcon } = useContext<
    IModalContext | undefined
  >(ModalContext)!;

  const classes: IStringProps = {
    prev: 'absolute bottom-0 left-0 flex w-1/2 m-5',
    save: 'absolute bottom-0 right-0 flex w-1/2 m-5 justify-end text-center items-end',
  };

  const props: ICustomIconButtonsProps = {
    prev: {
      name: 'PREV',
      handler: () => setStage((prev: number) => --prev),
    },
    cancel: {
      name: 'CANCEL',
      handler: () => {
        setStage(1);
        setSearchBar('');
        setActiveIcon('');
        setActiveModal(clearModal);
        setEditIcon(clearEditIcon);
      },
    },
    next: {
      name: 'NEXT',
      handler: () => {
        setSearchBar('');
        setStage((prev: number) => ++prev);
      },
      disabled: (!activeIcon && !editIcon.img) || !editIcon.id,
    },
    save: {
      name: editIcon.edit ? 'SAVE' : 'SAVE TO',
      handler: () => {
        if (editIcon.edit) {
          setLibraries((prev: ILibrary) => ({
            ...prev,
            [activeLibrary]: {
              ...prev[activeLibrary],
              [iconIndex]: editIcon,
            },
          }));
          setStage(1);
          setActiveIcon('');
          setEditIcon(clearEditIcon);
          setActiveModal(clearModal);
        } else {
          setStage(4);
        }
      },
    },
  };

  return (
    <>
      {stage > 1 && (
        <div className={classes.prev}>
          <Button {...props.prev} />
        </div>
      )}
      <div className={classes.save}>
        {!isCropActive && <Button {...props.cancel} />}
        {!isCropActive && stage < 3 && <Button {...props.next} />}
        {stage === 3 && <Button {...props.save} />}
      </div>
    </>
  );
};

export default CustomIconButtons;
