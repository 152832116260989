import { IEditComment } from './iEdit';

const EditComments = ({ comments, changeHandler }: IEditComment) => {
  return (
    <div className='flex flex-col px-5'>
      <textarea
        name='comments'
        value={comments}
        placeholder='Comments'
        onChange={changeHandler}
        id='edit-tower-comments'
        className='w-full h-[100px] text-start focus:bg-blue-100'
      />
    </div>
  );
};

export default EditComments;