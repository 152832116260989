import { IStringProps } from 'iApp';

const DocImageCapture = ({ imgProps, cropImage, onChange, onClick }) => {
  const classes: IStringProps = {
    container: 'flex w-full justify-around p-2',
    label: 'flex flex-col w-1/2 p-2 items-center',
    input: 'w-full',
    button:
      'p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
  };

  return (
    <>
      <div className={classes.container}>
        <img
          alt='tower thumbnail'
          {...imgProps}
        />
        <div className={classes.label}>
          <label htmlFor='label'>Label</label>
          <input
            type='text'
            name='label'
            onChange={onChange}
            value={cropImage.label}
            className={classes.input}
          />
        </div>
      </div>
      <div>
        <button
          className={classes.button}
          onClick={onClick.save}>
          Save
        </button>
        <button
          className={classes.button}
          onClick={onClick.cancel}>
          Cancel
        </button>
      </div>
    </>
  );
};

export default DocImageCapture;
