import { IGridLayoutProps, ILibraryIcon, ITowerList } from 'state/iState';
import { ReactNode, useCallback, useContext, useMemo } from 'react';
import { ICanvasContext, ISitesContext } from 'state/iContext';
import { CanvasContext, SitesContext } from 'state/context';
import { nextAvailableKey } from 'helpers/helperFunctions';
import GridLayout, { Layout } from 'react-grid-layout';
import { IStringProps } from 'iApp';
import GridAsset from './GridAsset';
import { IImageGridHandlers } from '../../views/grids/iSiteView';

const GridRooftop = ({ image }: IStringProps) => {
  const { activeTowerID, dragProps, setDragProps, openGridItemOptions, scale } =
    useContext<ICanvasContext | undefined>(CanvasContext)!;
  const { setTowerList, towerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;

  const rooftopObject = towerList[activeTowerID].layout.site;
  const rooftopAssets: IGridLayoutProps = rooftopObject.assets;

  const assetList: string[] = Object.keys(rooftopAssets);
  const assets = assetList.map((asset) => rooftopAssets[asset]);

  const nextAssetKey: number = nextAvailableKey(rooftopAssets, 0)!;

  const layoutOnLoad = useMemo(
    () => [
      {
        i: '-1',
        name: '',
        x: 200,
        y: 200,
        w: 0,
        minW: 0,
        minH: 0,
        h: 0,
        static: true,
        isResizable: false,
      },
      ...assets,
    ],
    [assets]
  );

  const classes: IStringProps = {
    img: 'absolute w-full h-full z-10 opacity-50',
    grid: 'relative absolute left-0 top-0 z-20 w-full h-full border border-red-600',
    asset:
      'relative flex flex-nowrap w-full border z-30 border-slate-200 bg-stone-300 object-fit justify-center cursor-all-scroll',
  };

  const canvasAssets: ReactNode[] = layoutOnLoad.map(
    (asset): ReactNode => (
      <div
        key={asset.i}
        data-grid={asset}
        className={classes.asset}
        onClick={() => openGridItemOptions(rooftopAssets[asset.i], 'asset')}
        onTouchStart={() =>
          openGridItemOptions(rooftopAssets[asset.i], 'asset')
        }>
        {asset.i !== '-1' && <GridAsset layout={asset} />}
      </div>
    )
  );

  const updateAssets = useCallback(
    (layout: Layout[]) => {
      let updatedLayout = {};
      layout
        .filter((item, i) => i > 0 && item.i !== '__dropping-elem__')
        .forEach((asset, i) => {
          updatedLayout[i] = {
            ...asset,
            i: i,
            w: rooftopAssets[asset.i].w,
            h: asset.h,
            minH: 5,
            minW: 5,
            name: rooftopAssets[asset.i].name,
            img: rooftopAssets[asset.i].img,
            isDraggable: true,
            isResizable: true,
          };
        });
      return updatedLayout;
    },
    [rooftopAssets]
  );

  const handler: IImageGridHandlers = {
    onLayoutChange: (layout: Layout[]) => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            site: {
              ...prev[activeTowerID].layout.site,
              assets: updateAssets(layout),
            },
          },
        },
      }));
      setDragProps((prev: ILibraryIcon) => ({ ...prev, dragging: false }));
    },
    onDragStart: () => {
      setDragProps((prev: ILibraryIcon) => ({ ...prev, dragging: true }));
    },
    onDrop: async (_, item: Layout, e) => {
      if (dragProps.id) {
        const dropZoneX = e.nativeEvent.offsetX;
        const dropZoneY = e.nativeEvent.offsetY;

        const newPosition = {
          x: item.x === 0 ? Math.floor((dropZoneX * scale) / 7.55) : item.x,
          y: item.y === 0 ? Math.floor((dropZoneY * scale) / 7.55) : item.y,
        };

        await setTowerList((prev: ITowerList) => ({
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            layout: {
              ...prev[activeTowerID].layout,
              site: {
                ...prev[activeTowerID].layout.site,
                assets: {
                  ...prev[activeTowerID].layout.site.assets,
                  [nextAssetKey]: {
                    i: nextAssetKey,
                    name: dragProps.id,
                    img: dragProps.content,
                    x: newPosition.x,
                    y: newPosition.y,
                    w: 5,
                    h: 5,
                    properties: dragProps.properties,
                  },
                },
              },
            },
          },
        }));
      }

      setDragProps({
        id: '',
        img: '',
        ports: {
          w: 10,
          h: 2,
        },
        properties: [],
      });
    },
    onResize: () => {
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: true,
      }));
    },
  };

  return (
    <>
      <img
        className={classes.img}
        alt='aerial view'
        src={image}
      />
      <GridLayout
        cols={200}
        width={1510}
        margin={[0, 0]}
        rowHeight={7.55}
        isBounded={true}
        compactType={null}
        isDraggable={true}
        isDroppable={true}
        isResizable={true}
        allowOverlap={true}
        layout={layoutOnLoad}
        transformScale={scale}
        key='rooftop-grid-layout'
        className={classes.grid}
        onLayoutChange={handler.onLayoutChange}
        onDragStop={handler.onLayoutChange}
        onDragStart={handler.onDragStart}
        onResize={handler.onResize}
        onDrop={handler.onDrop}>
        {canvasAssets}
      </GridLayout>
    </>
  );
};

export default GridRooftop;
