import { ICanvasContext, ISitesContext, IHeaderContext } from 'state/iContext';
import { CanvasContext, SitesContext, HeaderContext } from 'state/context';
import { IAddPanelProps, IAddPanelSaveHandlers } from '../iCanvasPanel';
import { IInputEvent, INumberProps, IStringProps } from 'iApp';
import { nextAvailableKey } from 'helpers/helperFunctions';
import SaveBox from 'components/buttons/SaveBox';
import AddTextBox from '../addGridItem/AddTextBox';
import AddImage from '../addGridItem/AddImage';
import AddLink from '../addGridItem/AddLink';
import EditPanel from './EditPanel';
import { useContext } from 'react';
import {
  ITowerList,
  IGridLayout,
  IAddItemProps,
  ICanvasLayoutSite,
  ICanvasLayoutTower,
} from 'state/iState';

const AddPanel = ({ type }: IStringProps) => {
  const { setTowerList, towerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;
  const { activeCanvasTab } = useContext<IHeaderContext | undefined>(
    HeaderContext
  )!;
  const {
    activeTowerID,
    addItemProps,
    setAddItemProps,
    clearPanel,
    setDragProps,
    clearDragProps,
    scale,
  } = useContext<ICanvasContext | undefined>(CanvasContext)!;

  const addingLink: boolean = type === 'drawings';
  const placeholder: IStringProps = {
    images: 'Image Box Label',
    textBoxes: 'Text Box Label',
    drawings: 'Drawing Reference Label',
  };

  const classes: IStringProps = {
    container:
      'relative flex flex-col h-1/2 m-2 p-2 border rounded rounded-md bg-slate-100',
    title: 'font-bold text-lg text-stone-400',
    labelContainer: 'flex flex-col justify-center m-1',
    label: 'ml-1 font-bold text-stone-500',
    input: 'rounded rounded-md',
    alert: 'text-red-600 font-bold',
    saveBox:
      'absolute flex -right-2 sm:right-0 bottom-5 w-[150px] justify-around sm:mx-5',
  };

  const activeLayouts: ICanvasLayoutSite | ICanvasLayoutTower =
    towerList[activeTowerID].layout[activeCanvasTab];
  const labelList: IGridLayout[] = [
    ...Object.keys(activeLayouts.textBoxes).map(
      (obj) => activeLayouts.textBoxes[obj]
    ),
    ...Object.keys(activeLayouts.images).map(
      (obj) => activeLayouts.images[obj]
    ),
  ];

  const nextKey: INumberProps = {
    images: nextAvailableKey(
      towerList[activeTowerID].layout[activeCanvasTab].images,
      100
    )!,
    textBoxes: nextAvailableKey(
      towerList[activeTowerID].layout[activeCanvasTab].textBoxes,
      200
    )!,
    drawings: nextAvailableKey(
      towerList[activeTowerID].layout[activeCanvasTab].drawings,
      300
    )!,
  };

  const saveGridItem = {
    images: {
      edit: {
        name: addItemProps.label.trim(),
      },
      save: {
        img: addItemProps.content?.toString().trim(),
      },
    },
    textBoxes: {
      edit: {
        name: addItemProps.label.trim(),
        text: addItemProps.content?.toString().trim(),
      },
      save: {
        text: addItemProps.content?.toString().trim(),
      },
    },
    drawings: {
      edit: {
        name: addItemProps.label.trim(),
        refID: addItemProps.content?.toString().trim(),
      },
      save: {
        refID: addItemProps.content?.toString().trim(),
      },
    },
  };

  const isLabelUnique = (label: string, labelList: IGridLayout[]): boolean =>
    labelList
      .filter((item) => item)
      .map((item: IGridLayout) => item.name!)
      .find((str: string) => str === label.trim()) || !label
      ? false
      : true;

  // TODO: build recurrsive function that provides a new render location if initial position already has a similar item
  // const gridItemPositions = Object.keys(
  //   towerList[activeTowerID].layout[activeCanvasTab][type]
  // ).map(
  //   (item) => towerList[activeTowerID].layout[activeCanvasTab][type][item].x
  // );

  const stateSaveHandlers: IAddPanelSaveHandlers = {
    edit: (prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        layout: {
          ...prev[activeTowerID].layout,
          [activeCanvasTab]: {
            ...prev[activeTowerID].layout[activeCanvasTab],
            [type]: {
              ...prev[activeTowerID].layout[activeCanvasTab][type],
              [addItemProps.index!]: {
                ...prev[activeTowerID].layout[activeCanvasTab][type]![
                  addItemProps.index!
                ],
                ...saveGridItem[type].edit,
              },
            },
          },
        },
      },
    }),
    save: (prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        layout: {
          ...prev[activeTowerID].layout,
          [activeCanvasTab]: {
            ...prev[activeTowerID].layout[activeCanvasTab],
            [type]: {
              ...prev[activeTowerID].layout[activeCanvasTab][type]!,
              [nextKey[type]]: {
                i: nextKey[type],
                name: addItemProps.label.trim(),
                x: 350,
                y: 515,
                w: addingLink ? 10 : 20,
                h: addingLink ? 5 : 20,
                maxH: 30,
                maxW: 30,
                minH: addingLink ? 5 : 10,
                minW: addingLink ? 5 : 10,
                resizeHandles: ['se'],
                ...saveGridItem[type].save,
              },
            },
          },
        },
      },
    }),
  };

  const props: IAddPanelProps = {
    input: {
      type: 'text',
      name: 'label',
      id: 'input-label',
      className: classes.input,
      defaultValue: addItemProps.label,
      placeholder: placeholder[type],
      onChange: (e: IInputEvent) =>
        setAddItemProps((prev: IAddItemProps) => ({
          ...prev,
          label: e.target.value,
        })),
    },
    saveBox: {
      classes: classes.saveBox,
      clickHandlers: {
        save: async () => {
          await setTowerList(
            addItemProps.edit ? stateSaveHandlers.edit : stateSaveHandlers.save
          );
          clearPanel();
          setDragProps(clearDragProps);
        },
        cancel: () => {
          clearPanel();
          setDragProps(clearDragProps);
        },
      },
      disabled:
        !isLabelUnique(addItemProps.label, labelList) ||
        !addItemProps.label.trim() ||
        !addItemProps.content,
    },
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>
        Add {type[0].toUpperCase() + type.slice(1)}{' '}
        {addingLink && <span>Link</span>}
      </h1>
      <div className={classes.labelContainer}>
        <label
          htmlFor='label'
          className={classes.label}>
          Label:
        </label>
        <input {...props.input} />
        {!isLabelUnique(addItemProps.label, labelList) && (
          <span className={classes.alert}>Please choose a unique label</span>
        )}
      </div>
      {type === 'textBoxes' && <AddTextBox />}
      {type === 'images' && <AddImage />}
      {addingLink && <AddLink />}
      {type === 'edit' && <EditPanel />}
      <SaveBox {...props.saveBox} />
    </div>
  );
};

export default AddPanel;
