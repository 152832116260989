import { ReactNode, useContext, useEffect, useMemo } from 'react';
import { nextAvailableKey } from 'helpers/helperFunctions';
import { ICallbackProps, IStringProps } from 'iApp';
import { IDocumentContext } from 'state/iContext';
import { DocumentContext } from 'state/context';
import { IEditFileProps } from 'state/iState';
import ToggleOption from '../ToggleOption';
import PageInput from './PageInput';

const TableOfContents = () => {
  const { editFileProps, setEditFileProps, setActiveFilePanel } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;

  const { content, active } = editFileProps.contents.tableOfContents;
  const nextKey: number = nextAvailableKey(content, 0)!;
  const tableLines: string[] = Object.keys(content);

  const pagePosition: string[] =
    editFileProps.contents.pages[0] === 'front'
      ? editFileProps.contents.pages.splice(1, 0, 'table')
      : ['table', ...editFileProps.contents.pages];

  const classes: IStringProps = useMemo(
    () => ({
      container: 'flex flex-col justify-start w-full h-2/3 max-h-2/3',
      title: 'w-full text-center text-lg text-stone-400 font-bold',
      contents: 'flex flex-col justify-between w-full h-full',
      inputs: 'w-full max-h-[90%] min-h-[75%] overflow-y-auto',
      buttonBox: 'relative w-full h-1/6',
      add: 'absolute z-20 flex w-1/6 right-0 bottom-1/2 justify-center items-center p-1 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
      icon: 'fa-solid fa-circle-plus mr-2',
      back: 'absolute flex w-1/6 right-0 bottom-0 justify-around p-1 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
    }),
    []
  );

  useEffect(() => {
    if (tableLines.length === 0) {
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          pages: pagePosition,
          tableOfContents: {
            ...prev.contents.tableOfContents,
            content: {
              0: {
                name: '',
                page: '',
              },
            },
          },
        },
      }));
    }
  }, [tableLines, setEditFileProps, pagePosition]);

  useEffect(() => {
    if (!active) {
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          pages: prev.contents.pages.filter((str) => str !== 'table'),
        },
      }));
    }
  }, [active, setEditFileProps]);

  const clickHandler: ICallbackProps = {
    add: () =>
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          tableOfContents: {
            ...prev.contents.tableOfContents,
            content: {
              ...prev.contents.tableOfContents.content,
              [nextKey]: {
                name: '',
                page: '',
              },
            },
          },
        },
      })),
    back: () => {
      setActiveFilePanel('');
    },
  };

  const inputs: ReactNode[] = useMemo(
    () =>
      tableLines.map((line) => (
        <PageInput
          line={Number(line)}
          key={+line}
        />
      )),
    [tableLines]
  );

  return (
    <>
      <div className={classes.container}>
        <h1 className={classes.title}>Table Of Contents</h1>
        <div className={classes.contents}>
          <ToggleOption
            name='Active'
            value={editFileProps.contents.tableOfContents.active}
            onChange={() =>
              setEditFileProps((prev: IEditFileProps) => ({
                ...prev,
                contents: {
                  ...prev.contents,
                  pages: pagePosition,
                  tableOfContents: {
                    ...prev.contents.tableOfContents,
                    active: !prev.contents.tableOfContents.active,
                  },
                },
              }))
            }
          />
          <div className={classes.inputs}>{inputs}</div>
          <div className={classes.buttonBox}>
            <button
              className={classes.add}
              onClick={clickHandler.add}>
              <i className={classes.icon} />
              <span>New Line</span>
            </button>
          </div>
        </div>
      </div>
      <div className={classes.buttonBox}>
        <button
          className={classes.back}
          onClick={clickHandler.back}>
          Back
        </button>
      </div>
    </>
  );
};

export default TableOfContents;
