import { highlightSearch } from 'helpers/helperFunctions';
import { IInputEvent, IStringProps } from 'iApp';
import FileListButtons from './FileListButtons';
import MoveFolder from '../folders/MoveFolder';
import { IEditFileProps } from 'state/iState';
import { IFileLine } from '../../iTowerFiles';
import { useContext, useMemo } from 'react';
import {
  IDocumentContext,
  ICanvasContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import {
  DocumentContext,
  CanvasContext,
  SitesContext,
  UserContext,
} from 'state/context';
import DatePicker from 'react-datepicker';

const FileLine = ({ id, file }: IFileLine) => {
  const { towerList } = useContext<ISitesContext | undefined>(SitesContext)!;
  const { searchBar } = useContext<IUserContext | undefined>(UserContext)!;
  const { editFileProps, setEditFileProps, setActiveFile, updateFolders } =
    useContext<IDocumentContext | undefined>(DocumentContext)!;
  const { activeTowerID } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;

  const classes: IStringProps = {
    container: 'flex w-full border border-stone-400 even:bg-slate-100',
    details: 'flex w-full text-center',
    input: 'w-full h-full p-0',
    picker: 'w-full text-center p-0',
    name: 'w-5/6 text-center hover:text-red-600 hover:font-bold',
    upload: 'w-1/6 text-center',
    date: 'w-1/6 ml-2',
    license: 'relative flex w-1/6 items-center',
    label:
      'absolute -left-32 m-1 h-[90%] font-sm text-green-600 font-bold bg-inherit rounded',
    expiry: 'flex mx-1 justify-between',
    end: 'flex w-1/6 justify-between',
  };

  const isActive: boolean = useMemo(
    () =>
      towerList[activeTowerID].dates.licenses.some(
        (file: string) => +file === +id
      ),
    [towerList[activeTowerID].dates.licenses]
  );

  const handler = {
    click: () => {
      file.isPDF
        ? setActiveFile(id)
        : setEditFileProps((prev: IEditFileProps) => ({
            ...prev,
            type: 'doc',
            id,
            contents: towerList[activeTowerID].documents.files[id].file,
          }));
    },
    change: (e: IInputEvent) =>
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          name: e.target.value,
        },
      })),
  };

  return (
    <div className={classes.container}>
      {+updateFolders.name === +id && updateFolders.move ? (
        <MoveFolder type='file' />
      ) : (
        <>
          {editFileProps.id === id ? (
            <div className={classes.details}>
              <input
                type='text'
                className={classes.input}
                defaultValue={editFileProps.contents.name}
                onChange={handler.change}
              />
              {file.expiry && (
                <div className='w-1/6 mr-6'>
                  <DatePicker
                    isClearable={true}
                    allowSameDay={true}
                    closeOnScroll={true}
                    name='search-file-date'
                    className={classes.picker}
                    placeholderText='File Expiry'
                    selected={
                      editFileProps.contents.date
                        ? new Date(editFileProps.contents.date)
                        : file.expiry
                        ? new Date(file.expiry)
                        : null
                    }
                    onChange={(updatedDate) => {
                      // To convert the updatedDate to a UTC timestamp
                      const utcTimestamp = updatedDate
                        ? new Date(updatedDate).getTime() -
                          new Date(updatedDate).getTimezoneOffset() * 60000
                        : null;
                      setEditFileProps((prev: IEditFileProps) => ({
                        ...prev,
                        contents: {
                          ...prev.contents,
                          date: utcTimestamp,
                        },
                      }));
                    }}
                  />
                </div>
              )}
            </div>
          ) : (
            <div className={classes.details}>
              <div
                className={classes.name}
                onClick={handler.click}
                onTouchStart={handler.click}>
                {highlightSearch(file.name, searchBar)}
              </div>
              <div className={classes.license}>
                {isActive && (
                  <div
                    id={`active-license-${id}`}
                    className={classes.label}>
                    Active License
                  </div>
                )}
                {file.expiry && (
                  <div
                    id={`file-expiry-${id}`}
                    className={classes.expiry}>
                    <span className='mr-2'>Expiry:</span>
                    <span>
                      {new Date(file.expiry).toUTCString().slice(4, 16)}
                    </span>
                  </div>
                )}
              </div>
              <div className={classes.date}>
                {new Date(file.upload).toUTCString().slice(4, 16)}
              </div>
            </div>
          )}
        </>
      )}
      <FileListButtons
        parentID={file.folder}
        isActive={editFileProps.id === id ? 'edit' : 'static'}
        id={id}
      />
    </div>
  );
};

export default FileLine;
