import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { ReactNode, useMemo } from 'react';

const PDFDetails = ({ content, name }) => {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        section: {
          width: '49%',
          margin: '0.5%',
        },
        title: {
          fontWeight: 'bold',
          textDecoration: 'underline',
          fontSize: '12px',
        },
        details: {
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '2px',
          fontSize: '10px',
        },
      }),
    []
  );

  const lines: ReactNode[] = useMemo(
    () =>
      Object.keys(content).map(
        (line: string): ReactNode => (
          <View
            key={line}
            style={styles.details}>
            <Text style={styles.details}>{line}:</Text>
            <Text style={styles.details}>
              {content[line] ? content[line] : 'N/A'}
            </Text>
          </View>
        )
      ),
    [content]
  );

  return (
    <View style={styles.section}>
      <Text style={styles.title}>{name}</Text>
      {lines}
    </View>
  );
};

export default PDFDetails;
