// // PDF Files
// const file1 = require('../assets/files/InspectionReport.pdf');
// const file2 = require('../assets/files/RadioLicense.pdf');
import { ICompany, ILibraryList, ILibrary, ITowerList, IUser } from './iState';

import companyLogo from '../assets/user/company-logo-placeholder.png';
import profilePhoto from '../assets/user/stockProfile.jpg';
import antennaA2 from '../assets/towers/antenna A2.png';

// Tower Images
import guyed from '../assets/siteTypes/guyed.jpg';
import fourLeg from '../assets/siteTypes/4-leg.jpg';
import threeLeg from '../assets/siteTypes/3-leg.jpg';
import rooftop from '../assets/siteTypes/rooftop.jpg';
import monopole from '../assets/siteTypes/monopole.jpg';

// Section Images
import floorPlan from '../assets/towers/floor-plan.png';
import sectionImage from '../assets/towers/section-image.png';

// Rooftop Images
import rooftop4 from '../assets/rooftop/rooftop4.png';
import rooftop8 from '../assets/rooftop/rooftop8.png';

// Misc Icons
import alarm from '../assets/libraryIcons/Misc/alarm.svg';
import antenna from '../assets/libraryIcons/Misc/antenna.svg';
import battery from '../assets/libraryIcons/Misc/battery.svg';
import beacon from '../assets/libraryIcons/Misc/beacon.svg';
import camera from '../assets/libraryIcons/Misc/camera.svg';
import ceilingLight from '../assets/libraryIcons/Misc/ceilingLight.svg';
import deskPhone from '../assets/libraryIcons/Misc/deskPhone.svg';
import fax from '../assets/libraryIcons/Misc/fax.svg';
import firewall from '../assets/libraryIcons/Misc/firewall.svg';
import headset from '../assets/libraryIcons/Misc/headset.svg';
import satellite from '../assets/libraryIcons/Misc/satellite.svg';
import scanner from '../assets/libraryIcons/Misc/scanner.svg';
import speaker from '../assets/libraryIcons/Misc/speaker.svg';
import solar from '../assets/libraryIcons/Misc/solar.svg';
import spotlight from '../assets/libraryIcons/Misc/spotlight.svg';

// Shapes Icons
import arrowDown from '../assets/libraryIcons/Shapes/arrowDown.svg';
import arrowLeft from '../assets/libraryIcons/Shapes/arrowLeft.svg';
import arrowRight from '../assets/libraryIcons/Shapes/arrowRight.svg';
import arrowUp from '../assets/libraryIcons/Shapes/arrowUp.svg';
import circle from '../assets/libraryIcons/Shapes/circle.svg';
import cube from '../assets/libraryIcons/Shapes/cube.svg';
import cylinder from '../assets/libraryIcons/Shapes/cylinder.svg';
import electric from '../assets/libraryIcons/Shapes/electric.svg';
import hexagon from '../assets/libraryIcons/Shapes/hexagon.svg';
import hexagon3D from '../assets/libraryIcons/Shapes/hexagon3D.svg';
import octagon from '../assets/libraryIcons/Shapes/octagon.svg';
import oval from '../assets/libraryIcons/Shapes/oval.svg';
import pentagon from '../assets/libraryIcons/Shapes/pentagon.svg';
import pyramid from '../assets/libraryIcons/Shapes/pyramid.svg';
import rectangle from '../assets/libraryIcons/Shapes/rectangle.svg';
import rectangle3D from '../assets/libraryIcons/Shapes/rectangle3D.svg';
import refresh from '../assets/libraryIcons/Shapes/refresh.svg';
import rhombus from '../assets/libraryIcons/Shapes/rhombus.svg';
import semicircle from '../assets/libraryIcons/Shapes/semicircle.svg';
import starGray from '../assets/libraryIcons/Shapes/starGrey.svg';
import starRed from '../assets/libraryIcons/Shapes/starRed.svg';
import triangle from '../assets/libraryIcons/Shapes/triangle.svg';

// Rigstar Icons
import twoWaySplitter from '../assets/libraryIcons/Rigstar/2-Way Splitter.svg';
import threeWaySplitter from '../assets/libraryIcons/Rigstar/3-Way Splitter.svg';
import airMaxBaseStation from '../assets/libraryIcons/Rigstar/AirMax AC BaseStation.jpg';
import apc1000Va from '../assets/libraryIcons/Rigstar/APC 1000VA.jpg';
import apcUpc from '../assets/libraryIcons/Rigstar/APC UPS.jpg';
import arubaAp from '../assets/libraryIcons/Rigstar/Aruba AP.jpg';
import axisIntercom from '../assets/libraryIcons/Rigstar/Axis I8016 Intercom.png';
import barionet from '../assets/libraryIcons/Rigstar/Barionet 50.png';
import rigstarBattery from '../assets/libraryIcons/Rigstar/Battery.svg';
import building from '../assets/libraryIcons/Rigstar/Building.jpg';
import cambiumRadio from '../assets/libraryIcons/Rigstar/Cambium  Radio.jpg';
import cambiumPolarDish from "../assets/libraryIcons/Rigstar/Cambium - 2' Dual Polar Dish.jpg";
import cambiumDish from '../assets/libraryIcons/Rigstar/Cambium Dish.jpg';
import cambiumPmp from '../assets/libraryIcons/Rigstar/Cambium PMP.png';
import cambiumPtp from '../assets/libraryIcons/Rigstar/Cambium PTP.png';
import cargoContainer from '../assets/libraryIcons/Rigstar/Cargo Container.png';
import cellBooster from '../assets/libraryIcons/Rigstar/Cell Booster (Internal).jpg';
import cellBoosterSystem from '../assets/libraryIcons/Rigstar/Cell Booster System (Internal).png';
import cellTower from '../assets/libraryIcons/Rigstar/Cell Tower.svg';
import cellular from '../assets/libraryIcons/Rigstar/Cellular.png';
import cisco2960 from '../assets/libraryIcons/Rigstar/Cisco 2960-8TC-L.jpg';
import cisco2960G8 from '../assets/libraryIcons/Rigstar/Cisco 2960G-8TC-L.jpg';
import cisco2960G24 from '../assets/libraryIcons/Rigstar/Cisco 2960G-24TC-L.jpg';
import cisco2960S24 from '../assets/libraryIcons/Rigstar/Cisco 2960S-24PS-L.jpg';
import cisco2960S48 from '../assets/libraryIcons/Rigstar/Cisco 2960S-48FPS-L.jpg';
import cisco3560C from '../assets/libraryIcons/Rigstar/Cisco 3560C-8PC-S.jpg';
import cisco3560CG from '../assets/libraryIcons/Rigstar/Cisco 3560CG-8PC-S.jpg';
import cisco3750 from '../assets/libraryIcons/Rigstar/Cisco 3750X-48T-S.jpg';
import cisco4900 from '../assets/libraryIcons/Rigstar/Cisco 4900M.jpg';
import cisco4948GE from '../assets/libraryIcons/Rigstar/Cisco 4948-10GE.jpg';
import cisco4948 from '../assets/libraryIcons/Rigstar/Cisco 4948.jpg';
import ciscoAir from '../assets/libraryIcons/Rigstar/Cisco Air-CT2504-15-K9.jpg';
import ciscoCatalyst from '../assets/libraryIcons/Rigstar/Cisco Catalyst - 8P.jpg';
import ciscoIndoorAp from '../assets/libraryIcons/Rigstar/Cisco Indoor AP.jpg';
import ciscoRouter from '../assets/libraryIcons/Rigstar/Cisco Router 2911.jpg';
import ciscoSwitch from '../assets/libraryIcons/Rigstar/Cisco Switch-Catalyst-2960s.jpg';
import communicationCabinet from '../assets/libraryIcons/Rigstar/Communications Cabinet.svg';
import concreteBlock from '../assets/libraryIcons/Rigstar/Concrete Blocks for Guy Wires.gif';
import demarkSwitch from '../assets/libraryIcons/Rigstar/Demark Switch.png';
import directionalAntenna from '../assets/libraryIcons/Rigstar/Directional Antenna.svg';
import directionalPanel from '../assets/libraryIcons/Rigstar/Directional Panel Antenna.svg';
import dragonWave from '../assets/libraryIcons/Rigstar/Dragonwave 18G.jpg';
import drillingRig from '../assets/libraryIcons/Rigstar/Drilling Rig.png';
import duplexer from '../assets/libraryIcons/Rigstar/Duplexer.svg';
import gigabitSwitch from '../assets/libraryIcons/Rigstar/Gigabit 8-port Switch.jpg';
import internetAccess from '../assets/libraryIcons/Rigstar/Internet Access.png';
import internet from '../assets/libraryIcons/Rigstar/Internet.png';
import iridiumAntenna from '../assets/libraryIcons/Rigstar/Iridium Antenna.jpg';
import kuBand from '../assets/libraryIcons/Rigstar/Ku Band.jpg';
import laptop from '../assets/libraryIcons/Rigstar/Laptop.png';
import lteAntenna from '../assets/libraryIcons/Rigstar/LTE Antenna.jpg';
import merakiAp from '../assets/libraryIcons/Rigstar/Meraki AP.jpg';
import merakiMr32Indoor from '../assets/libraryIcons/Rigstar/Meraki MR32 Indoor AP.jpg';
import merakiMr32 from '../assets/libraryIcons/Rigstar/Meraki MR32.jpg';
import merakiMr72 from '../assets/libraryIcons/Rigstar/Meraki MR72.jpg';
import merakiMs220 from '../assets/libraryIcons/Rigstar/Meraki MS220-8P.jpg';
import merakiMx64 from '../assets/libraryIcons/Rigstar/Meraki MX64.jpg';
import merakiMx64W from '../assets/libraryIcons/Rigstar/Meraki MX64W.jpg';
import merakiMx80 from '../assets/libraryIcons/Rigstar/Meraki MX80 Router.jpg';
import merakiMx84 from '../assets/libraryIcons/Rigstar/Meraki MX84 Router.jpg';
import merakiMx100 from '../assets/libraryIcons/Rigstar/Meraki MX100 Router.jpg';
import merakiWifi from '../assets/libraryIcons/Rigstar/Meraki Wi-Fi Router.jpg';
import microhard from '../assets/libraryIcons/Rigstar/Microhard IPN3G.png';
import microwaveAntenna from '../assets/libraryIcons/Rigstar/Microwave Antenna - Left.jpg';
import microwaveTower from '../assets/libraryIcons/Rigstar/Microwave Tower.svg';
import mikrotikHexlite from '../assets/libraryIcons/Rigstar/Mikrotik hEXlite.jpg';
import mikrotikVpn from '../assets/libraryIcons/Rigstar/Mikrotik VPN.svg';
import mimosaA5 from '../assets/libraryIcons/Rigstar/Mimosa - A5 AP.jpg';
import mimosaC5 from '../assets/libraryIcons/Rigstar/Mimosa - C5 SM.jpg';
import mimosaB5 from '../assets/libraryIcons/Rigstar/Mimosa B5 Backhaul.jpg';
import gangCharger from '../assets/libraryIcons/Rigstar/Motorola Gang Charger.svg';
import nonPenetratingMount from '../assets/libraryIcons/Rigstar/Non-Penetrating Roof Mount.png';
import outdoorSpeaker from '../assets/libraryIcons/Rigstar/Outdoor Speaker.png';
import voipDoorSpeaker from '../assets/libraryIcons/Rigstar/Outdoor VoIP Door Speaker.jpg';
import voipSpeaker from '../assets/libraryIcons/Rigstar/Outdoor VoIP Speaker.jpg';
import panelAntenna from '../assets/libraryIcons/Rigstar/Panel Antenna.jpg';
import patchPanel8 from '../assets/libraryIcons/Rigstar/Patch Panel (8 Port).png';
import patchPanel24 from '../assets/libraryIcons/Rigstar/Patch Panel (24 port).svg';
import peplinkBalanceOne from '../assets/libraryIcons/Rigstar/Peplink - Balance One.png';
import peplinkAP from '../assets/libraryIcons/Rigstar/Peplink AP.jpg';
import peplinkBalace580 from '../assets/libraryIcons/Rigstar/Peplink Balance 580.jpg';
import pepwaveMax from '../assets/libraryIcons/Rigstar/Pepwave Max700.jpg';
import pepWave from '../assets/libraryIcons/Rigstar/Pepwave.jpg';
import pipemast from '../assets/libraryIcons/Rigstar/Pipemast.png';
import poe from '../assets/libraryIcons/Rigstar/PoE.jpg';
import polycomPhone from '../assets/libraryIcons/Rigstar/Polycom Conference Phone IP6000.jpg';
import portableTower from '../assets/libraryIcons/Rigstar/Portable Tower.png';
import portable from '../assets/libraryIcons/Rigstar/Portable.svg';
import potsPhone from '../assets/libraryIcons/Rigstar/POTS Phone.png';
import powerBeamAC from '../assets/libraryIcons/Rigstar/PowerBeam AC -ISO.jpg';
import printerOne from '../assets/libraryIcons/Rigstar/Printer 1.png';
import printerTwo from '../assets/libraryIcons/Rigstar/Printer 2.png';
import pt3N from '../assets/libraryIcons/Rigstar/PT3N Hybrid Coupler.jpg';
import pt4N from '../assets/libraryIcons/Rigstar/PT4.8N Hybrid Coupler.jpg';
import pt6N from '../assets/libraryIcons/Rigstar/PT6N Hybrid Coupler.jpg';
import pt8N from '../assets/libraryIcons/Rigstar/PT8N Hybrid Coupler.jpg';
import pt10 from '../assets/libraryIcons/Rigstar/PT10 Hybrid Coupler.jpg';
import ptp650 from '../assets/libraryIcons/Rigstar/PTP 650 - Connectorized.jpg';
import ptp820 from '../assets/libraryIcons/Rigstar/PTP820.png';
import radioBattery from '../assets/libraryIcons/Rigstar/Radio Battery.jpg';
import rigweb from '../assets/libraryIcons/Rigstar/Rigweb.jpg';
import rst100 from '../assets/libraryIcons/Rigstar/RST100 Terminal - Satellite Phones.jpg';
import rst970 from '../assets/libraryIcons/Rigstar/RST970 - Intelligent Handset - Sat.jpg';
import satelliteArm from '../assets/libraryIcons/Rigstar/Satellite Arm.jpg';
import satelliteInternet from '../assets/libraryIcons/Rigstar/Satellite Internet - Viasat.jpg';
import skidShack from '../assets/libraryIcons/Rigstar/Skid Shack.png';
import speakerMic from '../assets/libraryIcons/Rigstar/Speaker Mic.jpg';
import starlink from '../assets/libraryIcons/Rigstar/Starlink Subscriber.jpg';
import surecallForce from '../assets/libraryIcons/Rigstar/Surecall Force 5 Cell Booster.png';
import surgeProtector from '../assets/libraryIcons/Rigstar/Surge Protector.png';
import surveillanceCamera from '../assets/libraryIcons/Rigstar/Surveillance Camera.svg';
import switch4 from '../assets/libraryIcons/Rigstar/Switch.svg';
import switch8 from '../assets/libraryIcons/Rigstar/Switch (8 Port).png';
import switch16 from '../assets/libraryIcons/Rigstar/Switch (16 Port).svg';
import tcpl from '../assets/libraryIcons/Rigstar/TCPL - 2 Trailer Template.png';
import shelter from '../assets/libraryIcons/Rigstar/Telecommunications Shelter.svg';
import tripodMount from '../assets/libraryIcons/Rigstar/Tripod Mount.jpg';
import trylonTitan from '../assets/libraryIcons/Rigstar/Trylon Super Titan.png';
import trylonTower from '../assets/libraryIcons/Rigstar/Trylon Tower.jpg';
import uapPro from '../assets/libraryIcons/Rigstar/UAP Pro WiFi AP.jpg';
import ubiquitiFibre from '../assets/libraryIcons/Rigstar/Ubiquiti Air Fibre.jpg';
import ubiquitiRocket from '../assets/libraryIcons/Rigstar/Ubiquiti Rocket Prism.jpg';
import ubnt8Poe from '../assets/libraryIcons/Rigstar/UBNT - 8 Port PoE+ - SFP.png';
import ubnt16Agg from '../assets/libraryIcons/Rigstar/UBNT - 16 Port Aggregation Switch.png';
import ubnt16Poe from '../assets/libraryIcons/Rigstar/UBNT - 16 Port PoE SFP - 150W.jpg';
import ubntCloud from '../assets/libraryIcons/Rigstar/UBNT - Cloud Key.png';
import ubntGateway from '../assets/libraryIcons/Rigstar/UBNT - Gateway Security-Router - USGPro4.png';
import ubntNanobeamLeft from '../assets/libraryIcons/Rigstar/UBNT - NanoBeam Left.png';
import ubntNanoBeamRight from '../assets/libraryIcons/Rigstar/UBNT - NanoBeam Right.png';
import unifiSwitch8 from '../assets/libraryIcons/Rigstar/Unifi - 8 Port Switch - PoE.png';
import unifiSwitch24 from '../assets/libraryIcons/Rigstar/Unifi - 24 Port Switch - PoE.png';
import unifiCloud from '../assets/libraryIcons/Rigstar/Unifi - Cloud Key.png';
import unifiSecurityLarge from '../assets/libraryIcons/Rigstar/Unifi - Security - Large.png';
import unifiSecuritySmall from '../assets/libraryIcons/Rigstar/Unifi - Security - Small.jpg';
import unifiUapPro from '../assets/libraryIcons/Rigstar/Unifi - UAP Pro AP.jpeg';
import ups685 from '../assets/libraryIcons/Rigstar/UPS - 685.jpg';
import ups1500Apc from '../assets/libraryIcons/Rigstar/UPS - 1500 APC V2.jpg';
import ups1500Mah from '../assets/libraryIcons/Rigstar/UPS - 1500 mAh.jpg';
import upsPowerBackup from '../assets/libraryIcons/Rigstar/UPS - Power Backup.jpg';
import upsSmall from '../assets/libraryIcons/Rigstar/UPS (Small).jpg';
import upsRackMount from '../assets/libraryIcons/Rigstar/UPS 1U Rack Mount - Tripplite.jpg';
import voipPhone from '../assets/libraryIcons/Rigstar/VoIP Phone.svg';
import wifiRouter from '../assets/libraryIcons/Rigstar/Wifi Router.svg';
import wifi from '../assets/libraryIcons/Rigstar/Wifi.svg';
import wirelessMiniRepeater from '../assets/libraryIcons/Rigstar/Wireless Mini Repeater.svg';
import yagiAntenna from '../assets/libraryIcons/Rigstar/Yagi Antenna.jpg';
import yealinkMicrophone from '../assets/libraryIcons/Rigstar/Yealink - Expandable Microphone.jpg';
import yealink860 from '../assets/libraryIcons/Rigstar/Yealink 860 Conference Phone.png';
import yealinkT41 from '../assets/libraryIcons/Rigstar/Yealink T41S.png';
import yealinkT46 from '../assets/libraryIcons/Rigstar/Yealink T46S.jpg';

export const company: ICompany = {
  name: '**COMPANY NAME**',
  logo: companyLogo,
  libraries: { Misc: true, 'Two-Way Radio': true, 'Video Surveillence': false },
  jobTitles: [
    'Owner',
    'Manager',
    'Support',
    'Customer Care',
    'Software Engineer',
    'Field Crew',
  ],
};

export const standardLibraries: ILibraryList = { Rigstar: true, Shapes: true };

export const userJohn: IUser = {
  first: 'John',
  last: 'Smith',
  title: 'Software Engineer',
  email: 'email@email.com',
  phone: {
    primary: '11111111111',
    mobile: '22222222222',
    ext: '333',
  },
  company: '**COMPANY NAME**',
  logo: companyLogo,
  image: profilePhoto,
  favorites: [1, 2, 3, 9],
  library: { 'John Smith': true },
};

export const towers: ITowerList = {
  1: {
    towerId: 1,
    code: '00001',
    comments: 'Lorem ipsum dolor',
    company: 'Company Name #1 C o m p a n y N a m e',
    contact: {
      name: 'Alice Arcitect',
      phone: 4031111111,
      email: 'alice1231231@alice.com',
    },
    geoCode: 'Calgary, AB T2A 7X4, Canada',
    height: 50,
    img: fourLeg,
    location: { lat: 51.048066, lng: -113.91836 },
    name: 'Alpha',
    type: { site: 'Guyed', tower: '4 Leg' },
    dates: {
      inspection: 1675209600000,
      licenses: [1, 29],
    },
    documents: {
      files: {
        0: {
          name: 'Alpha Inspection Report',
          folder: 3,
          upload: 1677000000000,
          file: require('../assets/files/InspectionReport.pdf'),
          isPDF: true,
        },
        1: {
          name: 'Alpha Radio License',
          folder: 2,
          upload: 1677000000000,
          expiry: 1691300413773,
          file: require('../assets/files/RadioLicense.pdf'),
          isPDF: true,
        },
        2: {
          name: 'Bravo Inspection Report',
          folder: 3,
          upload: 1677000000000,
          file: require('../assets/files/InspectionReport.pdf'),
          isPDF: true,
        },
        3: {
          name: 'Bravo Radio License',
          folder: 2,
          upload: 1677000000000,
          expiry: 1677000000000,
          file: require('../assets/files/RadioLicense.pdf'),
          isPDF: true,
        },
        4: {
          name: 'Charlie Inspection Report',
          folder: 3,
          upload: 1677000000000,
          file: require('../assets/files/InspectionReport.pdf'),
          isPDF: true,
        },
        5: {
          name: 'Charlie Radio License',
          folder: 2,
          upload: 1677000000000,
          expiry: 1677000000000,
          file: require('../assets/files/RadioLicense.pdf'),
          isPDF: true,
        },
        6: {
          name: 'Delta Inspection Report',
          folder: 3,
          upload: 1676100000000,
          file: require('../assets/files/InspectionReport.pdf'),
          isPDF: true,
        },
        7: {
          name: 'Delta Radio License',
          folder: 2,
          upload: 1676100000000,
          expiry: 1676100000000,
          file: require('../assets/files/RadioLicense.pdf'),
          isPDF: true,
        },
        8: {
          name: 'Echo Inspection Report',
          folder: 3,
          upload: 1676100000000,
          file: require('../assets/files/InspectionReport.pdf'),
          isPDF: true,
        },
        9: {
          name: 'Echo Radio License',
          folder: 2,
          upload: 1676100000000,
          expiry: 1676100000000,
          file: require('../assets/files/RadioLicense.pdf'),
          isPDF: true,
        },
        10: {
          name: 'Foxtrot Inspection Report',
          folder: 3,
          upload: 1678000000000,
          file: require('../assets/files/InspectionReport.pdf'),
          isPDF: true,
        },
        11: {
          name: 'Foxtrot Radio License',
          folder: 2,
          upload: 1678000000000,
          expiry: 1678000000000,
          file: require('../assets/files/RadioLicense.pdf'),
          isPDF: true,
        },
        12: {
          name: 'Golf Inspection Report',
          folder: 3,
          upload: 1678000000000,
          file: require('../assets/files/InspectionReport.pdf'),
          isPDF: true,
        },
        13: {
          name: 'Golf Radio License',
          folder: 2,
          upload: 1679000000000,
          expiry: 1679000000000,
          file: require('../assets/files/RadioLicense.pdf'),
          isPDF: true,
        },
        14: {
          name: 'Hotel Inspection Report',
          folder: 3,
          upload: 1679000000000,
          file: require('../assets/files/InspectionReport.pdf'),
          isPDF: true,
        },
        15: {
          name: 'Hotel Radio License',
          folder: 2,
          upload: 1679000000000,
          expiry: 1679000000000,
          file: require('../assets/files/RadioLicense.pdf'),
          isPDF: true,
        },
        16: {
          name: 'India Inspection Report',
          folder: 3,
          upload: 1679000000000,
          file: require('../assets/files/InspectionReport.pdf'),
          isPDF: true,
        },
        17: {
          name: 'India Radio License',
          folder: 2,
          upload: 1678000000000,
          expiry: 1678000000000,
          file: require('../assets/files/RadioLicense.pdf'),
          isPDF: true,
        },
        18: {
          name: 'Juliet Inspection Report',
          folder: 3,
          upload: 1678000000000,
          file: require('../assets/files/InspectionReport.pdf'),
          isPDF: true,
        },
        19: {
          name: 'Juliet Radio License',
          folder: 2,
          upload: 1678000000000,
          expiry: 1678000000000,
          file: require('../assets/files/RadioLicense.pdf'),
          isPDF: true,
        },
        20: {
          name: 'Kilo Inspection Report',
          folder: 3,
          upload: 1678000000000,
          file: require('../assets/files/InspectionReport.pdf'),
          isPDF: true,
        },
        21: {
          name: 'Kilo Radio License',
          folder: 2,
          upload: 1678000000000,
          expiry: 1678000000000,
          file: require('../assets/files/RadioLicense.pdf'),
          isPDF: true,
        },
        22: {
          name: 'Lima Inspection Report',
          folder: 3,
          upload: 1678000000000,
          file: require('../assets/files/InspectionReport.pdf'),
          isPDF: true,
        },
        23: {
          name: 'Lima Radio License',
          folder: 2,
          upload: 1678000000000,
          expiry: 1678000000000,
          file: require('../assets/files/RadioLicense.pdf'),
          isPDF: true,
        },
        24: {
          name: 'Mike Inspection Report',
          folder: 3,
          upload: 1678000000000,
          file: require('../assets/files/InspectionReport.pdf'),
          isPDF: true,
        },
        25: {
          name: 'Mike Radio License',
          folder: 2,
          upload: 1678000000000,
          expiry: 1678000000000,
          file: require('../assets/files/RadioLicense.pdf'),
          isPDF: true,
        },
        26: {
          name: 'November Inspection Report',
          folder: 3,
          upload: 1678000000000,
          file: require('../assets/files/InspectionReport.pdf'),
          isPDF: true,
        },
        27: {
          name: 'November Radio License',
          folder: 2,
          upload: 1678000000000,
          expiry: 1678000000000,
          file: require('../assets/files/RadioLicense.pdf'),
          isPDF: true,
        },
        28: {
          name: 'Oscar Inspection Report',
          folder: 3,
          upload: 1678000000000,
          file: require('../assets/files/InspectionReport.pdf'),
          isPDF: true,
        },
        29: {
          name: 'Oscar Radio License Oscar Radio License Oscar Radio License Oscar Radio License',
          folder: 2,
          upload: 1678000000000,
          expiry: 1678000000000,
          file: require('../assets/files/RadioLicense.pdf'),
          isPDF: true,
        },
        30: {
          name: 'Floor Plan',
          folder: 1,
          upload: 1677000000000,
          img: floorPlan,
          isPDF: true,
        },
        31: {
          name: 'Rooftop Drawing',
          folder: 13,
          upload: 1677000000000,
          img: sectionImage,
          isPDF: true,
        },
      },
      folders: {
        0: {
          name: 'root',
          folders: [1, 2, 3, 4],
          files: [],
        },
        1: {
          name: 'drawings',
          folders: [13],
          files: [30],
        },
        2: {
          name: 'equipment',
          folders: [],
          files: [],
        },
        3: {
          name: 'licenses',
          datesRequired: true,
          folders: [5, 6],
          files: [1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29],
        },
        4: {
          name: 'reports',
          folders: [],
          files: [0, 2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28],
        },
        5: {
          name: 'jan',
          datesRequired: true,
          folders: [],
          files: [],
        },
        6: {
          name: 'feb',
          datesRequired: true,
          folders: [7],
          files: [],
        },
        7: {
          name: 'sub 3',
          datesRequired: true,
          folders: [8],
          files: [],
        },
        8: {
          name: 'sub 4',
          datesRequired: true,
          folders: [9],
          files: [],
        },
        9: {
          name: 'sub 5',
          datesRequired: true,
          folders: [10],
          files: [],
        },
        10: {
          name: 'sub 6',
          datesRequired: true,
          folders: [11],
          files: [],
        },
        11: {
          name: 'sub 7',
          datesRequired: true,
          folders: [12],
          files: [],
        },
        12: {
          name: 'sub 8',
          datesRequired: true,
          folders: [],
          files: [],
        },
        13: {
          name: 'drawing sub folder',
          datesRequired: false,
          folders: [],
          files: [31],
        },
      },
    },
    layout: {
      tower: {
        legs: {
          0: {
            legGrid: {
              i: 0,
              name: 'Face A',
              x: 335,
              y: 500,
              w: 10,
              h: 50,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
          1: {
            legGrid: {
              i: 1,
              name: 'Face B',
              x: 370,
              y: 500,
              w: 10,
              h: 50,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 10,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 20,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
          2: {
            legGrid: {
              i: 2,
              name: 'Face C',
              x: 405,
              y: 500,
              w: 10,
              h: 50,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 20,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
          3: {
            legGrid: {
              i: 3,
              name: 'Face D',
              x: 440,
              y: 500,
              w: 10,
              h: 50,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 25,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        textBoxes: {
          0: {
            i: 100,
            name: 'Example Text Box Note',
            x: 80,
            y: 560,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        images: {
          0: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 560,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        drawings: {},
      },
      data: {
        shelves: {
          0: {
            shelfGrid: {
              i: 0,
              name: 'Rack A',
              x: 100,
              y: 500,
              w: 10,
              h: 40,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cisco 2960',
                img: cisco2960,
                x: 0,
                y: 10,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Mikrotik hEXlite',
                img: mikrotikHexlite,
                x: 0,
                y: 30,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        images: {
          100: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 550,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        textBoxes: {
          200: {
            i: 200,
            name: 'Example Text Box Note',
            x: 80,
            y: 550,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        drawings: {},
      },
      site: {
        image: null,
        imageGrid: null,
        assets: {},
        images: {},
        textBoxes: {},
        drawings: {},
      },
    },
  },
  2: {
    towerId: 2,
    code: '00002',
    comments:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
    company: 'Company Name #2',
    contact: {
      name: 'Bob Billy Builder Sr.',
      phone: 4032222222,
      email: 'bob@bob.com',
    },
    geoCode: 'Calgary, AB T3N 1B9, Canada',
    height: 90,
    img: monopole,
    location: { lat: 51.140078, lng: -113.935779 },
    name: 'Bravo',
    type: { site: 'Monopole', tower: 'Monopole' },
    dates: {
      inspection: 1677628800000,
      licenses: [],
    },
    documents: {
      files: {},
      folders: {
        0: {
          name: 'root',
          folders: [1, 2, 3, 4],
          files: [],
        },
        1: {
          name: 'drawings',
          folders: [],
          files: [],
        },
        2: {
          name: 'licenses',
          datesRequired: true,
          folders: [],
          files: [],
        },
        3: {
          name: 'reports',
          folders: [],
          files: [],
        },
        4: {
          name: 'equipment',
          folders: [],
          files: [],
        },
      },
    },
    layout: {
      tower: {
        legs: {
          // 0: {
          //   legGrid: {
          //     i: 0,
          //     name: 'Face A',
          //     x: 335,
          //     y: 500,
          //     w: 10,
          //     h: 90,
          //     // resizeHandles: ['n', 's'],
          //   },
          //   icons: {
          //     '-1': {
          //       i: -1,
          //       name: '',
          //       x: 0,
          //       y: 50,
          //       w: 0,
          //       minW: 0,
          //       minH: 0,
          //       h: 0,
          //       static: true,
          //       isResizable: false,
          //     },
          //     0: {
          //       i: 0,
          //       name: 'Cellular',
          //       img: cellular,
          //       x: 0,
          //       y: 0,
          //       w: 10,
          //       h: 4,
          //       minW: 10,
          //       isResizable: true,
          //     },
          //     1: {
          //       i: 1,
          //       name: 'Microwave Antenna - Left',
          //       img: microwaveAntenna,
          //       x: 0,
          //       y: 40,
          //       w: 10,
          //       h: 4,
          //       minW: 10,
          //       isResizable: true,
          //     },
          //   },
          //   sections: {
          //     '-1': {
          //       i: -1,
          //       name: '',
          //       x: 0,
          //       y: 40,
          //       w: 0,
          //       minW: 0,
          //       minH: 0,
          //       h: 0,
          //       static: true,
          //       isResizable: false,
          //     },
          //     0: {
          //       name: 'Section A',
          //       i: 0,
          //       x: 0,
          //       y: 0,
          //       h: 10,
          //       w: 4,
          //       minW: 4,
          //       maxW: 4,
          //     },
          //     1: {
          //       name: 'Section B',
          //       i: 1,
          //       x: 0,
          //       y: 10,
          //       h: 5,
          //       w: 4,
          //       minW: 4,
          //       maxW: 4,
          //     },
          //     2: {
          //       name: 'Section C',
          //       i: 2,
          //       x: 0,
          //       y: 20,
          //       h: 12,
          //       w: 4,
          //       minW: 4,
          //       maxW: 4,
          //     },
          //   },
          // },
        },
        textBoxes: {
          // 0: {
          //   i: 100,
          //   name: 'Example Text Box Note',
          //   x: 80,
          //   y: 490,
          //   w: 20,
          //   h: 20,
          //   maxH: 30,
          //   maxW: 30,
          //   minH: 10,
          //   minW: 10,
          //   resizeHandles: ['se'],
          //   text: 'Example Text Box Note',
          // },
        },
        images: {
          // 0: {
          //   i: 100,
          //   name: 'Exmaple Upload Image',
          //   img: antennaA2,
          //   x: 180,
          //   y: 490,
          //   w: 20,
          //   h: 20,
          //   minH: 10,
          //   minW: 10,
          //   resizeHandles: ['se'],
          // },
        },
        drawings: {},
      },
      data: {
        shelves: {
          // 0: {
          //   shelfGrid: {
          //     i: 0,
          //     name: 'Rack A',
          //     100 80,
          //     y: 500,
          //     w: 10,
          //     h: 40,
          //     // resizeHandles: ['n', 's'],
          //   },
          //   icons: {
          //     '-1': {
          //       i: -1,
          //       name: '',
          //       x: 0,
          //       y: 40,
          //       w: 0,
          //       minW: 0,
          //       minH: 0,
          //       h: 0,
          //       static: true,
          //       isResizable: false,
          //     },
          //     0: {
          //       i: 0,
          //       name: 'Cisco 2960',
          //       img: cisco2960,
          //       x: 0,
          //       y: 10,
          //       w: 10,
          //       h: 4,
          //       minW: 10,
          //       isResizable: true,
          //     },
          //     1: {
          //       i: 1,
          //       name: 'Mikrotik hEXlite',
          //       img: mikrotikHexlite,
          //       x: 0,
          //       y: 30,
          //       w: 10,
          //       h: 4,
          //       minW: 10,
          //       isResizable: true,
          //     },
          //   },
          //   sections: {
          //     '-1': {
          //       i: -1,
          //       name: '',
          //       x: 0,
          //       y: 40,
          //       w: 0,
          //       minW: 0,
          //       minH: 0,
          //       h: 0,
          //       static: true,
          //       isResizable: false,
          //     },
          //     0: {
          //       name: 'Section A',
          //       i: 0,
          //       x: 0,
          //       y: 0,
          //       h: 10,
          //       w: 4,
          //       minW: 4,
          //       maxW: 4,
          //     },
          //     1: {
          //       name: 'Section B',
          //       i: 1,
          //       x: 0,
          //       y: 10,
          //       h: 5,
          //       w: 4,
          //       minW: 4,
          //       maxW: 4,
          //     },
          //     2: {
          //       name: 'Section C',
          //       i: 2,
          //       x: 0,
          //       y: 20,
          //       h: 12,
          //       w: 4,
          //       minW: 4,
          //       maxW: 4,
          //     },
          //   },
          // },
        },
        images: {
          // 100: {
          //   i: 100,
          //   name: 'Exmaple Upload Image',
          //   img: antennaA2,
          //   x: 180,
          //   y: 550,
          //   w: 20,
          //   h: 20,
          //   minH: 10,
          //   minW: 10,
          //   resizeHandles: ['se'],
          // },
        },
        textBoxes: {
          // 200: {
          //   i: 200,
          //   name: 'Example Text Box Note',
          //   x: 80,
          //   y: 550,
          //   w: 20,
          //   h: 20,
          //   maxH: 30,
          //   maxW: 30,
          //   minH: 10,
          //   minW: 10,
          //   resizeHandles: ['se'],
          //   text: 'Example Text Box Note',
          // },
        },
        drawings: {},
      },
      site: {
        image: null,
        imageGrid: null,
        assets: {},
        images: {},
        textBoxes: {},
        drawings: {},
      },
    },
  },
  3: {
    towerId: 3,
    code: '00003',
    comments:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    company: 'Company Name #3',
    contact: {
      name: 'Craig Contractor',
      phone: 4033333333,
      email: 'craig@craig.com',
    },
    geoCode: 'Calgary, AB T3J 4C2, Canada',
    height: 66,
    img: threeLeg,
    location: { lat: 51.125406, lng: -113.919501 },
    name: 'Charlie Lorem ipsum dolor sit amet, consectetur',
    type: { site: 'Self-Supporting', tower: '3 Leg' },
    dates: {
      inspection: 1680307200000,
      licenses: [],
    },
    documents: {
      files: {},
      folders: {
        0: {
          name: 'root',
          folders: [1, 2, 3, 4],
          files: [],
        },
        1: {
          name: 'drawings',
          folders: [],
          files: [],
        },
        2: {
          name: 'licenses',
          datesRequired: true,
          folders: [],
          files: [],
        },
        3: {
          name: 'reports',
          folders: [],
          files: [],
        },
        4: {
          name: 'equipment',
          folders: [],
          files: [],
        },
      },
    },
    layout: {
      tower: {
        legs: {
          0: {
            legGrid: {
              i: 0,
              name: 'Face A',
              x: 335,
              y: 500,
              w: 10,
              h: 66,
              // resizeHandles: ['n', 's'],
            },
            icons: [
              {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            ],
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
          1: {
            legGrid: {
              i: 1,
              name: 'Face B',
              x: 370,
              y: 500,
              w: 10,
              h: 66,
              // resizeHandles: ['n', 's'],
            },
            icons: [
              {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            ],
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
          2: {
            legGrid: {
              i: 2,
              name: 'Face C',
              x: 405,
              y: 500,
              w: 10,
              h: 66,
              // resizeHandles: ['n', 's'],
            },
            icons: [
              {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            ],
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        textBoxes: {
          0: {
            i: 100,
            name: 'Example Text Box Note',
            x: 80,
            y: 490,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        images: {
          0: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 490,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        drawings: {},
      },
      data: {
        shelves: {
          0: {
            shelfGrid: {
              i: 0,
              name: 'Rack A',
              x: 100,
              y: 500,
              w: 10,
              h: 40,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cisco 2960',
                img: cisco2960,
                x: 0,
                y: 10,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Mikrotik hEXlite',
                img: mikrotikHexlite,
                x: 0,
                y: 30,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        images: {
          100: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 550,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        textBoxes: {
          200: {
            i: 200,
            name: 'Example Text Box Note',
            x: 80,
            y: 550,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        drawings: {},
      },
      site: {
        image: null,
        imageGrid: null,
        drawings: {},
        assets: {},
        images: {},
        textBoxes: {},
      },
    },
  },
  4: {
    towerId: 4,
    code: '00004',
    comments:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    company: 'Company Name #4',
    contact: {
      name: 'Dr. Daniel Danger Jr.',
      phone: 4034444444,
      email: 'drdandanieldangerjr@dan.com',
    },
    geoCode: 'Calgary, AB T1Y 6H6, Canada',
    height: 200,
    img: rooftop,
    location: { lat: 51.077257, lng: -113.982449 },
    name: 'Delta Excepteur sint occaecat cupidatat non proident,',
    type: { site: 'Rooftop', tower: 'Rooftop' },
    dates: {
      inspection: 1682899200000,
      licenses: [],
    },
    documents: {
      files: {
        0: {
          name: 'Floor Plan',
          folder: 1,
          upload: 1677000000000,
          img: floorPlan,
        },
        1: {
          name: 'Rooftop Drawing',
          folder: 1,
          upload: 1677000000000,
          img: sectionImage,
        },
      },
      folders: {
        0: {
          name: 'root',
          folders: [1, 2, 3, 4],
          files: [],
        },
        1: {
          name: 'drawings',
          folders: [],
          files: [0, 1],
        },
        2: {
          name: 'licenses',
          datesRequired: true,
          folders: [],
          files: [],
        },
        3: {
          name: 'reports',
          folders: [],
          files: [],
        },
        4: {
          name: 'equipment',
          folders: [],
          files: [],
        },
      },
    },
    layout: {
      tower: {
        legs: {
          0: {
            legGrid: {
              i: 0,
              name: 'Face A',
              x: 335,
              y: 500,
              w: 10,
              h: 200,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        textBoxes: {
          0: {
            i: 100,
            name: 'Example Text Box Note',
            x: 80,
            y: 490,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        images: {
          0: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 490,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        drawings: {},
      },
      data: {
        shelves: {
          0: {
            shelfGrid: {
              i: 0,
              name: 'Rack A',
              x: 100,
              y: 500,
              w: 10,
              h: 40,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cisco 2960',
                img: cisco2960,
                x: 0,
                y: 10,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Mikrotik hEXlite',
                img: mikrotikHexlite,
                x: 0,
                y: 30,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        images: {
          100: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 550,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        textBoxes: {
          200: {
            i: 200,
            name: 'Example Text Box Note',
            x: 80,
            y: 550,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        drawings: {},
      },
      site: {
        image: rooftop4,
        imageGrid: {
          i: 0,
          x: 310,
          y: 480,
          w: 200,
          h: 200,
        },
        drawings: {},
        assets: {},
        images: {},
        textBoxes: {},
      },
    },
  },
  5: {
    towerId: 5,
    code: '00005',
    comments:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    company: 'Company Name #5',
    contact: {
      name: 'Alice Arcitect',
      phone: 4031111111,
      email: 'alice@alice.com',
    },
    geoCode: 'Calgary, AB T2X, Canada',
    height: 245,
    img: guyed,
    location: { lat: 50.904095, lng: -114.018925 },
    name: 'Echo',
    type: { site: 'Guyed', tower: '3 Leg' },
    dates: {
      inspection: 1685577600000,
      licenses: [],
    },
    documents: {
      files: {
        0: {
          name: 'Floor Plan',
          folder: 1,
          upload: 1677000000000,
          img: floorPlan,
        },
        1: {
          name: 'Rooftop Drawing',
          folder: 1,
          upload: 1677000000000,
          img: sectionImage,
        },
      },
      folders: {
        0: {
          name: 'root',
          folders: [1, 2, 3, 4],
          files: [],
        },
        1: {
          name: 'drawings',
          folders: [],
          files: [0, 1],
        },
        2: {
          name: 'licenses',
          datesRequired: true,
          folders: [],
          files: [],
        },
        3: {
          name: 'reports',
          folders: [],
          files: [],
        },
        4: {
          name: 'equipment',
          folders: [],
          files: [],
        },
      },
    },
    layout: {
      tower: {
        legs: {
          0: {
            legGrid: {
              i: 0,
              name: 'Face A',
              x: 335,
              y: 500,
              w: 10,
              h: 245,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
          1: {
            legGrid: {
              i: 1,
              name: 'Face B',
              x: 370,
              y: 500,
              w: 10,
              h: 245,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
          2: {
            legGrid: {
              i: 2,
              name: 'Face C',
              x: 405,
              y: 500,
              w: 10,
              h: 245,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        textBoxes: {
          0: {
            i: 100,
            name: 'Example Text Box Note',
            x: 80,
            y: 490,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        images: {
          0: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 490,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        drawings: {},
      },
      data: {
        shelves: {
          0: {
            shelfGrid: {
              i: 0,
              name: 'Rack A',
              x: 100,
              y: 500,
              w: 10,
              h: 40,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cisco 2960',
                img: cisco2960,
                x: 0,
                y: 10,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Mikrotik hEXlite',
                img: mikrotikHexlite,
                x: 0,
                y: 30,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        images: {
          100: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 550,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        textBoxes: {
          200: {
            i: 200,
            name: 'Example Text Box Note',
            x: 80,
            y: 550,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        drawings: {},
      },
      site: {
        image: null,
        imageGrid: null,
        drawings: {},
        assets: {},
        images: {},
        textBoxes: {},
      },
    },
  },
  6: {
    towerId: 6,
    code: '00006',
    comments:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    company: 'Company Name #6',
    contact: {
      name: 'Bob Builder',
      phone: 4032222222,
      email: 'bob@bob.com',
    },
    geoCode: 'Calgary, AB T1X 0L5, Canada',
    height: 145,
    img: monopole,
    location: { lat: 51.037564, lng: -113.907252 },
    name: 'Foxtrot',
    type: { site: 'Monopole', tower: 'Monopole' },
    dates: {
      inspection: 1688169600000,
      licenses: [],
    },
    documents: {
      files: {
        0: {
          name: 'Floor Plan',
          folder: 1,
          upload: 1677000000000,
          img: floorPlan,
        },
        1: {
          name: 'Rooftop Drawing',
          folder: 1,
          upload: 1677000000000,
          img: sectionImage,
        },
      },
      folders: {
        0: {
          name: 'root',
          folders: [1, 2, 3, 4],
          files: [],
        },
        1: {
          name: 'drawings',
          folders: [],
          files: [0, 1],
        },
        2: {
          name: 'licenses',
          datesRequired: true,
          folders: [],
          files: [],
        },
        3: {
          name: 'reports',
          folders: [],
          files: [],
        },
        4: {
          name: 'equipment',
          folders: [],
          files: [],
        },
      },
    },
    layout: {
      tower: {
        legs: {
          0: {
            legGrid: {
              i: 0,
              name: 'Face A',
              x: 335,
              y: 500,
              w: 10,
              h: 145,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        textBoxes: {
          0: {
            i: 100,
            name: 'Example Text Box Note',
            x: 80,
            y: 490,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        images: {
          0: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 490,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        drawings: {},
      },
      data: {
        shelves: {
          0: {
            shelfGrid: {
              i: 0,
              name: 'Rack A',
              x: 100,
              y: 500,
              w: 10,
              h: 40,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cisco 2960',
                img: cisco2960,
                x: 0,
                y: 10,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Mikrotik hEXlite',
                img: mikrotikHexlite,
                x: 0,
                y: 30,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        images: {
          100: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 550,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        textBoxes: {
          200: {
            i: 200,
            name: 'Example Text Box Note',
            x: 80,
            y: 550,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        drawings: {},
      },
      site: {
        image: null,
        imageGrid: null,
        drawings: {},
        assets: {},
        images: {},
        textBoxes: {},
      },
    },
  },
  7: {
    towerId: 7,
    code: '00007',
    comments:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    company: 'Company Name #7',
    contact: {
      name: 'Craig Contractor',
      phone: 4033333333,
      email: 'craig@craig.com',
    },
    geoCode: 'De Winton, AB T0L 0X0, Canada',
    height: 360,
    img: fourLeg,
    location: { lat: 50.822932, lng: -113.993424 },
    name: 'Golf',
    type: { site: 'Self-Supporting', tower: '4 Leg' },
    dates: {
      inspection: 1690848000000,
      licenses: [],
    },
    documents: {
      files: {
        0: {
          name: 'Floor Plan',
          folder: 1,
          upload: 1677000000000,
          img: floorPlan,
        },
        1: {
          name: 'Rooftop Drawing',
          folder: 1,
          upload: 1677000000000,
          img: sectionImage,
        },
      },
      folders: {
        0: {
          name: 'root',
          folders: [1, 2, 3, 4],
          files: [],
        },
        1: {
          name: 'drawings',
          folders: [],
          files: [0, 1],
        },
        2: {
          name: 'licenses',
          datesRequired: true,
          folders: [],
          files: [],
        },
        3: {
          name: 'reports',
          folders: [],
          files: [],
        },
        4: {
          name: 'equipment',
          folders: [],
          files: [],
        },
      },
    },
    layout: {
      tower: {
        legs: {
          0: {
            legGrid: {
              i: 0,
              name: 'Face A',
              x: 335,
              y: 500,
              w: 10,
              h: 360,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
          1: {
            legGrid: {
              i: 1,
              name: 'Face B',
              x: 370,
              y: 500,
              w: 10,
              h: 360,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
          2: {
            legGrid: {
              i: 2,
              name: 'Face C',
              x: 405,
              y: 500,
              w: 10,
              h: 360,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
          3: {
            legGrid: {
              i: 3,
              name: 'Face D',
              x: 440,
              y: 500,
              w: 10,
              h: 360,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        textBoxes: {
          0: {
            i: 100,
            name: 'Example Text Box Note',
            x: 80,
            y: 490,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        images: {
          0: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 490,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        drawings: {},
      },
      data: {
        shelves: {
          0: {
            shelfGrid: {
              i: 0,
              name: 'Rack A',
              x: 100,
              y: 500,
              w: 10,
              h: 40,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cisco 2960',
                img: cisco2960,
                x: 0,
                y: 10,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Mikrotik hEXlite',
                img: mikrotikHexlite,
                x: 0,
                y: 30,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        images: {
          100: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 550,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        textBoxes: {
          200: {
            i: 200,
            name: 'Example Text Box Note',
            x: 80,
            y: 550,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        drawings: {},
      },
      site: {
        image: null,
        imageGrid: null,
        drawings: {},
        assets: {},
        images: {},
        textBoxes: {},
      },
    },
  },
  8: {
    towerId: 8,
    code: '00008',
    comments:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.", "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat.',
    company: 'Company Name #8',
    contact: {
      name: 'Dan Danger',
      phone: 4034444444,
      email: 'dan@dan.com',
    },
    geoCode: 'Calgary, AB T2E 8C3, Canada',
    height: 400,
    img: rooftop,
    location: { lat: 51.093731, lng: -114.004302 },
    name: 'Hotel',
    type: { site: 'Rooftop', tower: 'Rooftop' },
    dates: {
      inspection: 1672531200000,
      licenses: [],
    },
    documents: {
      files: {
        0: {
          name: 'Floor Plan',
          folder: 1,
          upload: 1677000000000,
          img: floorPlan,
        },
        1: {
          name: 'Rooftop Drawing',
          folder: 1,
          upload: 1677000000000,
          img: sectionImage,
        },
      },
      folders: {
        0: {
          name: 'root',
          folders: [1, 2, 3, 4],
          files: [],
        },
        1: {
          name: 'drawings',
          folders: [],
          files: [0, 1],
        },
        2: {
          name: 'licenses',
          datesRequired: true,
          folders: [],
          files: [],
        },
        3: {
          name: 'reports',
          folders: [],
          files: [],
        },
        4: {
          name: 'equipment',
          folders: [],
          files: [],
        },
      },
    },
    layout: {
      tower: {
        legs: {
          0: {
            legGrid: {
              i: 0,
              name: 'Face A',
              x: 335,
              y: 500,
              w: 10,
              h: 400,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        images: {
          100: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 490,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        textBoxes: {
          200: {
            i: 200,
            name: 'Example Text Box Note',
            x: 80,
            y: 490,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        drawings: {
          300: {
            i: 300,
            name: 'Ref 1 - Floor Plan',
            x: 325,
            y: 500,
            w: 10,
            h: 5,
            maxH: 30,
            maxW: 30,
            minH: 5,
            minW: 5,
            refID: 0,
            resizeHandles: ['se'],
          },
          301: {
            i: 301,
            name: 'Ref 2 - Roof Plan',
            x: 350,
            y: 530,
            w: 10,
            h: 5,
            maxH: 30,
            maxW: 30,
            minH: 5,
            minW: 5,
            refID: 1,
            resizeHandles: ['se'],
          },
        },
      },
      data: {
        shelves: {
          0: {
            shelfGrid: {
              i: 0,
              name: 'Rack A',
              x: 100,
              y: 500,
              w: 10,
              h: 40,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cisco 2960',
                img: cisco2960,
                x: 0,
                y: 10,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Mikrotik hEXlite',
                img: mikrotikHexlite,
                x: 0,
                y: 30,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        images: {
          100: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 550,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        textBoxes: {
          200: {
            i: 200,
            name: 'Example Text Box Note',
            x: 80,
            y: 550,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        drawings: {},
      },
      site: {
        image: rooftop8,
        imageGrid: {
          i: 0,
          x: 310,
          y: 480,
          w: 200,
          h: 200,
        },
        assets: {
          0: {
            i: 0,
            name: 'Cambium Dish',
            img: cambiumDish,
            x: 67,
            y: 24,
            w: 7,
            minW: 2,
            minH: 2,
            h: 7,
            isResizable: true,
          },
        },
        images: {
          100: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 550,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        textBoxes: {
          200: {
            i: 200,
            name: 'Example Text Box Note',
            x: 80,
            y: 550,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        drawings: {
          300: {
            i: 300,
            name: 'Ref 1 - Floor Plan',
            x: 335,
            y: 500,
            w: 10,
            h: 5,
            maxH: 30,
            maxW: 30,
            minH: 5,
            minW: 5,
            resizeHandles: ['se'],
            refID: 0,
          },
          301: {
            i: 301,
            name: 'Ref 2 - Roof Plan',
            x: 350,
            y: 530,
            w: 10,
            h: 5,
            maxH: 30,
            maxW: 30,
            minH: 5,
            minW: 5,
            refID: 1,
            resizeHandles: ['se'],
          },
        },
      },
    },
  },
  9: {
    towerId: 9,
    code: '00009',
    comments: 'Lorem ipsum dolor',
    company: 'Nombre de empresa #1',
    contact: {
      name: 'Alice Arcitect',
      phone: 4031111111,
      email: 'alice1231231@alice.com',
    },
    geoCode: 'Alcalá de los Gazules, 11180, Cádiz, Spain',
    height: 50,
    img: fourLeg,
    location: { lat: 36.444066, lng: -5.747605 },
    name: 'India',
    type: { site: 'Guyed', tower: '4 Leg' },
    dates: {
      inspection: 1675209600000,
      licenses: [],
    },
    documents: {
      files: {
        0: {
          name: 'Floor Plan',
          folder: 1,
          upload: 1677000000000,
          img: floorPlan,
        },
        1: {
          name: 'Rooftop Drawing',
          folder: 1,
          upload: 1677000000000,
          img: sectionImage,
        },
      },
      folders: {
        0: {
          name: 'root',
          folders: [1, 2, 3, 4],
          files: [],
        },
        1: {
          name: 'drawings',
          folders: [],
          files: [0, 1],
        },
        2: {
          name: 'licenses',
          datesRequired: true,
          folders: [],
          files: [],
        },
        3: {
          name: 'reports',
          folders: [],
          files: [],
        },
        4: {
          name: 'equipment',
          folders: [],
          files: [],
        },
      },
    },
    layout: {
      tower: {
        legs: {
          0: {
            legGrid: {
              i: 0,
              name: 'Face A',
              x: 335,
              y: 500,
              w: 10,
              h: 50,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
          1: {
            legGrid: {
              i: 1,
              name: 'Face B',
              x: 370,
              y: 500,
              w: 10,
              h: 50,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 10,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 20,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
          2: {
            legGrid: {
              i: 2,
              name: 'Face C',
              x: 405,
              y: 500,
              w: 10,
              h: 50,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 20,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
          3: {
            legGrid: {
              i: 3,
              name: 'Face D',
              x: 440,
              y: 500,
              w: 10,
              h: 50,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 25,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        textBoxes: {
          0: {
            i: 100,
            name: 'Example Text Box Note',
            x: 80,
            y: 490,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        images: {
          0: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 490,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        drawings: {},
      },
      data: {
        shelves: {
          0: {
            shelfGrid: {
              i: 0,
              name: 'Rack A',
              x: 100,
              y: 500,
              w: 10,
              h: 40,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cisco 2960',
                img: cisco2960,
                x: 0,
                y: 10,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Mikrotik hEXlite',
                img: mikrotikHexlite,
                x: 0,
                y: 30,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        images: {
          100: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 550,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        textBoxes: {
          200: {
            i: 200,
            name: 'Example Text Box Note',
            x: 80,
            y: 550,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        drawings: {},
      },
      site: {
        image: null,
        imageGrid: null,
        drawings: {},
        assets: {},
        images: {},
        textBoxes: {},
      },
    },
  },
  10: {
    towerId: 10,
    code: '00010',
    comments:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
    company: 'Nombre de empresa #2',
    contact: {
      name: 'Bob Billy Builder Sr.',
      phone: 4032222222,
      email: 'bob@bob.com',
    },
    geoCode: 'Municipality of Valdemoro, Madrid, Spain',
    height: 90,
    img: monopole,
    location: { lat: 40.516439, lng: -3.683597 },
    name: 'Juliet',
    type: { site: 'Monopole', tower: 'Monopole' },
    dates: {
      inspection: 1677628800000,
      licenses: [],
    },
    documents: {
      files: {
        0: {
          name: 'Floor Plan',
          folder: 1,
          upload: 1677000000000,
          img: floorPlan,
        },
        1: {
          name: 'Rooftop Drawing',
          folder: 1,
          upload: 1677000000000,
          img: sectionImage,
        },
      },
      folders: {
        0: {
          name: 'root',
          folders: [1, 2, 3, 4],
          files: [],
        },
        1: {
          name: 'drawings',
          folders: [],
          files: [0, 1],
        },
        2: {
          name: 'licenses',
          datesRequired: true,
          folders: [],
          files: [],
        },
        3: {
          name: 'reports',
          folders: [],
          files: [],
        },
        4: {
          name: 'equipment',
          folders: [],
          files: [],
        },
      },
    },
    layout: {
      tower: {
        legs: {
          0: {
            legGrid: {
              i: 0,
              name: 'Face A',
              x: 335,
              y: 500,
              w: 10,
              h: 90,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        textBoxes: {
          0: {
            i: 100,
            name: 'Example Text Box Note',
            x: 80,
            y: 490,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        images: {
          0: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 490,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        drawings: {},
      },
      data: {
        shelves: {
          0: {
            shelfGrid: {
              i: 0,
              name: 'Rack A',
              x: 100,
              y: 500,
              w: 10,
              h: 40,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cisco 2960',
                img: cisco2960,
                x: 0,
                y: 10,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Mikrotik hEXlite',
                img: mikrotikHexlite,
                x: 0,
                y: 30,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        images: {
          100: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 550,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        textBoxes: {
          200: {
            i: 200,
            name: 'Example Text Box Note',
            x: 80,
            y: 550,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        drawings: {},
      },
      site: {
        image: null,
        imageGrid: null,
        drawings: {},
        assets: {},
        images: {},
        textBoxes: {},
      },
    },
  },
  11: {
    towerId: 11,
    code: '00011',
    comments:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    company: 'Nombre de empresa #5',
    contact: {
      name: 'Alice Arcitect',
      phone: 4031111111,
      email: 'alice@alice.com',
    },
    geoCode: 'El Goloso, Madrid, Spain',
    height: 245,
    img: guyed,
    location: { lat: 40.18088, lng: -3.70653 },
    name: 'Kilo',
    type: { site: 'Guyed', tower: '3 Leg' },
    dates: {
      inspection: 1685577600000,
      licenses: [],
    },
    documents: {
      files: {
        0: {
          name: 'Floor Plan',
          folder: 1,
          upload: 1677000000000,
          img: floorPlan,
        },
        1: {
          name: 'Rooftop Drawing',
          folder: 1,
          upload: 1677000000000,
          img: sectionImage,
        },
      },
      folders: {
        0: {
          name: 'root',
          folders: [1, 2, 3, 4],
          files: [],
        },
        1: {
          name: 'drawings',
          folders: [],
          files: [0, 1],
        },
        2: {
          name: 'licenses',
          datesRequired: true,
          folders: [],
          files: [],
        },
        3: {
          name: 'reports',
          folders: [],
          files: [],
        },
        4: {
          name: 'equipment',
          folders: [],
          files: [],
        },
      },
    },
    layout: {
      tower: {
        legs: {
          0: {
            legGrid: {
              i: 0,
              name: 'Face A',
              x: 335,
              y: 500,
              w: 10,
              h: 245,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
          1: {
            legGrid: {
              i: 1,
              name: 'Face B',
              x: 370,
              y: 500,
              w: 10,
              h: 245,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
          2: {
            legGrid: {
              i: 2,
              name: 'Face C',
              x: 405,
              y: 500,
              w: 10,
              h: 245,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 50,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cellular',
                img: cellular,
                x: 0,
                y: 0,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Microwave Antenna - Left',
                img: microwaveAntenna,
                x: 0,
                y: 40,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        textBoxes: {
          0: {
            i: 100,
            name: 'Example Text Box Note',
            x: 80,
            y: 490,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        images: {
          0: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 490,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        drawings: {},
      },
      data: {
        shelves: {
          0: {
            shelfGrid: {
              i: 0,
              name: 'Rack A',
              x: 100,
              y: 500,
              w: 10,
              h: 40,
              // resizeHandles: ['n', 's'],
            },
            icons: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                i: 0,
                name: 'Cisco 2960',
                img: cisco2960,
                x: 0,
                y: 10,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
              1: {
                i: 1,
                name: 'Mikrotik hEXlite',
                img: mikrotikHexlite,
                x: 0,
                y: 30,
                w: 10,
                h: 4,
                minW: 10,
                isResizable: true,
              },
            },
            sections: {
              '-1': {
                i: -1,
                name: '',
                x: 0,
                y: 40,
                w: 0,
                minW: 0,
                minH: 0,
                h: 0,
                static: true,
                isResizable: false,
              },
              0: {
                name: 'Section A',
                i: 0,
                x: 0,
                y: 0,
                h: 10,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              1: {
                name: 'Section B',
                i: 1,
                x: 0,
                y: 10,
                h: 5,
                w: 4,
                minW: 4,
                maxW: 4,
              },
              2: {
                name: 'Section C',
                i: 2,
                x: 0,
                y: 20,
                h: 12,
                w: 4,
                minW: 4,
                maxW: 4,
              },
            },
          },
        },
        images: {
          100: {
            i: 100,
            name: 'Exmaple Upload Image',
            img: antennaA2,
            x: 180,
            y: 550,
            w: 20,
            h: 20,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
          },
        },
        textBoxes: {
          200: {
            i: 200,
            name: 'Example Text Box Note',
            x: 80,
            y: 550,
            w: 20,
            h: 20,
            maxH: 30,
            maxW: 30,
            minH: 10,
            minW: 10,
            resizeHandles: ['se'],
            text: 'Example Text Box Note',
          },
        },
        drawings: {},
      },
      site: {
        image: null,
        imageGrid: null,
        assets: {},
        images: {},
        textBoxes: {},
        drawings: {},
      },
    },
  },
};

export const customIconList = {
  Alarm: alarm,
  Antenna: antenna,
  Battery: battery,
  Beacon: beacon,
  Camera: camera,
  Above: ceilingLight,
  'Desk Phone': deskPhone,
  Fax: fax,
  Firewall: firewall,
  Headset: headset,
  Satellite: satellite,
  Scanner: scanner,
  Speaker: speaker,
  Solar: solar,
  Spotlight: spotlight,
  'Arrow Down': arrowDown,
  'Arrow Left': arrowLeft,
  Arrow: arrowRight,
  'Arrow Up': arrowUp,
  Circle: circle,
  Cube: cube,
  Cylinder: cylinder,
  Electric: electric,
  Hexagon: hexagon,
  'Hexagon 3D': hexagon3D,
  Octagon: octagon,
  Oval: oval,
  Pentagon: pentagon,
  Pyramid: pyramid,
  Rectangle: rectangle,
  'Rectangle 3D': rectangle3D,
  Refresh: refresh,
  Rhombus: rhombus,
  Semicircle: semicircle,
  'Star (Gray)': starGray,
  'Star (Red)': starRed,
  Triangle: triangle,
};
// TODO: RESTRUCTURE DATA - 1 ICONS LIBRARY AND LIBRARYDATA REFERENCING ICONIDS
export const libraryData: ILibrary = {
  'John Smith': {
    1: {
      id: '2-Way Splitter',
      content: twoWaySplitter,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    2: {
      id: 'APC 1000VA',
      content: apc1000Va,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    3: {
      id: 'Barionet 50',
      content: barionet,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    4: {
      id: 'Cambium Dish',
      content: cambiumDish,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    5: {
      id: 'Cell Tower',
      content: cellTower,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    6: {
      id: 'Microhard IPN3G',
      content: microhard,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    7: {
      id: 'Portable Tower',
      content: portableTower,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    8: {
      id: 'Satellite Internet - Viasat',
      content: satelliteInternet,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    9: {
      id: 'UBNT - 16 Port PoE SFP - 150W',
      content: ubnt16Poe,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    10: {
      id: 'VoIP Phone',
      content: voipPhone,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
  },
  Rigstar: {
    1: {
      id: '2-Way Splitter',
      content: twoWaySplitter,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    2: {
      id: '3-Way Splitter',
      content: threeWaySplitter,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    3: {
      id: 'AirMax AC BaseStation',
      content: airMaxBaseStation,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    4: {
      id: 'APC 1000VA',
      content: apc1000Va,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    5: {
      id: 'APC UPS',
      content: apcUpc,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    6: {
      id: 'Aruba AP',
      content: arubaAp,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    7: {
      id: 'Axis I8016 Intercom',
      content: axisIntercom,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    8: {
      id: 'Barionet 50',
      content: barionet,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    9: {
      id: 'Battery',
      content: rigstarBattery,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    10: {
      id: 'Building',
      content: building,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    11: {
      id: 'Cambium Radio',
      content: cambiumRadio,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    12: {
      id: "Cambium - 2' Dual Polar Dish",
      content: cambiumPolarDish,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    13: {
      id: 'Cambium Dish',
      content: cambiumDish,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    14: {
      id: 'Cambium PMP',
      content: cambiumPmp,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    15: {
      id: 'Cambium PTP',
      content: cambiumPtp,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    16: {
      id: 'Cargo Container',
      content: cargoContainer,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    17: {
      id: 'Cell Booster (Internal)',
      content: cellBooster,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    18: {
      id: 'Cell Booster System (Internal)',
      content: cellBoosterSystem,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    19: {
      id: 'Cell Tower',
      content: cellTower,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    20: {
      id: 'Cellular',
      content: cellular,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    21: {
      id: 'Cisco 2960-8TC-L',
      content: cisco2960,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    22: {
      id: 'Cisco 2960G-8TC-L',
      content: cisco2960G8,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    23: {
      id: 'Cisco 2960G-24TC-L',
      content: cisco2960G24,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    24: {
      id: 'Cisco 2960S-24PS-L',
      content: cisco2960S24,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    25: {
      id: 'Cisco 2960S-48FPS-L',
      content: cisco2960S48,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    26: {
      id: 'Cisco 3560C-8PC-S',
      content: cisco3560C,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    27: {
      id: 'Cisco 3560CG-8PC-S',
      content: cisco3560CG,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    28: {
      id: 'Cisco 3750X-48T-S',
      content: cisco3750,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    29: {
      id: 'Cisco 4900M',
      content: cisco4900,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    30: {
      id: 'Cisco 4948-10GE',
      content: cisco4948GE,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    31: {
      id: 'Cisco 4948',
      content: cisco4948,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    32: {
      id: 'Cisco Air-CT2504-15-K9',
      content: ciscoAir,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    33: {
      id: 'Cisco Catalyst - 8P',
      content: ciscoCatalyst,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    34: {
      id: 'Cisco Indoor AP',
      content: ciscoIndoorAp,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    35: {
      id: 'Cisco Router 2911',
      content: ciscoRouter,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    36: {
      id: 'Cisco Switch-Catalyst-2960s',
      content: ciscoSwitch,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    37: {
      id: 'Communications Cabinet',
      content: communicationCabinet,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    38: {
      id: 'Concrete Blocks for Guy Wires',
      content: concreteBlock,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    39: {
      id: 'Demark Switch',
      content: demarkSwitch,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    40: {
      id: 'Directional Antenna',
      content: directionalAntenna,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    41: {
      id: 'Directional Panel Antenna',
      content: directionalPanel,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    42: {
      id: 'Dragonwave 18G',
      content: dragonWave,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    43: {
      id: 'Drilling Rig',
      content: drillingRig,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    44: {
      id: 'Duplexer',
      content: duplexer,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    45: {
      id: 'Gigabit 8-port Switch',
      content: gigabitSwitch,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    46: {
      id: 'Internet Access',
      content: internetAccess,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    47: {
      id: 'Internet',
      content: internet,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    48: {
      id: 'Iridium Antenna',
      content: iridiumAntenna,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    49: {
      id: 'Ku Band',
      content: kuBand,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    50: {
      id: 'Laptop',
      content: laptop,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    51: {
      id: 'LTE Antenna',
      content: lteAntenna,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    52: {
      id: 'Meraki AP',
      content: merakiAp,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    53: {
      id: 'Meraki MR32 Indoor AP',
      content: merakiMr32Indoor,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    54: {
      id: 'Meraki MR32',
      content: merakiMr32,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    55: {
      id: 'Meraki MR72',
      content: merakiMr72,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    56: {
      id: 'Meraki MS220-8P',
      content: merakiMs220,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    57: {
      id: 'Meraki MX64',
      content: merakiMx64,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    58: {
      id: 'Meraki MX64W',
      content: merakiMx64W,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    59: {
      id: 'Meraki MX80 Router',
      content: merakiMx80,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    60: {
      id: 'Meraki MX84 Router',
      content: merakiMx84,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    61: {
      id: 'Meraki MX100 Router',
      content: merakiMx100,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    62: {
      id: 'Meraki Wi-Fi Router',
      content: merakiWifi,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    63: {
      id: 'Microhard IPN3G',
      content: microhard,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    64: {
      id: 'Microwave Antenna - Left',
      content: microwaveAntenna,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    65: {
      id: 'Microwave Tower',
      content: microwaveTower,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    66: {
      id: 'Mikrotik hEXlite',
      content: mikrotikHexlite,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    67: {
      id: 'Mikrotik VPN',
      content: mikrotikVpn,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    68: {
      id: 'Mimosa - A5 AP',
      content: mimosaA5,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    69: {
      id: 'Mimosa - C5 SM',
      content: mimosaC5,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    70: {
      id: 'Mimosa B5 Backhaul',
      content: mimosaB5,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    71: {
      id: 'Motorola Gang Charger',
      content: gangCharger,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    72: {
      id: 'Non-Penetrating Roof Mount',
      content: nonPenetratingMount,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    73: {
      id: 'Outdoor Speaker',
      content: outdoorSpeaker,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    74: {
      id: 'Outdoor VoIP Door Speaker',
      content: voipDoorSpeaker,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    75: {
      id: 'Outdoor VoIP Speaker',
      content: voipSpeaker,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    76: {
      id: 'Panel Antenna',
      content: panelAntenna,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    77: {
      id: 'Patch Panel (8 Port)',
      content: patchPanel8,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    78: {
      id: 'Patch Panel (24 port)',
      content: patchPanel24,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    79: {
      id: 'Peplink - Balance One',
      content: peplinkBalanceOne,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    80: {
      id: 'Peplink AP',
      content: peplinkAP,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    81: {
      id: 'Peplink Balance 580',
      content: peplinkBalace580,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    82: {
      id: 'Pepwave Max700',
      content: pepwaveMax,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    83: {
      id: 'Pepwave',
      content: pepWave,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    84: {
      id: 'Pipemast',
      content: pipemast,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    85: {
      id: 'PoE',
      content: poe,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    86: {
      id: 'Polycom Conference Phone IP6000',
      content: polycomPhone,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    87: {
      id: 'Portable Tower',
      content: portableTower,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    88: {
      id: 'Portable',
      content: portable,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    89: {
      id: 'POTS Phone',
      content: potsPhone,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    90: {
      id: 'PowerBeam AC -ISO',
      content: powerBeamAC,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    91: {
      id: 'Printer 1',
      content: printerOne,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    92: {
      id: 'Printer 2',
      content: printerTwo,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    93: {
      id: 'PT3N Hybrid Coupler',
      content: pt3N,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    94: {
      id: 'PT4.8N Hybrid Coupler',
      content: pt4N,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    95: {
      id: 'PT6N Hybrid Coupler',
      content: pt6N,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    96: {
      id: 'PT8N Hybrid Coupler',
      content: pt8N,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    97: {
      id: 'PT10 Hybrid Coupler',
      content: pt10,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    98: {
      id: 'PTP 650 - Connectorized',
      content: ptp650,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    99: {
      id: 'PTP820',
      content: ptp820,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    100: {
      id: 'Radio Battery',
      content: radioBattery,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    101: {
      id: 'Rigweb',
      content: rigweb,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    102: {
      id: 'RST100 Terminal - Satellite Phones',
      content: rst100,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    103: {
      id: 'RST970 - Intelligent Handset - Sat',
      content: rst970,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    104: {
      id: 'Satellite Arm',
      content: satelliteArm,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    105: {
      id: 'Satellite Internet - Viasat',
      content: satelliteInternet,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    106: {
      id: 'Skid Shack',
      content: skidShack,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    107: {
      id: 'Speaker Mic',
      content: speakerMic,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    108: {
      id: 'Starlink Subscriber',
      content: starlink,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    109: {
      id: 'Surecall Force 5 Cell Booster',
      content: surecallForce,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    110: {
      id: 'Surge Protector',
      content: surgeProtector,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    111: {
      id: 'Surveillance Camera',
      content: surveillanceCamera,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    112: {
      id: 'Switch (8 Port)',
      content: switch4,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    113: {
      id: 'Switch (16 Port)',
      content: switch8,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    114: {
      id: 'Switch (4 Port)',
      content: switch16,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    115: {
      id: 'TCPL - 2 Trailer Template',
      content: tcpl,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    116: {
      id: 'Telecommunications Shelter',
      content: shelter,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    117: {
      id: 'Tripod Mount',
      content: tripodMount,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    118: {
      id: 'Trylon Super Titan',
      content: trylonTitan,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    119: {
      id: 'Trylon Tower',
      content: trylonTower,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    120: {
      id: 'UAP Pro WiFi AP',
      content: uapPro,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    121: {
      id: 'Ubiquiti Air Fibre',
      content: ubiquitiFibre,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    122: {
      id: 'Ubiquiti Rocket Prism',
      content: ubiquitiRocket,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    123: {
      id: 'UBNT - 8 Port PoE+ - SFP',
      content: ubnt8Poe,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    124: {
      id: 'UBNT - 16 Port Aggregation Switch',
      content: ubnt16Agg,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    125: {
      id: 'UBNT - 16 Port PoE SFP - 150W',
      content: ubnt16Poe,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    126: {
      id: 'UBNT - Cloud Key',
      content: ubntCloud,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    127: {
      id: 'UBNT - Gateway Security-Router - USGPro4',
      content: ubntGateway,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    128: {
      id: 'UBNT - NanoBeam Left',
      content: ubntNanobeamLeft,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    129: {
      id: 'UBNT - NanoBeam Right',
      content: ubntNanoBeamRight,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    130: {
      id: 'Unifi - 8 Port Switch - PoE',
      content: unifiSwitch8,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    131: {
      id: 'Unifi - 24 Port Switch - PoE',
      content: unifiSwitch24,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    132: {
      id: 'Unifi - Cloud Key',
      content: unifiCloud,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    133: {
      id: 'Unifi - Security - Large',
      content: unifiSecurityLarge,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    134: {
      id: 'Unifi - Security - Small',
      content: unifiSecuritySmall,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    135: {
      id: 'Unifi - UAP Pro AP',
      content: unifiUapPro,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    136: {
      id: 'UPS - 685',
      content: ups685,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    137: {
      id: 'UPS - 1500 APC V2',
      content: ups1500Apc,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    138: {
      id: 'UPS - 1500 mAh',
      content: ups1500Mah,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    139: {
      id: 'UPS - Power Backup',
      content: upsPowerBackup,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    140: {
      id: 'UPS (Small)',
      content: upsSmall,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    141: {
      id: 'UPS 1U Rack Mount - Tripplite',
      content: upsRackMount,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    142: {
      id: 'VoIP Phone',
      content: voipPhone,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    143: {
      id: 'Wifi Router',
      content: wifiRouter,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    144: {
      id: 'Wifi',
      content: wifi,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    145: {
      id: 'Wireless Mini Repeater',
      content: wirelessMiniRepeater,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    146: {
      id: 'Yagi Antenna',
      content: yagiAntenna,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    147: {
      id: 'Yealink - Expandable Microphone',
      content: yealinkMicrophone,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    148: {
      id: 'Yealink 860 Conference Phone',
      content: yealink860,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    149: {
      id: 'Yealink T41S',
      content: yealinkT41,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    150: {
      id: 'Yealink T46S',
      content: yealinkT46,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
  },
  Shapes: {
    1: {
      id: 'Arrow Down',
      content: arrowDown,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    2: {
      id: 'Arrow Left',
      content: arrowLeft,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    3: {
      id: 'Arrow Right',
      content: arrowRight,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    4: {
      id: 'Arrow Up',
      content: arrowUp,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    5: {
      id: 'Circle',
      content: circle,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    6: {
      id: 'Cube',
      content: cube,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    7: {
      id: 'Cylinder',
      content: cylinder,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    8: {
      id: 'Electric',
      content: electric,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    9: {
      id: 'Hexagon',
      content: hexagon,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    10: {
      id: 'Hexagon 3D',
      content: hexagon3D,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    11: {
      id: 'Octagon',
      content: octagon,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    12: {
      id: 'Oval',
      content: oval,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    13: {
      id: 'Pentagon',
      content: pentagon,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    14: {
      id: 'Pyramid',
      content: pyramid,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    15: {
      id: 'Rectangle',
      content: rectangle,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    16: {
      id: 'Rectangle 3D',
      content: rectangle3D,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    17: {
      id: 'Refresh',
      content: refresh,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    18: {
      id: 'Rhombus',
      content: rhombus,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    19: {
      id: 'Semicircle',
      content: semicircle,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    20: {
      id: 'Star (Gray)',
      content: starGray,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    21: {
      id: 'Star (Red)',
      content: starRed,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    22: {
      id: 'Triangle',
      content: triangle,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
  },
  Misc: {
    1: {
      id: 'Alarm',
      content: alarm,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    2: {
      id: 'Antenna',
      content: antenna,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    3: {
      id: 'Battery',
      content: battery,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    4: {
      id: 'Beacon',
      content: beacon,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    5: {
      id: 'Camera',
      content: camera,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    6: {
      id: 'Above Light',
      content: ceilingLight,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    7: {
      id: 'Desk Phone',
      content: deskPhone,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    8: {
      id: 'Fax',
      content: fax,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    9: {
      id: 'Firewall',
      content: firewall,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    10: {
      id: 'Headset',
      content: headset,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    11: {
      id: 'Satellite',
      content: satellite,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    12: {
      id: 'Scanner',
      content: scanner,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    13: {
      id: 'Speaker',
      content: speaker,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    14: {
      id: 'Solar',
      content: solar,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
    15: {
      id: 'Spotlight',
      content: spotlight,
      ports: {
        w: 10,
        h: 4,
      },
      properties: [],
    },
  },
  'Two-Way Radio': {},
  'Video Surveillence': {},
};

export const allIcons = {
  standard: {
    alarm,
    antenna,
    battery,
    beacon,
    camera,
    ceilingLight,
    deskPhone,
    fax,
    firewall,
    headset,
    satellite,
    scanner,
    speaker,
    solar,
    spotlight,
    arrowDown,
    arrowLeft,
    arrowRight,
    arrowUp,
    circle,
    cube,
    cylinder,
    electric,
    hexagon,
    hexagon3D,
    octagon,
    oval,
    pentagon,
    pyramid,
    rectangle,
    rectangle3D,
    refresh,
    rhombus,
    semicircle,
    starGray,
    starRed,
    triangle,
  },
  '**COMPANY NAME**': {
    '2-Way Splitter': twoWaySplitter,
    '3-Way Splitter': threeWaySplitter,
    'AirMax AC BaseStation': airMaxBaseStation,
    'APC 1000VA': apc1000Va,
    'APC UPS': apcUpc,
    'Aruba AP': arubaAp,
    'Axis I8016 Intercom': axisIntercom,
    'Barionet 50': barionet,
    Battery: rigstarBattery,
    Building: building,
    'Cambium  Radio': cambiumRadio,
    "Cambium - 2' Dual Polar Dish": cambiumPolarDish,
    'Cambium Dish': cambiumDish,
    'Cambium PMP': cambiumPmp,
    'Cambium PTP': cambiumPtp,
    'Cargo Container': cargoContainer,
    'Cell Booster (Internal)': cellBooster,
    'Cell Booster System (Internal)': cellBoosterSystem,
    'Cell Tower': cellTower,
    Cellular: cellular,
    'Cisco 2960-8TC-L': cisco2960,
    'Cisco 2960G-8TC-L': cisco2960G8,
    'Cisco 2960G-24TC-L': cisco2960G24,
    'Cisco 2960S-24PS-L': cisco2960S24,
    'Cisco 2960S-48FPS-L': cisco2960S48,
    'Cisco 3560C-8PC-S': cisco3560C,
    'Cisco 3560CG-8PC-S': cisco3560CG,
    'Cisco 3750X-48T-S': cisco3750,
    'Cisco 4900M': cisco4900,
    'Cisco 4948-10GE': cisco4948GE,
    'Cisco 4948': cisco4948,
    'Cisco Air-CT2504-15-K9': ciscoAir,
    'Cisco Catalyst - 8P': ciscoCatalyst,
    'Cisco Indoor AP': ciscoIndoorAp,
    'Cisco Router 2911': ciscoRouter,
    'Cisco Switch-Catalyst-2960s': ciscoSwitch,
    'Communications Cabinet': communicationCabinet,
    'Concrete Blocks for Guy Wires': concreteBlock,
    'Demark Switch': demarkSwitch,
    'Directional Antenna': directionalAntenna,
    'Directional Panel Antenna': directionalPanel,
    'Dragonwave 18G': dragonWave,
    'Drilling Rig': drillingRig,
    Duplexer: duplexer,
    'Gigabit 8-port Switch': gigabitSwitch,
    'Internet Access': internetAccess,
    Internet: internet,
    'Iridium Antenna': iridiumAntenna,
    'Ku Band': kuBand,
    Laptop: laptop,
    'LTE Antenna': lteAntenna,
    'Meraki AP': merakiAp,
    'Meraki MR32 Indoor AP': merakiMr32Indoor,
    'Meraki MR32': merakiMr32,
    'Meraki MR72': merakiMr72,
    'Meraki MS220-8P': merakiMs220,
    'Meraki MX64': merakiMx64,
    'Meraki MX64W': merakiMx64W,
    'Meraki MX80 Router': merakiMx80,
    'Meraki MX84 Router': merakiMx84,
    'Meraki MX100 Router': merakiMx100,
    'Meraki Wi-Fi Router': merakiWifi,
    'Microhard IPN3G': microhard,
    'Microwave Antenna - Left': microwaveAntenna,
    'Microwave Tower': microwaveTower,
    'Mikrotik hEXlite': mikrotikHexlite,
    'Mikrotik VPN': mikrotikVpn,
    'Mimosa - A5 AP': mimosaA5,
    'Mimosa - C5 SM': mimosaC5,
    'Mimosa B5 Backhaul': mimosaB5,
    'Motorola Gang Charger': gangCharger,
    'Non-Penetrating Roof Mount': nonPenetratingMount,
    'Outdoor Speaker': outdoorSpeaker,
    'Outdoor VoIP Door Speaker': voipDoorSpeaker,
    'Outdoor VoIP Speaker': voipSpeaker,
    'Panel Antenna': panelAntenna,
    'Patch Panel (8 Port)': patchPanel8,
    'Patch Panel (24 port)': patchPanel24,
    'Peplink - Balance One': peplinkBalanceOne,
    'Peplink AP': peplinkAP,
    'Peplink Balance 580': peplinkBalace580,
    'Pepwave Max700': pepwaveMax,
    Pepwave: pepWave,
    Pipemast: pipemast,
    PoE: poe,
    'Polycom Conference Phone IP6000': polycomPhone,
    'Portable Tower': portableTower,
    Portable: portable,
    'POTS Phone': potsPhone,
    'PowerBeam AC -ISO': powerBeamAC,
    'Printer 1': printerOne,
    'Printer 2': printerTwo,
    'PT3N Hybrid Coupler': pt3N,
    'PT4.8N Hybrid Coupler': pt4N,
    'PT6N Hybrid Coupler': pt6N,
    'PT8N Hybrid Coupler': pt8N,
    'PT10 Hybrid Coupler': pt10,
    'PTP 650 - Connectorized': ptp650,
    PTP820: ptp820,
    'Radio Battery': radioBattery,
    Rigweb: rigweb,
    'RST100 Terminal - Satellite Phones': rst100,
    'RST970 - Intelligent Handset - Sat': rst970,
    'Satellite Arm': satelliteArm,
    'Satellite Internet - Viasat': satelliteInternet,
    'Skid Shack': skidShack,
    'Speaker Mic': speakerMic,
    'Starlink Subscriber': starlink,
    'Surecall Force 5 Cell Booster': surecallForce,
    'Surge Protector': surgeProtector,
    'Surveillance Camera': surveillanceCamera,
    Switch: switch4,
    'Switch (8 Port)': switch8,
    'Switch (16 Port)': switch16,
    'TCPL - 2 Trailer Template': tcpl,
    'Telecommunications Shelter': shelter,
    'Tripod Mount': tripodMount,
    'Trylon Super Titan': trylonTitan,
    'Trylon Tower': trylonTower,
    'UAP Pro WiFi AP': uapPro,
    'Ubiquiti Air Fibre': ubiquitiFibre,
    'Ubiquiti Rocket Prism': ubiquitiRocket,
    'UBNT - 8 Port PoE+ - SFP': ubnt8Poe,
    'UBNT - 16 Port Aggregation Switch': ubnt16Agg,
    'UBNT - 16 Port PoE SFP - 150W': ubnt16Poe,
    'UBNT - Cloud Key': ubntCloud,
    'UBNT - Gateway Security-Router - USGPro4': ubntGateway,
    'UBNT - NanoBeam Left': ubntNanobeamLeft,
    'UBNT - NanoBeam Right': ubntNanoBeamRight,
    'Unifi - 8 Port Switch - PoE': unifiSwitch8,
    'Unifi - 24 Port Switch - PoE': unifiSwitch24,
    'Unifi - Cloud Key': unifiCloud,
    'Unifi - Security - Large': unifiSecurityLarge,
    'Unifi - Security - Small': unifiSecuritySmall,
    'Unifi - UAP Pro AP': unifiUapPro,
    'UPS - 685': ups685,
    'UPS - 1500 APC V2': ups1500Apc,
    'UPS - 1500 mAh': ups1500Mah,
    'UPS - Power Backup': upsPowerBackup,
    'UPS (Small)': upsSmall,
    'UPS 1U Rack Mount - Tripplite': upsRackMount,
    'VoIP Phone': voipPhone,
    'Wifi Router': wifiRouter,
    Wifi: wifi,
    'Wireless Mini Repeater': wirelessMiniRepeater,
    'Yagi Antenna': yagiAntenna,
    'Yealink - Expandable Microphone.jpg': yealinkMicrophone,
    'Yealink 860 Conference Phone.png': yealink860,
    'Yealink T41S.png': yealinkT41,
    'Yealink T46S.jpg': yealinkT46,
  },
};
