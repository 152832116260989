import { useContext, useEffect, useState, ReactNode, useMemo } from 'react';
import { IDocumentContext, ISettingsContext } from 'state/iContext';
import { DocumentContext, SettingsContext } from 'state/context';
import { IGuyInspectionHandlers } from '../iDocumentEditor';
import { nextAvailableKey } from 'helpers/helperFunctions';
import DocGuyInspectionLine from './DocGuyInspectionLine';
import DocumentInputField from '../DocumentInputField';
import { IInputEvent, IStringProps } from 'iApp';
import { IEditFileProps } from 'state/iState';
import ToggleOption from '../ToggleOption';

const DocGuyInspection = () => {
  const { scale } = useContext<ISettingsContext | undefined>(SettingsContext)!;
  const { setActiveFilePanel, editFileProps, setEditFileProps } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;

  const clearInputs: IStringProps = useMemo(
    () => ({
      level: '',
      elevation: '',
      anchor: '',
      sizeType: '',
      initial: '',
      specified: '',
      range: '',
      measured: '',
      shackle: '',
      turnbuckle: '',
      gap: '',
    }),
    []
  );
  const [inputs, setInputs] = useState<IStringProps>(clearInputs);
  const [activeEdit, setActiveEdit] = useState<string>();

  const nextKey: number = nextAvailableKey(
    editFileProps.contents.guy.content,
    0
  )!;

  const {
    level,
    elevation,
    anchor,
    sizeType,
    initial,
    specified,
    range,
    measured,
    shackle,
    turnbuckle,
    gap,
  } = inputs;

  const emptyFields: boolean =
    !level &&
    !elevation &&
    !anchor &&
    !sizeType &&
    !initial &&
    !specified &&
    !range &&
    !measured &&
    !shackle &&
    !turnbuckle &&
    !gap;

  const classes: IStringProps = useMemo(
    () => ({
      panel: 'relative flex flex-col w-full h-full p-0.5 justify-between',
      header: 'w-full text-center text-lg text-stone-400 font-bold mb-4',
      title: 'w-[9.09%] p-1 border border-stone-500 text-center text-stone-500',
      input: 'w-[9.09%] px-1 py-0 border border-stone-500',
      box: 'flex flex-col w-full h-full bg-slate-200 p-2 rounded',
      table:
        'flex flex-col w-full h-5/6 px-6 mb-20 justify-start overflow-y-auto',
      tableTop: 'flex w-full px-6 justify-center',
      add: 'self-end w-1/6 p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600 disabled:bg-stone-400',
      back: 'w-1/6 p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
      activeLeft:
        'w-[9.09%] p-1 border border-l-4 border-stone-500 border-l-red-600 text-center text-stone-500',
      activeRight:
        'w-[9.09%] p-1 border border-r-4 border-stone-500 border-r-red-600 text-center text-stone-500',
    }),
    []
  );

  const tableLines: ReactNode[] = Object.keys(
    editFileProps.contents.guy.content
  ).map((id: string) => {
    const { [+id]: toBeRemoved, ...rest } = editFileProps.contents.guy.content;

    const clickHandler: IGuyInspectionHandlers = {
      edit: (e: IInputEvent) => {
        e.stopPropagation();
        setActiveEdit(id);
        setInputs(editFileProps.contents.guy.content[id]);
        if (activeEdit) {
          setActiveEdit(undefined);
          setInputs(clearInputs);
        }
      },
      remove: () => {
        setEditFileProps((prev: IEditFileProps) => ({
          ...prev,
          contents: {
            ...prev.contents,
            guy: {
              ...prev.contents.guy,
              content: rest,
            },
          },
        }));
        if (activeEdit === id) {
          setActiveEdit(undefined);
          setInputs(clearInputs);
        }
      },
    };

    return (
      <DocGuyInspectionLine
        clickHandler={clickHandler}
        activeEdit={activeEdit}
        key={id}
        id={id}
      />
    );
  });

  useEffect(() => {
    setInputs(clearInputs);
    setActiveEdit(undefined);
  }, []);

  useEffect(() => {
    if (!editFileProps.contents.guy.active) {
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          pages: prev.contents.pages.filter((str: string) => str !== 'guy'),
        },
      }));
    }
  }, [editFileProps.contents.guy.active]);

  return (
    <div
      className={classes.panel}
      onDoubleClick={() => {
        if (activeEdit) {
          setActiveEdit(undefined);
          setInputs(clearInputs);
        }
      }}>
      <h1 className={classes.header}>Guy Tension & Hardware</h1>
      <ToggleOption
        name='Active Page'
        value={editFileProps.contents.guy.active}
        onChange={() => {
          setEditFileProps((prev: IEditFileProps) => ({
            ...prev,
            contents: {
              ...prev.contents,
              guy: {
                ...prev.contents.guy,
                active: !prev.contents.guy.active,
              },
              pages: [...prev.contents.pages, 'guy'],
            },
          }));
        }}
      />
      <div className={classes.table}>
        <div className={classes.tableTop}>
          <div className={classes.title}>Guy Level</div>
          <div className={classes.title}>Elevation (ft)</div>
          <div className={classes.title}>Guy Anchor</div>
          <div className={classes.title}>Guy Size and Type</div>
          <div className={classes.title}>Initial Tension (kips)</div>
          <div className={classes.title}>Specified Tension (kips)</div>
          <div className={classes.title}>Allowable Range (kips)</div>
          <div className={classes.title}>Measured Tension (kips)</div>
          <div className={classes.title}>Shackle Size</div>
          <div className={classes.title}>Turnbuckle Size</div>
          <div className={classes.title}>Measured Gap</div>
        </div>
        {tableLines}
      </div>
      <div className={classes.box}>
        <DocumentInputField
          value={level === 'N/A' ? '' : level}
          name='Guy Level'
          onDoubleClick={(e) => e.stopPropagation()}
          onChange={(e: IInputEvent) =>
            setInputs((prev: IStringProps) => ({
              ...prev,
              level: e.target.value,
            }))
          }
        />
        <DocumentInputField
          value={elevation === 'N/A' ? '' : elevation}
          name={`Elevation (${scale})`}
          onDoubleClick={(e) => e.stopPropagation()}
          onChange={(e: IInputEvent) =>
            setInputs((prev: IStringProps) => ({
              ...prev,
              elevation: e.target.value,
            }))
          }
        />
        <DocumentInputField
          value={anchor === 'N/A' ? '' : anchor}
          name='Guy Anchor'
          onDoubleClick={(e) => e.stopPropagation()}
          onChange={(e: IInputEvent) =>
            setInputs((prev: IStringProps) => ({
              ...prev,
              anchor: e.target.value,
            }))
          }
        />
        <DocumentInputField
          value={sizeType === 'N/A' ? '' : sizeType}
          name='Guy Size & Type'
          onDoubleClick={(e) => e.stopPropagation()}
          onChange={(e: IInputEvent) =>
            setInputs((prev: IStringProps) => ({
              ...prev,
              sizeType: e.target.value,
            }))
          }
        />
        <DocumentInputField
          value={initial === 'N/A' ? '' : initial}
          name='Initial Tension (kips)'
          onDoubleClick={(e) => e.stopPropagation()}
          onChange={(e: IInputEvent) =>
            setInputs((prev: IStringProps) => ({
              ...prev,
              initial: e.target.value,
            }))
          }
        />
        <DocumentInputField
          value={specified === 'N/A' ? '' : specified}
          name='Specified Tension (kips)'
          onDoubleClick={(e) => e.stopPropagation()}
          onChange={(e: IInputEvent) =>
            setInputs((prev: IStringProps) => ({
              ...prev,
              specified: e.target.value,
            }))
          }
        />
        <DocumentInputField
          value={range === 'N/A' ? '' : range}
          name='Allowable Range (kips)'
          onDoubleClick={(e) => e.stopPropagation()}
          onChange={(e: IInputEvent) =>
            setInputs((prev: IStringProps) => ({
              ...prev,
              range: e.target.value,
            }))
          }
        />
        <DocumentInputField
          value={measured === 'N/A' ? '' : measured}
          name='Measured Tension (kips)'
          onDoubleClick={(e) => e.stopPropagation()}
          onChange={(e: IInputEvent) =>
            setInputs((prev: IStringProps) => ({
              ...prev,
              measured: e.target.value,
            }))
          }
        />
        <DocumentInputField
          value={shackle === 'N/A' ? '' : shackle}
          name='Shackle Size'
          onDoubleClick={(e) => e.stopPropagation()}
          onChange={(e: IInputEvent) =>
            setInputs((prev: IStringProps) => ({
              ...prev,
              shackle: e.target.value,
            }))
          }
        />
        <DocumentInputField
          value={turnbuckle === 'N/A' ? '' : turnbuckle}
          name='Turnbuckle Size'
          onDoubleClick={(e) => e.stopPropagation()}
          onChange={(e: IInputEvent) =>
            setInputs((prev: IStringProps) => ({
              ...prev,
              turnbuckle: e.target.value,
            }))
          }
        />
        <DocumentInputField
          value={gap === 'N/A' ? '' : gap}
          name='Measured Gap'
          onDoubleClick={(e) => e.stopPropagation()}
          onChange={(e: IInputEvent) =>
            setInputs((prev: IStringProps) => ({
              ...prev,
              gap: e.target.value,
            }))
          }
        />
        <button
          disabled={emptyFields}
          onClick={() => {
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                guy: {
                  ...prev.contents.guy,
                  content: {
                    ...prev.contents.guy.content,
                    [activeEdit ? activeEdit : nextKey]: {
                      level: inputs.level.trim() || 'N/A',
                      elevation: inputs.elevation.trim() || 'N/A',
                      anchor: inputs.anchor.trim() || 'N/A',
                      sizeType: inputs.sizeType.trim() || 'N/A',
                      initial: inputs.initial.trim() || 'N/A',
                      specified: inputs.specified.trim() || 'N/A',
                      range: inputs.range.trim() || 'N/A',
                      measured: inputs.measured.trim() || 'N/A',
                      shackle: inputs.shackle.trim() || 'N/A',
                      turnbuckle: inputs.turnbuckle.trim() || 'N/A',
                      gap: inputs.gap.trim() || 'N/A',
                    },
                  },
                },
              },
            }));
            setInputs(clearInputs);
          }}
          className={classes.add}>
          {activeEdit ? 'Update' : 'Add'}
        </button>
      </div>
      <button
        onClick={() => setActiveFilePanel('')}
        className={classes.back}>
        Back
      </button>
    </div>
  );
};

export default DocGuyInspection;
