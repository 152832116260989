import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import PDFBuilder from './documentEditor/PDFBuilder';
import { IDocumentContext } from 'state/iContext';
import FileList from './fileList/files/FileList';
import { DocumentContext } from 'state/context';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { useContext, useEffect } from 'react';
import DisplayFile from './DisplayFile';
import { IStringProps } from 'iApp';
import Footer from 'Footer';

const TowerFiles = () => {
  const { activeFile, editFileProps, setEditFileProps, clearEditFileProps } =
    useContext<IDocumentContext | undefined>(DocumentContext)!;

  const classes: IStringProps = {
    container: 'flex flex-col w-full h-[90%] items-center',
    title:
      'w-[95%] text-center text-xl font-bold text-stone-500 border-b border-b-2 border-red-500',
    button:
      'p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
  };

  useEffect(() => setEditFileProps(clearEditFileProps), []);

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>FILE LIST</h1>
      {editFileProps.type === 'doc' ? (
        <PDFBuilder />
      ) : (
        <>{activeFile ? <DisplayFile /> : <FileList />}</>
      )}
      <Footer />
    </div>
  );
};

export default TowerFiles;
