import { useEffect, useMemo, useContext, ReactNode } from 'react';
import { ILibraryIcon, IPortBlock } from 'state/iState';
import { ICanvasContext } from 'state/iContext';
import EditPortSelect from './EditPortSelect';
import Close from 'components/buttons/Close';
import { CanvasContext } from 'state/context';
import { IInputEvent } from 'iApp';
import {
  IEditPort,
  IEditPortProps,
  IEditPortClasses,
  IEditPortOptions,
} from '../iPorts';

const EditPort = ({
  blocks,
  editPort,
  setEditIcon,
  setEditPort,
}: IEditPort) => {
  const { portDetails, setPortDetails, emptyPortBlock }  = useContext<ICanvasContext | undefined>(CanvasContext)!;

  const classes: IEditPortClasses = {
    title: 'font-bold my-3',
    container:
      'relative flex flex-wrap md:flex-col h-full border border-stone-300 rounded-sm bg-slate-100 justify-center items-center text-sm',
    portName: 'pt-1 font-bold',
    inputs: 'p-2 pt-0 flex flex-wrap justify-center',
    label: 'my-1 py-0 w-[90%] h-[25px]',
    save: 'rounded-md mb-0.5 px-2 border border-red-700 text-red-700 transition ease-in-out delay-100 hover:bg-red-100 md:absolute md:bottom-0 md:right-0 md:m-5',
  };

  const optionLists: IEditPortOptions = useMemo(
    () => ({
      type: [
        'Port Type',
        'IP',
        'Data',
        'Radio',
        'RX',
        'TX',
        'ANT',
        'AC',
        'DC',
        'Analog',
        'Fiber',
        'D-Video',
        'D-Audio',
        'A-Audio',
        'A-Video',
        'HDMI/DVI',
      ],
      side: [
        'Port Side',
        'Auto',
        'Left',
        'Right',
        'Top',
        'Bottom',
        'Left/Right',
        'Top/Bottom',
      ],
      limit: ['Port Limit', '0', '1', '2', '3', '4'],
      grouping: ['Port Grouping', 'X', 'Y', 'Z'],
    }),
    []
  );

  const options: ReactNode[] = useMemo(
    () =>
      Object.keys(optionLists).map(
        (option): ReactNode => (
          <EditPortSelect
            key={option}
            name={option}
            defaultValue={portDetails[option]}
            optionList={optionLists[option]}
            setPortDetails={setPortDetails}
          />
        )
      ),
    [portDetails, setPortDetails, optionLists]
  );

  useEffect(() => {
    if (blocks[editPort]) setPortDetails(blocks[editPort]);
  }, [blocks, setPortDetails, editPort]);

  const props: IEditPortProps = {
    input: {
      type: 'text',
      name: 'label',
      id: 'edit-port-label',
      placeholder: 'Port Label',
      className: classes.label,
      defaultValue: portDetails.label,
      onChange: (e: IInputEvent) =>
        setPortDetails((prev: IPortBlock) => ({
          ...prev,
          label: e.target.value,
        })),
    },
    button: {
      onClick: () => {
        setEditIcon((prev: ILibraryIcon) => ({
          ...prev,
          ports: {
            ...prev.ports,
            blocks: {
              ...prev.ports.blocks,
              [editPort]: portDetails,
            },
          },
        }));
        setEditPort('');
        setPortDetails(emptyPortBlock);
      },
      className: classes.save,
    },
  };

  return (
    <>
      <div className={classes.title}>Edit Port</div>
      <div className={classes.container}>
        <Close handler={() => setEditPort('')} />
        <h1 className={classes.portName}>{editPort.toUpperCase()}</h1>
        <div className={classes.inputs}>
          <input {...props.input} />
          {options}
        </div>
        <button {...props.button}>SAVE</button>
      </div>
    </>
  );
};

export default EditPort;
