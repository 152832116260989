import { ISettingsContext } from 'state/iContext';
import { SettingsContext } from 'state/context';
import { IImageThumbnail } from 'iApp';
import { useContext } from 'react';

const ImageThumbnail = ({ img, position }: IImageThumbnail) => {
  const { minimize } = useContext<ISettingsContext | undefined>(
    SettingsContext
  )!;

  const isMinimized: string = minimize ? 'p-2' : '';
  const imgClass: string = `rounded-full min-w-[80px] min-h-[80px] max-w-[80px] max-h-[80px] m-3 ${isMinimized} object-fill self-${position} text-center`;

  return (
    <img
      src={img}
      alt='tower'
      className={imgClass}
    />
  );
};

export default ImageThumbnail;
