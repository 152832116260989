import { INumberProps } from 'iApp';
import {
  IStageProgressActiveClasses,
  IStageProgressClasses,
} from '../iCustomIcon';

const StageProgress = ({ stage }: INumberProps) => {
  const classes: IStageProgressClasses = {
    container:
      'flex w-full h-[50px] mt-3 bg-slate-100 text-sm text-stone-400 font-semibold',
    stageClass: {
      active:
        'relative z-30 flex flex-col w-1/3 items-center bg-stone-200 border border-l-0 border-2 border-red-600 text-red-600 justify-center font-bold md:flex-row',
      inactive:
        'relative z-30 flex flex-col w-1/3 items-center border border-l-0 border-2 border-l-0 border-stone-300 justify-center md:flex-row',
    },
    number: {
      active:
        'flex w-[20px] h-[20px] p-1 m-0.5 border border-red-600 rounded-full justify-center items-center text-red-600 bg-stone-100 md:w-[30px] md:h-[30px] md:text-lg md:m-2',
      inactive:
        'flex w-[20px] h-[20px] p-1 m-0.5 border border-stone-300 rounded-full justify-center items-center bg-stone-200 md:w-[30px] md:h-[30px] md:text-lg md:m-2',
    },
    overlap: {
      left: {
        active:
          'absolute z-40 -left-2.5 w-[20px] h-[20px] bg-stone-200 border-r-2 border-b-2 border-r-red-600 border-b-red-600 -rotate-45',
        inactive:
          'absolute z-40 -left-2.5 w-[20px] h-[20px] bg-slate-100 border-r-2 border-b-2 border-r-stone-300 border-b-stone-300 -rotate-45',
      },
      right: {
        active:
          'absolute z-40 -right-3 w-[20px] h-[20px] bg-stone-200 border-r-2 border-b-2 border-r-red-600 border-b-red-600 -rotate-45',
        inactive:
          'absolute z-40 -right-3 w-[20px] h-[20px] bg-slate-100 border-r-2 border-b-2 border-r-stone-300 border-b-stone-300 -rotate-45',
      },
    },
  };

  const { container, stageClass, number, overlap } = classes;
  const activeClasses: IStageProgressActiveClasses = {
    one: {
      stage:
        stage === 1
          ? stageClass.active + ' border-l-2'
          : stageClass.inactive + ' border-l-2',
      number: stage === 1 ? number.active : number.inactive,
    },
    two: {
      stage: stage === 2 ? stageClass.active : stageClass.inactive,
      number: stage === 2 ? number.active : number.inactive,
      overlap: stage < 2 ? overlap.left.active : overlap.left.inactive,
    },
    three: {
      stage: stage >= 3 ? stageClass.active : stageClass.inactive,
      number: stage >= 3 ? number.active : number.inactive,
      overlap: stage === 2 ? overlap.left.active : overlap.left.inactive,
    },
  };

  return (
    <div className={container}>
      <div className={activeClasses.one.stage}>
        <div className={activeClasses.one.number}>1</div>
        Image
      </div>
      <div className={activeClasses.two.stage}>
        <div className={activeClasses.two.overlap} />
        <div className={activeClasses.two.number}>2</div>
        Ports
      </div>
      <div className={activeClasses.three.stage}>
        <div className={activeClasses.three.overlap} />
        <div className={activeClasses.three.number}>3</div>
        Properties
      </div>
    </div>
  );
};

export default StageProgress;
