import { DocumentContext, CanvasContext, SitesContext } from 'state/context';
import { IFileListButtonHandlers } from '../../iTowerFiles';
import { IEditFileProps, ITowerList } from 'state/iState';
import { IStringProps } from 'iApp';
import { useContext } from 'react';
import {
  IDocumentContext,
  ICanvasContext,
  ISitesContext,
} from 'state/iContext';

const FileListButtons = ({ isActive, id, parentID }: IStringProps) => {
  const {
    editFileProps,
    setEditFileProps,
    clearEditFileProps,
    setUpdateFolders,
  } = useContext<IDocumentContext | undefined>(DocumentContext)!;
  const { activeTowerID, clearAddProps, setAddItemProps } = useContext<
    ICanvasContext | undefined
  >(CanvasContext)!;
  const { towerList, setTowerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;

  const { [id]: toBeRemoved, ...rest } =
    towerList[activeTowerID].documents.files;

  const classes: IStringProps = {
    container: 'flex flex items-center',
    move:
      isActive === 'edit'
        ? 'hidden'
        : 'fa-solid fa-arrows-up-down-left-right mx-2 text-stone-500 hover:text-red-600',
    left:
      isActive === 'edit'
        ? 'fa-solid fa-check ml-5 mr-2 text-stone-500 valid:hover:text-red-600 disabled:opacity-50 '
        : 'fa-solid fa-pen-to-square mx-2 text-stone-500 hover:text-red-600',
    right:
      isActive === 'edit'
        ? 'fa-solid fa-x mx-2 text-stone-500 hover:text-red-600'
        : 'fa-solid fa-trash-can mx-2 text-stone-500 hover:text-red-600',
  };

  const handlers: IFileListButtonHandlers = {
    edit: {
      left: async () => {
        await setTowerList((prev: ITowerList) => ({
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            documents: {
              ...prev[activeTowerID].documents,
              files: {
                ...prev[activeTowerID].documents.files,
                [id]: {
                  ...prev[activeTowerID].documents.files[id],
                  name: editFileProps.contents.name.trim(),
                  expiry: editFileProps.contents?.date,
                },
              },
            },
          },
        }));
        setEditFileProps(clearEditFileProps);
      },
      right: () => {
        setEditFileProps(clearEditFileProps);
        setAddItemProps(clearAddProps);
      },
    },
    static: {
      move: () => {
        setUpdateFolders({
          active: true,
          move: true,
          folder: parentID,
          parent: '',
          name: id,
        });
      },
      left: () => {
        towerList[activeTowerID].documents.files[id].isPDF
          ? setEditFileProps((prev: IEditFileProps) => ({
              type: 'pdf',
              id: id,
              contents: {
                ...prev.contents,
                name: towerList[activeTowerID].documents.files[id].name,
              },
            }))
          : setEditFileProps({
              //TODO: Correct logic
              type: 'doc',
              id: id,
              contents: towerList[activeTowerID].documents.files[id].file,
            });
      },
      right: () => {
        setTowerList((prev: ITowerList) => ({
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            documents: {
              ...prev[activeTowerID].documents,
              files: rest,
              folders: {
                ...prev[activeTowerID].documents.folders,
                [parentID]: {
                  ...prev[activeTowerID].documents.folders[parentID],
                  files: prev[activeTowerID].documents.folders[
                    parentID
                  ].files.filter((file: string) => file !== id),
                },
              },
            },
          },
        }));
        setEditFileProps(clearEditFileProps);
        setAddItemProps(clearAddProps);
      },
    },
  };

  return (
    <div className={classes.container}>
      <button
        onClick={handlers[isActive].move}
        className={classes.move}
        disabled={isActive === 'edit' ? !editFileProps.contents.name : false}
      />
      <button
        onClick={handlers[isActive].left}
        className={classes.left}
        disabled={isActive === 'edit' ? !editFileProps.contents.name : false}
      />
      <button
        onClick={handlers[isActive].right}
        className={classes.right}
      />
    </div>
  );
};

export default FileListButtons;
