import {
  IEditContext,
  IMapContext,
  ISitesContext,
  IUserContext,
} from 'state/iContext';
import { useContext, ReactElement, useMemo } from 'react';
import {
  EditContext,
  MapContext,
  SitesContext,
  UserContext,
} from 'state/context';
import { INumberProps, IStringProps } from 'iApp';

const FavoritesButton = ({ towerId }: INumberProps) => {
  const { setFavorites } = useContext<IUserContext | undefined>(UserContext)!;
  const { favoritesTab, favoriteSites } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;
  const { setInfoWindowID } = useContext<IMapContext | undefined>(MapContext)!;
  const { isEditPanelOpen } = useContext<IEditContext | undefined>(
    EditContext
  )!;

  // Component classes
  const classes: IStringProps = {
    nonFavorite:
      'fa-regular fa-star z-50 m-1 text-stone-600 hover:text-red-600 cursor-pointer',
    favorite:
      'fa-solid fa-star z-50 m-1 text-red-600 hover:text-red-300 cursor-pointer',
  };

  // Adds selected site to user favorites list
  const favoriteClickHandler = useMemo(
    () => (): void => {
      const updatedFavorites: number[] = [...favoriteSites];
      const index: number = updatedFavorites.findIndex((id) => id === towerId);
      if (!isEditPanelOpen) {
        if (favoritesTab) {
          setInfoWindowID('');
        }
        updatedFavorites.splice(index, 1);
        setFavorites(updatedFavorites);
      }
    },
    [
      towerId,
      favoritesTab,
      setFavorites,
      favoriteSites,
      isEditPanelOpen,
      setInfoWindowID,
    ]
  );

  // Removes selected site from user favorites list
  const nonFavoriteClickHandler = useMemo(
    () => (): void => {
      if (!isEditPanelOpen) {
        setFavorites((prev: number[]) => [...prev, towerId]);
      }
    },
    [towerId, setFavorites, isEditPanelOpen]
  );

  // Renders star icon to respective favorite status
  const star: ReactElement<any, any> = useMemo(
    () =>
      favoriteSites.includes(towerId) ? (
        <i
          className={classes.favorite}
          onClick={favoriteClickHandler}
        />
      ) : (
        <i
          className={classes.nonFavorite}
          onClick={nonFavoriteClickHandler}
        />
      ),
    [
      towerId,
      favoriteSites,
      classes.favorite,
      classes.nonFavorite,
      favoriteClickHandler,
      nonFavoriteClickHandler,
    ]
  );
  return <>{star}</>;
};

export default FavoritesButton;
