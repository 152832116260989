import { useContext, useState, useMemo, useRef, useEffect } from 'react';
import { ICropSaveMethods, IChangeImageStates } from 'helpers/iHelpers';
import { ICallbackProps, IInputEvent, IStringProps } from 'iApp';
import DocCropImageWindow from '../images/DocCropImageWindow';
import { nextAvailableKey } from 'helpers/helperFunctions';
import ImageCropHelpers from 'helpers/imageCropHelpers';
import DocCroppedImage from '../images/DocCroppedImage';
import DocImageCapture from '../images/DocImageCapture';
import { IDocumentContext } from 'state/iContext';
import { DocumentContext } from 'state/context';
import { IEditFileProps } from 'state/iState';

const DocInventoryImagePanel = ({ panel }) => {
  const { editFileProps, setEditFileProps } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;

  const [cropImage, setCropImage] = useState<{
    image: string;
    label: string;
    editID?: string;
  }>({
    image: '',
    label: '',
  });

  const classes: IStringProps = useMemo(
    () => ({
      container:
        'flex flex-col w-full h-full border border-stone-400 rounded rounded-lg items-center',
      title: 'w-full p-2 text-center text-lg text-stone-400 font-bold',
      button:
        'p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
      zoomable: 'relative flex flex-col h-[350px] w-[300px]',
      canvas: `relative h-[350px] w-[300px] bg-stone-200`,
      transform: 'w-full h-full',
    }),
    []
  );

  const nextKey: number = nextAvailableKey(
    editFileProps.contents.inventory.images[panel],
    0
  )!;

  const {
    imgSrc,
    setImgSrc,
    setImgSrcExt,
    onCropSave,
    getImageFileExtension,
    setScaleDimensions,
  } = ImageCropHelpers(cropImage.image);

  const changeImageStates = ({ image, save }: IChangeImageStates): void => {
    setImgSrc(image);
    setImgSrcExt(getImageFileExtension(image));
    if (save) {
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          inventory: {
            ...prev.contents.inventory,
            images: {
              ...prev.contents.inventory.images,
              [panel]: {
                ...prev.contents.inventory.images[panel],
                [cropImage.editID ? cropImage.editID : nextKey]: {
                  image: cropImage.image,
                  label: cropImage.label,
                },
              },
            },
          },
        },
      }));
      setCropImage({ image: '', label: '' });
      setImgSrc('');
    }
  };

  // Methods to envoke while saving the cropped image
  const cropSaveMethods: ICropSaveMethods = {
    changeImageStates,
    onComplete: () => {},
  };

  // Props to pass to their respective elements
  const props = {
    img: {
      id: 'displayed-tower-image',
      className: 'h-[350px] w-[300px]',
      src: imgSrc,
    },
  };

  const updateImage = (data: string) => {
    setImgSrc(data);
    setCropImage((prev) => ({
      ...prev,
      image: data,
    }));
  };

  const croppedImages = useMemo(
    () =>
      Object.keys(editFileProps.contents.inventory.images[panel]).map((id) => {
        const { [+id]: toBeRemoved, ...rest } =
          editFileProps.contents.inventory.images[panel];

        const handlers: ICallbackProps = {
          edit: () => {
            setImgSrc(editFileProps.contents.inventory.images[panel][id].image);
            setCropImage({
              image: editFileProps.contents.inventory.images[panel][id].image,
              label: editFileProps.contents.inventory.images[panel][id].label,
              editID: id,
            });
          },
          remove: () =>
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                inventory: {
                  ...prev.contents.inventory,
                  images: {
                    ...prev.contents.inventory.images,
                    [panel]: rest,
                  },
                },
              },
            })),
        };

        return (
          <DocCroppedImage
            handlers={handlers}
            panel={panel}
            key={id}
            id={id}
          />
        );
      }),
    [editFileProps.contents.inventory.images[panel]]
  );

  useEffect(
    () =>
      setScaleDimensions({
        w: 350,
        h: 300,
      }),
    []
  );

  return (
    <div className={classes.container}>
      {imgSrc ? (
        <DocImageCapture
          imgProps={props.img}
          cropImage={cropImage}
          onChange={(e: IInputEvent) =>
            setCropImage((prev: { image: string; label: string }) => ({
              ...prev,
              label: e.target.value,
            }))
          }
          onClick={{
            save: (e: IInputEvent) => {
              onCropSave({ e, cropSaveMethods });
            },
            cancel: () => setImgSrc(''),
          }}
        />
      ) : (
        <div className='flex w-full h-full'>
          <DocCropImageWindow
            grid={panel}
            updateImage={updateImage}
          />
          <div className='w-full h-full p-2 overflow-y-auto'>
            {croppedImages.length === 0 ? (
              <div className='w-full h-full border text-center'>
                <span>No Images Captures</span>
              </div>
            ) : (
              <div className='h-full'>{croppedImages}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DocInventoryImagePanel;
