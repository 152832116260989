import { ReactNode, useContext, useEffect, useMemo } from 'react';
import { nextAvailableKey } from 'helpers/helperFunctions';
import DocDeficiencyLine from './DocDeficiencyLine';
import { IDocumentContext } from 'state/iContext';
import { DocumentContext } from 'state/context';
import { IEditFileProps } from 'state/iState';
import ToggleOption from '../ToggleOption';
import { IStringProps } from 'iApp';

const DocDeficiencies = () => {
  const { setActiveFilePanel, editFileProps, setEditFileProps } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;

  const { content, active } = editFileProps.contents.deficiencies;
  const nextKey: number = nextAvailableKey(content, 0)!;

  const classes: IStringProps = useMemo(
    () => ({
      container: 'w-full h-full p-2',
      title: 'font-semibold text-stone-500',
      inputs: 'flex flex-col w-full h-5/6 overflow-y-auto overflow-x-hidden',
      add: 'flex w-1/4 justify-center p-1 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
      back: 'p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
    }),
    []
  );

  const inputs: ReactNode[] = useMemo(
    () =>
      Object.keys(content).map(
        (id, index): ReactNode => (
          <DocDeficiencyLine
            content={content}
            index={index}
            key={id}
            id={id}
          />
        )
      ),
    [content]
  );

  useEffect(() => {
    if (Object.keys(content).length === 0) {
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          deficiencies: {
            ...prev.contents.deficiencies,
            content: {
              0: {
                deficiency: '',
                recommendation: '',
                check: '',
                contrInit: '',
                page: '',
              },
            },
          },
        },
      }));
    }
  }, [content]);

  useEffect(() => {
    if (!active) {
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          pages: prev.contents.pages.filter(
            (str: string) => str !== 'deficiencies'
          ),
        },
      }));
    }
  }, [active]);

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Deficiencies</h1>
      <ToggleOption
        name='Active Page'
        onChange={() => {
          setEditFileProps((prev: IEditFileProps) => ({
            ...prev,
            contents: {
              ...prev.contents,
              deficiencies: {
                ...prev.contents.deficiencies,
                active: !prev.contents.deficiencies.active,
              },
              pages: [...prev.contents.pages, 'deficiencies'],
            },
          }));
        }}
        value={active}
      />
      <div className={classes.inputs}>{inputs}</div>
      <button
        className={classes.add}
        onClick={() =>
          setEditFileProps((prev: IEditFileProps) => ({
            ...prev,
            contents: {
              ...prev.contents,
              deficiencies: {
                ...prev.contents.deficiencies,
                content: {
                  ...prev.contents.deficiencies.content,
                  [nextKey]: {
                    deficiency: '',
                    recommendation: '',
                    check: '',
                    contrInit: '',
                    page: '',
                  },
                },
              },
            },
          }))
        }>
        Add
      </button>
      <button
        onClick={() => setActiveFilePanel('')}
        className={classes.back}>
        Back
      </button>
    </div>
  );
};

export default DocDeficiencies;
