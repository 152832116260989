import { IAddItemProps, ITowerDocument, ITowerList } from 'state/iState';
import { ICanvasContext, ISitesContext } from 'state/iContext';
import { CanvasContext, SitesContext } from 'state/context';
import { IActiveDrawingProps } from './iDrawings';
import { IInputEvent, IStringProps } from 'iApp';
import SaveBox from 'components/buttons/SaveBox';
import EditDrawing from './EditDrawing';
import { useContext } from 'react';

const ActiveDrawing = ({ drawing }: { drawing: ITowerDocument }) => {
  const { addItemProps, activeTowerID, clearAddProps, setAddItemProps } =
    useContext<ICanvasContext | undefined>(CanvasContext)!;
  const { setTowerList } = useContext<ISitesContext | undefined>(SitesContext)!;

  const classes: IStringProps = {
    container: 'relative w-full h-full flex flex-col items-center',
    heading: 'font-bold text-xl text-stone-400 text-center',
    img: 'h-2/3 w-fit self-center object-contain border border-2 border-stone-400',
    pdf: 'h-full self-center border border-2 border-stone-400 object-stretch',
    saveBox: 'absolute flex top-0 right-0 w-[150px] justify-around my-1 mx-5',
  };

  const isImageFile = (fileName: string) => {
    const imageExtensions = ['.png', '.jpg', '.jpeg', '.svg'];
    const extension = fileName
      .substring(fileName.lastIndexOf('.'))
      .toLowerCase();
    return imageExtensions.includes(extension);
  };

  const renderDrawing = () =>
    isImageFile(drawing.img! || drawing.file!) ? (
      <img
        alt='imported-drawing'
        src={drawing.img || drawing.file}
        className={classes.img}
      />
    ) : (
      <object
        data={drawing.img || drawing.file}
        type='application/pdf'
        className={classes.pdf}
        width='100%'
        height='100%'>
        <p>PDF cannot be displayed. Please download the PDF file to view it.</p>
      </object>
    );

  const props: IActiveDrawingProps = {
    input: {
      type: 'text',
      name: 'drawing-label',
      id: 'drawing-label',
      placeholder: 'Drawing Label',
      defaultValue: addItemProps.label,
      onChange: (e: IInputEvent) =>
        setAddItemProps((prev: IAddItemProps) => ({
          ...prev,
          label: e.target.value,
        })),
    },
    saveBox: {
      classes: classes.saveBox,
      clickHandlers: {
        save: async () => {
          await setTowerList((prev: ITowerList) => ({
            ...prev,
            [activeTowerID]: {
              ...prev[activeTowerID],
              documents: {
                ...prev[activeTowerID].documents,
                folders: {
                  ...prev[activeTowerID].documents.folders,
                  1: {
                    ...prev[activeTowerID].documents.folders[1],
                    files: [
                      ...prev[activeTowerID].documents.folders[1].files,
                      addItemProps.index!,
                    ],
                  },
                },
                files: {
                  ...prev[activeTowerID].documents.files,
                  [addItemProps.index!]: {
                    ...prev[activeTowerID].documents.files[addItemProps.index!],
                    name: addItemProps.label.trim(),
                  },
                },
              },
            },
          }));
          setAddItemProps(clearAddProps);
        },
        cancel: () => setAddItemProps(clearAddProps),
      },
      disabled: !addItemProps.label,
    },
  };

  return (
    <div className={classes.container}>
      {addItemProps.edit ? (
        <>
          <input {...props.input} />
          <SaveBox {...props.saveBox} />
        </>
      ) : (
        <>
          <h1 className={classes.heading}>{drawing.name}</h1>
          <EditDrawing />
        </>
      )}
      {renderDrawing()}
    </div>
  );
};

export default ActiveDrawing;
