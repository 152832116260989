import { DocumentContext, CanvasContext, SitesContext } from 'state/context';
import { useContext, useEffect, useState, ReactNode } from 'react';
import { ISelectEvent, IStringProps } from 'iApp';
import { ITower, IUpdateFolders } from 'state/iState';
import {
  IDocumentContext,
  ICanvasContext,
  ISitesContext,
} from 'state/iContext';
import { getDescendants } from 'helpers/helperFunctions';

const MoveFolder = ({ type }) => {
  const { activeTowerID } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;
  const { towerList, setTowerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;
  const { updateFolders, setUpdateFolders } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;

  const [target, setTarget] = useState<number>(0);

  const folders = towerList[activeTowerID].documents.folders;
  const removedFolder = folders[+updateFolders.parent].folders.filter(
    (id: string) => +id !== +updateFolders.folder
  );

  const options: ReactNode[] = Object.keys(folders)
    .filter((id) => {
      // Exclude the current folder being moved and its descendants
      const descendants = getDescendants(updateFolders.folder, folders);
      return type === 'folder'
        ? +id !== +updateFolders.folder && !descendants.includes(+id)
        : +id !== +updateFolders.folder;
    })
    .map((id, index): ReactNode => {
      const optionStyle = {
        textIndent: `${index * 10}px`,
      };

      return (
        <option
          key={id}
          value={+id}
          style={optionStyle}>
          {folders[id].name}
        </option>
      );
    });

  useEffect(() => {
    if (+updateFolders.folder === 0) {
      setTarget(+Object.keys(folders).filter((id) => +id > 0)[0]);
    }
  }, []);

  const handler = {
    change: (e: ISelectEvent) => setTarget(+e.target.value),
    save: () => {
      setTowerList((prev: ITower) =>
        type === 'folder'
          ? {
              ...prev,
              [activeTowerID]: {
                ...prev[activeTowerID],
                documents: {
                  ...prev[activeTowerID].documents,
                  folders: {
                    ...prev[activeTowerID].documents.folders,
                    [target!]: {
                      ...prev[activeTowerID].documents.folders[target],
                      folders: [
                        ...prev[activeTowerID].documents.folders[target]
                          .folders,
                        +updateFolders.folder,
                      ],
                    },
                    [updateFolders.parent]: {
                      ...prev[activeTowerID].documents.folders[
                        updateFolders.parent
                      ],
                      folders: removedFolder,
                    },
                  },
                },
              },
            }
          : {
              ...prev,
              [activeTowerID]: {
                ...prev[activeTowerID],
                documents: {
                  ...prev[activeTowerID].documents,
                  files: {
                    ...prev[activeTowerID].documents.files,
                    [+updateFolders.name]: {
                      ...prev[activeTowerID].documents.files[
                        +updateFolders.name
                      ],
                      folder: +target!,
                    },
                  },
                  folders: {
                    ...prev[activeTowerID].documents.folders,
                    [+updateFolders.folder]: {
                      ...prev[activeTowerID].documents.folders[
                        +updateFolders.folder
                      ],
                      files: prev[activeTowerID].documents.folders[
                        +updateFolders.folder
                      ].files.filter((id) => +id !== +updateFolders.name),
                    },
                    [+target!]: {
                      ...prev[activeTowerID].documents.folders[+target!],
                      files: [
                        ...prev[activeTowerID].documents.folders[+target!]
                          .files,
                        +updateFolders.name,
                      ],
                    },
                  },
                },
              },
            }
      );
      setUpdateFolders((prev: IUpdateFolders) => ({
        ...prev,
        active: false,
        move: false,
      }));
    },
    cancel: () => {
      setUpdateFolders((prev: IUpdateFolders) => ({
        ...prev,
        active: false,
        move: false,
      }));
    },
  };

  const classes: IStringProps = {
    container: 'flex justify-center items-center w-full',
    title: 'font-bold text-stone-500 mr-2',
    select: 'py-0',
    button:
      'p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
  };

  return (
    <div className={classes.container}>
      <label
        className={classes.title}
        htmlFor='name'>
        New Folder Location:
      </label>
      <select
        id='target-folder'
        name='target-folder'
        className={classes.select}
        defaultValue={updateFolders.parent}
        onChange={handler.change}>
        {options}
      </select>
      <button
        className={classes.button}
        onClick={handler.save}>
        Save
      </button>
      <button
        className={classes.button}
        onClick={handler.cancel}>
        Cancel
      </button>
    </div>
  );
};

export default MoveFolder;
