import { IActiveToolBar, ITowerList } from 'state/iState';
import { ICallbackProps, IStringProps } from 'iApp';
import { useContext } from 'react';
import {
  ISitesContext,
  ICanvasContext,
  IHeaderContext,
  ISettingsContext,
} from 'state/iContext';
import {
  SitesContext,
  CanvasContext,
  HeaderContext,
  SettingsContext,
} from 'state/context';

const GridItemOptions = () => {
  const { minimize, setMinimize } = useContext<ISettingsContext | undefined>(
    SettingsContext
  )!;
  const { activeCanvasTab } = useContext<IHeaderContext | undefined>(
    HeaderContext
  )!;
  const { towerList, setTowerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;
  const {
    dragProps,
    clearPanel,
    setDragProps,
    activeTowerID,
    clearDragProps,
    setAddItemProps,
    setActiveToolBar,
  } = useContext<ICanvasContext | undefined>(CanvasContext)!;

  const activeGrid: IStringProps = {
    data: 'shelves',
    tower: 'legs',
  };

  const key: IStringProps = {
    drawings: 'drawings',
    images: 'image',
    textBoxes: 'text',
    sections: 'sections',
  };

  const content: IStringProps = {
    drawings: 'refID',
    images: 'img',
    textBoxes: 'text',
  };

  const classes: IStringProps = {
    label: 'w-full bg-slate-400 text-center',
    container: minimize
      ? 'flex flex-col h-full w-full bg-red-600 self-center justify-center'
      : 'flex h-[32px] w-full bg-red-600',
    button: minimize
      ? 'flex h-full w-full justify-center items-center text-slate-200 transition ease-in-out delay-100 hover:bg-red-700'
      : 'flex w-full justify-center items-center text-slate-200 transition ease-in-out delay-100 hover:bg-red-700',
    edit: 'fa-solid fa-pen-to-square',
    remove: 'fa-solid fa-trash-can',
  };

  const towerLayout = towerList[activeTowerID].layout.tower;
  const siteLayout = towerList[activeTowerID].layout.site;
  const dataLayout = towerList[activeTowerID].layout.data;

  const target = {
    tower: {
      asset: towerLayout.legs[+dragProps.target!]?.icons,
      drawings: towerLayout.drawings,
      images: towerLayout.images,
      textBoxes: towerLayout.textBoxes,
      sections: towerLayout.legs[+dragProps.target!]?.sections,
    },
    site: {
      asset: siteLayout.assets,
      drawings: siteLayout.drawings,
      images: siteLayout.images,
      textBoxes: siteLayout.textBoxes,
    },
    data: {
      asset: dataLayout.shelves[dragProps.target!]?.icons,
      drawings: dataLayout.drawings,
      images: dataLayout.images,
      textBoxes: dataLayout.textBoxes,
      sections: dataLayout.shelves[dragProps.target!]?.sections,
    },
  };

  const activeTarget = target[activeCanvasTab][dragProps.type!][dragProps.id];

  const { [+dragProps.id!]: toBeRemoved, ...rest } =
    target[activeCanvasTab][dragProps.type!]!;

  const asset = {
    tower: () => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            tower: {
              ...prev[activeTowerID].layout.tower,
              legs: {
                ...prev[activeTowerID].layout.tower.legs,
                [dragProps.target!]: {
                  ...prev[activeTowerID].layout.tower.legs[dragProps.target!],
                  icons: rest,
                },
              },
            },
          },
        },
      }));
    },
    site: () => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            site: {
              ...prev[activeTowerID].layout.site,
              assets: rest,
            },
          },
        },
      }));
    },
    data: () =>
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            data: {
              ...prev[activeTowerID].layout.data,
              shelves: {
                ...prev[activeTowerID].layout.data.shelves,
                [dragProps.target!]: {
                  ...prev[activeTowerID].layout.data.shelves[dragProps.target!],
                  icons: rest,
                },
              },
            },
          },
        },
      })),
  };

  const handlers = {
    asset: asset[activeCanvasTab],
    drawings: () => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [activeCanvasTab]: {
              ...prev[activeTowerID].layout[activeCanvasTab],
              drawings: rest,
            },
          },
        },
      }));
    },
    images: () => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [activeCanvasTab]: {
              ...prev[activeTowerID].layout[activeCanvasTab],
              images: rest,
            },
          },
        },
      }));
    },
    textBoxes: () => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [activeCanvasTab]: {
              ...prev[activeTowerID].layout[activeCanvasTab],
              textBoxes: rest,
            },
          },
        },
      }));
    },
    sections: () => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [activeCanvasTab]: {
              ...prev[activeTowerID].layout[activeCanvasTab],
              [activeGrid[activeCanvasTab]]: {
                ...prev[activeTowerID].layout[activeCanvasTab][
                  activeGrid[activeCanvasTab]
                ],
                [dragProps.target!]: {
                  ...prev[activeTowerID].layout[activeCanvasTab][
                    activeGrid[activeCanvasTab]
                  ][dragProps.target!],
                  sections: rest,
                },
              },
            },
          },
        },
      }));
    },
  };

  const optionHandler: ICallbackProps = {
    edit: () => {
      clearPanel();
      setMinimize(false);
      setActiveToolBar((prev: IActiveToolBar) => ({
        ...prev,
        select: false,
        [key[dragProps.type!]]: true,
      }));
      setAddItemProps({
        label: activeTarget.name,
        content:
          dragProps.type === 'sections'
            ? dragProps.id
            : activeTarget[content[dragProps.type!]],
        index: dragProps.type === 'sections' ? dragProps.target : dragProps.id,
        edit: true,
      });
      setDragProps(clearDragProps);
    },
    remove: () => {
      handlers[dragProps.type!]();
      setDragProps(clearDragProps);
    },
  };

  return (
    <>
      {!minimize && (
        <div className={classes.label}>
          {dragProps.type === 'sections' ||
            (dragProps.target!.length > 0 && (
              <>{towerLayout.legs[dragProps.target].legGrid.name + ': '}</>
            ))}
          {dragProps.type === 'sections'
            ? activeTarget.name
            : dragProps.content}
        </div>
      )}
      <div className={classes.container}>
        {dragProps.type !== 'asset' && (
          <div
            key='edit'
            className={classes.button}
            onClick={optionHandler.edit}
            onTouchStart={optionHandler.edit}>
            <i className={classes.edit} />
          </div>
        )}{' '}
        <div
          key='delete'
          className={classes.button}
          onClick={optionHandler.remove}
          onTouchStart={optionHandler.remove}>
          <i className={classes.remove} />
        </div>
      </div>
    </>
  );
};

export default GridItemOptions;
