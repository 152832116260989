import { IDocDeficiencyLine } from '../iDocumentEditor';
import { IDocumentContext } from 'state/iContext';
import { IInputEvent, IStringProps } from 'iApp';
import { DocumentContext } from 'state/context';
import { IEditFileProps } from 'state/iState';
import Close from 'components/buttons/Close';
import { useContext, useMemo } from 'react';

const DocDeficiencyLine = ({ content, id, index }: IDocDeficiencyLine) => {
  const { setEditFileProps } = useContext<IDocumentContext | undefined>(
    DocumentContext
  )!;

  const { [+id]: toBeRemoved, ...rest } = content;

  const classes: IStringProps = useMemo(
    () => ({
      inputBox: 'relative w-full border rounded rouned-lg bg-slate-100 m-1',
      inputTop: 'flex flex-wrap w-full my-2 p-0.5 justify-between',
      inputBot: 'flex w-full my-0.5 p-0.5 justify-between',
      inputHalf: 'flex flex-col w-1/2 my-0.5 p-0.5 justify-between',
      inputThird: 'flex flex-col w-1/3 my-0.5 p-0.5 justify-between',
      labelTop: 'mx-1 text-stone-500 font-bold',
      labelBot: 'mx-1 text-stone-500 font-bold text-sm',
      textarea: 'p-0 w-full placeholder:px-2',
    }),
    []
  );

  const changeHandler = (e: IInputEvent, name: string) =>
    setEditFileProps((prev: IEditFileProps) => ({
      ...prev,
      contents: {
        ...prev.contents,
        deficiencies: {
          ...prev.contents.deficiencies,
          content: {
            ...prev.contents.deficiencies.content,
            [id]: {
              ...prev.contents.deficiencies.content[id],
              [name]: e.target.value,
            },
          },
        },
      },
    }));

  return (
    <div
      key={id}
      className={classes.inputBox}>
      <Close
        handler={() =>
          setEditFileProps((prev: IEditFileProps) => ({
            ...prev,
            contents: {
              ...prev.contents,
              deficiencies: {
                ...prev.contents.deficiencies,
                content: rest,
              },
            },
          }))
        }
      />
      <div className={classes.inputTop}>
        <div className={classes.inputHalf}>
          <label
            htmlFor={'deficiency-' + index}
            className={classes.labelTop}>
            Deficiency #{Number(index) + 1}
          </label>
          <textarea
            id={'deficiency-' + index}
            name={'deficiency-' + index}
            className={classes.textarea}
            defaultValue={content[id].deficiency}
            onChange={(e) => changeHandler(e, 'deficiency')}
          />
        </div>
        <div className={classes.inputHalf}>
          <label
            className={classes.labelTop}
            htmlFor={'recommendation-' + index}>
            Recommendation
          </label>
          <textarea
            className={classes.textarea}
            id={'recommendation-' + index}
            name={'recommendation-' + index}
            defaultValue={content[id].recommendation}
            onChange={(e) => changeHandler(e, 'recommendation')}
          />
        </div>
      </div>
      <div className={classes.inputBot}>
        <div className={classes.inputThird}>
          <label
            htmlFor={'check-' + index}
            className={classes.labelBot}>
            Checking Procedure
          </label>
          <input
            type='text'
            id={'check-' + index}
            name={'check-' + index}
            className={classes.textarea}
            defaultValue={content[id].check}
            onChange={(e) => changeHandler(e, 'check')}
          />
        </div>
        <div className={classes.inputThird}>
          <label
            htmlFor={'contr init-' + index}
            className={classes.labelBot}>
            Contr. Init.
          </label>
          <input
            type='text'
            id={'contr init-' + index}
            name={'contr init-' + index}
            className={classes.textarea}
            defaultValue={content[id].contrInit}
            onChange={(e) => changeHandler(e, 'contrInit')}
          />
        </div>
        <div className={classes.inputThird}>
          <label
            htmlFor={'page-' + index}
            className={classes.labelBot}>
            Page #
          </label>
          <input
            type='text'
            id={'page-' + index}
            name={'page-' + index}
            className={classes.textarea}
            defaultValue={content[id].page}
            onChange={(e) => changeHandler(e, 'page')}
          />
        </div>
      </div>
    </div>
  );
};

export default DocDeficiencyLine;
