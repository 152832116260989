import { DocumentContext, CanvasContext, SitesContext } from 'state/context';
import { getDescendants } from 'helpers/helperFunctions';
import ActiveDrawing from './ActiveDrawing';
import DrawingTabs from './DrawingTabs';
import AddDrawing from './AddDrawing';
import { useContext } from 'react';
import Footer from 'Footer';
import {
  ISitesContext,
  ICanvasContext,
  IDocumentContext,
} from 'state/iContext';

const Drawings = () => {
  const { activeTowerID } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;
  const { towerList } = useContext<ISitesContext | undefined>(SitesContext)!;
  const { activeDrawing } = useContext<IDocumentContext | undefined>(
    DocumentContext
  )!;

  const drawingfolders: number[] = [
    1,
    ...getDescendants('1', towerList[activeTowerID].documents.folders),
  ];

  // console.log({
  //   drawingfolders,
  //   check: drawingfolders.some((id) => +id === 1),
  // });

  const drawingKeys: string[] = Object.keys(
    towerList[activeTowerID].documents.files
  ).filter((id) => drawingfolders.some((key) => +id === +key));

  console.log({ drawingKeys });

  return (
    <div className='relative flex flex-col w-full h-full overflow-y-auto'>
      <DrawingTabs />
      {drawingKeys.length === 0 || activeDrawing === 'add' ? (
        <AddDrawing />
      ) : (
        <ActiveDrawing
          drawing={towerList[activeTowerID].documents.files[activeDrawing]}
        />
      )}
      <Footer />
    </div>
  );
};
export default Drawings;
