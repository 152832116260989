import { IMapContext, IEditContext, ISitesContext } from 'state/iContext';
import { MapContext, EditContext, SitesContext } from 'state/context';
import InfoWindowCard from '../infoWindowDetails/InfoWindowCard';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { IEditProps } from '../../editPanel/iEdit';
import { useContext, ReactNode } from 'react';
import { LatLngLiteral } from 'state/iState';
import { IMarker } from '../IMap';

const SiteMarkers = ({ clusterer }) => {
  const {
    mapRef,
    infoWindowID,
    getGeoLocation,
    setInfoWindowID,
    measureDistance,
    changeMeasureEndPoints,
  } = useContext<IMapContext | undefined>(MapContext)!;
  const  { isEditPanelOpen, editProps, setEditProps } = useContext<IEditContext | undefined>(EditContext)!;
  const { towerList, siteTypes, favoritesTab, filteredSiteIds, favoriteSites }  = useContext<ISitesContext | undefined>(SitesContext)!;

  // Displays favorites/all sites
  const sitesToShow: number[] = favoritesTab ? favoriteSites : filteredSiteIds;

  // Renders all map markers
  const markers: ReactNode[] = sitesToShow.map((tower: number): ReactNode => {
    const { location, type } = towerList[tower];
    const { site } = type;
    const color: string = siteTypes[site as keyof typeof siteTypes]!;

    // Controls tower marker visibility
    const opacity = (): number => {
      if (!isEditPanelOpen) {
        return 1;
      } else {
        return editProps.towerId === tower ? 1 : 0.5;
      }
    };

    const key: string =
      editProps.towerId === tower && isEditPanelOpen
        ? editProps.name
        : towerList[tower].name;

    const title: string =
      editProps.towerId === tower && isEditPanelOpen
        ? editProps.name
        : towerList[tower].name;

    const position: LatLngLiteral =
      editProps.towerId === tower && isEditPanelOpen
        ? editProps.location
        : location;

    const draggable: boolean = editProps.towerId === tower && isEditPanelOpen;

    // Props to pass to their respective elements
    const markerProps: IMarker = {
      key,
      title,
      position,
      label: '',
      draggable,
      optimized: true,
      clusterer,
      opacity: opacity(),
      icon: {
        url: color,
        scaledSize: new google.maps.Size(25, 25),
      },
      onClick: () => {
        measureDistance
          ? changeMeasureEndPoints(tower)
          : setInfoWindowID(tower);
      },
      onMouseOver: () => {
        if (!measureDistance) {
          setInfoWindowID(tower);
        }
      },
      onDrag: ({ latLng }) => {
        setEditProps((prev: IEditProps) => ({
          ...prev,
          location: { lat: latLng.lat(), lng: latLng.lng() },
        }));
      },
      onDragEnd: ({ latLng }) => {
        const lat: number = latLng.lat();
        const lng: number = latLng.lng();
        getGeoLocation({
          lat,
          lng,
        })!;
        mapRef.current.panTo({ lat, lng });
      },
    };

    return (
      <Marker {...markerProps}>
        {infoWindowID === tower && !isEditPanelOpen && (
          <InfoWindow>
            <InfoWindowCard {...towerList[tower]} />
          </InfoWindow>
        )}
      </Marker>
    );
  });

  return <>{markers}</>;
};

export default SiteMarkers;
