import { IReadMoreClasses, ICommentProps } from '../IMap';

const ReadMore = ({ comments, handler }: ICommentProps) => {
  // Component classes
  const classes: IReadMoreClasses = {
    icon: 'absolute z-30 -top-0.5 fa-solid fa-circle-chevron-left text-lg text-gray-400 cursor-pointer hover:text-red-500',
    container:
      'w-full h-full bg-zinc-100 p-4 rounded-sm mt-3 border border-slate-200',
    label: 'font-normal',
    text: 'mt-3 font-medium',
  };

  return (
    <div
      id='info-window-read-more'
      className={classes.container}>
      <i
        className={classes.icon}
        onClick={handler}
      />
      <p className={classes.label}>Comments:</p>
      <div className={classes.text}>{comments}</div>
    </div>
  );
};

export default ReadMore;
