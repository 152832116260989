import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { IPDFInventoryLine } from '../iDocumentEditor';
import { useMemo } from 'react';

const PDFInventoryLine = ({ info }: IPDFInventoryLine) => {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'row',
          border: 'solid',
          borderWidth: '1px',
          width: '100%',
        },
        column: {
          border: 'solid',
          borderWidth: '1px',
          width: '20%',
          textAlign: 'center',
          fontSize: '9px',
          padding: 4,
          height: '100%',
        },
        asset: {
          border: 'solid',
          borderWidth: '1px',
          width: '40%',
          fontSize: '9px',
          padding: 4,
          alignItems: 'center',
          textAlign: 'center',
          height: '100%',
        },
      }),
    []
  );

  return (
    <View style={styles.container}>
      <Text style={styles.column}>{info.elevation}</Text>
      <Text style={styles.asset}>{info.name}</Text>
      <Text style={styles.column}>{info.location}</Text>
      <Text style={styles.column}>{info.properties}</Text>
    </View>
  );
};

export default PDFInventoryLine;
