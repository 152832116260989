import { IDocumentContext, IHeaderContext } from 'state/iContext';
import { DocumentContext, HeaderContext } from 'state/context';
import { IHeaderButtons } from 'state/iState';
import { IGridItem } from '../iGridItems';
import DragHandle from '../DragHandle';
import { useContext } from 'react';

const GridLink = ({ layout }: IGridItem) => {
  const { setActiveHeaderButton } = useContext<IHeaderContext | undefined>(
    HeaderContext
  )!;
  const { setActiveDrawing } = useContext<IDocumentContext | undefined>(
    DocumentContext
  )!;

  return (
    <>
      <button
        className='text-start p-0 underline bg-none hover:text-red-300'
        onClick={() => {
          setActiveHeaderButton((prev: IHeaderButtons) => ({
            ...prev,
            drawings: true,
            tower: false,
            site: false,
          }));
          setActiveDrawing(layout.refID!.toString());
        }}>
        {layout.name}
      </button>
      <DragHandle />
    </>
  );
};

export default GridLink;
