import { IInputEvent, INumberProps, IStringProps } from 'iApp';
import { IDocumentContext } from 'state/iContext';
import { DocumentContext } from 'state/context';
import { IEditFileProps } from 'state/iState';
import { useContext } from 'react';

const PageInput = ({ line }: INumberProps) => {
  const { editFileProps, setEditFileProps } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;

  const { content } = editFileProps.contents.tableOfContents;
  const { [line]: toBeRemoved, ...rest } = content;

  const handler = {
    name: (e: IInputEvent) =>
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          tableOfContents: {
            ...prev.contents.tableOfContents,
            content: {
              ...prev.contents.tableOfContents.content,
              [line]: {
                ...prev.contents.tableOfContents.content[line],
                name: e.target.value,
              },
            },
          },
        },
      })),
    page: (e: IInputEvent) =>
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          tableOfContents: {
            ...prev.contents.tableOfContents,
            content: {
              ...prev.contents.tableOfContents.content,
              [line]: {
                ...prev.contents.tableOfContents.content[line],
                page: e.target.value,
              },
            },
          },
        },
      })),
    remove: () => {
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          tableOfContents: {
            ...prev.contents.tableOfContents,
            content: rest,
          },
        },
      }));
    },
  };

  const classes: IStringProps = {
    container: 'flex w-full my-0.5 p-0.5 justify-between items-center',
    name: 'p-0 w-1/2 placeholder:px-2',
    number: 'p-0 w-1/6 placeholder:px-2',
    remove:
      'fa-solid fa-circle-minus mx-2 px-2 text-red-200 text-xs transition ease-in-out delay-100 hover:text-red-600 cursor-hand',
  };

  return (
    <div className={classes.container}>
      <input
        type='text'
        name='page-name'
        placeholder='Page Name'
        onChange={handler.name}
        value={content[line].name}
        className={classes.name}
      />
      <input
        type='text'
        placeholder="#'s"
        name='page-numbers'
        onChange={handler.page}
        value={content[line].page}
        className={classes.number}
      />
      <i
        className={classes.remove}
        onClick={handler.remove}
      />
    </div>
  );
};

export default PageInput;
