import { ICanvasContext, ISitesContext } from 'state/iContext';
import { CanvasContext, SitesContext } from 'state/context';
import { useContext } from 'react';
import Folder from './Folder';

const FolderContainer = () => {
  const { activeTowerID } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;
  const { towerList } = useContext<ISitesContext | undefined>(SitesContext)!;
  const folders = towerList[activeTowerID].documents.folders[0];

  return (
    <Folder
      name={folders.name}
      files={folders.files}
      folders={folders.folders}
      onFolderClick={() => {}}
      subIndex={0}
      folderID={0}
      parentFolderID={-1}
    />
  );
};

export default FolderContainer;
