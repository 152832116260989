import PDFInspectionChecklistSection from './PDFInspectionChecklistSection';
import { StyleSheet, View, Page, Text, Font } from '@react-pdf/renderer';
import { IPDFContents } from '../iDocumentEditor';
import PDFFooter from '../headerFooter/PDFFooter';
import PDFHeader from '../headerFooter/PDFHeader';
import { useMemo } from 'react';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
  ],
});

const PDFInspectionChecklist = ({ contents }: IPDFContents) => {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        page: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '0.5in',
          flexWrap: 'wrap',
          width: '100%',
          height: '90%',
        },
        header: {
          width: '100%',
          fontWeight: 600,
          textDecoration: 'underline',
          fontSize: '15px',
          textAlign: 'center',
          marginBottom: '10px',
          fontFamily: 'Open Sans',
        },
        legend: {
          flexDirection: 'row',
          width: '100%',
          height: '2%',
        },
        text: {
          border: 'solid',
          borderWidth: '1px',
          width: '100%',
          textAlign: 'center',
          fontSize: '5px',
          padding: 2,
        },
      }),
    []
  );

  const categoryID: string[] = useMemo(
    () => ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'],
    []
  );

  const lists = useMemo(
    () =>
      Object.keys(contents.checklist.lists).map((item: string, i: number) => (
        <PDFInspectionChecklistSection
          contents={contents.checklist.lists[item]}
          category={categoryID[i]}
          title={item}
          key={item}
          index={i}
        />
      )),
    [contents.checklist]
  );

  return (
    <Page
      size='A4'
      style={styles.page}>
      <View>
        {contents.header.active && (
          <PDFHeader contents={contents.header.contents} />
        )}
        <Text style={styles.header}>Inspection Checklist</Text>
        <View style={styles.legend}>
          <Text style={styles.text}>
            S = SATISFACTORY | U = UNSATIFACTORY | N/A = NOT APPLICABLE
          </Text>
        </View>
        <View>{lists}</View>
        {contents.footer.active && (
          <PDFFooter contents={contents.footer.contents} />
        )}
      </View>
    </Page>
  );
};

export default PDFInspectionChecklist;
