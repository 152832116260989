import DocInspectionChecklistLines from './DocInspectionChecklistLines';
import { IDocInspectionChecklistProps } from '../iDocumentEditor';
import { IStringProps } from 'iApp';
import { useMemo } from 'react';

const DocInspectionCategory = ({
  categoryID,
  category,
  id,
}: IDocInspectionChecklistProps) => {
  const classes: IStringProps = useMemo(
    () => ({
      container: 'flex flex-col w-full',
      header: 'flex w-full justify-center',
      inspectionID:
        'w-[5%] border border-stone-500 text-center text-lg font-bold',
      category:
        'w-[40%] border border-stone-500 text-start text-lg font-bold pl-2',
      checkbox: 'w-[5%] border border-stone-500 text-center font-bold',
      comments: 'w-[40%] border border-stone-500 text-center text-lg font-bold',
    }),
    []
  );

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.inspectionID}>
          <span>{categoryID[id]}</span>
        </div>
        <div className={classes.category}>
          <span>{category.toUpperCase()}</span>
        </div>
        <div className={classes.checkbox}>
          <span>S</span>
        </div>
        <div className={classes.checkbox}>
          <span>U</span>
        </div>
        <div className={classes.checkbox}>
          <span>N/A</span>
        </div>
        <div className={classes.comments}>
          <span>COMMENTS</span>
        </div>
      </div>
      <DocInspectionChecklistLines
        categoryID={categoryID}
        category={category}
        id={id}
      />
    </div>
  );
};

export default DocInspectionCategory;
