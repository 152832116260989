import Measurement from './edit/system/Measurement';
import { ISettingsContext } from 'state/iContext';
import { SettingsContext } from 'state/context';
import { ISettingsClasses } from './iSettings';
import { useContext } from 'react';

const QuickSettings = () => {
  const { setIsSettingsOpen }  = useContext<ISettingsContext | undefined>(SettingsContext)!;

  // Component Classes
  const classes: ISettingsClasses = {
    container:
      'absolute flex-col right-0 top-[45px] z-50 w-[250px] p-1 bg-stone-100 rounded-md border border-stone-300',
    title: 'font-bold text-slate-500',
    close:
      'fa-solid fa-xmark absolute text-xl text-stone-400 top-1 right-3 hover:text-stone-600',
    options: 'flex w-[200px] m-3 justify-around',
  };

  // Closes window
  const closeHandler = (): void => setIsSettingsOpen(false);

  return (
    <div
      id='settings'
      className={classes.container}>
      <div className={classes.title}>Settings</div>
      <i
        onClick={closeHandler}
        className={classes.close}
      />
      <div className={classes.options}>
        <Measurement />
      </div>
    </div>
  );
};

export default QuickSettings;
