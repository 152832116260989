import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { IPDFGuyInspectionLine } from '../iDocumentEditor';
import { useMemo } from 'react';

const PDFGuyInspectionLine = ({ content }: IPDFGuyInspectionLine) => {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'row',
          width: '100%',
        },
        column: {
          display: 'flex',
          flexDirection: 'column',
          border: 'solid',
          borderWidth: '1px',
          width: '12.5%',
          textAlign: 'center',
          fontSize: '9px',
          padding: 4,
          height: '100%',
        },
      }),
    []
  );

  return (
    <View style={styles.container}>
      <Text style={styles.column}>{content.level}</Text>
      <Text style={styles.column}>{content.elevation}</Text>
      <Text style={styles.column}>{content.anchor}</Text>
      <Text style={styles.column}>{content.sizeType}</Text>
      <Text style={styles.column}>{content.initial}</Text>
      <Text style={styles.column}>{content.specified}</Text>
      <Text style={styles.column}>{content.range}</Text>
      <Text style={styles.column}>{content.measured}</Text>
    </View>
  );
};

export default PDFGuyInspectionLine;
