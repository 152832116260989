import { IHeaderContext, ISettingsContext } from 'state/iContext';
import { HeaderContext, SettingsContext } from 'state/context';
import { ISiteTypeClasses } from './iSiteTypes';
import { IHeaderButtons } from 'state/iState';
import SiteTypeMenu from './SiteTypeMenu';
import MenuArrow from '../MenuArrow';
import { useContext } from 'react';

const SiteType = () => {
  const { activeHeaderButton, setActiveHeaderButton } = useContext<
    IHeaderContext | undefined
  >(HeaderContext)!;
  const { setIsSettingsOpen } = useContext<ISettingsContext | undefined>(
    SettingsContext
  )!;
  const { siteTypes } = activeHeaderButton!;

  // Component Classes
  const classes: ISiteTypeClasses = {
    container:
      'absolute right-9 top-2 inline-block text-left md:w-56 md:right-16',
    button:
      'inline-flex w-full justify-center bg-stone-100 px-2 py-1 text-sm font-medium text-gray-700 border border-gray-400 rounded-md justify-between',
  };

  // Toggles site type dropdown
  const clickHandler = (): void => {
    setIsSettingsOpen(false);
    setActiveHeaderButton(
      (prev: IHeaderButtons): IHeaderButtons => ({
        ...prev,
        account: false,
        siteTypes: !prev.siteTypes,
      })
    );
  };

  return (
    <div className={classes.container}>
      <button
        type='button'
        id='site-menu-button'
        onClick={clickHandler}
        className={classes.button}>
        Site Type
        <MenuArrow name={'siteType'} />
      </button>
      {siteTypes && <SiteTypeMenu />}
    </div>
  );
};

export default SiteType;
