import {
  IActiveSort,
  IDocumentAssetList,
  IDocumentTowerInfo,
  IEditFileProps,
  ITowerLayoutProps,
} from 'state/iState';
import { useContext, useEffect, useMemo } from 'react';
import DocInventoryLine from '../DocInventoryLine';
import { ICallbackProps, INumberProps, IStringProps } from 'iApp';
import {
  IDocumentContext,
  ICanvasContext,
  ISettingsContext,
  ISitesContext,
} from 'state/iContext';
import {
  DocumentContext,
  CanvasContext,
  SettingsContext,
  SitesContext,
} from 'state/context';

const DocInventoryPanel = () => {
  const { activeTowerID } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;
  const { scale } = useContext<ISettingsContext | undefined>(SettingsContext)!;
  const { towerList } = useContext<ISitesContext | undefined>(SitesContext)!;
  const {
    activeSort,
    editFileProps,
    setActiveSort,
    setEditFileProps,
    setDocumentTowerInfo,
  } = useContext<IDocumentContext | undefined>(DocumentContext)!;

  const inventory = editFileProps.contents.inventory;
  const legs: ITowerLayoutProps = towerList[activeTowerID].layout.tower.legs;
  const datas = towerList[activeTowerID].layout.data.shelves;
  const rooftop = towerList[activeTowerID].layout.site.assets;

  const classes: IStringProps = useMemo(
    () => ({
      sortInactive: 'fa-solid fa-sort text-sm ml-2 hover:text-red-600',
      sortUp: 'fa-solid fa-sort-up text-sm ml-2 hover:text-red-600',
      sortDown: 'fa-solid fa-sort-down text-sm ml-2 hover:text-red-600',
      container:
        'w-full h-full border border-stone-400 rounded rounded-lg overflow-y-auto',
      title: 'p-2 text-lg text-stone-500 font-bold',
      content: 'flex flex-col w-full h-full items-center',
      table: 'flex w-full justify-center pt-2',
      column: 'w-[10%] border border-stone-500 text-center font-bold',
      asset: 'w-[30%] border border-stone-500 text-center font-bold',
    }),
    []
  );

  // const sortIcon = (name: string): string => {
  //   if (activeSort.target === name && activeSort.decend) {
  //     return classes.sortDown;
  //   }
  //   if (activeSort.target === name && !activeSort.decend) {
  //     return classes.sortUp;
  //   }
  //   return classes.sortInactive;
  // };

  // const changeHandler: ICallbackProps = {
  //   elevation: () =>
  //     setActiveSort((prev: IActiveSort) => ({
  //       decend: prev.target === 'height' ? !prev.decend : true,
  //       target: 'height',
  //     })),
  //   name: () =>
  //     setActiveSort((prev: IActiveSort) => ({
  //       decend: prev.target === 'name' ? !prev.decend : true,
  //       target: 'name',
  //     })),
  //   location: () =>
  //     setActiveSort((prev: IActiveSort) => ({
  //       decend: prev.target === 'loc' ? !prev.decend : true,
  //       target: 'loc',
  //     })),
  // };

  //TODO: RESTRUCTURE LIBRARYDATA TO ACCESS PROPERTIES
  // const properties = () => { }

  const towerAssets =
    Object.keys(inventory.assets.tower).length > 0
      ? { ...inventory.assets.tower }
      : Object.keys(legs).flatMap((id) =>
          Object.keys(legs[id].icons)
            .filter((x) => +x >= 0)
            .map((icon) => ({
              elevation:
                towerList[activeTowerID].height - legs[id].icons[icon].y,
              name: legs[id].icons[icon].name,
              location: legs[id].legGrid.name,
            }))
        );

  const rooftopAssets =
    Object.keys(inventory.assets.rooftop).length > 0
      ? { ...inventory.assets.rooftop }
      : Object.keys(rooftop)
          .filter((x) => +x >= 0)
          .map((icon) => ({
            name: rooftop[icon].name,
          }));

  const dataAssets =
    Object.keys(inventory.assets.data).length > 0
      ? { ...inventory.assets.data }
      : Object.keys(datas).flatMap((id) =>
          Object.keys(datas[id].icons)
            .filter((x) => +x >= 0)
            .map((icon) => ({
              elevation: datas[id].shelfGrid.h - datas[id].icons[icon].y,
              name: datas[id].icons[icon].name,
              location: datas[id].shelfGrid.name,
            }))
        );

  useEffect(() => {
    setEditFileProps((prev: IEditFileProps) => ({
      ...prev,
      contents: {
        ...prev.contents,
        inventory: {
          ...prev.contents.inventory,
          assets: {
            ...prev.contents.inventory.assets,
            tower: towerAssets,
            rooftop: rooftopAssets,
            data: dataAssets,
          },
        },
      },
    }));
  }, []);

  useEffect(() => {
    setActiveSort({
      target: 'loc',
      decend: true,
    });
  }, []);

  const towerInventory = useMemo(
    () =>
      Object.keys(towerAssets).map((item) => (
        <DocInventoryLine
          asset={towerAssets[item]}
          key={item}
        />
      )),
    [towerAssets]
  );

  const dataInventory = useMemo(
    () =>
      Object.keys(dataAssets).map((item) => (
        <DocInventoryLine
          asset={dataAssets[item]}
          key={item}
        />
      )),
    [towerAssets]
  );

  const rooftopInventory = useMemo(
    () =>
      Object.keys(rooftopAssets).map((item) => (
        <div
          key={item}
          className={'flex w-full justify-center'}>
          <div
            className={
              'flex w-[30%] border border-stone-500 text-center justify-center items-center'
            }>
            <span>{rooftopAssets[item].name}</span>
          </div>
          <div
            className={
              'flex w-[30%] border border-stone-500 text-center justify-center items-center'
            }>
            {/* <span>{properties}</span> */}
          </div>
        </div>
      )),
    [rooftopAssets]
  );

  useEffect(() => {
    if (!inventory.active) {
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          pages: prev.contents.pages.filter(
            (str: string) => str !== 'inventory'
          ),
        },
      }));
    }
  }, [inventory.active, setEditFileProps]);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {towerInventory.length > 0 && (
          <>
            <h1 className={classes.title}>Tower</h1>
            <div className={classes.table}>
              <div className={classes.column}>
                <span>Elev.({scale})</span>
                {/* <i
              className={sortIcon('height')}
              onClick={changeHandler.elevation}
            /> */}
              </div>
              <div className={classes.asset}>
                <span>Asset / Equipment</span>
                {/* <i
              className={sortIcon('name')}
              onClick={changeHandler.name}
            /> */}
              </div>
              <div className={classes.column}>
                <span>Location</span>
                {/* <i
              className={sortIcon('loc')}
              onClick={changeHandler.location}
            /> */}
              </div>
              <div className={classes.column}>
                <span>Properties</span>
                {/* <i
              onClick={() => {
                setActiveSort((prev: IActiveSort) => ({
                  target: 'properties',
                  decend: prev.target === 'properties' ? !prev.decend : true,
                }));
              }}
              className={sortIcon('properties')}
            /> */}
              </div>
            </div>
            {towerInventory}
          </>
        )}
        {dataInventory.length > 0 && (
          <>
            <h1 className={classes.title}>data</h1>
            <div className={classes.table}>
              <div className={classes.column}>
                <span>Elev. U</span>
                {/* <i
              className={sortIcon('height')}
              onClick={changeHandler.elevation}
            /> */}
              </div>
              <div className={classes.asset}>
                <span>Asset / Equipment</span>
                {/* <i
              className={sortIcon('name')}
              onClick={changeHandler.name}
            /> */}
              </div>
              <div className={classes.column}>
                <span>data</span>
                {/* <i
              className={sortIcon('loc')}
              onClick={changeHandler.location}
            /> */}
              </div>
              <div className={classes.column}>
                <span>Properties</span>
                {/* <i
              onClick={() => {
                setActiveSort((prev: IActiveSort) => ({
                  target: 'properties',
                  decend: prev.target === 'properties' ? !prev.decend : true,
                }));
              }}
              className={sortIcon('properties')}
            /> */}
              </div>
            </div>
            {dataInventory}
          </>
        )}
        {rooftopInventory.length > 0 && (
          <>
            <h1 className={classes.title}>Rooftop</h1>
            <div className={classes.table}>
              {/* <div className={classes.column}>
                <span>***TEST***</span>
                <i
              className={sortIcon('height')}
              onClick={changeHandler.elevation}
            />
              </div> */}
              <div className={classes.asset}>
                <span>Asset / Equipment</span>
                {/* <i
              className={sortIcon('name')}
              onClick={changeHandler.name}
            /> */}
              </div>
              {/* <div className={classes.column}>
                <span>Location</span>
                <i
              className={sortIcon('loc')}
              // onClick={changeHandler.location}
            />
              </div> */}
              <div className={classes.asset}>
                <span>Properties</span>
                {/* <i
              onClick={() => {
                setActiveSort((prev: IActiveSort) => ({
                  target: 'properties',
                  decend: prev.target === 'properties' ? !prev.decend : true,
                }));
              }}
              className={sortIcon('properties')}
            /> */}
              </div>
            </div>
            {rooftopInventory}
          </>
        )}
      </div>
    </div>
  );
};

export default DocInventoryPanel;
