import ApplicationView from './applicationView/ApplicationView';
import UserDropdown from './userDropdown/UserDropdown';
import QuickSettings from './settings/QuickSettings';
import SiteType from './siteType/SiteType';
import { IHeaderClasses } from './iHeader';
import { useContext } from 'react';
import Logo from './Logo';
import {
  IMapContext,
  ICanvasContext,
  IUserContext,
  IHeaderContext,
  ISettingsContext,
} from 'state/iContext';
import {
  MapContext,
  CanvasContext,
  UserContext,
  HeaderContext,
  SettingsContext,
} from 'state/context';

const Header = () => {
  const { setInfoWindowID } = useContext<IMapContext | undefined>(MapContext)!;
  const { setDragProps, clearAddProps } = useContext<
    ICanvasContext | undefined
  >(CanvasContext)!;
  const {
    user: { logo },
  } = useContext<IUserContext | undefined>(UserContext)!;
  const { homePage } = useContext<IHeaderContext | undefined>(HeaderContext)!;
  const { isSettingsOpen } = useContext<ISettingsContext | undefined>(
    SettingsContext
  )!;

  // Component classes
  const classes: IHeaderClasses = {
    header:
      'w-full h-62 z-60 flex justify-between border border-stone-500 bg-stone-100',
    center: 'flex w-full',
    clientLogo:
      'absolute top-1 left-1/2 max-h-[40px] object-fill rounded-full -ml-14 hidden sm:inline',
    container: 'flex md:justify-end',
  };

  const handler = (): void => {
    setDragProps(clearAddProps);
    setInfoWindowID('');
  };

  return (
    <div
      id='header'
      onClick={handler}
      onTouchStart={handler}
      className={classes.header}>
      <div className={classes.center}>
        <Logo />
        {homePage ? (
          <img
            src={logo}
            id='client-logo'
            alt='company logo'
            className={classes.clientLogo}
          />
        ) : (
          <ApplicationView />
        )}
      </div>
      <div
        id='header-right'
        className={classes.container}>
        {homePage && <SiteType />}
        {}
        <UserDropdown />
        {isSettingsOpen && <QuickSettings />}
      </div>
    </div>
  );
};

export default Header;
