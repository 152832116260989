import { ICallbackProps, IStringProps } from 'iApp';
import { IDocumentContext } from 'state/iContext';
import { DocumentContext } from 'state/context';
import { useContext, useMemo } from 'react';
import FileName from './FileName';

const PanelButtons = () => {
  const { setActiveFilePanel } = useContext<IDocumentContext | undefined>(
    DocumentContext
  )!;

  const classes: IStringProps = useMemo(
    () => ({
      container: 'flex flex-wrap w-full h-full items-center justify-center',
      button:
        'group flex flex-col border border-stone-300 w-1/6 h-1/6 m-4 items-center rounded rounded-lg justify-center items-center transition ease-out hover:bg-red-700 hover:text-slate-200',
      label: 'text-center text-xs h-1/3',
      guy: 'fa-solid fa-lines-leaning text-2xl text-slate-500 group-hover:text-slate-200',
      text: 'fa-solid fa-font flex h-1/2 items-center text-2xl text-slate-500 group-hover:text-slate-200',
      data: 'fa-solid fa-bars-progress flex h-1/2 items-center text-2xl text-slate-500 group-hover:text-slate-200',
      image:
        'fa-regular fa-image flex h-1/2 items-center text-2xl text-slate-500 group-hover:text-slate-200',
      tower:
        'fa-solid fa-tower-cell flex h-1/2 items-center text-2xl text-slate-500 group-hover:text-slate-200',
      rooftop:
        'fa-solid fa-city flex h-1/2 items-center text-2xl text-slate-500 group-hover:text-slate-200',
      newPage:
        'fa-solid fa-file-circle-plus flex h-1/2 items-center text-2xl text-slate-500 group-hover:text-slate-200',
      header:
        'fa-solid fa-pager flex h-1/2 items-center text-2xl text-slate-500 group-hover:text-slate-200',
      footer:
        'fa-solid fa-pager rotate-180 flex h-1/2 items-center text-2xl text-slate-500 group-hover:text-slate-200',
      front:
        'fa-solid fa-file-arrow-up text-2xl text-slate-500 group-hover:text-slate-200',
      table:
        'fa-solid fa-rectangle-list flex h-1/2 items-center text-2xl text-slate-500 group-hover:text-slate-200',
      height:
        'fa-solid fa-ruler-vertical text-2xl text-slate-500 group-hover:text-slate-200',
      deficiencies:
        'fa-solid fa-screwdriver-wrench text-2xl text-slate-500 group-hover:text-slate-200',
      checklist:
        'fa-solid fa-clipboard-check text-2xl text-slate-500 group-hover:text-slate-200',
      verticality:
        'fa-solid fa-arrow-up-short-wide text-2xl text-slate-500 group-hover:text-slate-200',
    }),
    []
  );

  const handler: ICallbackProps = {
    guy: () => setActiveFilePanel('guy'),
    text: () => setActiveFilePanel('text'),
    data: () => setActiveFilePanel('data'),
    front: () => setActiveFilePanel('front'),
    image: () => setActiveFilePanel('image'),
    table: () => setActiveFilePanel('table'),
    tower: () => setActiveFilePanel('tower'),
    header: () => setActiveFilePanel('header'),
    height: () => setActiveFilePanel('height'),
    footer: () => setActiveFilePanel('footer'),
    newPage: () => setActiveFilePanel('newPage'),
    checklist: () => setActiveFilePanel('checklist'),
    verticality: () => setActiveFilePanel('verticality'),
    deficiencies: () => setActiveFilePanel('deficiencies'),
  };

  return (
    <div className={classes.container}>
      <FileName />
      <div
        onClick={handler.header}
        className={classes.button}>
        <i className={classes.header} />
        <span className={classes.label}>Header</span>
      </div>
      <div
        onClick={handler.footer}
        className={classes.button}>
        <i className={classes.footer} />
        <span className={classes.label}>Footer</span>
      </div>
      <div
        onClick={handler.front}
        className={classes.button}>
        <i className={classes.front} />
        <span>Front Page</span>
      </div>
      <div
        onClick={handler.table}
        className={classes.button}>
        <i className={classes.table} />
        <span className={classes.label}>Table of Contents</span>
      </div>
      {/* <div
        onClick={handler.text}
        className={classes.button}>
        <i className={classes.text} />
        <span className={classes.label}>Text</span>
      </div> */}
      <div
        onClick={handler.image}
        className={classes.button}>
        <i className={classes.image} />
        <span className={classes.label}>Images</span>
      </div>
      <div
        onClick={handler.tower}
        className={classes.button}>
        <i className={classes.tower} />
        <span className={classes.label}>Tower Info</span>
      </div>
      <div
        onClick={handler.data}
        className={classes.button}>
        <i className={classes.data} />
        <span className={classes.label}>Inventory</span>
      </div>
      <div
        onClick={handler.verticality}
        className={classes.button}>
        <i className={classes.verticality} />
        <span className={classes.label}>Verticality</span>
      </div>
      <div
        onClick={handler.height}
        className={classes.button}>
        <i className={classes.height} />
        <span className={classes.label}>Height Verification</span>
      </div>
      <div
        onClick={handler.guy}
        className={classes.button}>
        <i className={classes.guy} />
        <span className={classes.label}>Guy Tensions/Hardware</span>
      </div>
      <div
        onClick={handler.deficiencies}
        className={classes.button}>
        <i className={classes.deficiencies} />
        <span className={classes.label}>Deficiencies</span>
      </div>
      <div
        onClick={handler.checklist}
        className={classes.button}>
        <i className={classes.checklist} />
        <span className={classes.label}>Inspection Checklist</span>
      </div>
    </div>
  );
};

export default PanelButtons;
