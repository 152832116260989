import { ICanvasContext, ISettingsContext } from 'state/iContext';
import { CanvasContext, SettingsContext } from 'state/context';
import { IClasses } from './iResize';
import { useContext } from 'react';

const ResizeButton = ({ main, min, max }: IClasses) => {
  const { minimize, changePanelSize } = useContext<
    ISettingsContext | undefined
  >(SettingsContext)!;
  const { clearPanel } = useContext<ICanvasContext | undefined>(CanvasContext)!;

  const iconClasses: string = minimize ? min : max;

  return (
    <div className={main}>
      <i
        className={iconClasses}
        onClick={() => {
          clearPanel();
          changePanelSize();
        }}
      />
    </div>
  );
};

export default ResizeButton;
