import { IDocumentContext } from 'state/iContext';
import SaveBox from 'components/buttons/SaveBox';
import { DocumentContext } from 'state/context';
import { IStringProps } from 'iApp';
import { useContext } from 'react';

const AddDocumentRooftop = () => {
  const { setActiveFilePanel } = useContext<IDocumentContext | undefined>(
    DocumentContext
  )!;

  const classes: IStringProps = {
    container: 'flex flex-wrap w-1/4 h-full items-center justify-center',
    panel: 'relative w-full h-full',
    buttons: 'relative w-full h-1/6',
    save: 'absolute flex w-1/3 right-0 bottom-0 justify-around',
  };

  const saveBox = {
    classes: classes.save,
    clickHandlers: {
      save: () => {},
      cancel: (): void => setActiveFilePanel(''),
    },
    // disabled: !imageProps.image,
  };

  return (
    <div className={classes.panel}>
      Rooftop
      <div className={classes.buttons}>
        <SaveBox {...saveBox} />
      </div>
    </div>
  );
};

export default AddDocumentRooftop;
