import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { IPDFTableOfContents } from '../iDocumentEditor';
import { useMemo } from 'react';

const PDFTableOfContentsLine = ({ content, index }: IPDFTableOfContents) => {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
          paddingHorizontal: '0.5in',
        },
        line: {
          fontSize: '13px',
        },
        dashes: {
          margin: 5,
          width: '100%',
          height: '10px',
          borderBottom: 'active',
          borderBottomWidth: 1,
          borderBottomStyle: 'dashed',
        },
      }),
    []
  );

  return (
    <View
      break={index > 0 && index % 30 === 0}
      style={styles.container}>
      <Text style={styles.line}>{content.name}</Text>
      <Text style={styles.dashes} />
      <Text style={styles.line}>{content.page}</Text>
    </View>
  );
};

export default PDFTableOfContentsLine;
