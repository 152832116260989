import { StyleSheet, View, Page, Text, Font } from '@react-pdf/renderer';
import { IPDFContents } from '../iDocumentEditor';
import PDFFooter from '../headerFooter/PDFFooter';
import PDFHeader from '../headerFooter/PDFHeader';
import PDFImageBlock from './PDFImageBlock';
import { useMemo } from 'react';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
  ],
});

const PDFImages = ({ contents }: IPDFContents) => {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        page: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '0.5in',
          flexWrap: 'wrap',
          width: '100%',
          height: '100%',
        },
        container: {
          width: '100%',
          height: '100%',
          flexDirection: 'column',
          alignItems: 'center'
        },
        header: {
          width: '100%',
          fontWeight: 600,
          textDecoration: 'underline',
          fontSize: '15px',
          textAlign: 'center',
          marginBottom: '10px',
          fontFamily: 'Open Sans',
        },
        images: {
          width: '90%',
        },
        text: {
          border: 'solid',
          borderWidth: '1px',
          width: '100%',
          textAlign: 'center',
          fontSize: '5px',
          padding: 2,
        },
      }),
    []
  );

  const imagesData = Object.keys(contents.images.content).map((item: string) => ({
    content: contents.images.content[item],
    key: item,
  }));

  const pages: JSX.Element[][] = [];
  let currentPageImages: JSX.Element[] = [];
  let currentPageHeight: number = 0;

  imagesData.forEach(({ content, key }) => {
    const imageHeight = content.size.h;
    if (currentPageHeight + imageHeight > 600) {
      pages.push(currentPageImages);
      currentPageImages = [];
      currentPageHeight = 0;
    }
    currentPageImages.push(
      <PDFImageBlock content={content} key={key} />
    );
    currentPageHeight += imageHeight;
  });

  if (currentPageImages.length > 0) {
    pages.push(currentPageImages);
  }

  return (
    <>
      {pages.map((pageImages, pageIndex) => (
        <Page size='A4' style={styles.page} key={pageIndex}>
          <View style={styles.container}>
            {contents.header.active && (
              <PDFHeader contents={contents.header.contents} />
            )}
            <Text style={styles.header}>Images</Text>
            <View style={styles.images}>{pageImages}</View>
            {contents.footer.active && (
              <PDFFooter contents={contents.footer.contents} />
            )}
          </View>
        </Page>
      ))}
    </>
  );
};

export default PDFImages;
