import { IStringProps } from 'iApp';
import { IDocumentInputFeild } from './iDocumentEditor';

const DocumentInputField = ({
  name,
  value,
  onChange,
  onDoubleClick,
}: IDocumentInputFeild) => {
  
  const classes: IStringProps = {
    container: 'flex w-full my-0.5 p-0.5 justify-between',
    label: 'mx-1 text-stone-500 font-bold',
    input: 'p-0 w-1/2 placeholder:px-2',
  };

  return (
    <div className={classes.container}>
      <label
        className={classes.label}
        htmlFor={name}>
        {name}
      </label>
      <input
        type='text'
        name={name}
        value={value}
        placeholder='N/A'
        onChange={onChange}
        id={`input-${name}`}
        onDoubleClick={onDoubleClick}
        className={classes.input}
      />
    </div>
  );
};

export default DocumentInputField;
