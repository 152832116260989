import { DocumentContext, CanvasContext, SitesContext } from 'state/context';
import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
import { useContext, useState, useMemo } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import PDFDocumentPanel from './PDFDocumentPanel';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { ITowerList } from 'state/iState';
import PDFDocument from './PDFDocument';
import { IStringProps } from 'iApp';
import {
  IDocumentContext,
  ICanvasContext,
  ISitesContext,
} from 'state/iContext';
// import parse from 'html-react-parser';

const PDFBuilder = () => {
  const {
    editFileProps,
    setEditFileProps,
    clearEditFileProps,
    documentTowerInfo,
    activeTowerDetails,
  } = useContext<IDocumentContext | undefined>(DocumentContext)!;
  const { activeTowerID } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;
  const { towerList, setTowerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;

  const [previewPDF, setPreviewPDF] = useState<boolean>(false);

  const document = useMemo(
    () => (
      <PDFDocument
        info={documentTowerInfo}
        contents={editFileProps.contents}
        activeTowerDetails={activeTowerDetails}
      />
    ),
    [documentTowerInfo, editFileProps.contents, activeTowerDetails]
  );

  const classes: IStringProps = {
    container: 'flex flex-col w-full h-full items-center',
    button:
      'p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
    content: 'flex w-full min-h-[80%] justify-center',
    panel: previewPDF ? 'w-1/2' : 'w-full',
    viewer: 'w-1/2 h-full',
    buttons: 'flex',
  };

  return (
    <div className={classes.container}>
      <button
        className={classes.button}
        onClick={() => {
          setEditFileProps(clearEditFileProps);
        }}>
        Exit PDF Edit
      </button>
      <div className={classes.content}>
        <div className={classes.panel}>
          <PDFDocumentPanel />
        </div>
        {previewPDF && (
          <PDFViewer className={classes.viewer}>{document}</PDFViewer>
        )}
      </div>
      <div className={classes.buttons}>
        <button
          onClick={() => {
            setTowerList((prev: ITowerList) => ({
              ...prev,
              [activeTowerID]: {
                ...prev[activeTowerID],
                documents: {
                  ...prev[activeTowerID].documents,
                  files: {
                    ...prev[activeTowerID].documents.files,
                    [editFileProps.id]: {
                      name:
                        editFileProps.contents.name ||
                        `${towerList[activeTowerID].name}-${Date.now()}`,
                      upload: Date.now(),
                      folder: editFileProps.folder!,
                      file: editFileProps.contents,
                      isPDF: false,
                    },
                  },
                  folders: {
                    ...prev[activeTowerID].documents.folders,
                    [editFileProps.folder!]: {
                      ...prev[activeTowerID].documents.folders[
                        editFileProps.folder!
                      ],
                      files: [
                        ...prev[activeTowerID].documents.folders[
                          editFileProps.folder!
                        ].files,
                        editFileProps.id,
                      ],
                    },
                  },
                },
              },
            }));
            setEditFileProps(clearEditFileProps);
          }}
          className={classes.button}>
          Save to File List
        </button>
        <PDFDownloadLink
          document={document}
          className={classes.button}
          fileName={editFileProps.contents.name}>
          {({ loading }) => (loading ? 'Loading...' : 'Download')}
        </PDFDownloadLink>
        <button
          className={classes.button}
          onClick={() => setPreviewPDF((prev: boolean) => !prev)}>
          {previewPDF ? 'Hide' : 'Preview'} PDF
        </button>
      </div>
    </div>
  );
};

export default PDFBuilder;
