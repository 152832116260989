import { ICanvasContext } from 'state/iContext';
import { useEffect, useContext } from 'react';
import { IActiveToolBar } from 'state/iState';
import { CanvasContext } from 'state/context';
import ToolBarRight from './ToolBarRight';
import ToolBarLeft from './ToolBarLeft';

const DataToolBar = (props) => {
  const { setActiveToolBar } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;

  useEffect(() => {
    const propsGrid = props.full
      ? { Properties: true, Grid: true }
      : {
          Properties: false,
          Grid: false,
        };

    setActiveToolBar((prev: IActiveToolBar) => ({
      ...prev,
      ...propsGrid,
    }));
  }, [props.full]);

  return (
    <div
      id='data-canvas-toolbar'
      className='flex flex-col z-60 w-full bg-slate-200 border border-stone-300 items-start justify-between text-stone-400 text-sm sm:flex-row'>
      <ToolBarLeft {...props} />
      {props.full && <ToolBarRight />}
    </div>
  );
};

export default DataToolBar;
