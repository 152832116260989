import React, { useMemo, useState } from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

const PDFShelters = ({ contents }) => {
  const [shelters, setShelters] = useState<JSX.Element[]>([]);

  const styles = useMemo(
    () =>
      StyleSheet.create({
        section: {
          width: '49%',
          margin: '0.5%',
        },
        title: {
          fontWeight: 'bold',
          textDecoration: 'underline',
          fontSize: '12px',
        },
        details: {
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '2px',
          fontSize: '10px',
        },
        shelters: {
          width: '100%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          marginTop: '2px',
          fontSize: '8px',
        },
      }),
    []
  );

  useMemo(() => {
    const newShelters = Object.keys(contents).map((id) => {
      const title = (
        <Text style={styles.title}>
          {contents[id]['Shelter Name']} Shelter Details
        </Text>
      );

      const details = Object.keys(contents[id])
        .filter((_, i) => i > 0)
        .map((line) => (
          <View
            key={line}
            style={styles.details}>
            <Text style={styles.details}>{line}:</Text>
            <Text style={styles.details}>
              {contents[id][line] ? contents[id][line] : 'N/A'}
            </Text>
          </View>
        ));

      return (
        <View
          style={styles.section}
          key={id}>
          {title}
          {details}
        </View>
      );
    });

    setShelters(newShelters);
  }, [contents, styles]);

  return <View style={styles.shelters}>{shelters}</View>;
};

export default PDFShelters;
