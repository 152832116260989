import ResizeButton from 'components/buttons/resizeButton/Resize';
import { IEditContext, ISettingsContext } from 'state/iContext';
import AddButton from 'components/buttons/addButton/AddButton';
import { EditContext, SettingsContext } from 'state/context';
import SiteListTabs from './siteListTabs/SiteListTabs';
import SearchBar from 'components/search/SearchBar';
import SiteList from './siteList/SiteList';
import { IMinMaxClasses } from './iSites';
import { useContext } from 'react';

const SitesPanel = () => {
  const { isEditPanelOpen } = useContext<IEditContext | undefined>(
    EditContext
  )!;
  const { minimize } = useContext<ISettingsContext | undefined>(
    SettingsContext
  )!;

  // Component classes
  const classes: IMinMaxClasses = {
    min: 'z-50 flex relative border-r border-r-stone-400 h-[100px] w-full md:flex-col md:h-full md:w-[120px]',
    max: 'z-50 flex flex-col relative border-r border-r-slate-400 h-1/3 w-full md:h-full md:w-[270px] lg:w-[300px]',
  };

  // Classes based on if panel is minimized
  const minMaxClasses: string = minimize ? classes.min : classes.max;

  return (
    <div
      id='sites'
      className={minMaxClasses}>
      {!isEditPanelOpen && <ResizeButton />}
      <SearchBar disabled={isEditPanelOpen} />
      {!minimize && <SiteListTabs />}
      <SiteList />
      {!isEditPanelOpen && <AddButton name='SITE' />}
    </div>
  );
};

export default SitesPanel;
