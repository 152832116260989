import { IInputEvent } from 'iApp';
import DocumentInputField from '../DocumentInputField';
import SaveBox from 'components/buttons/SaveBox';
import { ICallbackProps, IStringProps } from 'iApp';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { DocumentContext } from 'state/context';
import { IDocumentContext } from 'state/iContext';
import { IEditFileProps } from 'state/iState';

const FrontPage = () => {
  const {
    setActiveFilePanel,
    activeTowerDetails,
    setActiveTowerDetails,
    setDocumentTowerInfo,
    clearDocumentTowerInfo,
    clearActiveTowerDetails,
    setEditFileProps,
  } = useContext<IDocumentContext | undefined>(DocumentContext)!;

  const [activeNewPage, setActiveNewPage] = useState<string>('');
  
  return (
    <div className='w-full h-1/2'>
      {' '}
      <div className='flex flex-col w-full h-2/3 overflow-y-auto'>
        <h1 className='w-full text-center text-lg text-stone-400 font-bold'>
          Add Front Page
        </h1>
        <div className='flex flex-col w-full my-2 p-0.5 justify-between'>
          <DocumentInputField
            name={'Header'}
            onChange={(e: IInputEvent) => {
              setEditFileProps((prev: IEditFileProps) => ({
                ...prev,
                contents: {
                  ...prev.contents,
                  frontPage: {
                    ...prev.contents.frontPage,
                    header: e.target.value,
                  },
                  pages: ['front', ...prev.contents.pages],
                },
              }));
            }}
          />
          {/* <div>{toggleListDetails}</div> */}
          {/* <div className='flex flex-col w-full h-full'>

        </div> */}
        </div>
      </div>
      <button
        onClick={() => setActiveFilePanel('')}
        className='p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600'>
        Back
      </button>
    </div>
  );
};

export default FrontPage;
