import { IActiveTab, ISettingsCardClasses } from './iSettings';
import SystemSettings from './edit/system/SystemSettings';
import { IHeaderContext } from 'state/iContext';
import { HeaderContext } from 'state/context';
import Close from 'components/buttons/Close';
import EditUser from './edit/user/EditUser';
import { useContext } from 'react';

const SettingsCard = ({ account, settings }: IActiveTab) => {
  const { setActiveHeaderButton, lastActiveView } = useContext<IHeaderContext | undefined>(HeaderContext)!;

  // Component classes
  const classes: ISettingsCardClasses = {
    container: 'flex-col relative border border-stone-200 p-3 w-full md:w-4/5',
    titleClass: 'font-bold text-stone-500 mb-4',
    border: 'relative border border-t-4 border-stone-300 p-5 w-full  ',
  };

  const title: string = account ? 'User' : 'Settings';

  return (
    <div className={classes.container}>
      <Close handler={(): void => setActiveHeaderButton(lastActiveView)} />
      <div className={classes.titleClass}>{title}</div>
      <div className={classes.border}>
        {account && <EditUser />}
        {settings && <SystemSettings />}
      </div>
    </div>
  );
};

export default SettingsCard;
