import { ICanvasContext } from 'state/iContext';
import { CanvasContext } from 'state/context';
import { IAddItemProps } from 'state/iState';
import { IStringProps } from 'iApp';
import { useContext } from 'react';

const AddTextBox = () => {
  const { addItemProps, setAddItemProps } = useContext<
    ICanvasContext | undefined
  >(CanvasContext)!;

  const classes: IStringProps = {
    container: 'flex flex-col justify-center m-1',
    label: 'ml-1 font-bold text-stone-500 box-content',
    input: 'peer rounded rounded-md',
  };

  return (
    <div className={classes.container}>
      <label
        className={classes.label}
        htmlFor='contents'>
        Contents:
      </label>
      <input
        required
        type='text'
        name='contents'
        value={addItemProps.content}
        placeholder='Text Box Content'
        className={classes.input}
        onChange={(e) =>
          setAddItemProps((prev: IAddItemProps) => ({
            ...prev,
            content: e.target.value,
          }))
        }
      />
    </div>
  );
};

export default AddTextBox;
