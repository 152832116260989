import { Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import PDFGuyInspectionLine from './PDFGuyInspectionLine';
import PDFFooter from '../headerFooter/PDFFooter';
import PDFHeader from '../headerFooter/PDFHeader';
import { IPDFContents } from '../iDocumentEditor';
import { ReactNode, useMemo } from 'react';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
  ],
});

const PDFGuyInspection = ({ contents }: IPDFContents) => {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        page: {
          flexDirection: 'row',
          padding: '0.5in',
          flexWrap: 'wrap',
          width: '100%',
          height: '90%',
        },
        container: {
          flexDirection: 'column',
          width: '100%',
          height: '95%',
          alignContent: 'center',
          alignItems: 'center',
          marginTop: '20px',
        },
        title: {
          width: '100%',
          fontWeight: 600,
          textDecoration: 'underline',
          fontSize: '15px',
          textAlign: 'center',
          marginBottom: '10px',
          fontFamily: 'Open Sans',
        },
        header: {
          flexDirection: 'row',
          border: 'solid',
          borderWidth: '1px',
          width: '100%',
        },
        column: {
          display: 'flex',
          flexDirection: 'column',
          border: 'solid',
          borderWidth: '1px',
          width: '12.5%',
          textAlign: 'center',
          fontSize: '9px',
          padding: 4,
          height: '100%',
        },
      }),
    []
  );

  const lines: ReactNode[] = Object.keys(contents.guy.content).map((line) => (
    <PDFGuyInspectionLine
      content={contents.guy.content[line]}
      key={line}
    />
  ));

  return (
    <Page
      size='A4'
      style={styles.page}>
      <View style={styles.container}>
        {contents.header.active && (
          <PDFHeader contents={contents.header.contents} />
        )}
        <View>
          <Text style={styles.title}>GUY TENSIONS AND GUY HARDWARE</Text>
          <View style={styles.header}>
            <Text style={styles.column}>Guy Level</Text>
            <Text style={styles.column}>Elevation (ft)</Text>
            <Text style={styles.column}>Guy Anchor</Text>
            <Text style={styles.column}>Guy Size and Type</Text>
            <Text style={styles.column}>Initial Tension (kips)</Text>
            <Text style={styles.column}>Specified Tension (kips)</Text>
            <Text style={styles.column}>Allowable Range (kips)</Text>
            <Text style={styles.column}>Measured Tension (kips)</Text>
          </View>
          {lines}
        </View>
      </View>
      {contents.footer.active && (
        <PDFFooter contents={contents.footer.contents} />
      )}
    </Page>
  );
};

export default PDFGuyInspection;
