import { IEditContext } from 'state/iContext';
import { EditContext } from 'state/context';
import DatePicker from 'react-datepicker';
import { IEditProps } from './iEdit';
import { useContext } from 'react';
import { IStringProps } from 'iApp';

const EditDates = ({ date, type }) => {
  const { setEditProps } = useContext<IEditContext | undefined>(EditContext)!;

  const title: IStringProps = {
    inspection: 'Next Inspection',
    license: 'License Expiry',
  };

  return (
    <>
      <label htmlFor={type}>{title[type]}</label>
      <DatePicker
        name='inspection'
        selected={new Date(date)}
        allowSameDay={true}
        closeOnScroll={true}
        onChange={(updatedDate) => {
          setEditProps((prev: IEditProps) => ({
            ...prev,
            dates: {
              ...prev.dates,
              [type]: Date.parse(updatedDate!.toString()),
            },
          }));
        }}
      />
    </>
  );
};

export default EditDates;
