import { IEditProps } from 'components/home/editPanel/iEdit';

// Ensures 3 is returned if difference is too low
export const threeMinimum = (
  { first, last }: { first: number; last: number },
  length: number
  ): number => (length - 1 === last ? last - 3 : first);

// Grey out img arrow, if the displayed preview images are the start/end of the list
export const isImageListEnd = (
  index: number,
  length: number,
  classes: string
  ): string =>
  index === 0 || index === length - 1 ? classes + ' text-gray-300' : classes;


// Checks to see that all edit input fields have some form of data
export const isSaveValid = (props: IEditProps, metric: boolean): boolean => {
  const { img, name, code, height, contact, location } = props;
  const { name: contactName, phone: contactPhone, email: contactEmail } = contact || {};
  const { lat, lng } = location || {};

  return (
    img &&
    img !== '/static/media/map-pin-red.75805ec349a5d0eebaa8487ebefdc2fb.svg' &&
    name &&
    code &&
    height &&
    (metric ? height >= 15 : height >= 50) &&
    contactName &&
    contactPhone &&
    contactEmail &&
    lat &&
    lng
  );
};
