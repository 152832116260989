import DocStructureDetails from './documentDetails/shelters/DocShelterDetails';
import { IDocumentTowerInfo, IEditFileProps, ITower } from 'state/iState';
import { ReactNode, useContext, useEffect, useMemo } from 'react';
import DocStructureInfo from './documentDetails/DocStructureInfo';
import DocDetailInputs from './documentDetails/DocDetailInputs';
import DocDeficiencies from '../deficiencies/DocDeficiencies';
import DocInventoryInfo from '../inventory/DocInventoryInfo';
import { IBooleanProps, IStringProps } from 'iApp';
import SaveBox from 'components/buttons/SaveBox';
import ToggleOption from '../ToggleOption';
import {
  MapContext,
  SitesContext,
  CanvasContext,
  DocumentContext,
} from 'state/context';
import {
  IMapContext,
  ISitesContext,
  ICanvasContext,
  IDocumentContext,
} from 'state/iContext';

const AddDocumentTower = () => {
  const {
    setActiveFilePanel,
    activeTowerDetails,
    setActiveTowerDetails,
    documentTowerInfo,
    setDocumentTowerInfo,
    clearDocumentTowerInfo,
    clearActiveTowerDetails,
    editFileProps,
    setEditFileProps,
  } = useContext<IDocumentContext | undefined>(DocumentContext)!;
  const { towerList } = useContext<ISitesContext | undefined>(SitesContext)!;
  const { activeTowerID } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;
  const { setGeoLocationStrings, stringLocation } = useContext<
    IMapContext | undefined
  >(MapContext)!;

  const tower: ITower = towerList[activeTowerID];
  const toggleListTitles: string[] = Object.keys(activeTowerDetails);
  const { totalShelters } = documentTowerInfo['Shelter Details'];

  useEffect(() => {
    setGeoLocationStrings(tower.location);
  }, [tower.location]);

  const inputFields = {
    'Site Information': (
      <DocDetailInputs
        key={'Site Information'}
        name={'Site Information'}
      />
    ),
    'Technical Information': (
      <DocDetailInputs
        key={'Technical Information'}
        name={'Technical Information'}
      />
    ),
    'Inspection Details': (
      <DocDetailInputs
        key={'Inspection Details'}
        name={'Inspection Details'}
      />
    ),

    'Structure Information': <DocStructureInfo key={'structure-info'} />,
    'Climbing Facility': (
      <DocDetailInputs
        key={'Climbing Facility'}
        name={'Climbing Facility'}
      />
    ),
    'Tower Lighting': (
      <DocDetailInputs
        key={'Tower Lighting'}
        name={'Tower Lighting'}
      />
    ),
    'Shelter Details': <DocStructureDetails key={'shelter-details'} />,
    Inventory: <DocInventoryInfo key={'inventory'} />,
    Deficiencies: <DocDeficiencies key={'deficiencies'} />,
  };

  const classes: IStringProps = {
    container: 'flex flex-wrap w-1/4 h-full items-center justify-center',
    panel: 'relative flex flex-col w-full h-full justify-center',
    saveBox: 'absolute flex w-1/3 right-0 bottom-0 justify-around',
    content: 'flex flex-col w-full h-2/3 overflow-y-auto',
    title: 'w-full text-center text-lg text-stone-400 font-bold',
    details: 'flex flex-col w-full h-full',
    save: 'relative w-full h-1/6',
    back: 'w-[100px] self-center p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
  };

  const saveBox = {
    classes: classes.saveBox,
    clickHandlers: {
      save: (): void => {
        setEditFileProps((prev: IEditFileProps) => ({
          ...prev,
          contents: {
            ...prev.contents,
            tower: documentTowerInfo,
            pages: [...prev.contents.pages, 'tower'],
          },
        }));
        setActiveFilePanel('');
      },
      cancel: (): void => {
        setActiveTowerDetails(clearActiveTowerDetails);
        setDocumentTowerInfo(clearDocumentTowerInfo);
        setActiveFilePanel('');
      },
    },
  };

  const defaultTowerData = useMemo(
    () => ({
      ...clearDocumentTowerInfo,
      'Site Information': {
        'Location Code': tower.code,
        'Location Owner': tower.company,
        'Site Name': tower.name,
        'Site Address': tower.geoCode,
        City: stringLocation.locality,
        'Province/State': stringLocation.political,
      },
      'Inspection Details': {
        'Inspection Date': new Date().toISOString().slice(0, 10),
      },
      'Technical Information': {
        Latitude: tower.location.lat,
        Longitude: tower.location.lng,
      },
      'Structure Information': {
        'Structure Height': tower.height,
        'Structure Type': tower.type.site,
        'Construction Type': tower.type.tower,
      },
    }),
    []
  );

  useEffect(() => {
    if (!editFileProps.contents.tower) {
      setDocumentTowerInfo(defaultTowerData);
    }
  }, [defaultTowerData]);

  useEffect(() => {
    for (let rendered = 0; rendered < totalShelters; rendered++) {
      setDocumentTowerInfo((prev: IDocumentTowerInfo) => ({
        ...prev,
        ['Shelter Details']: {
          ...prev['Shelter Details'],
          shelters: {
            ...prev['Shelter Details'].shelters,
            [rendered]: {
              ...clearDocumentTowerInfo['Shelter Details'].shelters['0'],
              ...prev['Shelter Details'].shelters[rendered],
            },
          },
        },
      }));
    }
  }, [totalShelters]);

  const toggleListDetails: ReactNode[] = toggleListTitles.map(
    (item: string): ReactNode => (
      <ToggleOption
        key={item}
        name={item}
        value={activeTowerDetails[item]}
        onChange={() => {
          setActiveTowerDetails((prev: IBooleanProps) => ({
            ...prev,
            [item]: !prev[item],
          }));
          if (!activeTowerDetails[item]) {
            setDocumentTowerInfo((prev: IDocumentTowerInfo) => ({
              ...prev,
              [item]: defaultTowerData[item],
            }));
          }
        }}
      />
    )
  );

  const activeInputFields = toggleListTitles
    .filter((item: string) => activeTowerDetails[item])
    .map((item: string) => inputFields[item]);

  return (
    <div className={classes.panel}>
      <div className='w-1/2 self-center'>
        <ToggleOption
          name='Active On PDF'
          value={editFileProps.contents.pages.includes('tower')}
          onChange={() =>
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                pages: editFileProps.contents.pages.includes('tower')
                  ? prev.contents.pages.filter((page) => page !== 'tower')
                  : [...prev.contents.pages, 'tower'],
              },
            }))
          }
        />
      </div>
      <div className={classes.content}>
        <h1 className={classes.title}>Site Details</h1>
        <div>{toggleListDetails}</div>
        <div className={classes.details}>
          <h1 className={classes.title}>Details</h1>
          {activeInputFields}
        </div>
      </div>
      {Object.keys(editFileProps.contents.tower).length === 0 ? (
        <div className={classes.save}>
          <SaveBox {...saveBox} />
        </div>
      ) : (
        <button
          onClick={() => setActiveFilePanel('')}
          className={classes.back}>
          Back
        </button>
      )}
    </div>
  );
};

export default AddDocumentTower;
