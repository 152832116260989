import { IStringProps } from 'iApp';
import { useContext, useState } from 'react';
import { IDocumentContext } from 'state/iContext';
import { DocumentContext } from 'state/context';
import SaveBox from 'components/buttons/SaveBox';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Document, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import PDFText from './PDFText';

const styles = StyleSheet.create({
  container: {
    padding: 10,
    border: '2px',
    borderColor: 'red',
  },
  text: {
    // Default text styles for the PDF
    fontSize: 12,
    fontFamily: 'Helvetica',
  },
});

const AddDocumentText = () => {
  const { setActiveFilePanel, setEditFileProps } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;
  const classes: IStringProps = {
    container: 'flex flex-wrap w-1/4 h-full items-center justify-center',
    panel: 'relative w-full h-full',
  };

  const [content, setContent] = useState('');

  const handleEditorChange = (value) => {
    setContent(value);
  };

  // const convertToPdfStyles = (quillDelta) => {
  //   const pdfStyles: Style = {};

  //   if (quillDelta && Array.isArray(quillDelta)) {
  //     quillDelta.forEach((op) => {
  //       if (op.attributes) {
  //         if (op.attributes.bold) {
  //           pdfStyles.fontWeight = 'bold';
  //         }
  //         if (op.attributes.italic) {
  //           pdfStyles.fontStyle = 'italic';
  //         }
  //         if (op.attributes.font) {
  //           pdfStyles.fontFamily = op.attributes.font;
  //         }
  //         // ... map other formatting styles as needed
  //       }
  //     });
  //   }

  //   return pdfStyles;
  // };

  // const renderTextWithFormatting = () => {
  //   let quillDelta;
  //   // try {
  //   //   const parsedContent = JSON.parse(content);
  //   //   quillDelta = parsedContent && parsedContent.ops;
  //   // } catch (error) {
  //   //   // Handle the error when parsing JSON
  //   //   console.error('Error parsing JSON:', error);
  //   //   quillDelta = []; // Set a default value if parsing fails
  //   // }
  //   // const pdfStyles = convertToPdfStyles(quillDelta);

  //   return quillDelta.map((op, index) => {
  //     const { insert, attributes } = op;
  //     const { bold, italic, font } = attributes || {};

  //     return (
  //       <Text key={index}
  //         // style={[styles.text, pdfStyles]}
  //       >
  //         {insert}
  //       </Text>
  //     );
  //   });
  // };

  const saveBox = {
    classes: 'absolute flex w-1/3 h-full right-0 bottom-0 justify-around',
    clickHandlers: {
      save: async () => {
        setEditFileProps((prev) => ({
          ...prev,
          contents: {
            ...prev.contents,
            text: {
              0: content,
            },
          },
        }));
      },
      cancel: () => setActiveFilePanel(''),
    },
    // disabled: !imageProps.image,
  };

  return (
    <div className={classes.panel}>
      <ReactQuill
        theme='snow'
        value={content}
        onChange={handleEditorChange}
        className='h-1/2'
      />
      {/* <PDFText content={content} /> */}
      <div className='relative w-full h-[32px]'>
        <SaveBox {...saveBox} />
      </div>
    </div>
  );
};

export default AddDocumentText;
