import { ILibraryContext, IModalContext, IUserContext } from 'state/iContext';
import { LibraryContext, ModalContext, UserContext } from 'state/context';
import { IEditLibrary, ILibrary, IUser } from 'state/iState';
import { IEditLibraryProps } from '../iManageLibraries';
import { IInputEvent, IStringProps } from 'iApp';
import Button from 'components/buttons/Button';
import Close from 'components/buttons/Close';
import { useContext } from 'react';

const EditLibrary = () => {
  const {
    libraries,
    editLibrary,
    libraryList,
    setLibraries,
    setEditLibrary,
    setLibraryList,
    clearEditLibrary,
    setActiveLibrary,
    setActivePrivateLibraries,
  } = useContext<ILibraryContext | undefined>(LibraryContext)!;
  const { setCustomIconStage } = useContext<IModalContext | undefined>(ModalContext)!;
  const { setUser } = useContext<IUserContext | undefined>(UserContext)!;

  const classes: IStringProps = {
    container: 'relative flex flex-col w-full h-full md:w-2/3',
    title: 'm-3 text-stone-500 font-bold',
    inputBox: 'w-1/2 m-3 self-center',
    input: 'w-full rounded rounded-md',
    invalid: 'absolute text-red-600 font-bold',
    buttons: 'flex w-[150px] justify-between m-5 self-end',
  };

  const prevLibraryIndex: number = libraryList.indexOf(editLibrary.prevName);

  const props: IEditLibraryProps = {
    input: {
      id: 'name',
      type: 'text',
      name: 'name',
      placeholder: 'New Library Name',
      defaultValue: editLibrary.name,
      className: classes.input,
      onChange: (e: IInputEvent) =>
        setEditLibrary((prev: IEditLibrary) => ({
          ...prev,
          name: e.target.value.trim(),
        })),
    },
    delete: {
      name: 'DELETE',
      handler: () => {
        setActiveLibrary('');
        setEditLibrary((prev: ILibrary) => ({ ...prev, active: false }));
        setLibraryList([
          ...libraryList.slice(0, prevLibraryIndex),
          ...libraryList.slice(prevLibraryIndex + 1),
        ]);
        setUser((prev: IUser) => {
          const { [editLibrary.prevName]: target, ...rest } = prev.library;
          return { ...prev, library: rest };
        });
        setLibraries((prev: ILibrary) => {
          const { [editLibrary.prevName]: target, ...rest } = prev;
          return rest;
        });
        setActivePrivateLibraries((prev: ILibrary) => {
          const { [editLibrary.prevName]: target, ...rest } = prev;
          return rest;
        });
        setEditLibrary(clearEditLibrary);
      },
    },
    save: {
      name: 'SAVE',
      handler: async () => {
        setActiveLibrary('');
        setEditLibrary((prev: ILibrary) => ({ ...prev, active: false }));
        if (editLibrary.prevName) {
          await setLibraryList([
            ...libraryList.slice(0, prevLibraryIndex),
            ...libraryList.slice(prevLibraryIndex + 1),
          ]);
          await setUser((prev: IUser) => {
            const { [editLibrary.prevName]: target, ...rest } = prev.library;
            return {
              ...prev,
              library: {
                ...rest,
                [editLibrary.name]: prev.library[editLibrary.prevName],
              },
            };
          });
          await setLibraries((prev: ILibrary) => {
            const { [editLibrary.prevName]: target, ...rest } = prev;
            return { ...rest, [editLibrary.name]: prev[editLibrary.prevName] };
          });
        } else {
          await setUser((prev: IUser) => ({
            ...prev,
            library: { ...prev.library, [editLibrary.name]: true },
          }));
          await setLibraries((prev: ILibrary) => ({
            ...prev,
            [editLibrary.name]: {},
          }));
        }
        setEditLibrary(clearEditLibrary);
        setCustomIconStage(1);
      },
      disabled:
        editLibrary.name === '' ||
        Object.keys(libraries).includes(editLibrary.name),
    },
  };

  return (
    <div className={classes.container}>
      <Close handler={() => setEditLibrary(clearEditLibrary)} />
      <h1 className={classes.title}>
        {editLibrary.prevName ? 'EDIT' : 'NEW'} LIBRARY
      </h1>
      <div className={classes.inputBox}>
        <input {...props.input} />
        {editLibrary.name && libraries[editLibrary.name] && (
          <div className={classes.invalid}>Please choose unique name</div>
        )}
      </div>
      <div className={classes.buttons}>
        {editLibrary.prevName && <Button {...props.delete} />}
        <Button {...props.save} />
      </div>
    </div>
  );
};

export default EditLibrary;
