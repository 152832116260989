import { IChangeImageStates, IFileSelectComplete } from 'helpers/iHelpers';
import { nextAvailableKey } from 'helpers/helperFunctions';
import { IAddItemProps, ITowerList } from 'state/iState';
import ImageCropHelpers from 'helpers/imageCropHelpers';
import { useContext, useEffect, useMemo } from 'react';
import SaveBox from 'components/buttons/SaveBox';
import { IInputEvent, IStringProps } from 'iApp';
import { IAddDrawingsProps } from './iDrawings';
import {
  ISitesContext,
  ICanvasContext,
  IDocumentContext,
  ISettingsContext,
} from 'state/iContext';
import {
  SitesContext,
  CanvasContext,
  DocumentContext,
  SettingsContext,
} from 'state/context';

const AddDrawing = () => {
  const { addItemProps, activeTowerID, clearAddProps, setAddItemProps } =
    useContext<ICanvasContext | undefined>(CanvasContext)!;
  const { towerList, setTowerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;
  const { setActiveDrawing } = useContext<IDocumentContext | undefined>(
    DocumentContext
  )!;
  const {
    fileTypes: { acceptedImageTypes },
  } = useContext<ISettingsContext | undefined>(SettingsContext)!;

  const nextDocumentKey: number = nextAvailableKey(
    towerList[activeTowerID].documents.files,
    0
  )!;

  useEffect(() => {
    setAddItemProps(clearAddProps);
  }, []);

  const {
    imgSrc,
    setImgSrc,
    fileInputRef,
    onFileSelect,
    setImgSrcExt,
    setFileReader,
    clearToDefault,
    getImageFileExtension,
  } = ImageCropHelpers(addItemProps.content);

  // Changes/saves the state of the image and file extension
  const changeImageStates = ({ image }: IChangeImageStates): void => {
    setImgSrc(image);
    setAddItemProps((prev: IAddItemProps) => ({
      ...prev,
      content: image,
    }));
    setImgSrcExt(getImageFileExtension(image));
  };

  // Additional methods to envoke once a file is selected
  const onFileSelectComplete = ({ e, file }: IFileSelectComplete): void => {
    setFileReader({
      file,
      changeImageStates,
    });
    clearToDefault(e);
  };

  const classes: IStringProps = {
    container: 'flex flex-col w-full h-full justify-center items-center',
    heading: 'font-bold text-xl text-stone-500',
    imgImported:
      'relative w-5/6 h-5/6 flex flex-col justify-around items-center border border-stone-300 rounded rounded-lg bg-slate-100',
    embed: 'h-4/5 self-center border border-2 border-stone-400',
    img: 'h-2/3 w-fit self-center object-contain border border-2 border-stone-400',
    input: 'flex flex-col w-full h-full',
    saveBox:
      'absolute flex -right-2 sm:right-0 bottom-5 w-[150px] justify-around sm:mx-5',
  };

  const props: IAddDrawingsProps = {
    file: {
      type: 'file',
      multiple: false,
      className: 'm-2',
      ref: fileInputRef!,
      id: 'imported-drawing',
      name: 'imported-drawing',
      accept: acceptedImageTypes,
      onChange: (e: IInputEvent) => onFileSelect({ e, onFileSelectComplete }),
    },
    img: {
      id: 'displayed-user-image',
      src: addItemProps.content,
      className: classes.img,
    },
    text: {
      type: 'text',
      name: 'name',
      id: 'drawing-name',
      placeholder: 'Reference Title',
      onChange: (e: IInputEvent) =>
        setAddItemProps((prev: IAddItemProps) => ({
          ...prev,
          label: e.target.value,
        })),
    },
    saveBox: {
      classes: classes.saveBox,
      clickHandlers: {
        save: async () => {
          await setTowerList((prev: ITowerList) => ({
            ...prev,
            [activeTowerID]: {
              ...prev[activeTowerID],
              documents: {
                ...prev[activeTowerID].documents,
                folders: {
                  ...prev[activeTowerID].documents.folders,
                  1: {
                    ...prev[activeTowerID].documents.folders[1],
                    files: [
                      ...prev[activeTowerID].documents.folders[1].files,
                      nextDocumentKey,
                    ],
                  },
                },
                files: {
                  ...prev[activeTowerID].documents.files,
                  [nextDocumentKey]: {
                    [imgSrc.search('application/pdf;') > 0 ? 'file' : 'img']:
                      addItemProps.content,
                    folder: 1,
                    upload: Date.now(),
                    name: addItemProps.label.trim(),
                    isPDF: true,
                  },
                },
              },
            },
          }));
          setImgSrc('');
          setAddItemProps(clearAddProps);
          setActiveDrawing(nextDocumentKey.toString());
        },
        cancel: () => {
          setImgSrc('');
          setAddItemProps(clearAddProps);
        },
      },
      disabled: !addItemProps.content || !addItemProps.label,
    },
  };

  const displayedImage = useMemo(
    () =>
      imgSrc.search('application/pdf;') > 0 ? (
        <embed
          src={imgSrc}
          type='application/pdf'
          className={classes.embed}
          width='100%'
          height='100%'
        />
      ) : (
        <img
          alt='imported thumbail'
          {...props.img}
        />
      ),
    [imgSrc, props.img]
  );

  return (
    <div className={classes.container}>
      <h1 className={classes.heading}>Add Drawing Reference</h1>
      {imgSrc ? (
        <div className={classes.imgImported}>
          <div className={classes.input}>
            <input {...props.text} />
            {displayedImage}
          </div>
          <SaveBox {...props.saveBox} />
        </div>
      ) : (
        <input {...props.file} />
      )}
    </div>
  );
};

export default AddDrawing;
