import { useContext, useEffect, useMemo } from 'react';
import DocumentInputField from '../DocumentInputField';
import { IDocumentContext } from 'state/iContext';
import { IInputEvent, IStringProps } from 'iApp';
import { DocumentContext } from 'state/context';
import { IEditFileProps } from 'state/iState';
import ToggleOption from '../ToggleOption';

const DocHeightVerification = () => {
  const { setActiveFilePanel, editFileProps, setEditFileProps } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;

  const classes: IStringProps = useMemo(
    () => ({
      container: 'w-full h-full p-2',
      header: 'font-semibold text-stone-500',
      inputs: 'flex flex-col w-full h-5/6 overflow-y-auto overflow-x-hidden',
      button:
        'p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
    }),
    []
  );

  useEffect(() => {
    if (!editFileProps.contents.height.active) {
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          pages: prev.contents.pages.filter((str: string) => str !== 'height'),
        },
      }));
    }
  }, [editFileProps.contents.height.active]);

  return (
    <div className={classes.container}>
      <h1 className={classes.header}>Height Verification</h1>
      <ToggleOption
        name='Active Page'
        value={editFileProps.contents.height.active}
        onChange={() => {
          setEditFileProps((prev: IEditFileProps) => ({
            ...prev,
            contents: {
              ...prev.contents,
              height: {
                ...prev.contents.height,
                active: !prev.contents.height.active,
              },
              pages: [...prev.contents.pages, 'height'],
            },
          }));
        }}
      />
      <div className={classes.inputs}>
        <DocumentInputField
          name='METHOD OF MEASUREMENT'
          onChange={(e: IInputEvent) =>
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                height: {
                  ...prev.contents.height,
                  content: {
                    ...prev.contents.height.content,
                    method: e.target.value,
                  },
                },
              },
            }))
          }
          value={editFileProps.contents.height.content.method}
        />
        <DocumentInputField
          name='TOTAL HEIGHT = FOUNDATION + STEEL + APPURTENANCE'
          onChange={(e: IInputEvent) =>
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                height: {
                  ...prev.contents.height,
                  content: {
                    ...prev.contents.height.content,
                    totalHeight: e.target.value,
                  },
                },
              },
            }))
          }
          value={editFileProps.contents.height.content.totalHeight}
        />
        <DocumentInputField
          name='HEIGHT with APPURTENANCE'
          onChange={(e: IInputEvent) =>
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                height: {
                  ...prev.contents.height,
                  content: {
                    ...prev.contents.height.content,
                    withAppurtenance: e.target.value,
                  },
                },
              },
            }))
          }
          value={editFileProps.contents.height.content.withAppurtenance}
        />
        <DocumentInputField
          name='HEIGHT of APPURTENANCE'
          onChange={(e: IInputEvent) =>
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                height: {
                  ...prev.contents.height,
                  content: {
                    ...prev.contents.height.content,
                    appurtenance: e.target.value,
                  },
                },
              },
            }))
          }
          value={editFileProps.contents.height.content.appurtenance}
        />
        <DocumentInputField
          name='HEIGHT of STRUCTURE'
          onChange={(e: IInputEvent) =>
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                height: {
                  ...prev.contents.height,
                  content: {
                    ...prev.contents.height.content,
                    structure: e.target.value,
                  },
                },
              },
            }))
          }
          value={editFileProps.contents.height.content.structure}
        />
        <DocumentInputField
          name='HEIGHT of FOUNDATION'
          onChange={(e: IInputEvent) =>
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                height: {
                  ...prev.contents.height,
                  content: {
                    ...prev.contents.height.content,
                    foundation: e.target.value,
                  },
                },
              },
            }))
          }
          value={editFileProps.contents.height.content.foundation}
        />
        <DocumentInputField
          name='HEIGHT of INSTALLED ANTENNA'
          onChange={(e: IInputEvent) =>
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                height: {
                  ...prev.contents.height,
                  content: {
                    ...prev.contents.height.content,
                    antenna: e.target.value,
                  },
                },
              },
            }))
          }
          value={editFileProps.contents.height.content.antenna}
        />
        <DocumentInputField
          name='HEIGHT of TOWER EXTENSION
            (IF APPLICABLE)'
          onChange={(e: IInputEvent) =>
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                height: {
                  ...prev.contents.height,
                  content: {
                    ...prev.contents.height.content,
                    extension: e.target.value,
                  },
                },
              },
            }))
          }
          value={editFileProps.contents.height.content.extension}
        />
        <DocumentInputField
          name='TOP of TOWER'
          onChange={(e: IInputEvent) =>
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                height: {
                  ...prev.contents.height,
                  content: {
                    ...prev.contents.height.content,
                    towerTop: e.target.value,
                  },
                },
              },
            }))
          }
          value={editFileProps.contents.height.content.towerTop}
        />
        <DocumentInputField
          name='TOP of LIGHTNING ROD'
          onChange={(e: IInputEvent) =>
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                height: {
                  ...prev.contents.height,
                  content: {
                    ...prev.contents.height.content,
                    rodTop: e.target.value,
                  },
                },
              },
            }))
          }
          value={editFileProps.contents.height.content.rodTop}
        />
        <DocumentInputField
          name='TOP of TOWER LIGHTING'
          onChange={(e: IInputEvent) =>
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                height: {
                  ...prev.contents.height,
                  content: {
                    ...prev.contents.height.content,
                    lightingTop: e.target.value,
                  },
                },
              },
            }))
          }
          value={editFileProps.contents.height.content.lightingTop}
        />
        <DocumentInputField
          name='TOP of CONCRETE'
          onChange={(e: IInputEvent) =>
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                height: {
                  ...prev.contents.height,
                  content: {
                    ...prev.contents.height.content,
                    concreteTop: e.target.value,
                  },
                },
              },
            }))
          }
          value={editFileProps.contents.height.content.concreteTop}
        />
      </div>
      <button
        onClick={() => setActiveFilePanel('')}
        className={classes.button}>
        Back
      </button>
    </div>
  );
};

export default DocHeightVerification;
