import { ILibraryContext, IModalContext, IUserContext } from 'state/iContext';
import { IIconButtons, IIconOptionClasses } from '../../../../icons/iIcons';
import { LibraryContext, ModalContext, UserContext } from 'state/context';
import { IActiveModal, ILibrary } from 'state/iState';
import { ReactNode, useContext } from 'react';
import { IMapElement } from 'iApp';

const IconOptions = () => {
  const { iconIndex, activeLibrary, setActiveIcon, libraries, setLibraries } =
    useContext<ILibraryContext | undefined>(LibraryContext)!;
  const { setCustomIconStage, setActiveModal, setEditIcon } = useContext<
    IModalContext | undefined
  >(ModalContext)!;
  const {
    user: { library },
  } = useContext<IUserContext | undefined>(UserContext)!;

  const classes: IIconOptionClasses = {
    container: 'flex h-[32px] w-full bg-red-600',
    button:
      'flex w-full justify-center items-center text-slate-200 transition ease-in-out delay-100 hover:bg-red-700',
    icons: {
      copy: 'fa-solid fa-copy',
      edit: 'fa-solid fa-pen-to-square',
      // promote: 'fa-solid fa-circle-up',
      delete: 'fa-solid fa-trash-can',
    },
  };

  const buttonIcons: string[] = Object.keys(classes.icons);
  const customLibrary = Object.keys(library);
  const renderCondition: number = customLibrary.includes(activeLibrary) ? 0 : 1;

  const buttons: ReactNode[] = buttonIcons
    .filter((_, i) => i < buttonIcons.length - renderCondition)
    .map((icon): IMapElement => {
      const iconButtons: IIconButtons = {
        copy: () => {
          setCustomIconStage(3);
          setActiveModal((prev: IActiveModal) => ({
            ...prev,
            customIcon: true,
          }));
          setEditIcon({
            content: libraries[activeLibrary][iconIndex].content,
            id: libraries[activeLibrary][iconIndex].id,
            ports: libraries[activeLibrary][iconIndex].ports,
            properties: libraries[activeLibrary][iconIndex].properties,
          });
        },
        edit: () => {
          setCustomIconStage(2);
          setActiveModal((prev: IActiveModal) => ({
            ...prev,
            customIcon: true,
          }));
          setEditIcon({
            img: libraries[activeLibrary][iconIndex].content,
            id: libraries[activeLibrary][iconIndex].id,
            ports: libraries[activeLibrary][iconIndex].ports,
            properties: libraries[activeLibrary][iconIndex].properties,
            edit: true,
          });
        },
        // promote: () => console.log(icon),
        delete: () => {
          setActiveIcon('');
          setLibraries((prev: ILibrary) => {
            const { [iconIndex]: target, ...rest } = prev[activeLibrary];
            return {
              ...prev,
              [activeLibrary]: rest,
            };
          });
        },
      };
      return (
        <div
          key={icon}
          className={classes.button}
          onClick={iconButtons[icon]}>
          <i className={classes.icons[icon]} />
        </div>
      );
    });

  return <div className={classes.container}>{buttons}</div>;
};

export default IconOptions;
