import { ICanvasContext } from 'state/iContext';
import { CanvasContext } from 'state/context';
import PropertyLabel from '../PropertyLabel';
import { IGridItem } from '../iGridItems';
import { useContext } from 'react';

const GridAsset = ({ layout }: IGridItem) => {
  const {
    activeToolBar: { Properties },
  } = useContext<ICanvasContext | undefined>(CanvasContext)!;

  return (
    <>
      <img
        className='w-full h-full object-contain'
        src={layout.img}
        alt='icon/asset'
      />
      {Properties && (
        <PropertyLabel
          label={layout.name!}
          type='icon'
        />
      )}
    </>
  );
};

export default GridAsset;
