import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { IHeaderContext, ICanvasContext } from 'state/iContext';
import { HeaderContext, CanvasContext } from 'state/context';
import AddCanvasButton from './toolbar/AddCanvasButton';
import { useContext, useEffect, useMemo } from 'react';
import HistoryPanel from './toolbar/history/History';
import CanvasGridLines from './CanvasGridLines';
import DataToolBar from './toolbar/DataToolBar';
import CanvasPanel from './panel/CanvasPanel';
import { ILibraryIcon } from 'state/iState';
import { IStringProps } from 'iApp';
import Footer from 'Footer';
import React from 'react';

const Canvas = () => {
  const {
    scale,
    setScale,
    dragProps,
    setDragProps,
    activeToolBar,
    clearDragProps,
  } = useContext<ICanvasContext | undefined>(CanvasContext)!;
  const {
    activeHeaderButton: { tower, site, data },
  } = useContext<IHeaderContext | undefined>(HeaderContext)!;

  const classes: IStringProps = {
    container: 'flex flex-col h-full md:flex-row-reverse md:w-full',
    zoomable: 'relative flex flex-col h-[94%] md:h-full w-screen',
    canvas: `relative w-full h-full bg-stone-200`,
    transform: 'w-full h-full',
    placeholder: 'h-full w-full opacity-0',
  };

  const options = useMemo(
    () => ({
      x: data ? -4400 : tower ? -3900 : -3400,
      y: data ? -7400 : -3600,
    }),
    [data, tower, site]
  );

  const activeTab: string = useMemo(
    () => (data ? 'data' : tower ? 'tower' : 'site'),
    [data, tower]
  );

  const handlers = {
    dragStop: () =>
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
      })),
    onZoom: (e) => {
      setScale(e.state.scale);
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
      }));
    },
    clear: () => setDragProps(clearDragProps),
  };

  useEffect(() => setDragProps(clearDragProps), []);

  const customStyles = {
    width: '100%', // Overwrite the width style
    // Add any other custom styles you need
  };

  return (
    <div
      key={activeTab}
      className={classes.container}>
      <div className={classes.zoomable}>
        <TransformWrapper
          maxScale={10}
          minScale={0.1}
          initialScale={scale}
          limitToBounds={false}
          initialPositionX={options.x}
          initialPositionY={options.y}
          panning={{
            disabled: dragProps.dragging,
            excluded: ['input', 'textarea'],
          }}
          onPanningStop={handlers.dragStop}
          onWheelStop={handlers.dragStop}
          onZoom={handlers.onZoom}>
          {(util) => (
            <React.Fragment>
              <DataToolBar
                {...util}
                full={true}
              />
              <div
                className={classes.canvas}
                onDoubleClick={handlers.clear}>
                <TransformComponent
                  wrapperStyle={customStyles}
                  contentClass={classes.transform}
                  wrapperClass={classes.transform}>
                  <img
                    src=''
                    alt='prod grid placeholder'
                    className={classes.placeholder}
                  />
                  <CanvasGridLines />
                </TransformComponent>
              </div>
            </React.Fragment>
          )}
        </TransformWrapper>
        {(tower || data) && <AddCanvasButton />}
        {activeToolBar.history && <HistoryPanel />}
        <Footer />
      </div>
      <CanvasPanel />
    </div>
  );
};

export default Canvas;
