import { DocumentContext, CanvasContext, SitesContext } from 'state/context';
import { nextAvailableKey } from 'helpers/helperFunctions';
import { ITower, IUpdateFolders } from 'state/iState';
import { IInputEvent, IStringProps } from 'iApp';
import { useContext, useState } from 'react';
import {
  IDocumentContext,
  ICanvasContext,
  ISitesContext,
} from 'state/iContext';

const UpdateFolder = () => {
  const { activeTowerID } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;
  const { towerList, setTowerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;
  const { updateFolders, setUpdateFolders } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;

  const folder =
    towerList[activeTowerID].documents.folders[updateFolders.folder];

  const [datesRequired, setDatesRequired] = useState<boolean>(
    folder.datesRequired
  );

  const nextKey: number = nextAvailableKey(
    towerList[activeTowerID].documents.folders,
    0
  )!;

  const updateFolder = updateFolders.edit
    ? {
        [updateFolders.folder]: {
          name: updateFolders.name.trim(),
          folders: folder.folders,
          files: folder.files,
        },
      }
    : {
        [nextKey]: {
          name: updateFolders.name.trim(),
          folders: [],
          files: [],
          datesRequired,
        },
      };

  const handler = {
    text: (e: IInputEvent) =>
      setUpdateFolders((prev: IUpdateFolders) => ({
        ...prev,
        name: e.target.value,
      })),
    date: () => setDatesRequired((prev: boolean) => !prev),
    save: () => {
      setTowerList((prev: ITower) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          documents: {
            ...prev[activeTowerID].documents,
            folders: {
              ...prev[activeTowerID].documents.folders,
              [updateFolders.folder]: {
                ...prev[activeTowerID].documents.folders[updateFolders.folder],
                folders: [
                  ...prev[activeTowerID].documents.folders[updateFolders.folder]
                    .folders,
                  nextKey,
                ],
              },
              ...updateFolder,
            },
          },
        },
      }));
      setUpdateFolders((prev: IUpdateFolders) => ({ ...prev, active: false }));
    },
    cancel: () => {
      setUpdateFolders((prev: IUpdateFolders) => ({ ...prev, active: false }));
    },
  };

  const classes: IStringProps = {
    container:
      'flex justify-center items-center w-full border border-stone-400 even:bg-slate-100',
    title: 'font-bold text-stone-500',
    text: 'mx-4 p-0',
    checkbox: 'mx-2',
    button:
      'p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
  };

  return (
    <div className={classes.container}>
      <label
        htmlFor='name'
        className={classes.title}>
        {updateFolders.edit ? <span>Edit</span> : <span>New</span>} Folder Name:
      </label>
      <input
        type='text'
        name='folder'
        onChange={handler.text}
        className={classes.text}
        value={updateFolders.name}
      />
      <label htmlFor='datesRequired'>Files Require Expiry Date:</label>
      <input
        type='checkbox'
        name='datesRequired'
        checked={datesRequired}
        onChange={handler.date}
        id='dates-required-checkbox'
        className={classes.checkbox}
      />
      <button
        className={classes.button}
        onClick={handler.save}>
        Save
      </button>
      <button
        className={classes.button}
        onClick={handler.cancel}>
        Cancel
      </button>
    </div>
  );
};

export default UpdateFolder;
