import { IChangeImageStates, IFileSelectComplete } from 'helpers/iHelpers';
import { ICanvasContext, ISettingsContext } from 'state/iContext';
import { CanvasContext, SettingsContext } from 'state/context';
import ImageCropHelpers from 'helpers/imageCropHelpers';
import { IAddCanvasImageProps } from '../iCanvasPanel';
import { IInputEvent, IStringProps } from 'iApp';
import { IAddItemProps } from 'state/iState';
import { useContext } from 'react';

const AddImage = () => {
  const { addItemProps, setAddItemProps } = useContext<
    ICanvasContext | undefined
  >(CanvasContext)!;
  const {
    fileTypes: { acceptedImageTypes },
  } = useContext<ISettingsContext | undefined>(SettingsContext)!;

  const {
    setImgSrc,
    fileInputRef,
    onFileSelect,
    setImgSrcExt,
    setFileReader,
    clearToDefault,
    getImageFileExtension,
  } = ImageCropHelpers(addItemProps.content);

  // Changes/saves the state of the image and file extension
  const changeImageStates = ({ image }: IChangeImageStates): void => {
    setImgSrc(image);
    setAddItemProps((prev: IAddItemProps) => ({
      ...prev,
      content: image,
    }));
    setImgSrcExt(getImageFileExtension(image));
  };

  // Additional methods to envoke once a file is selected
  const onFileSelectComplete = ({ e, file }: IFileSelectComplete): void => {
    setFileReader({
      file: file,
      changeImageStates,
    });
    clearToDefault(e);
  };

  const classes: IStringProps = {
    cropContainer: 'flex flex-col justify-center items-center',
    reactCrop:
      'min-w-[208px] min-h-[208px] max-w-[208px] max-h-[208px] object-fit',
    canvas: '',
    img: 'h-1/2 w-full self-center object-contain',
  };

  const props: IAddCanvasImageProps = {
    input: {
      type: 'file',
      multiple: false,
      className: 'm-2',
      ref: fileInputRef!,
      id: 'imported-image-box',
      name: 'imported-image-box',
      accept: acceptedImageTypes,
      onChange: (e: IInputEvent) => onFileSelect({ e, onFileSelectComplete }),
    },
    img: {
      id: 'displayed-user-image',
      src: addItemProps.content,
      className: classes.img,
    },
  };

  return (
    <>
      {addItemProps.content ? (
        <img
          alt='imported grid item'
          {...props.img}
        />
      ) : (
        <input {...props.input} />
      )}
    </>
  );
};

export default AddImage;
