import { ISettingsContext } from 'state/iContext';
import { SettingsContext } from 'state/context';
import { ReactNode, useContext } from 'react';
import { IStringProps } from 'iApp';

const DocVerticalityTable = ({ lines }: { lines: ReactNode[] }) => {
  const { scale } = useContext<ISettingsContext | undefined>(SettingsContext)!;

  const classes: IStringProps = {
    table: 'w-full h-1/3 border overflow-y-auto',
    head: 'flex w-full px-6 border-x border-x-4 border-x-white',
    headOne:
      'w-1/5 p-1 text-center text-stone-500 font-bold border border-stone-500 text-center underline',
    headTwo:
      'w-2/5 p-1 text-center text-stone-500 font-bold border border-stone-500 text-center underline',
    sub: 'flex w-1/5 justify-center',
    subOne:
      'flex w-1/3 border border-stone-500 text-center font-bold justify-center items-center text-xs',
    subTwo: 'flex w-2/5 justify-center',
    subText:
      'flex w-1/5 border border-stone-500 text-center font-bold justify-center items-center text-xs',
  };

  return (
    <div className={classes.table}>
      <div className={classes.head}>
        <h1 className={classes.headOne}>Observed Mast Data</h1>
        <h1 className={classes.headTwo}>
          Tower Twist Measurements (in Degrees)
        </h1>
        <h1 className={classes.headTwo}>Calculated Tower Plumb (mm)</h1>
      </div>
      <div className={classes.head}>
        <div className={classes.sub}>
          <div className={classes.subOne}>
            <span>Mast Elev ({scale})</span>
          </div>
          <div className={classes.subOne}>
            <span>Face Width (mm)</span>
          </div>
          <div className={classes.subOne}>
            <span>Leg Size (mm)</span>
          </div>
        </div>
        <div className={classes.subTwo}>
          <div className={classes.subText}>
            <span>Total Measured Twist</span>
          </div>
          <div className={classes.subText}>
            <span>Total Allowable Twist</span>
          </div>
          <div className={classes.subText}>
            <span>Relative Twist Between Elevations</span>
          </div>
          <div className={classes.subText}>
            <span>Allowable Twist Between Elevations</span>
          </div>
          <div className={classes.subText}>
            <span>Within S37 Limits?</span>
          </div>
        </div>
        <div className={classes.subTwo}>
          <div className={classes.subText}>
            <span>Total Measured Deflection</span>
          </div>
          <div className={classes.subText}>
            <span>Total Allowable Deflection</span>
          </div>
          <div className={classes.subText}>
            <span>Relative Deflection Between Elevations</span>
          </div>
          <div className={classes.subText}>
            <span>Allowable Deflection Between Elevations</span>
          </div>
          <div className={classes.subText}>
            <span>Within S37 Limits?</span>
          </div>
        </div>
      </div>
      {lines}
    </div>
  );
};

export default DocVerticalityTable;
