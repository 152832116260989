import { IPortStage, IPortStageClasses, IPortStageProps } from './iPorts';
import ResizeAddPorts from './ResizeAddPorts';
import EditPort from './editPort/EditPort';
import ImagePreview from './ImagePreview';
import { useState } from 'react';

const PortStage = ({ editIcon, setEditIcon }: IPortStage) => {
  const [editPort, setEditPort] = useState<string>('');

  const classes: IPortStageClasses = {
    container: 'flex flex-col w-full h-full',
    boxes:
      'flex flex-col-reverse w-full h-[80%] mt-3 justify-between md:flex-row',
    right: 'flex flex-col w-full h-1/2 md:h-full md:w-1/2 md:ml-2',
    info: 'w-full m-2 text-center text-stone-400 text-[0.6rem] md:w-1/2',
  };

  const { ports } = editIcon;
  const props: IPortStageProps = {
    resize: {
      ports,
      editPort,
      handlers: { setEditPort, setEditIcon },
    },
    editPort: {
      editPort,
      blocks: ports.blocks!,
      setEditPort,
      setEditIcon,
    },
  };

  return (
    <div className={classes.container}>
      <div className={classes.boxes}>
        <ResizeAddPorts {...props.resize} />
        <div className={classes.right}>
          {editPort ? (
            <EditPort {...props.editPort} />
          ) : (
            <ImagePreview img={editIcon.content} />
          )}
        </div>
      </div>
      <span className={classes.info}>
        Click on the grey squares to add ports to your icon
      </span>
    </div>
  );
};

export default PortStage;
