import { DocumentContext, CanvasContext, SitesContext } from 'state/context';
import { IAddItemProps, ITower } from 'state/iState';
import { ICallbackProps, IStringProps } from 'iApp';
import { useContext } from 'react';
import {
  ISitesContext,
  ICanvasContext,
  IDocumentContext,
} from 'state/iContext';

const EditDrawing = () => {
  const { activeTowerID, setAddItemProps } = useContext<
    ICanvasContext | undefined
  >(CanvasContext)!;
  const { towerList, setTowerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;
  const { activeDrawing, setActiveDrawing } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;

  const { [activeDrawing]: toBeModified, ...rest } =
    towerList[activeTowerID].documents.files;

  const handler: ICallbackProps = {
    edit: () => {
      setAddItemProps((prev: IAddItemProps) => ({
        ...prev,
        edit: true,
        label: toBeModified.name,
        index: activeDrawing,
      }));
    },
    trash: () => {
      setActiveDrawing('add');
      setTowerList((prev: ITower) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          documents: {
            ...prev[activeTowerID].documents,
            files: rest,
          },
        },
      }));
    },
  };

  const classes: IStringProps = {
    constiner: 'absolute top-0 right-0 flex',
    edit: 'fa-regular fa-pen-to-square text-lg m-1 font-bold text-stone-400 cursor-pointer hover:text-red-700',
    trash:
      'fa-regular fa-trash-can text-lg m-1 font-bold text-stone-400 cursor-pointer hover:text-red-700',
  };

  return (
    <div className={classes.container}>
      <i
        className={classes.edit}
        onClick={handler.edit}
      />
      <i
        className={classes.trash}
        onClick={handler.trash}
      />
    </div>
  );
};

export default EditDrawing;