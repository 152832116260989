import { IHeaderContext, ICanvasContext, ISitesContext } from 'state/iContext';
import { HeaderContext, CanvasContext, SitesContext } from 'state/context';
import { IActiveToolBar, IAddItemProps, ITowerList } from 'state/iState';
import { nextAvailableKey } from 'helpers/helperFunctions';
import { useContext, useMemo } from 'react';
import { IStringProps } from 'iApp';

const AddCanvasButton = () => {
  const { activeTowerID, setActiveToolBar, setAddItemProps } = useContext<
    ICanvasContext | undefined
  >(CanvasContext)!;
  const { towerList, setTowerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;
  const {
    activeHeaderButton: { data },
  } = useContext<IHeaderContext | undefined>(HeaderContext)!;

  const legs = towerList[activeTowerID].layout.tower.legs;
  const datas = towerList[activeTowerID].layout.data.shelves;
  const noLegs = Object.keys(legs).length === 0;
  const noData = Object.keys(datas).length === 0;

  const nextDataKey: number = useMemo(
    () => (!noData ? nextAvailableKey(datas, 0)! : 0),
    [datas]
  );
  const nextTowerKey: number = useMemo(
    () => (!noLegs ? nextAvailableKey(legs, 0)! : 0),
    [legs]
  );
  const dataPositions = noData
    ? [100]
    : Object.keys(datas).map((data) => datas[data].shelfGrid.x);
  const towerPositions = noLegs
    ? [300]
    : Object.keys(legs).map((leg) => legs[leg].legGrid.x);

  const nextDataPosition = Math.max(...dataPositions) + 35;
  const nextTowerPosition = Math.max(...towerPositions) + 35;

  const addClickHandler = data
    ? () => {
        setTowerList((prev: ITowerList) => ({
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            layout: {
              ...prev[activeTowerID].layout,
              data: {
                ...prev[activeTowerID].layout.data,
                shelves: {
                  ...prev[activeTowerID].layout.data.shelves,
                  [nextDataKey]: {
                    shelfGrid: {
                      i: nextDataKey,
                      name: `Rack ${nextDataKey + 1}`,
                      x: nextDataPosition,
                      y: 500,
                      w: 10,
                      h: 40,
                    },
                    icons: {
                      '-1': {
                        i: -1,
                        name: '',
                        x: 0,
                        y: 40,
                        w: 0,
                        minW: 0,
                        minH: 0,
                        h: 0,
                        static: true,
                        isResizable: false,
                      },
                    },
                  },
                },
              },
            },
          },
        }));
        setAddItemProps((prev: IAddItemProps) => ({
          ...prev,
          label: `data ${nextDataKey + 1}`,
          index: nextDataKey,
        }));
        setActiveToolBar((prev: IActiveToolBar) => ({
          ...prev,
          edit: !prev.edit,
          select: prev.edit,
          image: false,
          text: false,
          drawings: false,
          sections: false,
        }));
      }
    : () => {
        setTowerList((prev: ITowerList) => ({
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            layout: {
              ...prev[activeTowerID].layout,
              tower: {
                ...prev[activeTowerID].layout.tower,
                legs: {
                  ...prev[activeTowerID].layout.tower.legs,
                  [nextTowerKey]: {
                    legGrid: {
                      i: nextTowerKey,
                      name: `Leg ${nextTowerKey + 1}`,
                      x: nextTowerPosition,
                      y: 500,
                      w: 10,
                      h: prev[activeTowerID].height,
                      minW: 10,
                      maxW: 10,
                    },
                    icons: {
                      '-1': {
                        i: -1,
                        name: '',
                        x: 0,
                        y: prev[activeTowerID].height,
                        w: 0,
                        minW: 0,
                        minH: 0,
                        h: 0,
                        static: true,
                        isResizable: false,
                      },
                    },
                  },
                },
              },
            },
          },
        }));
      };

  const classes: IStringProps = {
    container: 'absolute right-0 top-10 flex flex-col px-4',
    button: 'group flex items-center rounded hover:bg-red-600',
    label: 'hidden p-1 text-slate-200 group-hover:flex',
    icon: 'fa-solid fa-square-plus px-1 text-2xl text-slate-400 hover:text-slate-300',
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.button}
        onClick={addClickHandler}>
        <span className={classes.label}>New {data ? 'Rack' : 'Leg'}</span>
        <i className={classes.icon} />
      </div>
    </div>
  );
};

export default AddCanvasButton;
