import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { IPDFHeaderFooterContent } from '../iDocumentEditor';
import { ReactNode, useMemo } from 'react';

const PDFHeader = ({ contents }: IPDFHeaderFooterContent) => {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'row',
          width: '100%',
          height: '20px',
          justifyContent: 'space-between',
          marginBottom: '20px',
          borderBottom: '1px',
          paddingHorizontal: 4,
        },
        image: { width: '30%', objectFit: 'contain' },
        text: { fontSize: '10px' },
      }),
    []
  );

  const left: ReactNode = contents['left'].image ? (
    <Image
      style={styles.image}
      src={contents['left'].content}
    />
  ) : (
    <Text style={styles.text}>{contents['left'].content}</Text>
  );
  const center: ReactNode = contents['center'].image ? (
    <Image
      style={styles.image}
      src={contents['center'].content}
    />
  ) : (
    <Text style={styles.text}>{contents['center'].content}</Text>
  );
  const right: ReactNode = contents['right'].image ? (
    <Image
      style={styles.image}
      src={contents['right'].content}
    />
  ) : (
    <Text style={styles.text}>{contents['right'].content}</Text>
  );

  return (
    <View
      fixed
      style={styles.container}>
      {left}
      {center}
      {right}
    </View>
  );
};

export default PDFHeader;
