import { Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import PDFDeficiencyLine from './PDFDeficiencyLine';
import PDFFooter from '../headerFooter/PDFFooter';
import PDFHeader from '../headerFooter/PDFHeader';
import { ReactNode, useMemo } from 'react';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
  ],
});

const PDFDeficiencies = ({ contents }) => {
  const { content } = contents.deficiencies;

  const values = Object.values(content);
  const deficiencyGroups = values.reduce(
    (groups: ReactNode[][], item, i) => {
      const groupIndex = Math.floor(i / 10);
      groups[groupIndex] = groups[groupIndex] || [];
      groups[groupIndex].push(
        <PDFDeficiencyLine
          info={item}
          key={i}
        />
      );
      return groups;
    },
    [[]]
  );

  const styles = useMemo(
    () =>
      StyleSheet.create({
        page: {
          flexDirection: 'row',
          padding: '0.5in',
          flexWrap: 'wrap',
          width: '100%',
          height: '90%',
        },
        header: {
          width: '100%',
          fontWeight: 600,
          textDecoration: 'underline',
          fontSize: '15px',
          textAlign: 'center',
          marginBottom: '10px',
          fontFamily: 'Open Sans',
        },
        title: {
          fontWeight: 'bold',
          textDecoration: 'underline',
          fontSize: '12px',
          marginBottom: '20px',
        },
        text: {
          fontSize: '10px',
          padding: '10px',
        },
        deficiencies: {
          flexDirection: 'column',
          width: '100%',
          height: '95%',
          alignContent: 'center',
          alignItems: 'center',
          marginTop: '20px',
        },
        tableHeader: {
          flexDirection: 'row',
          border: 'solid',
          borderWidth: '1px',
          width: '100%',
        },
        check: {
          border: 'solid',
          borderWidth: '1px',
          width: '10%',
          textAlign: 'center',
          fontSize: '9px',
          padding: 4,
          height: '100%',
        },
        deficiency: {
          border: 'solid',
          borderWidth: '1px',
          width: '30%',
          fontSize: '15px',
          padding: 4,
          alignItems: 'center',
          textAlign: 'center',
          height: '100%',
        },
        recommendation: {
          border: 'solid',
          borderWidth: '1px',
          width: '40%',
          fontSize: '15px',
          padding: 4,
          alignItems: 'center',
          textAlign: 'center',
          height: '100%',
        },
        contr: {
          border: 'solid',
          borderWidth: '1px',
          width: '10%',
          textAlign: 'center',
          fontSize: '12px',
          padding: 4,
          alignItems: 'center',
          height: '100%',
        },
        photo: {
          border: 'solid',
          borderWidth: '1px',
          width: '10%',
          textAlign: 'center',
          fontSize: '10px',
          padding: 4,
          alignItems: 'center',
          height: '100%',
        },
      }),
    []
  );

  const deficiencies = useMemo(
    () =>
      deficiencyGroups
        .filter((group) => group.length !== 0)
        .map((group, i) => (
          <Page
            key={i}
            size='A4'
            style={styles.page}>
            <View style={styles.deficiencies}>
              {contents.header.active && (
                <PDFHeader contents={contents.header.contents} />
              )}
              <View>
                <Text style={styles.header}>SUMMARY OF DEFICIENCIES</Text>
                <Text style={styles.text}>
                  The following is a list of deficiencies identified and
                  referenced by the checking procedure in the appendix. Please
                  refer to the detailed sheets for pictures.
                </Text>
                <View style={styles.tableHeader}>
                  <Text style={styles.check}>Checking Procedure</Text>
                  <Text style={styles.deficiency}>Deficiency</Text>
                  <Text style={styles.recommendation}>Recommendation</Text>
                  <Text style={styles.contr}>Contr. Init.</Text>
                  <Text style={styles.photo}>Photo on Page(s)</Text>
                </View>
              </View>
              {group}
            </View>
            {contents.footer.active && (
              <PDFFooter contents={contents.footer.contents} />
            )}
          </Page>
        )),
    [deficiencyGroups]
  );

  return <>{deficiencies}</>;
};

export default PDFDeficiencies;
