import guyed from '../assets/siteTypes/guyed.jpg';
import fourLeg from '../assets/siteTypes/4-leg.jpg';
import threeLeg from '../assets/siteTypes/3-leg.jpg';
import rooftop from '../assets/siteTypes/rooftop.jpg';
import stealth from '../assets/siteTypes/stealth.jpg';
import monopole from '../assets/siteTypes/monopole.jpg';
import camouflage from '../assets/siteTypes/camouflage.jpg';
import towerYellow from '../assets/towers/tower-yellow.svg';
import towerPurple from '../assets/towers/tower-purple.svg';
import towerBlack from '../assets/towers/tower-black.svg';
import towerBrown from '../assets/towers/tower-brown.svg';
import towerGreen from '../assets/towers/tower-green.svg';
import towerBlue from '../assets/towers/tower-blue.svg';
import towerRed from '../assets/towers/tower-red.svg';
import rooftopYellow from '../assets/towers/rooftop-yellow.png';
import { IEditProps } from '../components/home/editPanel/iEdit';
import { PixelCrop } from 'react-image-crop';
import {
  ISiteTypes,
  IMenuFilters,
  ITowerColors,
  IMeasurements,
  LatLngLiteral,
  IActiveToolBar,
  IHeaderButtons,
  IAccountDropDown,
  IMeasureEndPoints,
  IPortBlock,
  ILibraryIcon,
  IAddItemProps,
  IEditLibrary,
  IEditFileProps,
  IDocumentTowerInfo,
  IInspectionChecklist,
} from './iState';

export const version: string = `0.0.1`

// Initial state values
export const measurements: IMeasurements = {
  imperial: {
    long: 'miles',
    short: 'ft',
  },
  metric: {
    long: 'kms',
    short: 'm',
  },
};

export const headerButtons: IHeaderButtons = {
  siteTypes: false,
  account: false,
  settings: false,
  tower: true,
  site: false,
  data: false,
  drawings: false,
  files: false,
};

export const userDropdown: IAccountDropDown = [
  'My Account',
  'Settings',
  'Logout',
];

export const towerColors: ITowerColors = {
  black: towerBlack,
  blue: towerBlue,
  brown: towerBrown,
  green: towerGreen,
  purple: towerPurple,
  red: towerRed,
  yellow: towerYellow,
};

export const siteTypes: ISiteTypes = {
  'All Types': null,
  Monopole: towerColors.red,
  Guyed: towerColors.blue,
  'Self-Supporting': towerColors.purple,
  Rooftop: rooftopYellow,
};

export const towerTypes: Array<string> = [
  'Monopole',
  '3 Leg',
  '4 Leg',
  'Rooftop',
];

export const stockImages: string[] = [
  threeLeg,
  fourLeg,
  camouflage,
  guyed,
  monopole,
  rooftop,
  stealth,
];

export const fileTypes = {
  acceptedImageTypes: 'image/x-png, image/png, image/jpg, image/jpeg, image/svg, application/pdf',
  imageTypeList: [
    'image/x-png',
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/svg',
    'application/pdf',
  ],
};

export const defaultMenuFilters: IMenuFilters = {
  'all-types': true,
  monopole: true,
  guyed: true,
  'self-supporting': true,
  rooftop: true,
};

export const defaultCropConfig: PixelCrop = {
  unit: 'px',
  x: 5,
  y: 5,
  width: 15,
  height: 15,
};

export const mapCenter: LatLngLiteral = {
  lat: 0,
  lng: 0,
};

export const measureDistanceEndPoints: IMeasureEndPoints = {
  start: null,
  end: null,
};

export const activeDataToolBar: IActiveToolBar = {
  select: true,
  // hand: false,
  // move: false,
  image: false,
  text: false,
  drawings: false,
  sections: false,
  edit: false,
  // textPosition: false,
  // zoomIn: false,
  // zoomOut: false,
  // undo: null,
  // redo: null,
  // resetTransform: null,
  Lease: true,
  Properties: true,
  Ports: false,
  Grid: true,
  'Link Style': false,
  history: false,
};

export const editPanelProps: IEditProps = {
  towerId: 0,
  company: '',
  img: '',
  name: '',
  code: '',
  type: '',
  location: mapCenter,
  height: 0,
  contact: {
    name: '',
    phone: 0,
    email: '',
  },
  comments: '',
  dates: {
    inspection: Date.now(),
    license: Date.now(),
  },
  layout: {
    tower: {
      legs: {},
      images: {},
      textBoxes: {},
      drawings: {},
    },
    site: {
      image: null,
      imageGrid: null,
      assets: {},
      images: {},
      textBoxes: {},
      drawings: {},
    },
  },
};
export const clearModal = {
  manageLibraries: false,
  customIcon: false,
  createDocument: false,
  downloadDocument: false,
  permissionSettings: false,
};

export const emptyPortBlock: IPortBlock = {
  label: '',
  type: 'Port Type',
  side: 'Port Side',
  limit: 'Port Limit',
  grouping: 'Port Grouping',
};

export const clearDragProps: ILibraryIcon = {
  id: '',
  content: '',
  ports: {
    w: 10,
    h: 2,
  },
  properties: [],
};

export const clearAddProps: IAddItemProps = {
  label: '',
  content: '',
};

export const clearEditLibrary: IEditLibrary = {
  active: false,
  name: '',
  prevName: '',
};

export const clearEditIcon: ILibraryIcon = {
  content: '',
  id: '',
  ports: {
    w: 2,
    h: 2,
    blocks: {},
  },
  properties: [],
};

export const clearEditFileProps: IEditFileProps = {
  type: '',
  id: '',
  contents: {
    name: '',
    frontPage: {
      active: false,
      header: '',
    },
    tableOfContents: {
      active: false,
      content: {},
    },
    header: {
      active: false,
      contents: {
        left: {
          image: false,
          content: '',
        },
        center: {
          image: false,
          content: '',
        },
        right: {
          image: false,
          content: '',
        },
      },
    },
    footer: {
      active: false,
      contents: {
        left: {
          image: false,
          content: '',
        },
        center: {
          image: false,
          content: '',
        },
        right: {
          image: false,
          content: '',
        },
      },
    },
    pages: [],
    text: {},
    images: {
      active: false,
      content: {},
    },
    inventory: {
      active: false,
      assets: {
        tower: {},
        data: {},
        rooftop: {},
      },
      images: {
        tower: {},
        data: {},
        rooftop: {},
      },
    },
    tower: {
      'Site Information': {},
      'Inspection Details': {},
      'Technical Information': {},
      'Structure Information': {},
      'Shelter Details': {},
      'Climbing Facility': {},
      'Tower Lighting': {},
    },
    deficiencies: {
      active: false,
      content: {},
    },
    guy: {
      active: false,
      content: {},
    },
    height: {
      active: false,
      content: {
        totalHeight: '',
        withAppurtenance: '',
        appurtenance: '',
        structure: '',
        foundation: '',
        antenna: '',
        extension: '',
        method: '',
        towerTop: '',
        rodTop: '',
        lightingTop: '',
        concreteTop: '',
      },
    },
    data: {
      activePages: {
        inventory: false,
        image: false,
      },
      assets: {},
      images: {},
    },
    rooftop: {
      activePages: {
        inventory: false,
        image: false,
      },
      assets: {},
      images: {},
    },
    verticality: {
      active: false,
      content: {},
    },
    checklist: {
      active: false,
      lists: {},
    },
  },
};

export const editorModules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    [{ align: [] }],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean'],
  ],
};

export const editorFormats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'size',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'script',
  'font',
  'color',
  'background',
  'align',
];

export const clearDocumentTowerInfo: IDocumentTowerInfo = {
  'Site Information': {
    'Location Code': '',
    'Location Owner': '',
    'Site Name': '',
    'Site Address': '',
    City: '',
    'Province/State': '',
  },
  'Technical Information': {
    Latitude: '',
    Longitude: '',
    'Site Access': '',
    Standard: '',
  },
  'Inspection Details': {
    'Inspection Date': new Date().toISOString().slice(0, 10),
    Temperature: '',
    'Weather Condition': '',
    'Wind Speed': '',
    'Wind Direction': '',
    Inspectors: [],
  },
  'Structure Information': {
    'Structure Height': 50,
    'Structure Type': '',
    'Construction Type': '',
    Manufacturer: '',
  },
  'Shelter Details': {
    totalShelters: 1,
    shelters: {
      0: {
        'Shelter Name': '',
        'Shelter MFG': '',
        'Shelter Serial #': '',
        'Shelter Dimensions': '',
        'HVAC MFG': '',
        'HVAC Serial #': '',
      },
    },
  },
  'Climbing Facility': {
    Type: '',
    Location: '',
    'Safety Rail': '',
  },
  'Tower Lighting': {
    Type: '',
    Manufacturer: '',
    '# of Bulbs': 0,
    Elevation: 0,
    Cable: '',
  },
};

export const clearActiveTowerDetails = {
  'Site Information': false,
  'Technical Information': false,
  'Inspection Details': false,
  'Structure Information': false,
  'Climbing Facility': false,
  'Tower Lighting': false,
  'Shelter Details': false,
};

export const clearInspectionChecklist: IInspectionChecklist = {
  'Site Conditions': {
    1: {
      title:
        'All temporary structures, rubbish and construction materials have been removed from site Debris in Compound',
      comments: '',
      grade: '',
    },
    2: {
      title: 'Clearing and grubbing has been performed and all debris removed',
      comments: '',
      grade: '',
    },
    3: {
      title: 'Access road in good condition Overgrown',
      comments: '',
      grade: '',
    },
    4: {
      title: 'Adequate drainage and erosion control has been provided',
      comments: '',
      grade: '',
    },
    5: {
      title: 'Chain link fencing in good condition',
      comments: '',
      grade: '',
    },
    6: {
      title: 'Gates in good condition',
      comments: '',
      grade: '',
    },
    7: {
      title: 'Locks provided to prevent unauthorized access',
      comments: '',
      grade: '',
    },
    8: {
      title: 'Shelter is in good condition (dents/graffiti/damage)',
      comments: '',
      grade: '',
    },
    9: {
      title: 'Warning signs provided if needed',
      comments: '',
      grade: '',
    },
  },
  'Tower Base': {
    1: {
      title: 'Foundations in good condition',
      comments: '',
      grade: '',
    },
    2: {
      title: 'No voids, cdatas or honeycombing visible',
      comments: '',
      grade: '',
    },
    3: {
      title: 'Weepholes provided to allow drainage',
      comments: '',
      grade: '',
    },
    4: {
      title: 'Anchorage nuts and/or nut locking device tight',
      comments: '',
      grade: '',
    },
    5: {
      title: 'Full bearing on baseplate',
      comments: '',
      grade: '',
    },
    6: {
      title: 'Backfill provided such that accumulation of water is prevented',
      comments: '',
      grade: '',
    },
    7: {
      title: 'No structural defects visible',
      comments: '',
      grade: '',
    },
    8: {
      title:
        'All steel materials and fittings are hot dip galvanized and rust - free',
      comments: '',
      grade: '',
    },
  },
  Guys: {
    1: {
      title: 'Guy tensions complies with CSA-S37',
      comments: '',
      grade: '',
    },
    2: {
      title:
        'Guys are free of kinks, damaged strands, bird caging, corrosion or other deficiencies',
      comments: '',
      grade: '',
    },
    3: {
      title: 'Guy cable initial tensions set within specified range',
      comments: '',
      grade: '',
    },
    4: {
      title:
        'Passive ends of guys are tied with galvanized wire or compressed metal bands',
      comments: '',
      grade: '',
    },
    5: {
      title: 'Preformed guy grips are properly applied and fully wrapped',
      comments: '',
      grade: '',
    },
    6: {
      title: 'Tension tags installed (one per guy at each anchor)',
      comments: '',
      grade: '',
    },
    7: {
      title: 'Yellow guy guards installed on bottom guy at anchor',
      comments: '',
      grade: '',
    },
    8: {
      title: 'Ice breakers installed above grounding clamps',
      comments: '',
      grade: '',
    },
    9: {
      title: 'Full articulation provided at anchor ends',
      comments: '',
      grade: '',
    },
    10: {
      title:
        'Galvanized chains or cables provided to prevent turnbuckle rotation',
      comments: '',
      grade: '',
    },
    11: {
      title: 'Cotter pins in good condition',
      comments: '',
      grade: '',
    },
    12: {
      title: 'Minimum sizes of hardware used',
      comments: '',
      grade: '',
    },
    13: {
      title: 'Lay of guy grips match the lay of the guy cables',
      comments: '',
      grade: '',
    },
    14: {
      title:
        'Colour of preformed grips indicate that they are suitable for the guy cable size',
      comments: '',
      grade: '',
    },
    15: {
      title: 'Guy wires are ground at each anchor',
      comments: '',
      grade: '',
    },
  },
  Anchors: {
    1: {
      title: 'Visible portions of foundations in good condition',
      comments: '',
      grade: '',
    },
    2: {
      title:
        'All steel materials and fittings are hot dip galvanized and rust-free',
      comments: '',
      grade: '',
    },
    3: {
      title: 'Backfill provided such that accumulation of water is prevented',
      comments: '',
      grade: '',
    },
    4: {
      title:
        'Clearing and grubbing has been performed around anchors and along length of guy',
      comments: '',
      grade: '',
    },
    5: {
      title: 'No trees come in contact with guy cables along their length',
      comments: '',
      grade: '',
    },
    6: {
      title: 'Anodes installed',
      comments: '',
      grade: '',
    },
    7: {
      title: 'No burrows or other holes from animals present in the ground',
      comments: '',
      grade: '',
    },
  },
  Structure: {
    1: {
      title: 'Verticality and alignment complies with CSA-S37',
      comments: '',
      grade: '',
    },
    2: {
      title:
        'Structural members are rust-free, not bent, fractured, loose, missing or where on-site modifications may have reduced their structural resistance (e.g. slotting, excessive reaming, bending, intermediate splicing, etc.)',
      comments: '',
      grade: '',
    },
    3: {
      title:
        'Structural connections rust-free, are not missing, fractured, loose or damaged Many Loose Splice Bolts',
      comments: '',
      grade: '',
    },
    4: {
      title: 'HSS members equipped with drain hole',
      comments: '',
      grade: '',
    },
    5: {
      title: 'All bolts used for structural sections are ASTM A325',
      comments: '',
      grade: '',
    },
    6: {
      title:
        'For monopoles where drainage weep holes are not provided at the base, top cap is installed to prevent water from entering the monopol',
      comments: '',
      grade: '',
    },
  },
  Grounding: {
    1: {
      title:
        'Guyed and self-support towers - each leg of tower grounded at base',
      comments: '',
      grade: '',
    },
    2: {
      title: 'Monopoles - tower grounded at (3) locations at base',
      comments: '',
      grade: '',
    },
    3: {
      title: 'No kinks or sharp bends in any grounding leads',
      comments: '',
      grade: '',
    },
    4: {
      title: 'Grounding connection are in good condition',
      comments: '',
      grade: '',
    },
    5: {
      title:
        'All grounding leads are continuous and travel in a downward direction',
      comments: '',
      grade: '',
    },
    6: {
      title: 'Tower equipped with a lightning rod',
      comments: '',
      grade: '',
    },
    7: {
      title: '45 degree cone of protection provided by the lightning rod',
      comments: '',
      grade: '',
    },
  },
  'Climbing Facilities': {
    1: {
      title: 'Continuous ladder provided from base to top of structure',
      comments: '',
      grade: '',
    },
    2: {
      title: 'Ladder rungs are evenly spaced over height of structure',
      comments: '',
      grade: '',
    },
    3: {
      title:
        'Minimum clearance of 180 mm between ladder rung and any obstruction behind ladder',
      comments: '',
      grade: '',
    },
    4: {
      title: 'Minimum climbing circle of 660 mm provided',
      comments: '',
      grade: '',
    },
    5: {
      title: 'Fall arresting system installed and in good condition',
      comments: '',
      grade: '',
    },
    6: {
      title: 'Trolley travels easily along fall arresting system',
      comments: '',
      grade: '',
    },
    7: {
      title:
        'Fall arresting system installed as per manufacturer specifications',
      comments: '',
      grade: '',
    },
    8: {
      title:
        'Tower anti-climb equipped with locking device Fence is Anti-climb',
      comments: '',
      grade: '',
    },
    9: {
      title: 'Warning signs installed at base of tower',
      comments: '',
      grade: '',
    },
    10: {
      title:
        'Warning signs installed on tower above and below any climbing obstruction',
      comments: '',
      grade: '',
    },
  },
  'Painting & Galvanizing': {
    1: {
      title:
        'Structure is painted (if yes, specify estimated paint coverage remaining)',
      comments: '',
      grade: '',
    },
    2: {
      title:
        'There are no significant scratches, flaking, rust, bubbles or other damage to the galvanizing',
      comments: '',
      grade: '',
    },
  },
  'Electrical / Lights': {
    1: {
      title: 'Lighting system in good condition Mid Light is twisted',
      comments: '',
      grade: '',
    },
    2: { title: 'Lighting system in working order', comments: '', grade: '' },
    3: {
      title:
        'Ice guards installed for lights if ice free fall distance exceeds 15m',
      comments: '',
      grade: '',
    },
    4: {
      title: 'Conduit and junction boxes are weathered tight and secured',
      comments: '',
      grade: '',
    },
    5: { title: 'Photocell installed with ice guard', comments: '', grade: '' },
  },
  'Antennas & Mounts': {
    1: {
      title:
        'Antenna mounts installed such that they do not interfere with climbing circle',
      comments: '',
      grade: '',
    },
    2: {
      title: 'Structural members are in good condition',
      comments: '',
      grade: '',
    },
    3: {
      title: 'Structural connections are in good condition',
      comments: '',
      grade: '',
    },
    4: {
      title:
        'Bolts of adequate length are used, with no missing or loose bolts',
      comments: '',
      grade: '',
    },
    5: {
      title: 'Antennas and attachments in good condition',
      comments: '',
      grade: '',
    },
    6: {
      title: 'There are no unused antennas or antenna mounts on the tower',
      comments: '',
      grade: '',
    },
  },
  'Transmission Lines': {
    1: {
      title:
        'Transmission lines installed so that they do not interfere with climbing clearances',
      comments: '',
      grade: '',
    },
    2: {
      title:
        'Transmission lines supported at regular intervals with approved hangers',
      comments: '',
      grade: '',
    },
    3: {
      title: 'There are no unused transmission lines on the tower',
      comments: '',
      grade: '',
    },
    4: {
      title:
        'Transmission lines installed so that they do not interfere with climbing clearances',
      comments: '',
      grade: '',
    },
  },
};
