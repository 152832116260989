import { Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { IPDFTowerDetails } from '../iDocumentEditor';
import PDFShelters from './pdfDetails/PDFShelters';
import PDFFooter from '../headerFooter/PDFFooter';
import PDFHeader from '../headerFooter/PDFHeader';
import PDFDetails from './pdfDetails/PDFDetails';
import { useMemo } from 'react';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
  ],
});

const PDFTowerDetails = ({
  activeTowerDetails,
  contents,
  info,
}: IPDFTowerDetails) => {
  const activeSiteDetails = Object.keys(activeTowerDetails).find(
    (item) => activeTowerDetails[item]
  );

  const styles = useMemo(
    () =>
      StyleSheet.create({
        page: {
          flexDirection: 'row',
          padding: '0.5in',
          flexWrap: 'wrap',
          width: '100%',
          height: '90%',
        },
        container: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: '100%',
          height: '90%',
        },
        section: {
          width: '49%',
          margin: '0.5%',
        },
        header: {
          width: '100%',
          fontWeight: 600,
          textDecoration: 'underline',
          fontSize: '15px',
          textAlign: 'center',
          marginBottom: '10px',
          fontFamily: 'Open Sans',
        },
        title: {
          fontWeight: 'bold',
          textDecoration: 'underline',
          fontSize: '12px',
          marginBottom: '20px',
        },
        details: {
          width: '100%',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '2px',
          fontSize: '10px',
        },
        shelters: {
          width: '100%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
          marginTop: '2px',
          fontSize: '8px',
        },
      }),
    []
  );

  return (
    <Page
      size='A4'
      style={styles.page}>
      {activeSiteDetails && (
        <>
          {contents.header.active && (
            <PDFHeader contents={contents.header.contents} />
          )}
          <View style={styles.container}>
            <Text style={styles.header}>Site Details</Text>
            <>
              {activeTowerDetails['Site Information'] && (
                <PDFDetails
                  content={info['Site Information']}
                  name='Site Information'
                />
              )}
              {activeTowerDetails['Technical Information'] && (
                <PDFDetails
                  content={info['Technical Information']}
                  name='Technical Information'
                />
              )}
              {activeTowerDetails['Inspection Details'] && (
                <PDFDetails
                  content={info['Inspection Details']}
                  name='Inspection Details'
                />
              )}
              {activeTowerDetails['Climbing Facility'] && (
                <PDFDetails
                  content={info['Climbing Facility']}
                  name='Climbing Facility'
                />
              )}
              {activeTowerDetails['Structure Information'] && (
                <PDFDetails
                  content={info['Structure Information']}
                  name='Structure Information'
                />
              )}
              {activeTowerDetails['Tower Lighting'] && (
                <PDFDetails
                  content={info['Tower Lighting']}
                  name='Tower Lighting'
                />
              )}
              {activeTowerDetails['Shelter Details'] && (
                <PDFShelters contents={info['Shelter Details'].shelters} />
              )}
            </>
          </View>
        </>
      )}
      {contents.footer.active && (
        <PDFFooter contents={contents.footer.contents} />
      )}
    </Page>
  );
};

export default PDFTowerDetails;
