import { ISitesContext } from 'state/iContext';
import { SitesContext } from 'state/context';
import { useContext, useMemo } from 'react';
import { IEditInput } from './iEdit';

const EditField = ({
  half,
  prop,
  type,
  title,
  towerId,
  placeholder,
  changeHandler,
}: IEditInput) => {
  const { towerList } = useContext<ISitesContext | undefined>(SitesContext)!;

  // Edits strings for id/key/title
  const editTitle: string = title
    .toLowerCase()
    .replace(/site /gi, '')
    .replace(/\s/, '-');
  const removeIfContact: string = title.replace(/contact /gi, '');
  let style: string = 'flex flex-col px-5';

  // Adds red highlight to empty fields
  const editFieldClass: string = !prop!
    ? 'focus:bg-blue-100 px-2 border border-2 border-red-600'
    : 'focus:bg-blue-100 px-2';

  // Adds half width class
  if (half) {
    style += ' w-1/2';
  }

  const allTowerNames = useMemo(
    () =>
      Object.keys(towerList).map((id: string) =>
        towerList[id].name.toLowerCase()
      ),
    [towerList]
  );

  return (
    <div className={style}>
      {removeIfContact}
      {title === 'Name' &&
        allTowerNames.includes(prop.toString().toLowerCase()) &&
        towerList[towerId].name.toLowerCase() !== prop.toString().toLowerCase() &&
        (
          <div className='text-red-600 text-sm font-bold'>Please choose unique name</div>
        )}
      <input
        required
        type={type}
        value={prop!}
        onChange={changeHandler}
        placeholder={placeholder}
        className={editFieldClass}
        name={`site-${editTitle}`}
        id={`edit-site-${editTitle}`}
      />
    </div>
  );
};

export default EditField;
