import { IEditUserInputProps, IEditUserPlaceholder } from './iEditUser';

const EditUserInput = ({
  name,
  type,
  value,
  classes,
  onChange,
  disabled,
}: IEditUserInputProps) => {
  const id: string = `edit-user-${name.toLowerCase()}`;
  const placeholderEnd: IEditUserPlaceholder = {
    text: 'Name',
    tel: 'Number',
    email: '',
  };
  const placeholder: string = `Enter ${name} ${
    placeholderEnd[type as keyof typeof placeholderEnd]
  }`;

  return (
    <div className={classes.container}>
      <label
        className={classes.label}
        htmlFor={name}>
        {name}
      </label>
      <input
        id={id}
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className={classes.box}
        placeholder={placeholder}
      />
    </div>
  );
};

export default EditUserInput;
