import {
  ILibraryContext,
  IModalContext,
  ICanvasContext,
  IUserContext,
} from 'state/iContext';
import {
  LibraryContext,
  ModalContext,
  CanvasContext,
  UserContext,
} from 'state/context';
import { useContext, ReactElement, useState, useMemo, ReactNode } from 'react';
import { highlightSearch } from 'helpers/helperFunctions';
import { IIconClasses } from './iIcons';
import { ILibrary } from 'state/iState';
import { IHandlers } from 'iApp';

// TODO: Rename to assets///////////---------
const Icons = ({ library }: ILibrary) => {
  const { toggleIcon, activeIcon, searchFiltered } = useContext<
    ILibraryContext | undefined
  >(LibraryContext)!;
  const { setDragProps } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;
  const { searchBar } = useContext<IUserContext | undefined>(UserContext)!;
  const {
    activeModal: { manageLibraries },
  } = useContext<IModalContext | undefined>(ModalContext)!;

  const [hoverData, sethoverData] = useState<string>('');
  const [hover, setHover] = useState<boolean>(false);

  // Component classes
  const classes: IIconClasses = {
    container:
      'flex flex-wrap w-full h-fit bg-slate-100 justify-center items-start',
    image: 'w-full h-[70px] object-scale-down',
    icon: {
      active:
        'relative flex flex-col bg-white min-w-[85px] max-w-[85px] min-h-[100px] max-h-[100px] rounded rounded-sm my-1 mx-2 p-0.5 text-center border border-4 border-red-600 cursor-pointer hover:border-red-700',
      inactive:
        'relative flex flex-col bg-white min-w-[85px] max-w-[85px] min-h-[100px] max-h-[100px] rounded rounded-sm my-1 mx-2 p-0.5 text-center border border-stone-200 cursor-pointer hover:border-red-700 hover:border-2',
    },
    hover:
      'absolute bottom-2 flex w-90 p-1 justify-center z-10 text-xs bg-white',
    displayOnly:
      'relative flex flex-col bg-white min-w-[85px] max-w-[85px] min-h-[100px] max-h-[100px] rounded rounded-sm m-0.5 p-0.5 text-center border border-stone-200',
    label: 'text-xs truncate',
  };

  const { active, inactive } = classes.icon;

  const iconIds: string[] = useMemo(
    () =>
      Object.keys(library).filter((icon) =>
        searchFiltered.includes(library[icon].id)
      ),
    [searchFiltered, library]
  );

  const icons: ReactElement[] | ReactNode = useMemo(
    () =>
      iconIds.length > 0 ? (
        iconIds.map((icon): ReactElement => {
          const iconName: string = library[icon].id;
          const activeClass: string =
            activeIcon === iconName ? active : inactive;
          const currentClass: string = manageLibraries
            ? classes.displayOnly
            : activeClass;

          const handlers: IHandlers = {
            click: (): void => {
              if (!manageLibraries) {
                toggleIcon(iconName);
              }
            },
            mouseOver: (): void => {
              sethoverData(iconName);
              setHover(true);
            },
            mouseOut: (): void => {
              sethoverData('');
              setHover(false);
            },
          };

          return (
            <div
              draggable
              id={iconName}
              key={iconName}
              onClick={handlers.click}
              className={currentClass}
              onMouseOut={handlers.mouseOut}
              onMouseOver={handlers.mouseOver}
              onDragStart={(e) => {
                setDragProps(library[icon]);
              }}>
              <img
                alt='library icon'
                src={library[icon].content}
                className={classes.image}
              />
              <label className={classes.label}>
                {highlightSearch(iconName, searchBar.trim())}
              </label>
              {hover && hoverData === iconName && iconName.length >= 12 && (
                <div className={classes.hover}>{iconName}</div>
              )}
            </div>
          );
        })
      ) : (
        <div className='flex w-full justify-center bg-slate-300 text-stone-500'>
          No Icons Saved
        </div>
      ),
    [
      hover,
      active,
      library,
      inactive,
      iconIds,
      hoverData,
      searchBar,
      activeIcon,
      toggleIcon,
      setDragProps,
      classes.image,
      classes.hover,
      classes.label,
      manageLibraries,
      classes.displayOnly,
    ]
  );

  return <div className={classes.container}>{icons}</div>;
};

export default Icons;
