import { CanvasContext, ModalContext, SettingsContext } from 'state/context';
import GridItemOptions from './highlightOptions/GridItemOptions';
import LeasePanel from './toolbar/leaseSections/LeasePanel';
import Resize from 'components/buttons/resizeButton/Resize';
import SearchBar from 'components/search/SearchBar';
import Libraries from './libraries/Libraries';
import EditPanel from './toolbar/EditPanel';
import AddPanel from './toolbar/AddPanel';
import { IActiveClasses } from 'iApp';
import { useContext } from 'react';
import {
  IModalContext,
  ICanvasContext,
  ISettingsContext,
} from 'state/iContext';

const CanvasPanel = () => {
  const { minimize, setMinimize } = useContext<ISettingsContext | undefined>(
    SettingsContext
  )!;
  const {
    activeToolBar: { image, text, drawings, edit, sections },
    dragProps,
  } = useContext<ICanvasContext | undefined>(CanvasContext)!;
  const { isModalActive } = useContext<IModalContext | undefined>(
    ModalContext
  )!;

  const classes: IActiveClasses = {
    active:
      'absolute bottom-0 relative flex flex-col h-1/3 w-full border border-slate-300 md:h-full md:w-1/3 md:max-w-[400px]',
    inactive:
      'relative flex h-[50px] w-full h-[50px] md:h-full md:w-[50px] border border-t-red-700 border-slate-300 md:border-r-red-700 md:border-t-red-50',
  };

  const activeClass: string = minimize ? classes.inactive : classes.active;
  const isPanelActive: boolean = image || text || drawings || edit || sections;

  return (
    <div
      id='data-panel'
      className={activeClass}
      onDoubleClick={() => setMinimize(false)}

    >
      <Resize />
      {!isPanelActive &&
        (dragProps.type && minimize ? (
          <GridItemOptions />
        ) : (
          <SearchBar disabled={isModalActive} />
        ))}
      {!minimize && !image && !text && !drawings && !edit && !sections && (
        <Libraries />
      )}
      {!minimize && image && <AddPanel type='images' />}
      {!minimize && text && <AddPanel type='textBoxes' />}
      {!minimize && drawings && <AddPanel type='drawings' />}
      {!minimize && sections && <LeasePanel />}
      {!minimize && edit && <EditPanel />}
    </div>
  );
};

export default CanvasPanel;
