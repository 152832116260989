import { IDocGuyInspectionLine } from '../iDocumentEditor';
import { IDocumentContext } from 'state/iContext';
import { DocumentContext } from 'state/context';
import { IStringProps } from 'iApp';
import { useContext } from 'react';
import { useMemo } from 'react';

const DocGuyInspectionLine = ({
  id,
  activeEdit,
  clickHandler,
}: IDocGuyInspectionLine) => {
  const { editFileProps } = useContext<IDocumentContext | undefined>(
    DocumentContext
  )!;

  const classes: IStringProps = useMemo(
    () => ({
      container: 'relative group flex w-full px-6 justify-center items-center',
      title: 'w-[9.09%] p-1 border border-stone-500 text-center text-stone-500',
      activeLeft:
        'w-[9.09%] p-1 border border-l-4 border-stone-500 border-l-red-600 text-center text-stone-500',
      activeRight:
        'w-[9.09%] p-1 border border-r-4 border-stone-500 border-r-red-600 text-center text-stone-500',
      edit: 'fa-regular fa-pen-to-square absolute left-0 hidden group-hover:flex',
      remove:
        'fa-solid fa-circle-minus absolute right-0 hidden group-hover:flex',
    }),
    []
  );

  return (
    <div className={classes.container}>
      <i
        className={classes.edit}
        onClick={clickHandler.edit}
      />
      <div className={activeEdit === id ? classes.activeLeft : classes.title}>
        {editFileProps.contents.guy.content[id].level}
      </div>
      <div className={classes.title}>
        {editFileProps.contents.guy.content[id].elevation}
      </div>
      <div className={classes.title}>
        {editFileProps.contents.guy.content[id].anchor}
      </div>
      <div className={classes.title}>
        {editFileProps.contents.guy.content[id].sizeType}
      </div>
      <div className={classes.title}>
        {editFileProps.contents.guy.content[id].initial}
      </div>
      <div className={classes.title}>
        {editFileProps.contents.guy.content[id].specified}
      </div>
      <div className={classes.title}>
        {editFileProps.contents.guy.content[id].range}
      </div>
      <div className={classes.title}>
        {editFileProps.contents.guy.content[id].measured}
      </div>
      <div className={classes.title}>
        {editFileProps.contents.guy.content[id].measured}
      </div>
      <div className={classes.title}>
        {editFileProps.contents.guy.content[id].measured}
      </div>
      <div className={activeEdit === id ? classes.activeRight : classes.title}>
        {editFileProps.contents.guy.content[id].measured}
      </div>
      <i
        onClick={clickHandler.remove}
        className={classes.remove}
      />
    </div>
  );
};

export default DocGuyInspectionLine;
