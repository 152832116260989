import { IEditPortSelect } from '../iPorts';
import { IPortBlock } from 'state/iState';
import { IInputEvent } from 'iApp';
import { ReactNode } from 'react';

const EditPortSelect = ({ name, defaultValue, optionList, setPortDetails }: IEditPortSelect) => {
  const id: string = `edit-port-${name}`;
  const options: ReactNode[] = optionList.map(
    (option: string, i: number): ReactNode => (
      <option
        key={option}
        value={option}
        disabled={i === 0}>
        {option}
      </option>
    )
  );

  return (
    <select
      id={id}
      key={id}
      name={name}
      defaultValue={defaultValue}
      onChange={(e: IInputEvent) =>
        setPortDetails((prev: IPortBlock) => ({
          ...prev,
          [name]: e.target.value,
        }))
      }
      className='my-1 py-0 w-1/2 md:w-[90%] h-[25px]'>
      {options}
    </select>
  );
};

export default EditPortSelect;
