import {
  ICanvasContext,
  IDocumentContext,
  IHeaderContext,
  ISitesContext,
} from 'state/iContext';
import {
  CanvasContext,
  DocumentContext,
  HeaderContext,
  SitesContext,
} from 'state/context';
import { IStringProps } from 'iApp';
import { useContext } from 'react';
import { IHeaderButtons } from 'state/iState';

const LicensePanel = ({ close, towerID }) => {
  const { towerList, setWarningClicked } = useContext<
    ISitesContext | undefined
  >(SitesContext)!;
  const { setActiveTowerID } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;
  const { setActiveHeaderButton } = useContext<IHeaderContext | undefined>(
    HeaderContext
  )!;
  const { setActiveFile } = useContext<IDocumentContext | undefined>(
    DocumentContext
  )!;

  // Component classes
  const classes: IStringProps = {
    icon: 'absolute z-30 -top-0.5 fa-solid fa-circle-chevron-left text-lg text-gray-400 cursor-pointer hover:text-red-500',
    container:
      'w-full h-full bg-zinc-100 p-4 rounded-sm mt-3 border border-slate-200',
    label: 'font-normal',
    name: 'w-3/5 mt-3 font-medium text-start hover:text-red-600',
    warning: 'text-orange-600',
    expired: 'text-red-700',
  };

  const files = towerList[towerID].documents.files;

  const currentDate = Date.now();

  const fileList = Object.keys(files)
    .filter((file) =>
      towerList[towerID].dates.licenses.some((id: string) => +id === +file)
    )
    .map((id) => {
      const expiryDate = new Date(files[id].expiry).getTime();
      const timeRemaining = expiryDate - currentDate;
      const isExpired = expiryDate < currentDate;
      const isSoonExpired = timeRemaining <= 90 * 24 * 60 * 60 * 1000;

      return (
        <div
          className='flex w-full justify-between'
          key={id}>
          <button
            className={classes.name}
            onClick={() => {
              setActiveTowerID(towerID);
              setActiveHeaderButton((prev: IHeaderButtons) => ({
                ...prev,
                files: true,
              }));
              setActiveFile(id);
            }}>
            {files[id].name}
          </button>
          <div
            className={`w-2/5 mt-3 font-medium ${
              isExpired ? classes.expired : isSoonExpired ? classes.warning : ''
            }`}>
            {isExpired && (
              <div>
                <i className='fa-solid fa-triangle-exclamation text-red-700 mr-1' />
                <span>Expired</span>
              </div>
            )}
            {isSoonExpired && !isExpired && (
              <i className='fa-solid fa-circle-exclamation text-orange-6 00' />
            )}
            {new Date(files[id].expiry).toUTCString().slice(4, 16)}
          </div>
        </div>
      );
    });

  return (
    <div
      id='info-window-license-panel'
      className={classes.container}>
      <i
        className={classes.icon}
        onClick={close}
      />
      <p className={classes.label}>Active Licenses:</p>
      {fileList}
    </div>
  );
};

export default LicensePanel;
