import PDFVerticalityTwistPlumbLine from './PDFVerticalityTwistPlumbLine';
import { Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import PDFVerticalityMastLine from './PDFVerticalityMastLines';
import { IPDFContents } from '../iDocumentEditor';
import PDFFooter from '../headerFooter/PDFFooter';
import PDFHeader from '../headerFooter/PDFHeader';
import { ReactNode } from 'react';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
  ],
});

const PDFVerticality = ({ contents }: IPDFContents) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      padding: '0.5in',
      flexWrap: 'wrap',
      width: '100%',
      height: '90%',
    },
    container: {
      flexDirection: 'column',
      width: '100%',
      height: '95%',
      alignContent: 'center',
      alignItems: 'center',
      marginTop: '20px',
    },
    title: {
      width: '100%',
      fontWeight: 600,
      textDecoration: 'underline',
      fontSize: '15px',
      textAlign: 'center',
      marginBottom: '10px',
      fontFamily: 'Open Sans',
    },
    tables: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '100%',
      minHeight: '50%',
    },
    column: {
      flexDirection: 'column',
      border: 'solid',
      borderWidth: '1px',
      width: '100%',
    },
    row: {
      flexDirection: 'row',
      border: 'solid',
      borderWidth: '1px',
      width: '100%',
    },
    head: {
      display: 'flex',
      flexDirection: 'column',
      border: 'solid',
      borderWidth: '1px',
      width: '100%',
      textAlign: 'center',
      fontSize: '9px',
      padding: 4,
    },
    thirty: {
      display: 'flex',
      flexDirection: 'column',
      border: 'solid',
      borderWidth: '1px',
      width: '33.33%',
      textAlign: 'center',
      fontSize: '9px',
      padding: 4,
    },
    twenty: {
      display: 'flex',
      flexDirection: 'column',
      border: 'solid',
      borderWidth: '1px',
      width: '20%',
      textAlign: 'center',
      fontSize: '9px',
      padding: 4,
    },
  });

  const contentList: string[] = Object.keys(contents.verticality.content);

  const mastLines: ReactNode[] = contentList.map((line) => (
    <PDFVerticalityMastLine
      {...contents.verticality.content[line].mast}
      key={`mast-${line}`}
    />
  ));
  const twistLines: ReactNode[] = contentList.map((line) => (
    <PDFVerticalityTwistPlumbLine
      {...contents.verticality.content[line].twist}
      key={`twist-${line}`}
    />
  ));
  const plumbLines: ReactNode[] = contentList.map((line) => (
    <PDFVerticalityTwistPlumbLine
      {...contents.verticality.content[line].plumb}
      key={`plumb-${line}`}
    />
  ));

  return (
    <Page
      size='A4'
      style={styles.page}>
      <View style={styles.container}>
        {contents.header.active && (
          <PDFHeader contents={contents.header.contents} />
        )}
        <Text style={styles.title}>VERTICALITY</Text>
        <View style={styles.tables}>
          <View style={styles.column}>
            <View style={styles.column}>
              <Text style={styles.head}>Observed Mast Data</Text>
              <View style={styles.row}>
                <Text style={styles.thirty}>Mast Elev (m)</Text>
                <Text style={styles.thirty}>Face Width (mm)</Text>
                <Text style={styles.thirty}>Leg Size (mm)</Text>
              </View>
            </View>
            {mastLines}
          </View>
          <View style={styles.column}>
            <Text style={styles.head}>
              Tower Twist Measurements (in Degrees)
            </Text>
            <View style={styles.row}>
              <Text style={styles.twenty}>Total Measured Twist</Text>
              <Text style={styles.twenty}>Total Allowable Twist</Text>
              <Text style={styles.twenty}>
                Relative Twist Between Elevations
              </Text>
              <Text style={styles.twenty}>
                Allowable Twist Between Elevations
              </Text>
              <Text style={styles.twenty}>Within S37 Limits?</Text>
            </View>
            {twistLines}
          </View>
          <View style={styles.column}>
            <Text style={styles.head}>Calculated Tower Plumb (in mm)</Text>
            <View style={styles.row}>
              <Text style={styles.twenty}>Total Measured Deflection</Text>
              <Text style={styles.twenty}>Total Allowable Deflection</Text>
              <Text style={styles.twenty}>
                Relative Deflection Between Elevations
              </Text>
              <Text style={styles.twenty}>
                Allowable Deflection Between Elevations
              </Text>
              <Text style={styles.twenty}>Within S37 Limits?</Text>
            </View>
            {plumbLines}
          </View>
        </View>
      </View>
      {contents.footer.active && (
        <PDFFooter contents={contents.footer.contents} />
      )}
    </Page>
  );
};

export default PDFVerticality;
