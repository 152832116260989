import { ICanvasContext, ICompanyContext, ISitesContext } from 'state/iContext';
import { CanvasContext, CompanyContext, SitesContext } from 'state/context';
import { IGridLayoutProps, ILibraryIcon, ITowerList } from 'state/iState';
import { nextAvailableKey } from 'helpers/helperFunctions';
import { ReactNode, useContext, useMemo } from 'react';
import GridLayout, { Layout } from 'react-grid-layout';
import PropertyLabel from './PropertyLabel';
import { IStringProps } from 'iApp';

const LeaseSections = ({ id, sectionLayout, type }) => {
  const {
    scale,
    setDragProps,
    activeTowerID,
    activeToolBar: { Properties },
  } = useContext<ICanvasContext | undefined>(CanvasContext)!;
  const { icons } = useContext<ICompanyContext | undefined>(CompanyContext)!;
  const { setTowerList, towerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;

  const gridItem: IStringProps = {
    tower: 'legs',
    data: 'shelves',
  };

  const classes: IStringProps = {
    container: 'absolute group z-10 h-full w-1/2 top-0 -left-11',
    button:
      'hidden absolute fa-solid fa-square-plus -top-5 w-full justify-center px-1 text-sm text-slate-400 hover:text-red-600 group-hover:flex',
    grid: 'group w-full',
    section:
      'relative flex flex-nowrap z-10 w-full justify-center cursor-row-resize',
  };

  const colors: string[] = [
    '#ff0000', // red
    '#0000ff', // blue
    '#00ff00', // green
    '#ee82ee', // violet
    '#008000', // green
    '#ffa500', // orange
    '#008080', // teal
    '#7b7b7b', // zinc
    '#ffbf00', // amber
    '#00ff00', // lime
    '#000000', // black
  ];

  const sections: IGridLayoutProps =
    towerList[activeTowerID].layout[type][gridItem[type]][id].sections;

  const height: number =
    type === 'tower'
      ? towerList[activeTowerID].height
      : towerList[activeTowerID].layout.data.shelves[sectionLayout[0].i]
          .shelfGrid.h;

  const nextKey: number = useMemo(
    () => nextAvailableKey(sections, 0)!,
    [sections]
  );

  const layoutOnLoad = useMemo(
    () => [
      {
        ...sectionLayout[0],
        name: `${sectionLayout[0].name} sections`,
        w: 4,
      },
    ],
    [sectionLayout]
  );

  // Checks if the length of the grid item is fully covered by lease sections
  const gridCoverage: number = useMemo(
    () =>
      sections
        ? Object.keys(sections)
            .map((section) => sections[section].h)
            .reduce((a, b) => +a + +b)
        : 0,
    [sections]
  );

  const openAssetOptions = (id: string): void =>
    setDragProps((prev: ILibraryIcon) => ({
      ...prev,
      id,
      dragging: true,
      target: sectionLayout[0].i.toString(),
      type: 'sections',
    }));

  const updateSections = (layout: Layout[]): IGridLayoutProps => {
    const updatedSections = layout.map((section, i) => {
      const resizeInBounds =
        +height - +section.y < +section.h ? +height - +section.y : +section.h;

      return {
        ...section,
        i,
        minW: 4,
        x: section.x,
        name: section.i,
        h: resizeInBounds,
        img: icons[section.i],
        isDraggable: true,
        isResizable: true,
      };
    });

    return updatedSections;
  };

  const handler = {
    onLayoutChange: (layout: Layout[]) => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [type]: {
              ...prev[activeTowerID].layout.tower,
              [gridItem[type]]: {
                ...prev[activeTowerID].layout[type][gridItem[type]],
                [sectionLayout[0].i]: {
                  ...prev[activeTowerID].layout[type][gridItem[type]][
                    sectionLayout[0].i
                  ],
                  sections: updateSections(layout),
                },
              },
            },
          },
        },
      }));
    },
    onDragStop: () => {
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
      }));
    },
    onMouseDown: () =>
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: true,
      })),
    onMouseUp: () =>
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
      })),
    addSection: () =>
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [type]: {
              ...prev[activeTowerID].layout.tower,
              [gridItem[type]]: {
                ...prev[activeTowerID].layout[type][gridItem[type]],
                [id]: {
                  ...prev[activeTowerID].layout[type][gridItem[type]][id],
                  sections: {
                    ...prev[activeTowerID].layout[type][gridItem[type]][id]
                      .sections,
                    [nextKey]: {
                      name: `New Lease ${nextKey + 1}`,
                      i: nextKey,
                      x: 50,
                      y: 0,
                      h: gridCoverage <= 40 ? 10 : 50 - gridCoverage,
                      w: 4,
                      minW: 4,
                      maxW: 4,
                    },
                  },
                },
              },
            },
          },
        },
      })),
  };

  const sectionsToDisplay: ReactNode[] | null = sections
    ? Object.keys(sections).map((section: string): ReactNode => {
        const borderColor = colors[+section % colors.length];

        const sectionStyle = {
          borderColor,
          borderWidth: '1px',
          borderRightWidth: 0,
          borderStyle: 'solid',
        };

        return (
          <div
            style={sectionStyle}
            className={classes.section}
            key={sections[section].name}
            data-grid={sections[section]}
            onMouseUp={handler.onMouseUp}
            onMouseDown={handler.onMouseDown}
            onClick={() => openAssetOptions(section)}
            onTouchStart={() => openAssetOptions(section)}>
            {Properties && (
              <PropertyLabel
                label={sections[section].name}
                type='section'
              />
            )}
          </div>
        );
      })
    : null;

  return (
    <div className={classes.container}>
      {gridCoverage < 50 && (
        <button
          className={classes.button}
          onClick={handler.addSection}
        />
      )}
      <GridLayout
        cols={4}
        width={42}
        margin={[0, 0]}
        isBounded={true}
        rowHeight={7.55}
        compactType={null}
        isDraggable={true}
        isDroppable={false}
        allowOverlap={false}
        layout={layoutOnLoad}
        transformScale={scale}
        className={classes.grid}
        onDrag={handler.onLayoutChange}
        onDragStop={handler.onDragStop}
        onLayoutChange={handler.onLayoutChange}
        key={sectionLayout.i + '-grid-section-layout'}>
        {sectionsToDisplay}
      </GridLayout>
    </div>
  );
};

export default LeaseSections;
