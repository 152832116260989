import { ILibraryContext, IModalContext, IUserContext } from 'state/iContext';
import { LibraryContext, ModalContext, UserContext } from 'state/context';
import Close from 'components/buttons/Close';
import { IStringProps } from 'iApp';
import { useContext } from 'react';

const ModalHeader = ({ name }: IStringProps) => {
  const { setActiveIcon } = useContext<ILibraryContext | undefined>(
    LibraryContext
  )!;
  const { setSearchBar } = useContext<IUserContext | undefined>(UserContext)!;
  const {
    clearModal,
    setEditIcon,
    clearEditIcon,
    setActiveModal,
    setCustomIconStage,
  } = useContext<IModalContext | undefined>(ModalContext)!;

  const classes: IStringProps = {
    header: 'w-full',
    title: 'flex flex-wrap text-lg font-semibold text-stone-500',
  };

  return (
    <div className={classes.header}>
      <h1 className={classes.title}>{name}</h1>
      <Close
        handler={() => {
          setSearchBar('');
          setActiveIcon('');
          setCustomIconStage(1);
          setActiveModal(clearModal);
          setEditIcon(clearEditIcon);
        }}
      />
    </div>
  );
};

export default ModalHeader;
