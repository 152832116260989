import Measurement from './Measurement';

const SystemSettings = () => {
  return (
    <div className='flex w-full justify-around'>
      <Measurement />
    </div>
  );
};

export default SystemSettings;