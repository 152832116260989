import { IHeaderContext, IUserContext } from 'state/iContext';
import { HeaderContext, UserContext } from 'state/context';
import { highlightSearch } from 'helpers/helperFunctions';
import { IStringProps } from 'iApp';
import { useContext } from 'react';

const PropertyLabel = ({ label, type }: IStringProps) => {
  const { searchBar } = useContext<IUserContext | undefined>(UserContext)!;
  const { activeCanvasTab } = useContext<IHeaderContext | undefined>(
    HeaderContext
  )!;

  const assetLabel: string =
    activeCanvasTab === 'tower' || 'data'
      ? ' -right-[80px] top-0'
      : ' text-center';

  const classes: IStringProps = {
    icon:
      'absolute w-full max-w-content text-xs flex-nowrap whitespace-nowrap hover:font-bold' +
      assetLabel,
    import:
      'absolute w-full max-w-content -bottom-4 text-center text-xs flex-nowrap whitespace-nowrap hover:font-bold',
    box: `absolute w-full max-w-content text-center -top-10 self-center flex-nowrap whitespace-nowrap font-bold text-stone-400`,
    section:
      'absolute -left-7 top-1/2 -rotate-90 w-full max-w-content text-center text-stone-500 text-[8px] flex-nowrap whitespace-nowrap hover:font-bold',
  };

  return (
    <div className={classes[type]}>
      {highlightSearch(label, searchBar.trim())}
    </div>
  );
};

export default PropertyLabel;
