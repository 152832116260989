import { IAddItemProps, ITower } from 'state/iState';
import { IInputEvent, IStringProps } from 'iApp';
import { useContext } from 'react';
import {
  SitesContext,
  CanvasContext,
  HeaderContext,
  SettingsContext,
} from 'state/context';
import {
  ISitesContext,
  ICanvasContext,
  IHeaderContext,
  ISettingsContext,
} from 'state/iContext';

const EditSection = () => {
  const { setTowerList } = useContext<ISitesContext | undefined>(SitesContext)!;
  const { scale } = useContext<ISettingsContext | undefined>(SettingsContext)!;
  const { activeTowerID, addItemProps, setAddItemProps, clearAddProps } =
    useContext<ICanvasContext | undefined>(CanvasContext)!;
  const { activeCanvasTab } = useContext<IHeaderContext | undefined>(
    HeaderContext
  )!;

  const gridItem: { tower: string[]; data: string[] } = {
    tower: ['legs', 'legGrid', scale],
    data: ['shelves', 'shelfGrid', 'U'],
  };

  const classes: IStringProps = {
    container: 'flex w-full justify-between',
    input: 'p-0',
    edit: 'flex fa-solid fa-check text-stone-400 p-1 justify-center hover:text-green-600',
    cancel:
      'flex fa-solid fa-xmark text-stone-400 p-1 justify-center hover:text-red-600',
  };

  return (
    <div className={classes.container}>
      <input
        type='text'
        className={classes.input}
        value={addItemProps.label}
        onChange={(e: IInputEvent) =>
          setAddItemProps((prev: IAddItemProps) => ({
            ...prev,
            label: e.target.value,
          }))
        }
      />
      <i
        className={classes.edit}
        onClick={() => {
          setTowerList((prev: ITower) => ({
            ...prev,
            [activeTowerID]: {
              ...prev[activeTowerID],
              layout: {
                ...prev[activeTowerID].layout,
                [activeCanvasTab]: {
                  ...prev[activeTowerID].layout[activeCanvasTab],
                  [gridItem[activeCanvasTab][0]]: {
                    ...prev[activeTowerID].layout[activeCanvasTab][
                      gridItem[activeCanvasTab][0]
                    ],
                    [+addItemProps.index!]: {
                      ...prev[activeTowerID].layout[activeCanvasTab][
                        gridItem[activeCanvasTab][0]
                      ][addItemProps.index],
                      sections: {
                        ...prev[activeTowerID].layout[activeCanvasTab][
                          gridItem[activeCanvasTab][0]
                        ][addItemProps.index].sections,
                        [addItemProps.content]: {
                          ...prev[activeTowerID].layout[activeCanvasTab][
                            gridItem[activeCanvasTab][0]
                          ][addItemProps.index].sections[addItemProps.content],
                          name: addItemProps.label,
                        },
                      },
                    },
                  },
                },
              },
            },
          }));
          setAddItemProps(clearAddProps);
        }}
      />
      <i
        className={classes.cancel}
        onClick={() => setAddItemProps(clearAddProps)}
      />
    </div>
  );
};

export default EditSection;
