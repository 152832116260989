import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useMemo } from 'react';

const PDFDeficiencyLine = ({ info }) => {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'row',
          border: 'solid',
          borderWidth: '1px',
          width: '100%',
        },
        small: {
          border: 'solid',
          borderWidth: '1px',
          width: '10%',
          textAlign: 'center',
          fontSize: '10px',
          padding: 4,
          height: '100%',
        },
        deficiency: {
          border: 'solid',
          borderWidth: '1px',
          width: '30%',
          fontSize: '10px',
          padding: 4,
          alignItems: 'center',
          height: '100%',
        },
        recommendation: {
          border: 'solid',
          borderWidth: '1px',
          width: '40%',
          fontSize: '10px',
          padding: 4,
          alignItems: 'center',
          height: '100%',
        },
      }),
    []
  );

  return (
    <View style={styles.container}>
      <Text style={styles.small}>{info.check}</Text>
      <Text style={styles.deficiency}>{info.deficiency}</Text>
      <Text style={styles.recommendation}>{info.recommendation}</Text>
      <Text style={styles.small}>{info.contrInit}</Text>
      <Text style={styles.small}>{info.page}</Text>
    </View>
  );
};

export default PDFDeficiencyLine;
