import { ILibraryContext, IModalContext, ICanvasContext } from 'state/iContext';
import { LibraryContext, ModalContext, CanvasContext } from 'state/context';
import GridItemOptions from '../highlightOptions/GridItemOptions';
import AddButton from 'components/buttons/addButton/AddButton';
import IconOptions from '../highlightOptions/IconOptions';
import { useContext, ReactNode, useMemo } from 'react';
import LibraryTab from './LibraryTab';
import { IStringProps } from 'iApp';

const Libraries = () => {
  const { activeIcon, libraryList } = useContext<ILibraryContext | undefined>(
    LibraryContext
  )!;
  const { dragProps, setDragProps, clearDragProps } = useContext<
    ICanvasContext | undefined
  >(CanvasContext)!;
  const { setActiveModal, clearModal, isModalActive } = useContext<
    IModalContext | undefined
  >(ModalContext)!;

  const classes: IStringProps = {
    container:
      'relative flex flex-col border border-stone-300 h-full w-full font-medium justify-between',
    libraries: 'h-full overflow-y-auto cursor-pointer',
    header: 'flex justify-between p-0.5 bg-slate-200 border-b border-stone-500',
    title: 'items-center text-sm ml-1',
    icon: 'fa-solid fa-list-ul m-0.5 p-1',
    manageLibrary:
      'fa-solid fa-list-check p-1 mr-3 text-stone-400 border border-stone-400 rounded-md bg-stone-100 cursor-pointer hover:text-red-600 transition ease-in-out delay-100 hover:bg-slate-200',
    list: 'h-fit',
    buttons: 'sticky z-20 -bottom-12 w-full',
  };

  const libraries: ReactNode[] = useMemo(
    () =>
      libraryList.map(
        (lib): ReactNode => (
          <LibraryTab
            key={lib}
            name={lib}
          />
        )
      ),
    [libraryList]
  );

  return (
    <div
      id='data-library-panel'
      className={classes.container}>
      <div
        className={classes.libraries}
        onClick={() => setDragProps(clearDragProps)}
        onTouchStart={() => setDragProps(clearDragProps)}>
        <div className={classes.header}>
          <div className={classes.title}>
            <i className={classes.icon} />
            Libraries
          </div>
          <i
            onClick={() =>
              setActiveModal({
                ...clearModal,
                manageLibraries: true,
              })
            }
            className={classes.manageLibrary}
          />
        </div>
        <div className={classes.list}>{libraries}</div>
      </div>
      {!isModalActive && (
        <div className={classes.buttons}>
          {dragProps.type ? (
            <GridItemOptions />
          ) : !activeIcon ? (
            <AddButton name='ICON' />
          ) : (
            <IconOptions />
          )}
        </div>
      )}
    </div>
  );
};

export default Libraries;
