import { useContext, useEffect, useState, ReactNode } from 'react';
import { ICanvasContext, ISitesContext } from 'state/iContext';
import { CanvasContext, SitesContext } from 'state/context';
import { nextAvailableKey } from 'helpers/helperFunctions';
import { IInputEvent, IStringProps } from 'iApp';
import { ITowerList } from 'state/iState';
import DatePicker from 'react-datepicker';

const AddLicense = ({ folderID, close }) => {
  const { activeTowerID } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;
  const { towerList, setTowerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;

  const [replace, setReplace] = useState<string>('');

  const [fileInfo, setFileInfo] = useState<{
    name: '';
    file: File | null;
    date: number | null;
    active: boolean;
  }>({
    name: '',
    file: null,
    date: null,
    active: true,
  });

  const files = towerList[activeTowerID].documents.files;
  const nextKey: number = nextAvailableKey(files, 0)!;

  const optionIds: string[] = Object.keys(files).filter((id) =>
    towerList[activeTowerID].dates.licenses.some(
      (file: string) => +file === +id
    )
  );

  const options: ReactNode[] = optionIds.map(
    (id): ReactNode => (
      <option
        key={`fileID-${id}`}
        value={id}>
        {files[id].name}
      </option>
    )
  );

  const classes: IStringProps = {
    container:
      'flex justify-center items-center w-full border border-stone-400 even:bg-slate-100',
    nameLabel: 'font-bold text-stone-500',
    nameInput: 'mx-4 p-0',
    date: 'mr-4',
    picker: 'p-0 text-center',
    checkbox: 'ml-2 mr-4',
    select: 'mx-1 px-1 py-0 text-sm',
    button:
      'p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
  };

  useEffect(() => setReplace(''), []);

  return (
    <div className={classes.container}>
      <label
        htmlFor='file-name'
        className={classes.nameLabel}>
        File Name
      </label>
      <input
        type='text'
        name='file-name'
        value={fileInfo.name}
        className={classes.nameInput}
        onChange={(e: IInputEvent) =>
          setFileInfo((prev) => ({
            ...prev,
            name: e.target.value,
          }))
        }
      />
      <div className={classes.date}>
        <DatePicker
          isClearable={true}
          allowSameDay={true}
          closeOnScroll={true}
          name='search-file-date'
          className={classes.picker}
          placeholderText='File Expiry'
          selected={fileInfo.date ? new Date(fileInfo.date) : null}
          onChange={(updatedDate) => {
            // To convert the updatedDate to a UTC timestamp
            const utcTimestamp = updatedDate
              ? new Date(updatedDate).getTime() -
                new Date(updatedDate).getTimezoneOffset() * 60000
              : null;
            setFileInfo((prev) => ({
              ...prev,
              date: utcTimestamp,
            }));
          }}
        />
      </div>
      <label htmlFor='active-file'>Active License</label>
      <input
        type='checkbox'
        name='active-file'
        id='active-file-toggle'
        checked={fileInfo.active}
        className={classes.checkbox}
        onChange={() =>
          setFileInfo((prev) => ({
            ...prev,
            active: !prev.active,
          }))
        }
      />
      <label htmlFor='active-file'>Replace File</label>
      <input
        type='checkbox'
        name='replace-file'
        id='repalce-file-toggle'
        className={classes.checkbox}
        checked={replace ? true : false}
        onChange={() => setReplace(replace ? '' : optionIds[0])}
      />
      {replace && (
        <select
          name='replacing-license'
          id='replacing-license-select'
          className={classes.select}
          onChange={(e) => setReplace(e.target.value)}>
          {options}
        </select>
      )}
      <input
        type='file'
        id='file-upload'
        name='file-upload'
        onChange={(e: IInputEvent) => {
          const file: File = e.target.files![0];
          setFileInfo((prev) => ({
            ...prev,
            file,
          }));
        }}
      />
      <button
        className={classes.button}
        onClick={() => {
          setTowerList((prev: ITowerList) => {
            const updateActiveFiles = replace
              ? [
                  ...prev[activeTowerID].dates.licenses.filter(
                    (id: number) => +id !== +replace
                  ),
                  nextKey,
                ]
              : [...prev[activeTowerID].dates.licenses, nextKey];

            return {
              ...prev,
              [activeTowerID]: {
                ...prev[activeTowerID],
                dates: {
                  ...prev[activeTowerID].dates,
                  licenses: updateActiveFiles,
                },
                documents: {
                  ...prev[activeTowerID].documents,
                  folders: {
                    ...prev[activeTowerID].documents.folders,
                    [folderID]: {
                      ...prev[activeTowerID].documents.folders[folderID],
                      files: [
                        ...prev[activeTowerID].documents.folders[folderID]
                          .files,
                        `${nextKey}`,
                      ],
                    },
                  },
                  files: {
                    ...prev[activeTowerID].documents.files,
                    [nextKey]: {
                      name: fileInfo.name.replace(/.pdf/i, '').trim(),
                      file: fileInfo.file,
                      upload: Date.now(),
                      expiry: fileInfo.date,
                      isPDF: true,
                      folder: +folderID,
                    },
                  },
                },
              },
            };
          });
          close();
        }}>
        Save
      </button>
      <button
        className={classes.button}
        onClick={close}>
        Cancel
      </button>
    </div>
  );
};

export default AddLicense;
