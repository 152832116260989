import { IDocumentAsset } from 'state/iState';
import { IStringProps } from 'iApp';
import { useMemo } from 'react';

const DocInventoryLine = ({
  asset: { elevation, name, location },
}: {
  asset: IDocumentAsset;
  }) => {
  
  const classes: IStringProps = useMemo(
    () => ({
      container: 'flex w-full justify-center',
      ten: 'flex w-[10%] border border-stone-500 text-center justify-center items-center',
      thirty:
        'flex w-[30%] border border-stone-500 text-center justify-center items-center',
    }),
    []
  );

  return (
    <div className={classes.container}>
      <div className={classes.ten}>
        <span>{elevation}</span>
      </div>
      <div className={classes.thirty}>
        <span>{name}</span>
      </div>
      <div className={classes.ten}>
        <span>{location}</span>
      </div>
      <div className={classes.ten}>{/* <span>{properties}</span> */}</div>
    </div>
  );
};
export default DocInventoryLine;
