import { libraryData, standardLibraries } from './developmentData';
import { useState, useContext, useEffect, useMemo } from 'react';
import {
  activeDataToolBar,
  clearActiveTowerDetails,
  clearAddProps,
  clearDocumentTowerInfo,
  clearDragProps,
  clearEditFileProps,
  clearEditIcon,
  clearEditLibrary,
  clearInspectionChecklist,
  clearModal,
  emptyPortBlock,
} from './state';
import { searchMatch } from 'helpers/helperFunctions';
import {
  ILibrary,
  IActiveModal,
  IAppChildren,
  IEditLibrary,
  ILibraryIcon,
  ILibraryList,
  IActiveToolBar,
  IPortBlock,
  IAddItemProps,
  IGridLayout,
  IActiveSort,
  IEditFileProps,
  IDocumentTowerInfo,
  IInspectionChecklist,
  IUpdateFolders,
} from './iState';
import {
  UserContext,
  CanvasContext,
  ModalContext,
  CompanyContext,
  LibraryContext,
  DocumentContext,
} from './context';
import {
  IUserContext,
  ICompanyContext,
  IApplicationProviderProps,
} from './iContext';
import { IBooleanProps } from 'iApp';

const ApplicationStateProvider = ({ children }: IAppChildren) => {
  const {
    user: { library },
    lowCaseSearch,
  } = useContext<IUserContext | undefined>(UserContext)!;
  const { companyLibraries } = useContext<ICompanyContext | undefined>(
    CompanyContext
  )!;

  // Canvas States
  const [scale, setScale] = useState<number>(1);
  const [activeTowerID, setActiveTowerID] = useState<number>(1);
  const [activeToolBar, setActiveToolBar] =
    useState<IActiveToolBar>(activeDataToolBar);
  const [portDetails, setPortDetails] = useState<IPortBlock>(emptyPortBlock);
  const [dragProps, setDragProps] = useState<ILibraryIcon>(clearDragProps);
  const [addItemProps, setAddItemProps] =
    useState<IAddItemProps>(clearAddProps);

  // Library States
  const [activeIcon, setActiveIcon] = useState<string>('');
  const [activeLibrary, setActiveLibrary] = useState<string>('');
  const [libraries, setLibraries] = useState<ILibrary>(libraryData);
  const [libraryList, setLibraryList] = useState<string[]>([]);
  const [activeStandardLibraries, setActiveStandardLibraries] =
    useState<ILibraryList>(standardLibraries);
  const [activePrivateLibraries, setActivePrivateLibraries] =
    useState<ILibraryList>({
      ...library,
      ...companyLibraries,
    });
  const [editLibrary, setEditLibrary] =
    useState<IEditLibrary>(clearEditLibrary);

  const libraryNames: string[] = useMemo(
    () => Object.keys(libraries),
    [libraries]
  );

  // Modal States
  const [activeModal, setActiveModal] = useState<IActiveModal>(clearModal);
  const [customIconStage, setCustomIconStage] = useState<number>(1);
  const [editIcon, setEditIcon] = useState<ILibraryIcon>(clearEditIcon);

  // Documents State
  const [activeDrawing, setActiveDrawing] = useState<string>('add');
  const [activeFile, setActiveFile] = useState<number | null>(null);
  const [editFileProps, setEditFileProps] =
    useState<IEditFileProps>(clearEditFileProps);
  const [activeSort, setActiveSort] = useState<IActiveSort>({
    target: 'upload',
    decend: true,
  });
  const [activeFilePanel, setActiveFilePanel] = useState<string>('tower');
  const [documentTowerInfo, setDocumentTowerInfo] =
    useState<IDocumentTowerInfo>(clearDocumentTowerInfo);
  const [activeTowerDetails, setActiveTowerDetails] = useState<IBooleanProps>(
    clearActiveTowerDetails
  );
  const [checklistData, setChecklistData] = useState<IInspectionChecklist>(
    clearInspectionChecklist
  );
  const [updateFolders, setUpdateFolders] = useState<IUpdateFolders>({
    active: false,
    folder: '',
    parent: '',
    name: '',
  });

  const clearPanel = () => {
    setActiveToolBar((prev: IActiveToolBar) => ({
      ...prev,
      select: true,
      image: false,
      text: false,
      drawings: false,
      edit: false,
      sections: false,
    }));
    setAddItemProps(clearAddProps);
  };

  const getAllIconNames = (): string[] => {
    let icons: string[] = [];
    for (let lib = 0; lib < libraryNames.length; lib++) {
      const iconNames: string[] = Object.keys(libraries[libraryNames[lib]]);
      if (iconNames.length > 0) {
        for (let icon = 0; icon < iconNames.length; icon++) {
          if (
            libraries[libraryNames[lib]] &&
            !icons.includes(libraries[libraryNames[lib]][iconNames[icon]].id)
          ) {
            icons = [
              ...icons,
              libraries[libraryNames[lib]][iconNames[icon]].id,
            ];
          }
        }
      }
    }
    return icons;
  };

  const iconIndex: number = activeLibrary
    ? Number(
        Object.keys(libraries[activeLibrary]).filter(
          (num) => libraries[activeLibrary][num].id === activeIcon
        )[0]
      )
    : 0;

  const iconIds: string[] = getAllIconNames();

  const {
    customIcon,
    createDocument,
    manageLibraries,
    downloadDocument,
    permissionSettings,
  } = activeModal;
  const isModalActive: boolean =
    customIcon ||
    createDocument ||
    manageLibraries ||
    downloadDocument ||
    permissionSettings;

  const searchFiltered: string[] = useMemo(
    () =>
      iconIds
        .filter((name): boolean =>
          searchMatch({ searchInList: [name], lowCaseSearch })
        )
        .map((icon) => icon),
    [lowCaseSearch, iconIds]
  );

  // Toggles what library will be displayed
  const toggleLibrary = (name: string): void => {
    const libraryChange: string = activeLibrary === name ? '' : name;
    setActiveLibrary(libraryChange);
  };

  // Toggles what icon will be focused
  const toggleIcon = (name: string): void => {
    const iconChange: string = activeIcon === name ? '' : name;
    setActiveIcon(iconChange);
  };

  const openGridItemOptions = (layout: IGridLayout, type: string) =>
    setDragProps((prev: ILibraryIcon) => ({
      ...prev,
      dragging: true,
      id: layout.i.toString(),
      content: layout.name!.toString(),
      type,
      target: '',
    }));

  useEffect(() => {
    setActivePrivateLibraries({
      ...library,
      ...companyLibraries,
    });
  }, [library, libraries, setLibraries, companyLibraries]);

  // Controls what libraries will be displayed
  useEffect(() => {
    const privateLibraries: string[] = Object.keys(
      activePrivateLibraries
    ).filter(
      (lib) =>
        activePrivateLibraries[lib as keyof typeof activePrivateLibraries]
    );
    const standardLibraries: string[] = Object.keys(
      activeStandardLibraries
    ).filter(
      (lib) =>
        activeStandardLibraries[lib as keyof typeof activeStandardLibraries]
    );
    const updatedList: string[] = [...privateLibraries, ...standardLibraries];
    setLibraryList(updatedList);
  }, [setLibraries, activePrivateLibraries, activeStandardLibraries]);

  // Props to pass to their respective elements
  const providerProps: IApplicationProviderProps = {
    data: {
      scale,
      setScale,
      activeTowerID,
      setActiveTowerID,
      activeToolBar,
      setActiveToolBar,
      portDetails,
      setPortDetails,
      emptyPortBlock,
      clearDragProps,
      dragProps,
      setDragProps,
      clearAddProps,
      addItemProps,
      setAddItemProps,
      clearPanel,
      openGridItemOptions,
    },
    libraries: {
      iconIndex,
      searchFiltered,
      clearEditLibrary,
      activeLibrary,
      setActiveLibrary,
      editLibrary,
      setEditLibrary,
      toggleLibrary,
      libraries,
      setLibraries,
      activeIcon,
      setActiveIcon,
      toggleIcon,
      libraryList,
      setLibraryList,
      activePrivateLibraries,
      setActivePrivateLibraries,
      activeStandardLibraries,
      setActiveStandardLibraries,
    },
    modals: {
      clearModal,
      clearEditIcon,
      activeModal,
      setActiveModal,
      isModalActive,
      customIconStage,
      setCustomIconStage,
      editIcon,
      setEditIcon,
    },
    documents: {
      activeDrawing,
      setActiveDrawing,
      activeFile,
      setActiveFile,
      editFileProps,
      setEditFileProps,
      clearEditFileProps,
      activeSort,
      setActiveSort,
      activeFilePanel,
      setActiveFilePanel,
      clearDocumentTowerInfo,
      documentTowerInfo,
      setDocumentTowerInfo,
      activeTowerDetails,
      setActiveTowerDetails,
      clearActiveTowerDetails,
      clearInspectionChecklist,
      checklistData,
      setChecklistData,
      updateFolders,
      setUpdateFolders,
    },
  };

  return (
    <CanvasContext.Provider value={providerProps.data}>
      <ModalContext.Provider value={providerProps.modals}>
        <LibraryContext.Provider value={providerProps.libraries}>
          <DocumentContext.Provider value={providerProps.documents}>
            {children}
          </DocumentContext.Provider>
        </LibraryContext.Provider>
      </ModalContext.Provider>
    </CanvasContext.Provider>
  );
};

export default ApplicationStateProvider;
