import { IEditContext, ISitesContext, ISettingsContext } from 'state/iContext';
import { EditContext, SitesContext, SettingsContext } from 'state/context';
import { IEditClasses, IEditProps, IUpdateEditProps } from './iEdit';
import editSaveHandlers from '../saveClose/btnEventHandlers';
import { ChangeEvent, useContext } from 'react';
import UpdateImage from './image/UpdateImage';
import Close from 'components/buttons/Close';
import EditDropdown from './EditDropdown';
import EditComments from './EditComments';
import EditImage from './image/EditImage';
import { ITower } from 'state/iState';
import EditHeight from './EditHeight';
import Save from '../saveClose/Save';
import { ISelectEvent } from 'iApp';
import EditField from './EditField';
import EditDates from './EditDates';

const EditPanel = () => {
  const { siteTypes, towerTypes, towerList } = useContext<
  ISitesContext | undefined
  >(SitesContext)!;
  const { metric } = useContext<ISettingsContext | undefined>(SettingsContext)!;
  const edit = useContext<IEditContext | undefined>(EditContext)!;

  const { save, cancel } = editSaveHandlers(edit!, false, metric);
  const { editProps, setEditProps, isEditPanelOpen, imagePanel, newSite } =
    edit!;

  const {
    towerId: tower,
    name,
    code,
    type,
    location,
    height,
    contact,
    comments,
    dates,
  } = editProps;

  const siteTypeNames: string[] = Object.keys(siteTypes!);
  const towerIcon: string = siteTypes[type.site];

  const defaultTowerDetails: ITower = towerList[tower];
  const defaultProps = newSite ? {} : defaultTowerDetails;

  // Component classes
  const classes: IEditClasses = {
    main: 'absolute flex flex-col right-0 bottom-0 z-[60] bg-stone-100 text-gray-500 border border-t-4 border-gray-300 overflow-y-auto overflow-x-hidden md:border-t md:border-r-2 md: w-full h-1/2 md:w-[270px] md:h-full md:w-[270px] md:left-[282px]',
    editTitle: 'font-bold text-lg ml-2 mt-4',
    title: 'font-bold my-0.5 ml-1',
    halfBox: 'flex',
    seperator: 'flex w-full justify-center mt-1',
    drag: 'flex flex-wrap whitespace-pre justify-center mb-2',
    icon: 'w-[20px] h-[20px]',
    dates: 'flex flex-col flex flex-col px-5',
  };

  // Props to pass to their respective elements
  const props: IUpdateEditProps = {
    name: {
      prop: name,
      type: 'text',
      title: 'Name',
      placeholder: 'Tower Name',
      towerId: tower,
      changeHandler: (e: ChangeEvent<HTMLInputElement>) =>
        setEditProps((prev: IEditProps) => ({ ...prev, name: e.target.value })),
    },
    code: {
      prop: code,
      type: 'text',
      title: 'Code',
      placeholder: 'Tower Code',
      changeHandler: (e: ChangeEvent<HTMLInputElement>) =>
        setEditProps((prev: IEditProps) => ({ ...prev, code: e.target.value })),
    },
    siteType: {
      title: 'Site Type',
      defaultValue: type.site,
      options: siteTypeNames.slice(1),
      changeHandler: (e: ISelectEvent) =>
        setEditProps((prev: IEditProps) => ({
          ...prev,
          type: { ...prev.type, site: e.target.value },
        })),
    },
    towerTypes: {
      title: 'Tower Type',
      options: towerTypes,
      defaultValue: type.tower,
      changeHandler: (e: ISelectEvent) =>
        setEditProps((prev: IEditProps) => ({
          ...prev,
          type: { ...prev.type, tower: e.target.value },
        })),
    },
    height: {
      length: height,
      changeHandler: (e: ChangeEvent<HTMLInputElement>) =>
        setEditProps((prev: IEditProps) => ({
          ...prev,
          height: Number(e.target.value),
        })),
    },
    contact: {
      name: {
        type: 'text',
        prop: contact.name,
        title: 'Contact Name',
        placeholder: 'Contact Name',
        changeHandler: (e: ChangeEvent<HTMLInputElement>) =>
          setEditProps((prev: IEditProps) => ({
            ...prev,
            contact: { ...prev.contact, name: e.target.value },
          })),
      },
      phone: {
        type: 'tel',
        prop: contact.phone,
        title: 'Contact Phone',
        placeholder: 'Contact Phone Number',
        changeHandler: (e: ChangeEvent<HTMLInputElement>) =>
          setEditProps((prev: IEditProps) => ({
            ...prev,
            contact: { ...prev.contact, phone: e.target.value },
          })),
      },
      email: {
        type: 'email',
        prop: contact.email,
        title: 'Contact Email',
        placeholder: 'Contact Email Address',
        changeHandler: (e: ChangeEvent<HTMLInputElement>) =>
          setEditProps((prev: IEditProps) => ({
            ...prev,
            contact: { ...prev.contact, email: e.target.value },
          })),
      },
    },
    inspection: {
      date: dates.inspection,
      type: 'inspection',
    },
    license: {
      date: dates.license,
      type: 'license',
    },
    location: {
      lat: {
        half: true,
        type: 'text',
        title: 'Latitude',
        prop: location.lat,
        placeholder: '00.000000',
        changeHandler: (e: ChangeEvent<HTMLInputElement>) =>
          setEditProps((prev: IEditProps) => ({
            ...prev,
            location: {
              ...prev.location,
              lat: Number(e.target.value.replace(/[a-z]/gi, '')),
            },
          })),
      },
      lng: {
        half: true,
        type: 'text',
        title: 'Longitude',
        prop: location.lng,
        placeholder: '00.000000',
        changeHandler: (e: ChangeEvent<HTMLInputElement>) =>
          setEditProps((prev: IEditProps) => ({
            ...prev,
            location: {
              ...prev.location,
              lng: Number(e.target.value.replace(/[a-z]/gi, '')),
            },
          })),
      },
    },
    comments: {
      comments,
      changeHandler: (e: ChangeEvent<HTMLTextAreaElement>) =>
        setEditProps((prev: IEditProps) => ({
          ...prev,
          comments: e.target.value,
        })),
    },
    save: {
      save: save('SAVE', editProps)!,
      cancel: cancel(defaultProps)!,
    },
  };

  return (
    <>
      <div
        id='edit-panel'
        className={classes.main}>
        <span className={classes.editTitle}>EDIT</span>
        <Close {...cancel(defaultProps)} />
        <EditImage />
        <div className={classes.title}>Site</div>
        <EditField {...props.name!} />
        <EditField {...props.code!} />
        <EditDropdown {...props.siteType!} />
        <div className={classes.halfBox}>
          <EditDropdown {...props.towerTypes!} />
          <EditHeight {...props.height!} />
        </div>
        <div className={classes.title}>Contact</div>
        <EditField {...props.contact!.name} />
        <EditField {...props.contact!.phone} />
        <EditField {...props.contact!.email} />
        <div className={classes.title}>Comments</div>
        <EditComments {...props.comments!} />
        <div className={classes.title}>Location</div>
        <div className={classes.halfBox}>
          <EditField {...props.location!.lat} />
          <EditField {...props.location!.lng} />
        </div>
        <div className={classes.title}>Dates</div>
        <div className={classes.dates}>
          <EditDates {...props.inspection!} />
          {/* <EditDates {...props.license!} /> */}
        </div>
        <div className={classes.seperator}>
          ----------------OR----------------
        </div>
        <div className={classes.drag}>
          Drag the tower {}
          <img
            src={towerIcon}
            alt='tower icon'
            className={classes.icon}
          />
          {} icon to update site location
        </div>
        <Save {...props.save!} />
      </div>
      {isEditPanelOpen && imagePanel && <UpdateImage />}
    </>
  );
};

export default EditPanel;
