import { useContext } from 'react';
import { SettingsContext } from 'state/context';
import { ISettingsContext } from 'state/iContext';

const Footer = () => {
  const { version } = useContext<ISettingsContext | undefined>(
    SettingsContext
  )!;
  return (
    <div className='absolute bottom-0 z-50 flex w-full justify-between bg-slate-400 pr-2 py-1 text-xs text-stone-700 text-end font-semibold'>
      <span>V{version}</span>
      <span>All Rights Reserved to Rigstar Industrial Telcom.</span>
    </div>
  );
};
export default Footer;