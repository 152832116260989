import {
  IEditContext,
  IHeaderContext,
  ISettingsContext,
  IUserContext,
} from 'state/iContext';
import {
  EditContext,
  HeaderContext,
  SettingsContext,
  UserContext,
} from 'state/context';
import SitesPanel from './sitesPanel/SitesPanel';
import EditPanel from './editPanel/EditPanel';
import { IHomeClasses } from './iHome';
import { useContext, useEffect } from 'react';
import Map from './map/Map';

const Home = () => {
  const { setIsSettingsOpen } = useContext<ISettingsContext | undefined>(
    SettingsContext
  )!;
  const { clearActiveButtons } = useContext<IHeaderContext | undefined>(
    HeaderContext
  )!;
  const { isEditPanelOpen } = useContext<IEditContext | undefined>(
    EditContext
  )!;

  // Component classes
  const classes: IHomeClasses = {
    main: 'w-full h-full flex flex-col',
    secondary: 'relative w-full h-full flex flex-col-reverse md:flex-row',
  };

  // Closes header dropdown windows
  const mouseDownHandler = (): void => {
    clearActiveButtons();
    setIsSettingsOpen(false);
  };


  return (
    <div
      id='home'
      data-testid='home'
      className={classes.main}>
      <div
        id='site-map-container'
        className={classes.secondary}
        onMouseDown={mouseDownHandler}>
        <SitesPanel />
        {isEditPanelOpen && <EditPanel />}
        <Map />
      </div>
    </div>
  );
};

export default Home;
