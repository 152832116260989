import { ICanvasGridHandlers } from './iCanvasGrid';
import { useContext, useCallback, useMemo, useRef } from 'react';
import GridTextBoxes from '../../gridItems/items/GridTextBoxes';
import { ICanvasContext, ISitesContext } from 'state/iContext';
import { CanvasContext, SitesContext } from 'state/context';
import GridRooftop from '../../gridItems/items/GridRooftop';
import GridImages from '../../gridItems/items/GridImages';
import GridLink from '../../gridItems/items/GridLink';
import GridLayout, { Layout } from 'react-grid-layout';
import RooftopImageInput from './RooftopImageInput';
import { IStringProps } from 'iApp';
import {
  ITowerList,
  ILibraryIcon,
  IGridLayoutProps,
  ICanvasLayoutSite,
} from 'state/iState';

const RooftopGrid = () => {
  const { scale, dragProps, setDragProps, activeTowerID, openGridItemOptions } =
    useContext<ICanvasContext | undefined>(CanvasContext)!;
  const { setTowerList, towerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;

  const ref = useRef<HTMLDivElement>(null);

  const classes: IStringProps = useMemo(
    () => ({
      container:
        'relative ' + (dragProps.dragging ? 'cursor-grab' : 'cursor-grabbing'),
      gridItem:
        'peer relative h-full flex flex-col bg-stone-100 -ml-[0.5px] -mt-[0.5px] -pb-[1px] border border-stone-400 justify-center',
      link: 'peer relative h-full flex flex-col bg-stone-50 -ml-[0.5px] -mt-[0.5px] pl-2 border border-stone-400 justify-center font-bold text-xs text-red-700',
      img: 'relative flex w-full h-full object-fill border border-slate-500 rounded rounded-md',
      input: 'flex flex-col justify-center items-center bg-white',
    }),
    [dragProps.dragging]
  );

  const rooftopObject: ICanvasLayoutSite = towerList[activeTowerID].layout.site;
  const rooftopTextBoxes: IGridLayoutProps = rooftopObject.textBoxes;
  const rooftopLinks: IGridLayoutProps = rooftopObject.drawings;
  const rooftopImages: IGridLayoutProps = rooftopObject.images;

  const images = Object.keys(rooftopImages).map(
    (image) => rooftopImages[image]
  );
  const textBoxes = Object.keys(rooftopTextBoxes).map(
    (box) => rooftopTextBoxes[box]
  );
  const drawingLinks = Object.keys(rooftopLinks).map(
    (link) => rooftopLinks[link]
  );

  const imageLayout = useMemo(
    () =>
      rooftopObject.image
        ? {
            i: rooftopObject.imageGrid!.i,
            x: rooftopObject.imageGrid!.x,
            y: rooftopObject.imageGrid!.y,
            w: rooftopObject.imageGrid!.w,
            h: rooftopObject.imageGrid!.h,
          }
        : {
            i: 'No Image Provided',
            x: 300,
            y: 500,
            w: 100,
            h: 50,
            static: true,
          },
    [rooftopObject]
  );

  const layoutOnLoad = useMemo(
    () => [
      {
        i: '-1',
        name: 'boundry',
        x: 2000,
        y: 200,
        w: 0,
        h: 0,
        minW: 0,
        minH: 0,
        static: true,
      },
      imageLayout!,
      ...images,
      ...textBoxes,
      ...drawingLinks,
    ],
    [images, textBoxes, drawingLinks, imageLayout]
  );

  const canvasImages = images.map((image) => (
    <div
      id={image.i}
      key={image.i}
      data-grid={image}
      className={classes.gridItem}
      onClick={() => openGridItemOptions(rooftopImages[image.i], 'images')}
      onTouchStart={() =>
        openGridItemOptions(rooftopImages[image.i], 'images')
      }>
      <GridImages layout={image} />
    </div>
  ));

  const canvasTextBoxes = textBoxes.map((box) => {
    return (
      <div
        id={box.i}
        key={box.i}
        data-grid={box}
        className={classes.gridItem}
        onClick={() =>
          openGridItemOptions(rooftopTextBoxes[box.i], 'textBoxes')
        }
        onTouchStart={() =>
          openGridItemOptions(rooftopTextBoxes[box.i], 'textBoxes')
        }>
        <GridTextBoxes layout={box} />
      </div>
    );
  });

  const canvasLinks = drawingLinks.map((link) => (
    <div
      id={link.i}
      key={link.i}
      data-grid={link}
      className={classes.link}
      onClick={() => openGridItemOptions(rooftopLinks[link.i], 'drawings')}
      onTouchStart={() =>
        openGridItemOptions(rooftopLinks[link.i], 'drawings')
      }>
      <GridLink layout={link} />
    </div>
  ));

  const updateImages = useCallback(
    (layout: Layout[]) => {
      let updatedLayout = {};
      layout.forEach((img) => {
        updatedLayout[img.i] = {
          ...img,
          i: img.i,
          h: img.h,
          w: img.w,
          name: rooftopImages[img.i].name,
          img: rooftopImages[img.i].img,
          isDraggable: true,
          isResizable: true,
        };
      });
      return updatedLayout;
    },
    [rooftopImages]
  );

  const updateTextBoxes = useCallback(
    (layout: Layout[]) => {
      let updatedLayout = {};
      layout.forEach((box) => {
        updatedLayout[box.i] = {
          ...box,
          i: box.i,
          h: box.h,
          W: box.w,
          name: rooftopTextBoxes[box.i].name,
          text: rooftopTextBoxes[box.i].text,
          isDraggable: true,
          isResizable: true,
        };
      });
      return updatedLayout;
    },
    [rooftopTextBoxes]
  );

  const updateDrawingLinks = useCallback(
    (layout: Layout[]) => {
      let updatedLayout = {};
      layout.forEach((link) => {
        updatedLayout[link.i] = {
          ...link,
          i: link.i,
          h: link.h,
          W: link.w,
          name: rooftopLinks[link.i].name,
          refID: rooftopLinks[link.i].refID,
          isDraggable: true,
          isResizable: true,
        };
      });
      return updatedLayout;
    },
    [rooftopLinks]
  );

  const handler: ICanvasGridHandlers = {
    onResizeStop: (_, __, next) => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            site: {
              ...prev[activeTowerID].layout.site,
              imageGrid: {
                ...prev[activeTowerID].layout.site.imageGrid,
                x: next.x,
                y: next.y,
                w: next.w,
                h: next.h,
              },
            },
          },
        },
      }));
    },
    onLayoutChange: (layout) => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            site: {
              ...prev[activeTowerID].layout.site,
              images: updateImages(layout.slice(1, images.length + 1)),
              textBoxes: updateTextBoxes(
                layout.slice(
                  images.length + 1,
                  images.length + textBoxes.length + 1
                )
              ),
              drawings: updateDrawingLinks(
                layout.slice(images.length + textBoxes.length + 1)
              ),
            },
          },
        },
      }));
    },
    onDragStop: (_) => console.log('onDragStop'),
    onResize: () =>
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: true,
      })),
  };

  return (
    <div
      ref={ref}
      className={classes.container}>
      <GridLayout
        cols={500}
        width={3775}
        margin={[0, 0]}
        autoSize={true}
        rowHeight={7.55}
        isResizable={true}
        allowOverlap={true}
        className='relative'
        layout={layoutOnLoad}
        transformScale={scale}
        compactType={'horizontal'}
        draggableHandle='.dragHandle'
        onResize={handler.onResize}
        onDragStop={handler.onDragStop}
        onResizeStop={handler.onResizeStop}
        onLayoutChange={handler.onLayoutChange}>
        {rooftopObject.image ? (
          <div
            className={classes.img}
            key={rooftopObject.imageGrid!.i}
            data-grid={rooftopObject.imageGrid}>
            <GridRooftop image={rooftopObject.image} />
          </div>
        ) : (
          <div
            key={imageLayout.i}
            data-grid={imageLayout}
            className={classes.input}>
            <RooftopImageInput />
          </div>
        )}
        {canvasImages}
        {canvasTextBoxes}
        {canvasLinks}
      </GridLayout>
    </div>
  );
};

export default RooftopGrid;
