import {
  ICanvasContext,
  ISettingsContext,
  ISitesContext,
} from 'state/iContext';
import { CanvasContext, SettingsContext, SitesContext } from 'state/context';
import { IAppViewClasses, IAppViewIcons } from './iHeaderTabs';
import AddButton from 'components/buttons/addButton/AddButton';
import { useContext, useState, useEffect } from 'react';
import HeaderTabs from './HeaderTabs';

const ApplicationView = () => {
  const {
    screenDimensions: { width },
  } = useContext<ISettingsContext | undefined>(SettingsContext)!;
  const { activeTowerID } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;
  const { towerList } = useContext<ISitesContext | undefined>(SitesContext)!;

  // Component classes
  const classes: IAppViewClasses = {
    container: 'flex justify-between w-full',
    tabs: {
      active: 'flex text-xs ml-5 font-semibold',
      inactive:
        'absolute top-[50px] right-0 flex z-50 h-[50px] bg-stone-200 border border-slate-300 sm:right-auto',
    },
    tower: {
      container: 'flex items-center',
      img: 'rounded-full min-w-[35px] min-h-[35px] max-w-[35px] max-h-[35x] object-fill self-center text-center',
      name: 'ml-2 font-semibold max-w-1/3 text-slate-500 truncate md:max-w-full',
    },
    addSite: 'flex w-[30px] sm:w-[100px] self-center mx-3',
  };

  const [visibleTabs, setVisibleTabs] = useState<boolean>(false);

  const smallScreen: boolean = width <= 700;

  // Closes header tabs dropdown on window enlarge
  useEffect(() => {
    if (!smallScreen) {
      setVisibleTabs(false);
    }
  }, [width, smallScreen]);

  // Small screen tab icons & behaviour
  const icons: IAppViewIcons = {
    true: (
      <i
        onClick={() => setVisibleTabs(false)}
        className='fa-solid fa-arrow-down-short-wide text-2xl self-center mx-3'
      />
    ),
    false: (
      <i
        onClick={() => setVisibleTabs(true)}
        className='fa-solid fa-bars text-2xl self-center mx-3'
      />
    ),
  };

  return (
    <div className={classes.container}>
      {smallScreen ? (
        icons[visibleTabs.toString() as keyof typeof icons]
      ) : (
        <div className={classes.tabs.active}>
          <HeaderTabs />
        </div>
      )}
      <div className={classes.tower.container}>
        <img
          src={towerList[activeTowerID].img}
          alt='tower thumbnail'
          className={classes.tower.img}
        />
        {width >= 420 && (
          <div className={classes.tower.name}>
            {towerList[activeTowerID].name}
          </div>
        )}
      </div>
      <div className={classes.addSite}>
        <AddButton name='SITE' />
      </div>
      {visibleTabs && (
        <div className={classes.tabs.inactive}>
          <HeaderTabs />
        </div>
      )}
    </div>
  );
};

export default ApplicationView;
