import { useContext, useState, createRef } from 'react';
import { ISettingsContext } from '../state/iContext';
import { IInputEvent, IStringProps } from '../iApp';
import { SettingsContext } from '../state/context';
import { PixelCrop } from 'react-image-crop';
import { Buffer } from 'buffer';
import {
  ICropSave,
  IFileSelect,
  IVerifyFile,
  ICropPreview,
  ISetFileReader,
} from './iHelpers';

const ImageCropHelpers = (image: string) => {
  const settings = useContext<ISettingsContext | undefined>(SettingsContext);
  const { fileTypes, defaultCropConfig } = settings!;
  const { imageTypeList } = fileTypes;

  // Returns file extension
  const getImageFileExtension = (data: string): string => {
    const match = data.match(/data:image\/([a-z]+);base64/i);
    if (match) {
      return match[1];
    }
    const extMatch = data.match(/(jpg|jpeg|png|svg)$/i);
    if (extMatch) {
      return extMatch[0];
    }
    return '';
  };

  interface IDimensions {
    w: number;
    h: number;
  }

  // Image Cropping and file Extension states
  const [crop, setCrop] = useState<PixelCrop>(defaultCropConfig);
  const [imgSrc, setImgSrc] = useState<string>('');
  const [imgSrcExt, setImgSrcExt] = useState<string>(
    getImageFileExtension(image)
  );
  const [canvasDimensions, setCanvasDimensions] = useState<IDimensions>({
    w: 210,
    h: 210,
  });
  const [scaleDimensions, setScaleDimensions] = useState<IDimensions>({
    w: 210,
    h: 210,
  });

  // References to crop image preview and file input
  const imagePreviewCanvasRef = createRef<HTMLCanvasElement>();
  const fileInputRef = createRef<HTMLInputElement>();

  // Checks to see that uploaded file is an image file and proceeds with passed methods
  const onFileSelect = ({ e, onFileSelectComplete }: IFileSelect): void => {
    const { files } = e.target;
    if (files && files.length > 0) {
      const isVerified: boolean = verifyFile({ files, imageTypeList });
      if (isVerified) {
        const file: File = files[0];
        onFileSelectComplete({ e, file });
      }
    }
  };

  // Reads image file on load and converts to usable data
  const setFileReader = ({
    file,
    changeImageStates,
    save,
  }: ISetFileReader): void => {
    const reader: FileReader = new FileReader();
    reader.addEventListener('load', (): void => {
      const image: string = reader.result!.toString();
      changeImageStates({ image, save });
    });
    reader.readAsDataURL(file);
  };

  // Converts Base64 encoded string to a File
  const base64StringtoFile = ({
    base64String,
    myFileName,
  }: IStringProps): File => {
    const arr: string[] = base64String.split(',');
    const mime: string = arr[0].match(/:(.*?);/)![1];
    const buffer: Buffer = Buffer.from(arr[1], 'base64');
    const uint8Array: Uint8Array = new Uint8Array(buffer);
    return new File([uint8Array], myFileName, { type: mime });
  };

  // Verifies if uploaded file is an image file
  const verifyFile = ({ files, imageTypeList }: IVerifyFile): boolean => {
    if (!files || files.length === 0) {
      alert('Image not provided.');
      return false;
    }

    const currentFile = files[0];
    const currentFileType = currentFile.type;

    if (!imageTypeList.includes(currentFileType)) {
      alert('This file is not allowed. Only images are allowed.');
      return false;
    }

    return true;
  };

  // Creates a preview of cropped image
  const cropPreview = async ({
    canvasRef,
    imgSrc,
    changedCrop,
  }: ICropPreview): Promise<Blob> => {
    return new Promise((resolve, reject) => {
      const canvas: HTMLCanvasElement = canvasRef;
      canvas.width = changedCrop.width;
      canvas.height = changedCrop.height;
      const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;
      ctx.imageSmoothingQuality = 'high';
      ctx.save();
      const image: HTMLImageElement = new Image();
      image.src = imgSrc;
      const scaleX: number = image.naturalWidth / scaleDimensions.w;
      const scaleY: number = image.naturalWidth / scaleDimensions.h;
      image.onload = (): void => {
        ctx.drawImage(
          image,
          changedCrop.x * scaleX,
          changedCrop.y * scaleY,
          changedCrop.width * scaleX,
          changedCrop.height * scaleY,
          0,
          0,
          changedCrop.width,
          changedCrop.height
        );
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Failed to create blob from canvas.'));
          }
        });
      };
    });
  };

  // Resets all crop/image states
  const clearToDefault = (event: IInputEvent): void => {
    if (event) event.preventDefault();
    const canvas: HTMLCanvasElement = imagePreviewCanvasRef.current!;
    if (canvas) {
      const ctx: CanvasRenderingContext2D = canvas.getContext('2d')!;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
    setImgSrc('');
    setImgSrcExt('');
    setCrop(defaultCropConfig);
    fileInputRef.current!.value = '';
  };

  // Crop event handlers
  const onCropChange = (changedCrop: PixelCrop): void => {
    setCanvasDimensions({ w: changedCrop.width, h: changedCrop.height });
    setCrop(changedCrop);
  };
  const onCropComplete = (changedCrop: PixelCrop): void => {
    const canvasRef: HTMLCanvasElement = imagePreviewCanvasRef.current!;
    cropPreview({ canvasRef, imgSrc, changedCrop });
  };

  // Converts cropped image from base64 data to readable/storable image file
  const onCropSave = ({ e, cropSaveMethods }: ICropSave): void => {
    const { changeImageStates, onComplete } = cropSaveMethods;
    if (imagePreviewCanvasRef.current) {
      const canvasRef: HTMLCanvasElement = imagePreviewCanvasRef.current!;
      const myFileName: string = 'croppedImage.' + imgSrcExt;
      const base64String: string = canvasRef.toDataURL('image/' + imgSrcExt);
      const croppedFile: File = base64StringtoFile({
        base64String,
        myFileName,
      });
      setFileReader({
        file: croppedFile,
        changeImageStates,
        save: true,
      });
      clearToDefault(e);
    } else {
      changeImageStates({ image, save: true });
    }
    onComplete();
  };

  return {
    onFileSelect,
    setFileReader,
    base64StringtoFile,
    cropPreview,
    getImageFileExtension,
    verifyFile,
    crop,
    setCrop,
    imgSrc,
    setImgSrc,
    imgSrcExt,
    setImgSrcExt,
    imagePreviewCanvasRef,
    fileInputRef,
    clearToDefault,
    onCropChange,
    onCropComplete,
    onCropSave,
    setScaleDimensions,
    canvasDimensions,
  };
};

export default ImageCropHelpers;
