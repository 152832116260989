import PDFInspectionChecklistLine from './PDFInspectionChecklistLine';
import { IPDFInspectionChecklistSection } from '../iDocumentEditor';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { ReactNode, useMemo } from 'react';

const PDFInspectionChecklistSection = ({
  contents,
  category,
  title,
  index,
}: IPDFInspectionChecklistSection) => {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          flexDirection: 'column',
          justifyContent: 'flex-start',
          width: '100%',
          height: index === 10 ? '50%' : undefined,
        },
        row: {
          flexDirection: 'row',
          width: '100%',
        },
        small: {
          border: 'solid',
          borderWidth: '1px',
          width: '5%',
          textAlign: 'center',
          fontSize: '9px',
          padding: 4,
          height: '100%',
        },
        title: {
          border: 'solid',
          borderWidth: '1px',
          width: '45%',
          fontSize: '9px',
          padding: 4,
          alignItems: 'center',
          height: '100%',
        },
        comments: {
          border: 'solid',
          borderWidth: '1px',
          width: '35%',
          fontSize: '9px',
          padding: 4,
          alignItems: 'center',
          textAlign: 'center',
          height: '100%',
        },
      }),
    []
  );

  const lines: ReactNode[] = useMemo(
    () =>
      Object.keys(contents).map((line, index) => (
        <PDFInspectionChecklistLine
          category={`${category}${index + 1}`}
          info={contents[line]}
          key={index}
        />
      )),
    [contents]
  );

  return (
    <View
      break={index === 2 || index === 4 || index === 7}
      style={styles.container}>
      <View style={styles.row}>
        <Text style={styles.small}>{category}</Text>
        <Text style={styles.title}>{title.toUpperCase()}</Text>
        <Text style={styles.small}>S</Text>
        <Text style={styles.small}>U</Text>
        <Text style={styles.small}>N/A</Text>
        <Text style={styles.comments}>COMMENTS</Text>
      </View>
      {lines}
    </View>
  );
};

export default PDFInspectionChecklistSection;
