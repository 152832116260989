import { DocumentContext, CanvasContext, SitesContext } from 'state/context';
import { IStringProps } from 'iApp';
import { useContext } from 'react';
import {
  ISitesContext,
  ICanvasContext,
  IDocumentContext,
} from 'state/iContext';

const DrawingTabs = () => {
  const { towerList } = useContext<ISitesContext | undefined>(SitesContext)!;
  const { activeDrawing, setActiveDrawing } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;
  const { activeTowerID } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;

  const classes: IStringProps = {
    container:
      'sticky border-b-2 border-b-red-600 bg-slate-200 px-2 top-0 flex p-0 z-50',
    activeTab:
      'border-2 border-red-600 px-1 font-bold text-red-500 cursor-pointer',
    inactiveTab:
      'border-2 border-slate-200 font-bold text-stone-500 hover:border-red-600 px-1 cursor-pointer hover:text-red-500',
  };

  const drawingKeys = Object.keys(
    towerList[activeTowerID].documents.files
  ).filter((id) => towerList[activeTowerID].documents.files[id].folder === 1);

  const drawingTabs = drawingKeys.map((key) => (
    <div
      key={key}
      className={
        activeDrawing === key ? classes.activeTab : classes.inactiveTab
      }
      onClick={() => setActiveDrawing(key)}>
      {towerList[activeTowerID].documents.files[key].name}
    </div>
  ));

  return (
    <div className={classes.container}>
      {drawingTabs}
      <div
        className={
          activeDrawing === 'add' ? classes.activeTab : classes.inactiveTab
        }
        onClick={() => setActiveDrawing('add')}>
        Add Drawing
      </div>
    </div>
  );
};

export default DrawingTabs;
