import { CanvasContext, SettingsContext, SitesContext } from 'state/context';
import { IChangeImageStates, IFileSelectComplete } from 'helpers/iHelpers';
import { IAddItemProps, ITowerList } from 'state/iState';
import ImageCropHelpers from 'helpers/imageCropHelpers';
import { IInputEvent, IStringProps } from 'iApp';
import SaveBox from 'components/buttons/SaveBox';
import { useContext } from 'react';
import {
  ISitesContext,
  ICanvasContext,
  ISettingsContext,
} from 'state/iContext';

const RooftopImageInput = () => {
  const { addItemProps, activeTowerID, clearAddProps, setAddItemProps } =
    useContext<ICanvasContext | undefined>(CanvasContext)!;
  const { setTowerList } = useContext<ISitesContext | undefined>(SitesContext)!;
  const {
    fileTypes: { acceptedImageTypes },
  } = useContext<ISettingsContext | undefined>(SettingsContext)!;

  const {
    imgSrc,
    setImgSrc,
    fileInputRef,
    onFileSelect,
    setImgSrcExt,
    setFileReader,
    clearToDefault,
    getImageFileExtension,
  } = ImageCropHelpers(addItemProps.content);

  // Changes/saves the state of the image and file extension
  const changeImageStates = ({ image }: IChangeImageStates): void => {
    setImgSrc(image);
    setAddItemProps((prev: IAddItemProps) => ({
      ...prev,
      content: image,
    }));
    setImgSrcExt(getImageFileExtension(image));
  };

  // Additional methods to envoke once a file is selected
  const onFileSelectComplete = ({ e, file }: IFileSelectComplete): void => {
    setFileReader({
      file: file,
      changeImageStates,
    });
    clearToDefault(e);
  };

  const classes: IStringProps = {
    container: 'flex flex-col w-full h-full justify-center items-center',
    heading: 'font-bold text-xl text-stone-500',
    imgImported:
      'relative w-5/6 h-5/6 flex flex-col justify-around items-center border border-stone-300 rounded rounded-lg bg-slate-100',
    img: 'h-2/3 w-fit self-center object-contain border border-2 border-stone-400',
    saveBox:
      'absolute flex -right-2 sm:right-0 bottom-5 w-[150px] justify-around sm:mx-5',
  };

  const props = {
    file: {
      type: 'file',
      multiple: false,
      className: 'm-2',
      ref: fileInputRef!,
      id: 'imported-aerial-view',
      name: 'imported-aerial-view',
      accept: acceptedImageTypes,
      onChange: (e: IInputEvent) => onFileSelect({ e, onFileSelectComplete }),
    },
    img: {
      id: 'displayed-user-image',
      src: addItemProps.content,
      className: classes.img,
    },
    saveBox: {
      classes: classes.saveBox,
      clickHandlers: {
        save: async () => {
          await setTowerList((prev: ITowerList) => ({
            ...prev,
            [activeTowerID]: {
              ...prev[activeTowerID],
              layout: {
                ...prev[activeTowerID].layout,
                site: {
                  ...prev[activeTowerID].layout.site,
                  image: addItemProps.content,
                  imageGrid: {
                    i: 0,
                    name: addItemProps.label,
                    x: 310,
                    y: 480,
                    w: 300,
                    h: 300,
                  },
                },
              },
            },
          }));
          setImgSrc('');
          setAddItemProps(clearAddProps);
        },
        cancel: () => {
          setImgSrc('');
          setAddItemProps(clearAddProps);
        },
      },
      disabled: !addItemProps.content,
    },
  };

  return (
    <>
      <h1 className='text-xl mb-10'>No Rooftop Image Uploaded</h1>
      {imgSrc ? (
        <div className={classes.imgImported}>
          <img
            alt='imported thumbail'
            {...props.img}
          />
          <SaveBox {...props.saveBox} />
        </div>
      ) : (
        <input {...props.file} />
      )}
    </>
  );
};

export default RooftopImageInput;
