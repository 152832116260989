import { ICanvasContext, IUserContext } from 'state/iContext';
import { CanvasContext, UserContext } from 'state/context';
import { highlightSearch } from 'helpers/helperFunctions';
import { IGridItem } from '../iGridItems';
import DragHandle from '../DragHandle';
import { IStringProps } from 'iApp';
import { useContext } from 'react';

const GridImages = ({ layout }: IGridItem) => {
  const { activeToolBar } = useContext<ICanvasContext | undefined>(CanvasContext)!;
  const { searchBar } = useContext<IUserContext | undefined>(UserContext)!;

  const classes: IStringProps = {
    img: 'w-full h-full object-contain',
    property:
      'absolute w-full max-w-content -bottom-4 text-center text-xs flex-nowrap whitespace-nowrap hover:font-bold',
  };

  return (
    <>
      <img
        src={layout.img}
        alt={layout.name}
        className={classes.img}
      />
      <DragHandle />
      {activeToolBar.Properties && (
        <div className={classes.property}>
          {highlightSearch(layout.name!, searchBar.trim())}
        </div>
      )}
    </>
  );
};

export default GridImages;
