import { IHeaderContext, ICanvasContext, ISitesContext } from 'state/iContext';
import { HeaderContext, CanvasContext, SitesContext } from 'state/context';
import { IGridItem, ITextBoxUpdateTargets } from '../iGridItems';
import PropertyLabel from '../PropertyLabel';
import { ITowerList } from 'state/iState';
import DragHandle from '../DragHandle';
import { useContext } from 'react';
import { IInputEvent } from 'iApp';

const GridTextBoxes = ({ layout }: IGridItem) => {
  const { activeCanvasTab } = useContext<IHeaderContext | undefined>(
    HeaderContext
  )!;
  const { setTowerList } = useContext<ISitesContext | undefined>(SitesContext)!;
  const { activeToolBar, activeTowerID } = useContext<
    ICanvasContext | undefined
  >(CanvasContext)!;

  const canvasTarget: ITextBoxUpdateTargets = {
    tower: (prev: ITowerList, e: IInputEvent) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        layout: {
          ...prev[activeTowerID].layout,
          tower: {
            ...prev[activeTowerID].layout.tower,
            textBoxes: {
              ...prev[activeTowerID].layout.tower.textBoxes,
              [layout.i]: {
                ...prev[activeTowerID].layout.tower.textBoxes![layout.i],
                text: e.target.value,
              },
            },
          },
        },
      },
    }),
    site: (prev: ITowerList, e: IInputEvent) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        layout: {
          ...prev[activeTowerID].layout,
          site: {
            ...prev[activeTowerID].layout.site,
            textBoxes: {
              ...prev[activeTowerID].layout.site.textBoxes,
              [layout.i]: {
                ...prev[activeTowerID].layout.site.textBoxes![layout.i],
                text: e.target.value,
              },
            },
          },
        },
      },
    }),
  };

  return (
    <>
      <textarea
        cols={30}
        rows={10}
        id={layout.name}
        name={layout.name}
        onDrop={(e) => e.preventDefault()}
        className='overflow-hidden focus:outline-none hover:overflow-auto'
        defaultValue={layout.text}
        onChange={(e: IInputEvent) => {
          setTowerList((prev: ITowerList) =>
            canvasTarget[activeCanvasTab](prev, e)
          );
        }}
        readOnly={activeToolBar.text}
      />
      <DragHandle />
      {activeToolBar.Properties && (
        <PropertyLabel
          label={layout.name!}
          type='import'
        />
      )}
    </>
  );
};

export default GridTextBoxes;
