import { ILibraryCheckBox, ILibraryCheckBoxClasses } from '../iManageLibraries';
import { ILibraryContext } from 'state/iContext';
import { LibraryContext } from 'state/context';
import { useContext } from 'react';

const LibraryCheckBox = ({ name, library, handler }: ILibraryCheckBox) => {
  const { toggleLibrary, activeLibrary, setActiveLibrary } = useContext<ILibraryContext | undefined>(LibraryContext)!;

  // Component classes
  const classes: ILibraryCheckBoxClasses = {
    container: {
      active: 'w-full py-1 pl-3 bg-stone-200 border-b border-b-stone-300',
      inactive:
        'w-full py-1 pl-3 border-b border-b-stone-300 odd:bg-slate-100 transition ease-in-out delay-100 hover:bg-stone-200',
    },
    input: 'mr-2 enabled:transition ease-in-out delay-100 hover:bg-red-600 checked:bg-stone-500 rounded-sm',
    label: 'text-stone-500',
  };

  const { active, inactive } = classes.container;
  const activeClass: string = activeLibrary === name ? active : inactive;

  // Controls what library of icons are displayed
  const clickHandler = (): void => {
    toggleLibrary(name);
  };

  // Toggles visible status of selected library
  const changeHandler = (): void => {
    setActiveLibrary(name);
    handler();
  };

  return (
    <div
      onClick={clickHandler}
      className={activeClass}>
      <input
        name={name}
        type='checkbox'
        checked={library[name]}
        onChange={changeHandler}
        className={classes.input}
        id={`manage-library-${name}`}
      />
      <label
        className={classes.label}
        htmlFor={name}>
        {name}
      </label>
    </div>
  );
};

export default LibraryCheckBox;
