import { ReactNode, useContext, useEffect, useMemo } from 'react';
import DocInspectionCategory from './DocInspectionCategory';
import { IDocumentContext } from 'state/iContext';
import { DocumentContext } from 'state/context';
import { IEditFileProps } from 'state/iState';
import ToggleOption from '../ToggleOption';
import { IStringProps } from 'iApp';

const DocInspectionChecklist = () => {
  const { checklistData, editFileProps, setEditFileProps, setActiveFilePanel } =
    useContext<IDocumentContext | undefined>(DocumentContext)!;

  const categoryID: string[] = useMemo(
    () => ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K'],
    []
  );

  const classes: IStringProps = useMemo(
    () => ({
      container:
        'flex flex-col w-full h-full p-1 text-stone-500 items-center justify-between',
      title: 'w-full p-2 text-center text-lg text-stone-400 font-bold',
      table: 'flex flex-col w-full h-full overflow-auto border',
      legend:
        'w-full p-1 pr-10 text-end text-stone-400 text-xs border border-stone-500 text-center',
      button:
        'p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
    }),
    []
  );

  useEffect(() => {
    if (Object.keys(editFileProps.contents.checklist.lists).length !== 0) {
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          checklist: {
            ...prev.contents.checklist,
            lists: checklistData,
          },
        },
      }));
    }
  }, []);

  const inspectionCategories: ReactNode[] = useMemo(
    () =>
      Object.keys(checklistData).map(
        (cat: string, i: number): ReactNode => (
          <DocInspectionCategory
            categoryID={categoryID}
            category={cat}
            key={cat}
            id={i}
          />
        )
      ),
    [editFileProps.contents.checklist, categoryID]
  );

  useEffect(() => {
    if (!editFileProps.contents.checklist.active) {
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          pages: prev.contents.pages.filter(
            (str: string) => str !== 'checklist'
          ),
        },
      }));
    }
  }, [editFileProps.contents.checklist.active]);

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Inspection Checklist</h1>
      <ToggleOption
        name='Active Page'
        value={editFileProps.contents.checklist.active}
        onChange={() => {
          setEditFileProps((prev: IEditFileProps) => ({
            ...prev,
            contents: {
              ...prev.contents,
              checklist: {
                ...prev.contents.checklist,
                active: !prev.contents.checklist.active,
              },
              pages: [...prev.contents.pages, 'checklist'],
            },
          }));
        }}
      />
      <div className={classes.table}>
        <div className={classes.legend}>
          S = SATISFACTORY | U = UNSATIFACTORY | N/A = NOT APPLICABLE
        </div>
        {inspectionCategories}
      </div>
      <button
        onClick={() =>
          setEditFileProps((prev: IEditFileProps) => ({
            ...prev,
            contents: {
              ...prev.contents,
              checklist: {
                ...prev.contents.checklist,
                lists: checklistData,
              },
            },
          }))
        }
        className={classes.button}>
        Update PDF
      </button>
      <button
        onClick={() => setActiveFilePanel('')}
        className={classes.button}>
        Back
      </button>
    </div>
  );
};

export default DocInspectionChecklist;
