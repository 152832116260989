import { IStringProps } from 'iApp';
import { useMemo } from 'react';

const DocImageSize = ({ isCustomActive, size, handler }) => {
  const classes: IStringProps = useMemo(
    () => ({
      contianer: 'flex w-full my-2 justify-between',
      label: 'mx-2 font-bold text-stone-500',
      size: 'flex w-1/2 justify-between',
      wh: 'w-1/2 p-0.5 text-center',
      input: 'w-full p-0 text-center',
      select: 'w-1/2 p-0 text-center',
    }),
    []
  );

  const deafultSelect: string =
    size.h <= 100
      ? 'xs'
      : size.h <= 200
      ? 'sm'
      : size.h <= 300
      ? 'md'
      : size.h <= 400
      ? 'lg'
      : 'xl';

  return isCustomActive ? (
    <div className={classes.container}>
      <label
        htmlFor='size'
        className={classes.label}>
        Size:
      </label>
      <div className={classes.size}>
        <div className={classes.wh}>
          <label htmlFor='width'>Width:</label>
          <input
            min={5}
            max={1000}
            name='width'
            type='number'
            value={size.w}
            id='image-width'
            onChange={handler.w}
            className={classes.input}
          />
        </div>
        <div className={classes.wh}>
          <label htmlFor='height'>Height:</label>
          <input
            min={5}
            max={1000}
            name='height'
            type='number'
            value={size.h}
            id='image-height'
            onChange={handler.h}
            className={classes.input}
          />
        </div>
      </div>
    </div>
  ) : (
    <div className={classes.container}>
      <label
        className={classes.label}
        htmlFor='size'>
        Size:
      </label>
      <select
        id='position'
        name='position'
        key={deafultSelect}
        onChange={handler.select}
        className={classes.select}
        defaultValue={deafultSelect}>
        <option value='xs'>Smallest</option>
        <option value='sm'>Small</option>
        <option value='md'>Medium</option>
        <option value='lg'>Large</option>
        <option value='xl'>Largest</option>
      </select>
    </div>
  );
};

export default DocImageSize;
