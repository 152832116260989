import { ICompanyContext, ILibraryContext, IUserContext } from 'state/iContext';
import { CompanyContext, LibraryContext, UserContext } from 'state/context';
import { ICompany, IUser } from 'state/iState';
import LibrariesList from './LibrariesList';
import { useContext } from 'react';
import {
  ILibrariesPanelClasses,
  ILibrariesPanelHandlers,
} from '../iManageLibraries';

const LibrariesPanel = () => {
  const {
    activePrivateLibraries,
    activeStandardLibraries,
    setActiveStandardLibraries,
  } = useContext<ILibraryContext | undefined>(LibraryContext)!;
  const { user, setUser } = useContext<IUserContext | undefined>(UserContext)!;
  const { setCompanyLibraries } = useContext<ICompanyContext | undefined>(
    CompanyContext
  )!;

  // Component classes
  const classes: ILibrariesPanelClasses = {
    container:
      'flex flex-col h-1/3 w-full border border-stone-300 bg-stone-100 overflow-y-auto sm:h-full',
    libraries: 'h-full',
    title: 'bg-slate-200 text-stone-600 font-semibold p-2',
  };

  // Isolates library names
  const privateLibraryNames: string[] = Object.keys(activePrivateLibraries);
  const standardLibraryNames: string[] = Object.keys(activeStandardLibraries);

  // Library selection behaviour
  const handlers: ILibrariesPanelHandlers = {
    privateLib: (name: string): void =>
      user.library[name]
        ? setUser((prev: IUser) => ({
            ...prev,
            library: {
              ...prev.library,
              [name]: !prev.library[name],
            },
          }))
        : setCompanyLibraries((prev: ICompany) => ({
            ...prev,
            [name]: !prev[name],
          })),
    standardLib: (name: string): void =>
      setActiveStandardLibraries((prev: any) => ({
        ...prev,
        [name]: !prev[name as keyof typeof prev],
      })),
  };

  return (
    <div className={classes.container}>
      <div className={classes.libraries}>
        <h3 className={classes.title}>Private Libraries</h3>
        <LibrariesList
          activeLibrary={activePrivateLibraries}
          library={privateLibraryNames}
          handler={handlers.privateLib}
        />
        <h3 className={classes.title}>Standard Libraries</h3>
        <LibrariesList
          activeLibrary={activeStandardLibraries}
          library={standardLibraryNames}
          handler={handlers.standardLib}
        />
      </div>
    </div>
  );
};

export default LibrariesPanel;
