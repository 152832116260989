import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { useMemo } from 'react';

const PDFImageBlock = ({ content }) => {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          width: '100%',
        },
        image: {
          width: content.size.w,
          height: content.size.h,
        },
        text: { fontSize: 12, textAlign: 'center' },
      }),
    [content.size]
  );

  return (
    <View style={styles.container}>
      <Image
        style={styles.image}
        src={content.image}
      />
      <Text style={styles.text}>{content.label}</Text>
    </View>
  );
};

export default PDFImageBlock;
