import { ISaveBoxProps } from 'iApp';

const SaveBox = ({ classes, clickHandlers, disabled }: ISaveBoxProps) => {
  return (
    <div className={classes}>
      <button
        className='border rounded-md py-1 px-3 text-stone-400 bg-stone-100 transition ease-in-out delay-100 hover:bg-stone-200'
        onClick={clickHandlers!.cancel}>
        Cancel
      </button>
      <button
        className='rounded-md py-1 px-3 border border-red-700 text-red-700 transition ease-in-out delay-100 hover:bg-red-100 disabled:text-gray-400 disabled:border-gray-400 disabled:bg-stone-200'
        disabled={disabled}
        onClick={clickHandlers!.save}>
        Save
      </button>
    </div>
  );
};

export default SaveBox;
