import { IArrowClass, IMenuArrowProps } from './iHeader';

const MenuArrow = ({ name }: IMenuArrowProps) => {
  // Component classes
  const arrowClasses: IArrowClass = {
    siteType: '-mr-1 ml-2 pt-1 h-5 w-5',
    account: '-mr-1 ml-2 h-5 w-5 pt-1 hidden md:inline-flex',
  };

  const arrowClass: string = arrowClasses[name as keyof typeof arrowClasses];

  return (
    <svg
      aria-hidden='true'
      fill='currentColor'
      viewBox='0 0 20 20'
      className={arrowClass}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        d='M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z'
        clipRule='evenodd'
      />
    </svg>
  );
};

export default MenuArrow;