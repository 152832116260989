import { Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { IPDFContents } from '../iDocumentEditor';
import PDFFooter from '../headerFooter/PDFFooter';
import PDFHeader from '../headerFooter/PDFHeader';

Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
  ],
});

const PDFHeightVerification = ({ contents }: IPDFContents) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      padding: '0.5in',
      flexWrap: 'wrap',
      width: '100%',
      height: '90%',
    },
    header: {
      width: '100%',
      fontWeight: 600,
      textDecoration: 'underline',
      fontSize: '15px',
      textAlign: 'center',
      marginBottom: '10px',
      fontFamily: 'Open Sans',
    },
    container: {
      flexDirection: 'column',
      width: '100%',
      minHeight: '50%',
    },
    title: {
      fontWeight: 'bold',
      textDecoration: 'underline',
      fontSize: '12px',
      marginBottom: '20px',
    },
    deficiencies: {
      flexDirection: 'column',
      width: '100%',
      height: '95%',
      alignContent: 'center',
      alignItems: 'center',
      marginTop: '20px',
    },
    section: {
      flexDirection: 'row',
      width: '100%',
      marginBottom: 6,
    },
    label: {
      display: 'flex',
      flexDirection: 'column',
      border: 'solid',
      borderWidth: '1px',
      width: '50%',
      fontSize: '9px',
      padding: 4,
    },
    input: {
      display: 'flex',
      flexDirection: 'column',
      border: 'solid',
      borderWidth: '1px',
      width: '50%',
      textAlign: 'center',
      alignItems: 'center',
      fontSize: '9px',
      padding: 4,
    },
    row: {
      flexDirection: 'row',
      width: '100%',
    },
    column: {
      flexDirection: 'column',
      width: '100%',
    },
  });

  return (
    <Page
      size='A4'
      style={styles.page}>
      <View style={styles.deficiencies}>
        {contents.header.active && (
          <PDFHeader contents={contents.header.contents} />
        )}
        <Text style={styles.header}>Height Verification</Text>
        <View style={styles.container}>
          <View style={styles.section}>
            <Text style={styles.label}>METHOD OF MEASUREMENT</Text>
            <Text style={styles.input}>{contents.height.content.method}</Text>
          </View>
          <View style={styles.section}>
            <View style={styles.column}>
              <View style={styles.row}>
                <View style={styles.label}>
                  <Text>TOTAL HEIGHT = FOUNDATION + STEEL</Text>
                  <Text>+ APPURTENANCE</Text>
                </View>
                <Text style={styles.input}>
                  {contents.height.content.totalHeight}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>HEIGHT with APPURTENANCE</Text>
                <Text style={styles.input}>
                  {contents.height.content.withAppurtenance}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>HEIGHT of APPURTENANCE</Text>
                <Text style={styles.input}>
                  {contents.height.content.appurtenance}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>HEIGHT of STRUCTURE</Text>
                <Text style={styles.input}>
                  {contents.height.content.structure}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>HEIGHT of FOUNDATION</Text>
                <Text style={styles.input}>
                  {contents.height.content.foundation}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>HEIGHT of INSTALLED ANTENNA</Text>
                <Text style={styles.input}>
                  {contents.height.content.antenna}
                </Text>
              </View>
              <View style={styles.row}>
                <Text style={styles.label}>
                  TOWER EXTENSION (IF APPLICABLE)
                </Text>
                <Text style={styles.input}>
                  {contents.height.content.extension}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.column}>
            <View style={styles.row}>
              <Text style={styles.label}>TOP of TOWER</Text>
              <Text style={styles.input}>
                {contents.height.content.towerTop}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>TOP of LIGHTNING ROD</Text>
              <Text style={styles.input}>{contents.height.content.rodTop}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>TOP of TOWER LIGHTING</Text>
              <Text style={styles.input}>
                {contents.height.content.lightingTop}
              </Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>TOP of CONCRETE</Text>
              <Text style={styles.input}>
                {contents.height.content.concreteTop}
              </Text>
            </View>
          </View>
        </View>
      </View>
      {contents.footer.active && (
        <PDFFooter contents={contents.footer.contents} />
      )}
    </Page>
  );
};

export default PDFHeightVerification;
