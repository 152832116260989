import { IEditImageClasses, IEditImageProps } from './iEditImage';
import { IEditContext } from 'state/iContext';
import { EditContext } from 'state/context';
import { useContext } from 'react';

const EditImage = () => {
  const { openImagePanel, editProps }  = useContext<IEditContext | undefined>(EditContext)!;

  // Component classes
  const classes: IEditImageClasses = {
    main: 'relative',
    imgClass: {
      active:
        'rounded-full w-[80px] h-[80px] m-3 object-fill self-center text-center border border-stone-300',
      inactive:
        'rounded-full w-[80px] h-[80px] m-3 object-fill self-center text-center border border-stone-300 border border-red-600',
    },
    iconContainer:
      'absolute top-[35px] left-[38px] w-[25px] rounded-full text-center',
    iconClass:
      'fa-solid z-50 fa-camera text-2xl text-stone-300 opacity-60 cursor-pointer hover:opacity-100',
  };

  // Props to pass to their respective elements
  const props: IEditImageProps = {
    img: {
      src: editProps.img,
      className:
        editProps.img ===
        '/static/media/map-pin-red.75805ec349a5d0eebaa8487ebefdc2fb.svg'
          ? classes.imgClass.inactive
          : classes.imgClass.active,
    },
    icon: {
      className: classes.iconClass,
      onClick: () => openImagePanel(),
    },
  };

  return (
    <div
      id='edit-image'
      className={classes.main}>
      <img
        alt='thumbail preview'
        {...props.img}
      />
      <div className={classes.iconContainer}>
        <i {...props.icon} />
      </div>
    </div>
  );
};

export default EditImage;
