import {
  isInfoLong,
  infoObjectToString,
  inspectionAlert,
} from 'helpers/helperFunctions';
import { IInfoWindowLine, ISiteInfoClasses } from './iInfoWindow';

const InfoWindowLine = ({ title, info, classes }: IInfoWindowLine) => {
  const { container, label, labelTitle, position, details } =
    classes as ISiteInfoClasses;

  const isDate: boolean = typeof info === 'number' && info > 180;
  const short: boolean = infoObjectToString(info).toString().length <= 20;
  const addAlertforDate: string = isDate
    ? isInfoLong(details, short) + ' ' + inspectionAlert(info)
    : isInfoLong(details, short);
  const convertIfDate: string = isDate
    ? new Date(info).toUTCString().slice(4, 16)
    : info;

  return (
    <div className={isInfoLong(container, short)}>
      <div className={label}>
        <div className={labelTitle}>{title}</div>:
      </div>
      <div className={isInfoLong(position, short)}>
        <div className={addAlertforDate}>{convertIfDate}</div>
      </div>
    </div>
  );
};

export default InfoWindowLine;
