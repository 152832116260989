import { ICustomIconClasses, ICustomIconProps } from './iCustomIcon';
import { ILibraryContext, IModalContext } from 'state/iContext';
import { LibraryContext, ModalContext } from 'state/context';
import { useContext, useState, useEffect } from 'react';
import { customIconList } from 'state/developmentData';
import PropertiesStage from './stages/PropertiesStage';
import { ILibrary, ILibraryIcon } from 'state/iState';
import CustomIconButtons from './CustomIconButtons';
import SaveToLibrary from './stages/SaveToLibrary';
import StageProgress from './stages/StageProgress';
import PortStage from './stages/ports/PortsStage';
import ImageStage from './stages/ImageStage';
import ModalHeader from '../ModalHeader';
import { IInputEvent } from 'iApp';

const CustomIcon = () => {
  const {
    iconIndex,
    libraries,
    activeIcon,
    setLibraries,
    setActiveIcon,
    activeLibrary,
  }  = useContext<ILibraryContext | undefined>(LibraryContext)!;
  const {
    editIcon,
    clearModal,
    setEditIcon,
    clearEditIcon,
    setActiveModal,
    customIconStage: stage,
    setCustomIconStage: setStage,
  } = useContext<IModalContext | undefined>(ModalContext)!;

  const [saveToLibrary, setSaveToLibrary] = useState<string>('');

  const classes: ICustomIconClasses = {
    container:
      'relative flex flex-col w-[90%] h-[80%] z-50 bg-white border border-stone-300 rounded-md md:w-[60%] items-center p-3',
    iconName: {
      container:
        'flex w-full mt-3 p-2 bg-stone-200 border border-stone-300 rounded rouned-md justify-center items-center',
      input: 'w-2/3 h-2/3 ml-4 required:border-red-500 valid:border-stone-300',
    },
  };

  useEffect(
    () =>
      setEditIcon((prev: ILibraryIcon) => ({
        ...prev,
        img: activeLibrary
          ? libraries[activeLibrary][iconIndex].img
          : customIconList[activeIcon],
      })),
    [activeIcon, setEditIcon, activeLibrary, iconIndex, libraries]
  );

  const props: ICustomIconProps = {
    input: {
      type: 'text',
      name: 'name',
      id: 'create-icon-name',
      defaultValue: editIcon.id,
      className: classes.iconName.input,
      required: true,
      onChange: (e: IInputEvent) =>
        setEditIcon((prev: ILibraryIcon) => ({
          ...prev,
          id: e.target.value,
        })),
    },
    image: {
      editIcon,
      setEditIcon,
      setStage,
    },
    portProperties: {
      editIcon,
      setEditIcon,
    },
    save: {
      handlers: {
        setSaveToLibrary,
        saveBox: {
          save: () => {
            if (saveToLibrary !== '') {
              setLibraries((prev: ILibrary) => ({
                ...prev,
                [saveToLibrary]: {
                  ...prev[saveToLibrary],
                  [editIcon.id]: editIcon,
                },
              }));
              setSaveToLibrary('');
              setStage(1);
              setEditIcon(clearEditIcon);
              setActiveIcon('');
              setActiveModal(clearModal);
            }
          },
          cancel: () => {
            setSaveToLibrary('');
            setStage(3);
          },
        },
      },
      saveToLibrary,
    },
    buttons: {
      stage,
      setStage,
      editIcon,
    },
  };

  return (
    <div className={classes.container}>
      <ModalHeader name={'CREATE CUSTOM ICON'} />
      <div className={classes.iconName.container}>
        <label htmlFor='name'>Icon Name:</label>
        <input {...props.input} />
      </div>
      <StageProgress stage={stage} />
      {stage === 1 && <ImageStage {...props.image} />}
      {stage === 2 && <PortStage {...props.portProperties} />}
      {stage >= 3 && <PropertiesStage {...props.portProperties} />}
      {stage === 4 && <SaveToLibrary {...props.save} />}
      <CustomIconButtons {...props.buttons} />
    </div>
  );
};

export default CustomIcon;
