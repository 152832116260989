import { ILibraryIcon, IPortBlock } from 'state/iState';
import { ReactNode, useContext, useMemo } from 'react';
import { ICanvasContext } from 'state/iContext';
import { CanvasContext } from 'state/context';
import BlockResize from './BlockResize';
import {
  IResizeAddPortClasses,
  IResizeAddPortsProps,
  IResizeAddPorts,
} from './iPorts';

const ResizeAddPorts = ({ ports, editPort, handlers }: IResizeAddPorts) => {
  const { setPortDetails, emptyPortBlock } = useContext<
    ICanvasContext | undefined
    >(CanvasContext)!;
  
  const { setEditPort, setEditIcon } = handlers;

  const classes: IResizeAddPortClasses = {
    container: 'flex flex-col w-full h-1/2  md:w-1/2 md:h-full md:mr-2',
    title: 'font-bold my-3',
    addPorts:
      'flex flex-col h-full pt-4 border border-stone-300 rounded-sm bg-slate-100',
    resize: 'flex flex-col h-1/3 md:h-1/6 justify-around items-center md:pt-5',
    grid: {
      container: 'flex w-full h-4/5 justify-center items-center',
      ports: `grid grid-cols-${ports.w} grid-rows-${ports.h}`,
      port: {
        active:
          'w-[15px] h-[15px] md:w-[20px] md:h-[20px] border border-stone-400 bg-red-600 transition ease-in-out delay-100 hover:bg-red-700',
        inactive:
          'w-[15px] h-[15px] md:w-[20px] md:h-[20px] border border-stone-400 bg-stone-300 transition ease-in-out delay-100 hover:bg-red-600 hover:border-slate-400',
      },
    },
  };

  const activePortDetails: IPortBlock =
    // ports.blocks![editPort]
    // ? ports.blocks![editPort]:
    emptyPortBlock;

  const gridSize: ReactNode[] = useMemo(() => {
    const grid: ReactNode[] = [];
    for (let ww = 1; ww <= ports.w; ww++) {
      for (let hh = 1; hh <= ports.h; hh++) {
        const id: string = `port-${ww}-${hh}`;
        const activeClass: string =
          editPort === id
            ? classes.grid.port.active
            : classes.grid.port.inactive;
        grid.push(
          <div
            id={id}
            key={id}
            onClick={() => {
              setEditPort(id);
              setPortDetails(activePortDetails);
            }}
            className={activeClass}
          />
        );
      }
    }
    return grid;
  }, [
    ports.h,
    ports.w,
    editPort,
    setEditPort,
    classes.grid,
    setPortDetails,
    activePortDetails,
  ]);

  const resizeProps: IResizeAddPortsProps = {
    width: {
      name: 'Width',
      handlers: {
        plus: () => {
          if (ports.w < 12) {
            setEditIcon((prev: ILibraryIcon) => ({
              ...prev,
              ports: { ...prev.ports, w: ++prev.ports.w },
            }));
          }
        },
        minus: () => {
          if (ports.w > 1) {
            setEditIcon((prev: ILibraryIcon) => ({
              ...prev,
              ports: { ...prev.ports, w: --prev.ports.w },
            }));
          }
        },
      },
      ports: ports.w,
    },
    height: {
      name: 'Height',
      handlers: {
        plus: () => {
          if (ports.h < 12) {
            setEditIcon((prev: ILibraryIcon) => ({
              ...prev,
              ports: { ...prev.ports, h: ++prev.ports.h },
            }));
          }
        },
        minus: () => {
          if (ports.h > 1) {
            setEditIcon((prev: ILibraryIcon) => ({
              ...prev,
              ports: { ...prev.ports, h: --prev.ports.h },
            }));
          }
        },
      },
      ports: ports.h,
    },
  };

  return (
    <div className={classes.container}>
      <div className={classes.title}>Resize & Add Ports</div>
      <div className={classes.addPorts}>
        <div className={classes.resize}>
          <BlockResize {...resizeProps.width} />
          <BlockResize {...resizeProps.height} />
        </div>
        <div className={classes.grid.container}>
          <div className={classes.grid.ports}>{gridSize}</div>
        </div>
      </div>
    </div>
  );
};

export default ResizeAddPorts;
