import PDFInspectionChecklist from './inspectionChecklist/PDFInspectionChecklist';
import PDFTableOfContents from './tableOfContents/PDFTableOfContents';
import PDFHeightVerification from './height/PDFHeightVerification';
import PDFDeficiencies from './deficiencies/PDFDeficiencies';
import PDFVerticality from './verticality/PDFVerticality';
import PDFGuyInspection from './guy/PDFGuyInspection';
import PDFTowerDetails from './tower/PDFTowerDetails';
import PDFInventory from './inventory/PDFInventory';
import { IPDFDocument } from './iDocumentEditor';
import { Document } from '@react-pdf/renderer';
import PDFImages from './images/PDFImages';

const PDFDocument = ({ contents, info, activeTowerDetails }: IPDFDocument) => {
  const pages = {
    tower: (
      <PDFTowerDetails
        activeTowerDetails={activeTowerDetails}
        contents={contents}
        key='tower-details'
        info={info}
      />
    ),
    table: (
      <PDFTableOfContents
        contents={contents}
        key='table'
      />
    ),
    inventory: (
      <PDFInventory
        contents={contents}
        key='inventory'
        info={info}
      />
    ),
    checklist: (
      <PDFInspectionChecklist
        contents={contents}
        key='checklist'
      />
    ),
    deficiencies: (
      <PDFDeficiencies
        contents={contents}
        key='deficiencies'
      />
    ),
    guy: (
      <PDFGuyInspection
        contents={contents}
        key='guy'
      />
    ),
    verticality: (
      <PDFVerticality
        contents={contents}
        key='verticality'
      />
    ),
    height: (
      <PDFHeightVerification
        contents={contents}
        key='height'
      />
    ),
    images: (
      <PDFImages
        contents={contents}
        key='images'
      />
    ),
  };

  const renderdPages = contents.pages.map((page: string) => pages[page]);

  return <Document title={contents.name}>{renderdPages}</Document>;
};

export default PDFDocument;
