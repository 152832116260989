import { kebabString } from 'helpers/helperFunctions';
import DataToolBarIcons from './DataToolbarIcons';
import { ICanvasContext } from 'state/iContext';
import { IActiveToolBar } from 'state/iState';
import { CanvasContext } from 'state/context';
import { IStringProps } from 'iApp';
import { useContext } from 'react';

const ToolBarRight = () => {
  const { activeToolBar, setActiveToolBar } = useContext<
    ICanvasContext | undefined
  >(CanvasContext)!;

  const { visible, nonvisible, history } = DataToolBarIcons.right;

  const iconList: string[] = [
    'Properties',
    // 'Ports',
    'Lease',
    'Grid',
    // 'Link Style',
    // 'history',
  ];

  const classes: IStringProps = {
    container: 'flex flex-wrap justify-center w-auto max-w-1/2',
    active:
      'min-w-[67px] h-[28px] flex justify-center text-center items-center border border-stone-300 p-0.5 bg-red-700 text-slate-200 cursor-pointer transition ease-in-out delay-100 hover:bg-red-800',
    inactive:
      'min-w-[67px] h-[28px] flex justify-center text-center items-center bg-stone-100 border border-stone-300 p-0.5 cursor-pointer transition ease-in-out delay-100 hover:bg-red-700 hover:text-slate-200',
  };

  const buttons: JSX.Element[] = iconList.map((icon): JSX.Element => {
    const id: string = `data-${kebabString(icon)}-button`;
    const activeClass: string = activeToolBar[
      icon as keyof typeof activeToolBar
    ]
      ? classes.active
      : classes.inactive;
    const eyeIcon: JSX.Element = activeToolBar[
      icon as keyof typeof activeToolBar
    ]
      ? visible
      : nonvisible;
    const buttonIcon: JSX.Element = icon === 'history' ? history : eyeIcon;

    const clickHandler = (icon: string) => {
      const handlers = {
        Properties: (prev: IActiveToolBar) => ({
          ...prev,
          Properties: !prev.Properties,
        }),
        Ports: (prev: IActiveToolBar) => ({
          ...prev,
          Ports: !prev.Ports,
        }),
        Lease: (prev: IActiveToolBar) => ({
          ...prev,
          Lease: !prev.Lease,
        }),
        Grid: (prev: IActiveToolBar) => ({
          ...prev,
          Grid: !prev.Grid,
        }),
        'Link Style': (prev: IActiveToolBar) => ({
          ...prev,
          'Link Style': !prev['Link Style'],
        }),
        history: (prev: IActiveToolBar) => ({
          ...prev,
          history: !prev.history,
          select: prev.history,
          hand: false,
          image: false,
          text: false,
          textPosition: false,
        }),
      };
      setActiveToolBar(handlers[icon as keyof typeof handlers]);
    };

    return (
      <div
        id={id}
        key={icon}
        className={activeClass}
        onClick={() => clickHandler(icon)}>
        {buttonIcon} {icon !== 'history' && icon}
      </div>
    );
  });

  return (
    <div
      id='data-toolbar-right'
      className={classes.container}>
      {buttons}
    </div>
  );
};

export default ToolBarRight;
