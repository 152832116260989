import { IApplicationTabIcons } from '../iHeader';

// Application tab icons
export const ApplicationTabIcons: IApplicationTabIcons = {
  tower: <i className='fa-solid fa-tower-cell text-base pb-1 m-1' />,
  site: <i className='fa-solid fa-map-location-dot text-base pb-1 m-1' />,
  drawings: (
    <i className='fa-solid fa-compass-drafting flex flex-col w-auto max-h-[20px] justify-center items-center text-center text-base pb-1' />
  ),
  data: (
    <>
      <i className='fa-solid fa-bars-progress text-xs p-0 -m-1' />
      <i className='fa-solid fa-bars-progress text-xs pb-1 -m-1' />
    </>
  ),
  files: <i className='fa-solid fa-file-invoice text-base pb-1 m-1' />,
};
