import { IButtonProps, ISaveHandlers } from '../../../iApp';
import { isSaveValid } from '../../../helpers/editHelpers';
import { IEditContext } from '../../../state/iContext';
import { IEditProps } from '../editPanel/iEdit';
import { ChangeEvent } from 'react';

const editSaveHandlers = (
  {
    openImagePanel,
    openEditPanel,
    editTower,
    setEditProps,
    setNewSite,
  }: IEditContext,
  image: boolean = false,
  metric?: boolean
): ISaveHandlers => {
  const save = (name: string, props: IEditProps): IButtonProps => {
    if (isSaveValid(props, metric!)) {
      const handler: () => void = image
        ? () => {
            openImagePanel();
          }
        : () => {
            editTower(props);
            setNewSite(false);
            openEditPanel({});
          };
      return {
        name,
        handler,
      };
    } else {
      return {
        name,
        handler: (e: ChangeEvent<HTMLInputElement>) => e.preventDefault(),
      };
    }
  };

  const cancel = (props?: IEditProps): IButtonProps => {
    const handler: () => void = image
      ? () => openImagePanel()
      : () => {
          if (props) {
            setEditProps(props);
          }
          setNewSite(false);
          openEditPanel({});
        };
    return {
      name: 'CANCEL',
      handler,
    };
  };
  return { save, cancel };
};

export default editSaveHandlers;
