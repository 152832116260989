import { ICanvasContext } from 'state/iContext';
import { CanvasContext } from 'state/context';
import { ILibraryIcon } from 'state/iState';
import { ICallbackProps } from 'iApp';
import { useContext } from 'react';

const DragHandle = () => {
  const { setDragProps } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;

  const handler: ICallbackProps = {
    down: () =>
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: true,
      })),
    up: () =>
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
      })),
  };

  return (
    <>
      <i
        onMouseUp={handler.up}
        onTouchEnd={handler.up}
        onMouseDown={handler.down}
        onTouchStart={handler.down}
        className='dragHandle absolute -top-2 -right-1 fa-solid fa-border-top-left origin-center rotate-90 opacity-30 text-xs cursor-move hover:text-red-600 hover:opacity-80'
      />
    </>
  );
};

export default DragHandle;
