import TowerDistance from './TowerDistance';
import LocateMe from './LocateMe';
import { EditContext } from 'state/context';
import { useContext } from 'react';
import { IEditContext } from 'state/iContext';

const MapButtons = () => {
  const { isEditPanelOpen } = useContext<IEditContext | undefined>(
    EditContext
  )!;

  return (
    <div className='absolute right-0 top-0 flex'>
      <LocateMe />
      {!isEditPanelOpen && <TowerDistance />}
    </div>
  );
};

export default MapButtons;
