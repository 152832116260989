import { IActiveTab, ITabControl } from './iSettings';
import SettingsCard from './SettingsCard';
import SettingsTabs from './SettingsTab';
import { useState } from 'react';

const AccountSettings = () => {
  // Active Tab State
  const [activeTab, setActiveTab] = useState<IActiveTab>({
    account: true,
    settings: false,
  });

  const tabControl: ITabControl = {
    activeTab,
    setActiveTab,
  };

  return (
    <div className='flex flex-col md:flex-row w-full h-full pb-10 overflow-y-auto border-t border-stone-400'>
      <SettingsTabs {...tabControl} />
      <SettingsCard {...activeTab} />
    </div>
  );
};

export default AccountSettings;
