import { useMemo, useState, ReactNode, useEffect } from 'react';
import { IIconProperty, ILibraryIcon } from 'state/iState';
import Button from 'components/buttons/Button';
import { IInputEvent } from 'iApp';
import {
  IPropertiesStage,
  IPropertiesStageProps,
  IPropertiesStageClasses,
} from '../iCustomIcon';

const PropertiesStage = ({ editIcon, setEditIcon }: IPropertiesStage) => {
  const clearProperty: IIconProperty = useMemo(
    () => ({
      name: '',
      value: '',
    }),
    []
  );
  const [property, setProperty] = useState<IIconProperty>(clearProperty);

  const classes: IPropertiesStageClasses = {
    container: 'w-full h-[80%] mt-3',
    quoteParts: {
      container:
        'flex w-full px-3 bg-stone-200 border border-stone-300 items-center justify-center rounded rouned-sm',
      lock: 'fa-solid fa-lock m-2',
      info: 'fa-solid fa-circle-info',
      input: 'w-2/3 h-[25px] m-3',
    },
    properties: {
      container:
        'flex flex-col w-full h-2/3 mt-3 border border-stone-300 rounded rouned-sm justify-center',
      listItem: 'flex',
      infoBox: 'flex w-full',
      name: 'w-1/3 px-1 border border-slate-200 truncate',
      value: 'w-2/3 px-1 border border-r-0 border-slate-200 truncate',
      remove:
        'flex w-[30px] justify-center items-center text-stone-500 border-b border-b-slate-200 cursor-pointer',
      icon: 'fa-regular fa-trash-can hover:text-red-600',
    },
    placeholder: 'text-stone-300 text-center font-medium',
    list: 'flex flex-col w-full h-full justify-start',
    form: {
      container: 'flex w-full mt-3 items-start justify-between items-center',
      input:
        'h-[25px] w-1/2 mr-3 border border-stone-300 placeholder:text-slate-400',
    },
  };

  const properties: ReactNode[] = useMemo(
    () =>
      editIcon.properties.map(
        (prop: IIconProperty, i: number): ReactNode => (
          <div
            key={i}
            className={classes.properties.listItem}>
            <div className={classes.properties.infoBox}>
              <div className={classes.properties.name}>{prop.name}</div>
              <div className={classes.properties.value}>{prop.value}</div>
            </div>
            <div className={classes.properties.remove}>
              <i
                onClick={() => {
                  const updatedProperties = [
                    ...editIcon.properties.slice(0, i),
                    ...editIcon.properties.slice(i + 1),
                  ];
                  setEditIcon((prev: ILibraryIcon) => ({
                    ...prev,
                    properties: updatedProperties,
                  }));
                }}
                className={classes.properties.icon}
              />
            </div>
          </div>
        )
      ),
    [editIcon.properties, setEditIcon, classes.properties]
  );

  const props: IPropertiesStageProps = {
    name: {
      type: 'text',
      name: 'name',
      defaultValue: property.name,
      onChange: (e: IInputEvent) =>
        setProperty((prev: IIconProperty) => ({
          ...prev,
          name: e.target.value.trim(),
        })),
      className: classes.form.input,
      placeholder: 'Property Name',
    },
    value: {
      type: 'text',
      name: 'value',
      defaultValue: property.value,
      onChange: (e: IInputEvent) =>
        setProperty((prev: IIconProperty) => ({
          ...prev,
          value: e.target.value.trim(),
        })),
      className: classes.form.input,
      placeholder: 'Default Value',
    },
    button: {
      type: 'reset',
      name: 'ADD',
      handler: () => {
        setEditIcon((prev: ILibraryIcon) => ({
          ...prev,
          properties: [...prev.properties, property],
        }));
        setProperty({
          name: ' ',
          value: ' ',
        });
      },
      disabled: !property.name || !property.value,
    },
  };

  useEffect(() => {
    if (property.value === ' ' && property.value === ' ') {
      setProperty(clearProperty);
    }
  }, [property, clearProperty]);

  return (
    <div className={classes.container}>
      {/* <div className={classes.quoteParts.container}>
        <i className={classes.quoteParts.lock} />
        <label htmlFor='quote'>Quote Parts</label>
        <input
          type='text'
          name='quote'
          className={classes.quoteParts.input}
        />
        <i className={classes.quoteParts.info} />
      </div> */}
      <div className={classes.properties.container}>
        {editIcon.properties.length === 0 ? (
          <span className={classes.placeholder}>
            Adding properties to your icon will allow you to store product &
            configuration data. (Model Number, IP Address, Serial Number,
            etc...)
          </span>
        ) : (
          <div className={classes.list}>{properties}</div>
        )}
      </div>
      <form className={classes.form.container}>
        <input {...props.name} />
        <input {...props.value} />
        <Button {...props.button} />
      </form>
    </div>
  );
};

export default PropertiesStage;
