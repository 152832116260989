import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { IVerticalityMast } from 'state/iState';

const PDFVerticalityMastLine = ({
  elevation,
  width,
  legSize,
}: IVerticalityMast) => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      border: 'solid',
      borderWidth: '1px',
      width: '100%',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      border: 'solid',
      borderWidth: '1px',
      width: '33.33%',
      textAlign: 'center',
      fontSize: '9px',
      padding: 4,
    },
  });

  return (
    <View style={styles.container}>
      <Text style={styles.column}>{elevation}</Text>
      <Text style={styles.column}>{width}</Text>
      <Text style={styles.column}>{legSize}</Text>
    </View>
  );
};

export default PDFVerticalityMastLine;
