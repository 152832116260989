import { IStringProps } from "iApp";

const ImagePreview = ({ img }: IStringProps) => {
  return (
    <>
      <div className='font-bold my-3'>Image Preview</div>
      <div className='flex h-full border border-stone-300 rounded-sm bg-slate-100 justify-center items-center'>
        <img
          src={img}
          alt='icon'
          className='w-[50px] h-[50px]'
        />
      </div>
    </>
  );
};

export default ImagePreview;
