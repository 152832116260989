import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { IVerticality, IVerticalityTwistPlumb } from 'state/iState';

const PDFVerticalityTwistPlumbLine = ({
  measured,
  allowable,
  relativeBetween,
  allowableBetween,
  limit,
}: IVerticalityTwistPlumb) => {
  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      border: 'solid',
      borderWidth: '1px',
      width: '100%',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      border: 'solid',
      borderWidth: '1px',
      width: '20%',
      textAlign: 'center',
      fontSize: '9px',
      padding: 4,
    },
  });

  return (
    <View style={styles.container}>
      <Text style={styles.column}>{measured}</Text>
      <Text style={styles.column}>{allowable}</Text>
      <Text style={styles.column}>{relativeBetween}</Text>
      <Text style={styles.column}>{allowableBetween}</Text>
      <Text style={styles.column}>{limit ? 'TRUE' : 'FAIL'}</Text>
    </View>
  );
};

export default PDFVerticalityTwistPlumbLine;
