const DataToolBarIcons = {
  left: {
    select: <i className='fa-solid fa-arrow-pointer' />,
    // hand: <i className='fa-regular fa-hand' />,
    // move: <i className='fa-solid fa-up-down-left-right' />,
    image: <i className='fa-regular fa-image' />,
    text: <i className='fa-solid fa-font' />,
    drawings: <i className='fa-solid fa-compass-drafting' />,
    sections: <i className="fa-solid fa-vector-square"/>,
    edit: <i className='fa-solid fa-wrench' />,
    // textPosition: (
    //   <>
    //     <i className='absolute left-[7px] -top-[1px] text-lg fa-solid fa-expand scale-125' />
    //     <i className='fa-solid fa-font text-xs self-center w-full' />
    //   </>
    // ),
    // undo: <i className='fa-solid fa-arrow-rotate-left' />,
    // redo: <i className='fa-solid fa-arrow-rotate-right' />,
    zoomIn: <i className='fa-solid fa-magnifying-glass-minus' />,
    zoomOut: <i className='fa-solid fa-magnifying-glass-plus' />,
    resetTransform: <i className='fa-solid fa-minimize' />,
  },
  right: {
    visible: <i className='fa-solid fa-eye mx-1' />,
    nonvisible: <i className='fa-solid fa-eye-slash mx-1' />,
    history: <i className='fa-solid fa-clock-rotate-left' />,
  },
};

export default DataToolBarIcons;
