import TowerFiles from 'components/application/canvas/views/files/TowerFiles';
import Drawings from 'components/application/canvas/views/drawings/Drawings';
import AccountSettings from './components/header/settings/AccountSettings';
import { IHeaderContext, IModalContext, IUserContext } from 'state/iContext';
import { HeaderContext, ModalContext, UserContext } from 'state/context';
import Canvas from 'components/application/canvas/Canvas';
import Modal from 'components/application/modals/Modal';
import { useAuth0 } from '@auth0/auth0-react';
import Header from 'components/header/Header';
import { useContext, useEffect } from 'react';
import Home from 'components/home/Home';
import 'styles/main.css';
import Login from 'Login';

const App = () => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  const { isModalActive } = useContext<IModalContext | undefined>(
    ModalContext
  )!;
  const { settings, tower, site, data, drawings, files } = useContext<
    IHeaderContext | undefined
  >(HeaderContext)!.activeHeaderButton;
  const { setSearchBar } = useContext<IUserContext | undefined>(UserContext)!;

  useEffect(() => {
    setSearchBar('');
  }, [tower, site, data, drawings, files]);

  return (
    <div
      id='app'
      className='max-h-screen w-full h-screen m-0 p-0 pb-12 overflow-hidden'>
      {/* {isAuthenticated ? ( */}
        <>
          <Header />
          {!tower && !site && !data && !drawings && !settings && !files && (
            <Home />
          )}
          {settings && <AccountSettings />}
          {drawings && <Drawings />}
          {files && <TowerFiles />}
          {(tower || site || data) && <Canvas />}
          {isModalActive && <Modal />}
        </>
      {/* // ) : (
      //   <Login />
      // )} */}
    </div>
  );
};

export default App;
