import { CanvasContext, SitesContext, SettingsContext } from 'state/context';
import GridTextBoxes from '../../gridItems/items/GridTextBoxes';
import GridBody from '../../gridItems/gridContainers/GridBody';
import GridImages from '../../gridItems/items/GridImages';
import GridLayout, { Layout } from 'react-grid-layout';
import GridLink from '../../gridItems/items/GridLink';
import { ICanvasGridHandlers } from './iCanvasGrid';
import { ResizableBox } from 'react-resizable';
import { IStringProps } from 'iApp';
import {
  ISitesContext,
  ICanvasContext,
  ISettingsContext,
} from 'state/iContext';
import { useRef, useMemo, useState, ReactNode, useContext } from 'react';
import {
  ITowerList,
  IGridLayout,
  ILibraryIcon,
  IGridLayoutProps,
} from 'state/iState';

const TowerGrid = () => {
  const { scale, setDragProps, activeTowerID } = useContext<
    ICanvasContext | undefined
  >(CanvasContext)!;
  const { setTowerList, towerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;
  const { metric } = useContext<ISettingsContext | undefined>(SettingsContext)!;

  const [expandTop, setExpandTop] = useState<boolean>(true);
  const [previousY, setPreviousY] = useState<number | null>(null);

  const classes: IStringProps = {
    container: 'relative ',
    gridItem:
      'peer relative h-full flex flex-col bg-stone-100 -ml-[0.5px] -mt-[0.5px] -pb-[1px] border border-stone-400 justify-center',
  };

  // Sets min/max parameters for tower height
  const minHeight: number = metric ? 15 : 50;
  const maxHeight: number = metric ? 122 : 400;

  const canvasTextBoxes: IGridLayoutProps =
    towerList[activeTowerID].layout.tower.textBoxes;
  const canvasImages: IGridLayoutProps =
    towerList[activeTowerID].layout.tower.images;
  const canvasLinks: IGridLayoutProps =
    towerList[activeTowerID].layout.tower.drawings;
  const allTowerLegs = towerList[activeTowerID].layout.tower.legs;

  const textBoxesList: string[] = Object.keys(canvasTextBoxes);
  const towerLegList: string[] = Object.keys(allTowerLegs);
  const imagesList: string[] = Object.keys(canvasImages);
  const linksList: string[] = Object.keys(canvasLinks);

  const gridRef = useRef(null);

  const legLayout = Array.from(towerLegList, (leg) => {
    const { legGrid } = allTowerLegs[leg];
    return { ...legGrid, maxH: maxHeight, minH: minHeight };
  });

  const layoutOnLoad = useMemo(
    () => [
      {
        i: -1,
        name: 'boundry',
        x: 2000,
        y: 200,
        w: 0,
        h: 0,
        minW: 0,
        minH: 0,
        static: true,
      },
      ...legLayout,
    ],
    [legLayout]
  );

  const updateCanvasLegs = (layout: Layout[]) => {
    let updateLegs: { [key: string]: any } = {};
    let offsetY = 0;
    layout.forEach((leg: Layout, i: number) => {
      const legIndex: string = towerLegList.filter(
        (id) => allTowerLegs[id].legGrid.name === leg.i
      )[0];
      if (i > 0 && i <= towerLegList.length) {
        const { '-1': bottom, ...rest } = allTowerLegs[legIndex].icons;
        const newBottomY = leg.h + offsetY;
        offsetY += leg.h;
        updateLegs[legIndex] = {
          ...allTowerLegs[legIndex],
          legGrid: {
            ...allTowerLegs[legIndex].legGrid,
            h: towerList[activeTowerID].height,
          },
          icons: {
            '-1': {
              ...bottom,
              y: newBottomY,
            },
            ...rest,
          },
        };
      }
    });
    return updateLegs;
  };

  const openGridItemOptions = (layout: IGridLayout, type: string) =>
    setDragProps((prev: ILibraryIcon) => ({
      ...prev,
      dragging: true,
      id: layout.i,
      content: layout.name,
      type,
      target: '',
    }));

  const images: ReactNode[] = imagesList.map((image: string): ReactNode => {
    return (
      <div
        className={classes.gridItem}
        id={canvasImages[image].name}
        key={canvasImages[image].name}
        data-grid={canvasImages[image]}
        onClick={() => openGridItemOptions(canvasImages[image], 'images')}
        onTouchStart={() => openGridItemOptions(canvasImages[image], 'images')}>
        <GridImages layout={canvasImages[image]} />
      </div>
    );
  });

  const textBoxes: ReactNode[] = textBoxesList.map(
    (box: string): ReactNode => (
      <div
        className={classes.gridItem}
        id={canvasTextBoxes[box].name}
        key={canvasTextBoxes[box].name}
        data-grid={canvasTextBoxes[box]}
        onClick={() => openGridItemOptions(canvasTextBoxes[box], 'textBoxes')}
        onTouchStart={() =>
          openGridItemOptions(canvasTextBoxes[box], 'textBoxes')
        }>
        <GridTextBoxes layout={canvasTextBoxes[box]} />
      </div>
    )
  );

  const links: ReactNode[] = linksList.map(
    (link: string): ReactNode => (
      <div
        className='peer relative h-full flex flex-col bg-stone-50 -ml-[0.5px] -mt-[0.5px] pl-2 border border-red-400 justify-center font-bold text-xs text-red-700 rounded rounded-lg'
        id={canvasLinks[link].name}
        key={canvasLinks[link].name}
        data-grid={canvasLinks[link]}
        onClick={() => openGridItemOptions(canvasLinks[link], 'drawings')}
        onTouchStart={() => openGridItemOptions(canvasLinks[link], 'drawings')}>
        <GridLink layout={canvasLinks[link]} />
      </div>
    )
  );

  const towerLegs: ReactNode[] = layoutOnLoad.map((leg, i): ReactNode => {
    if (i > 0 && i <= towerLegList.length) {
      return (
        <div
          id={leg.name}
          key={leg.name}
          data-grid={leg}
          className={classes.gridItem}>
          <ResizableBox
            height={leg.h * 7.55}
            width={leg.w * 7.55}
            className='relative absolute flex'
            resizeHandles={['n', 's']}
            handle={
              <div>
                <i className='fa-solid fa-grip-lines react-resizable-handle-n' />
                <i className='fa-solid fa-grip-lines react-resizable-handle-s' />
              </div>
            }
            onResizeStart={() => {
              setPreviousY(allTowerLegs[i - 1].legGrid.y);
            }}
            onResize={(event) => {
              const { movementY } = event as unknown as MouseEvent;
              const target = event.target as Element;
              setDragProps((prev: ILibraryIcon) => ({
                ...prev,
                dragging: true,
              }));
              if (target.classList.contains('react-resizable-handle-n')) {
                setExpandTop(true);
              }
              if (target.classList.contains('react-resizable-handle-s')) {
                setExpandTop(false);
              }
              setTowerList((prev: ITowerList) => {
                const updatedLegGrid = {
                  ...prev[activeTowerID].layout.tower.legs[i - 1].legGrid,
                  h: expandTop
                    ? prev[activeTowerID].layout.tower.legs[i - 1].legGrid.h -
                      movementY
                    : prev[activeTowerID].layout.tower.legs[i - 1].legGrid.h +
                      movementY,
                  y: expandTop
                    ? prev[activeTowerID].layout.tower.legs[i - 1].legGrid.y! +
                      movementY
                    : prev[activeTowerID].layout.tower.legs[i - 1].legGrid.y,
                };

                return {
                  ...prev,
                  [activeTowerID]: {
                    ...prev[activeTowerID],
                    height: expandTop
                      ? prev[activeTowerID].layout.tower.legs[i - 1].legGrid.h -
                        movementY
                      : prev[activeTowerID].layout.tower.legs[i - 1].legGrid.h +
                        movementY,
                    layout: {
                      ...prev[activeTowerID].layout,
                      tower: {
                        ...prev[activeTowerID].layout.tower,
                        legs: {
                          ...prev[activeTowerID].layout.tower.legs,
                          [i - 1]: {
                            ...prev[activeTowerID].layout.tower.legs[i - 1],
                            legGrid: updatedLegGrid,
                          },
                        },
                      },
                    },
                  },
                };
              });
            }}
            onResizeStop={(event) => {
              const { movementY } = event as unknown as MouseEvent;
              setDragProps((prev: ILibraryIcon) => ({
                ...prev,
                dragging: false,
              }));
              if (
                allTowerLegs[i - 1].legGrid.h < minHeight ||
                allTowerLegs[i - 1].legGrid > maxHeight
              ) {
                setTowerList((prev: ITowerList) => ({
                  ...prev,
                  [activeTowerID]: {
                    ...prev[activeTowerID],
                    height: expandTop
                      ? prev[activeTowerID].layout.tower.legs[i - 1].legGrid.h -
                        movementY
                      : prev[activeTowerID].layout.tower.legs[i - 1].legGrid.h +
                        movementY,
                    layout: {
                      ...prev[activeTowerID].layout,
                      tower: {
                        ...prev[activeTowerID].layout.tower,
                        legs: {
                          ...prev[activeTowerID].layout.tower.legs,
                          [i - 1]: {
                            ...prev[activeTowerID].layout.tower.legs[i - 1],
                            legGrid: {
                              ...prev[activeTowerID].layout.tower.legs[i - 1]
                                .legGrid,
                              h: 50,
                              y: previousY,
                            },
                          },
                        },
                      },
                    },
                  },
                }));
              }
              setPreviousY(null);
            }}>
            <GridBody
              key={leg.name}
              layout={leg}
              type='tower'
            />
          </ResizableBox>
        </div>
      );
    } else {
      return (
        <div
          id={leg.name}
          key={leg.name}
          data-grid={leg}
          className={classes.gridItem}
        />
      );
    }
  });

  const updateTowers = (layout: Layout[]): IGridLayout => {
    const updatedLayout: IGridLayout = { ...allTowerLegs };
    const legIndexMap: Record<string, string> = {};
    towerLegList.forEach((id) => {
      legIndexMap[allTowerLegs[id].legGrid.name] = id;
    });
    for (let i = 1; i <= towerLegList.length; i++) {
      const legIndex = legIndexMap[layout[i].i];
      const leg = updatedLayout[legIndex].legGrid;
      Object.assign(leg, layout[i], {
        i: allTowerLegs[legIndex].legGrid.i,
        name: layout[i].i,
      });
    }
    return updatedLayout;
  };

  const updateTextBoxes = (layout: Layout[]): IGridLayoutProps => {
    const updatedTextBoxes: IGridLayoutProps = {};
    for (let i = 0; i < layout.length; i++) {
      const box = layout[i];
      const textBox = canvasTextBoxes[textBoxesList[i]];
      updatedTextBoxes[i] = {
        ...box,
        i,
        name: box.i,
        text: textBox.text,
        maxH: 30,
        maxW: 30,
        minH: 10,
        minW: 10,
      };
    }
    return updatedTextBoxes;
  };

  const updateImages = (layout: Layout[]) => {
    const updatedImages: Record<string, any> = {};
    for (let i = 0; i < layout.length; i++) {
      const image = layout[i];
      updatedImages[i] = {
        ...image,
        i,
        name: image.i,
        img: canvasImages[imagesList[i]].img,
        maxH: 30,
        maxW: 30,
        minH: 10,
        minW: 10,
      };
    }
    return updatedImages;
  };

  const updateLinks = (layout: Layout[]) => {
    const updatedLinks: Record<string, any> = {};
    for (let i = 0; i < layout.length; i++) {
      const link = layout[i];
      const { i: linkI, name, refID } = canvasLinks[linksList[i]];
      updatedLinks[linkI] = {
        ...link,
        i: linkI,
        name,
        refID,
        isDraggable: true,
        isResizable: true,
      };
    }
    return updatedLinks;
  };

  const handler: ICanvasGridHandlers = {
    onResizeStop: (_, __, next) => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          height: next.h,
        },
      }));
    },
    onLayoutChange: (layout) => {
      setTowerList((prev: ITowerList) => {
        const tower = prev[activeTowerID].layout.tower;
        const updatedTower = {
          ...tower,
          legs: updateCanvasLegs(layout),
          textBoxes: updateTextBoxes(
            layout.slice(
              towerLegList.length + 1,
              towerLegList.length + textBoxesList.length + 1
            )
          ),
          images: updateImages(
            layout.slice(
              towerLegList.length + textBoxesList.length + 1,
              towerLegList.length + textBoxesList.length + imagesList.length + 1
            )
          ),
          drawings: updateLinks(
            layout.slice(
              towerLegList.length + textBoxesList.length + imagesList.length + 1
            )
          ),
        };
        const updatedLayout = {
          ...prev[activeTowerID].layout,
          tower: updatedTower,
        };
        return {
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            layout: updatedLayout,
          },
        };
      });
    },
    onDragStop: (layout) => {
      setTowerList((prev: ITowerList) => {
        const updatedTower = {
          ...prev[activeTowerID].layout.tower,
          legs: updateTowers(layout),
        };
        const updatedLayout = {
          ...prev[activeTowerID].layout,
          tower: updatedTower,
        };
        return {
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            layout: updatedLayout,
          },
        };
      });
    },
    onResize: () => {
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: true,
      }));
      console.log('resize');
    },
  };

  return (
    <div
      id='grid-layout'
      className={classes.container}
      onMouseUp={() =>
        setDragProps((prev: ILibraryIcon) => ({
          ...prev,
          dragging: false,
        }))
      }
      onMouseDown={() =>
        setDragProps((prev: ILibraryIcon) => ({
          ...prev,
          dragging: true,
        }))
      }>
      <GridLayout
        cols={504}
        width={3800}
        margin={[0, 0]}
        autoSize={false}
        rowHeight={7.55}
        innerRef={gridRef}
        isDraggable={true}
        isResizable={true}
        allowOverlap={true}
        className='relative'
        layout={layoutOnLoad}
        transformScale={scale}
        useCSSTransforms={true}
        compactType={'horizontal'}
        draggableHandle='.dragHandle'
        onDragStop={handler.onDragStop}
        onResizeStop={handler.onResizeStop}
        onLayoutChange={handler.onLayoutChange}>
        {towerLegs}
        {textBoxes}
        {images}
        {links}
      </GridLayout>
    </div>
  );
};

export default TowerGrid;
