import { createContext } from 'react';
import {
  ICropContext,
  IDocumentContext,
  IHeaderContext,
  IModalContext,
} from './iContext';
import {
  ICompanyContext,
  IEditContext,
  ILibraryContext,
  IMapContext,
  ICanvasContext,
  ISettingsContext,
  ISitesContext,
  IUserContext,
} from './iContext';

export const SettingsContext = createContext<ISettingsContext | undefined>(
  undefined
);
export const SitesContext = createContext<ISitesContext | undefined>(undefined);
export const EditContext = createContext<IEditContext | undefined>(undefined);
export const MapContext = createContext<IMapContext | undefined>(undefined);
export const HeaderContext = createContext<IHeaderContext | undefined>(
  undefined
);
export const UserContext = createContext<IUserContext | undefined>(undefined);
export const CompanyContext = createContext<ICompanyContext | undefined>(
  undefined
);
export const CanvasContext = createContext<ICanvasContext | undefined>(undefined);
export const LibraryContext = createContext<ILibraryContext | undefined>(
  undefined
);
export const ModalContext = createContext<IModalContext | undefined>(undefined);
export const CropContext = createContext<ICropContext | undefined>(undefined);
export const DocumentContext = createContext<IDocumentContext | undefined>(
  undefined
);
