import { IPDFInspectionChecklistLine } from '../iDocumentEditor';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { useMemo } from 'react';

const PDFInspectionChecklistLine = ({
  info,
  category,
}: IPDFInspectionChecklistLine) => {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        row: {
          flexDirection: 'row',
          width: '100%',
        },
        small: {
          border: 'solid',
          borderWidth: '1px',
          width: '5%',
          textAlign: 'center',
          fontSize: '9px',
          padding: 4,
          height: '100%',
        },
        title: {
          border: 'solid',
          borderWidth: '1px',
          width: '45%',
          fontSize: '9px',
          padding: 4,
          alignItems: 'center',
          height: '100%',
        },
        comments: {
          border: 'solid',
          borderWidth: '1px',
          width: '35%',
          fontSize: '9px',
          padding: 4,
          alignItems: 'center',
          textAlign: 'center',
          height: '100%',
        },
      }),
    []
  );

  return (
    <View style={styles.row}>
      <Text style={styles.small}>{category}</Text>
      <Text style={styles.title}>{info.title}</Text>
      <Text style={styles.small}>{info.grade === 's' && 'X'}</Text>
      <Text style={styles.small}>{info.grade === 'u' && 'X'}</Text>
      <Text style={styles.small}>{info.grade === 'na' && 'X'}</Text>
      <Text style={styles.comments}>{info.comments}</Text>
    </View>
  );
};

export default PDFInspectionChecklistLine;
