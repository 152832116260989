import { Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import { IPDFHeaderFooterContent } from '../iDocumentEditor';
import { ReactNode, useMemo } from 'react';
import { INumberProps } from 'iApp';

const PDFFooter = ({ contents }: IPDFHeaderFooterContent) => {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        container: {
          position: 'absolute',
          bottom: 20,
          left: 0,
          right: 0,
          textAlign: 'center',
          fontSize: '8px',
          justifyContent: 'center',
          paddingHorizontal: 50,
          paddingBottom: '0.15in',
        },
        box: {
          flexDirection: 'row',
          width: '100%',
          height: '20px',
          justifyContent: 'space-between',
          borderBottom: '1px',
          paddingHorizontal: 4,
        },
        image: {
          width: '30%',
          objectFit: 'contain',
        },
        text: { fontSize: '9px' },
        pageNum: { alignSelf: 'flex-end', padding: '2px' },
      }),
    []
  );

  const left: ReactNode = contents['left'].image ? (
    <Image
      style={styles.image}
      src={contents['left'].content}
    />
  ) : (
    <Text style={styles.text}>{contents['left'].content}</Text>
  );
  const center: ReactNode = contents['center'].image ? (
    <Image
      style={styles.image}
      src={contents['center'].content}
    />
  ) : (
    <Text style={styles.text}>{contents['center'].content}</Text>
  );
  const right: ReactNode = contents['right'].image ? (
    <Image
      style={styles.image}
      src={contents['right'].content}
    />
  ) : (
    <Text style={styles.text}>{contents['right'].content}</Text>
  );

  return (
    <View
      fixed
      style={styles.container}>
      <View style={styles.box}>
        {left}
        {center}
        {right}
      </View>
      <Text
        style={styles.pageNum}
        render={({ pageNumber }: INumberProps) => `PAGE ${pageNumber}`}
      />
    </View>
  );
};

export default PDFFooter;
