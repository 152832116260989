import GridTextBoxes from '../../gridItems/items/GridTextBoxes';
import { ISitesContext, ICanvasContext } from 'state/iContext';
import GridBody from '../../gridItems/gridContainers/GridBody';
import { SitesContext, CanvasContext } from 'state/context';
import GridImages from '../../gridItems/items/GridImages';
import GridLayout, { Layout } from 'react-grid-layout';
import GridLink from '../../gridItems/items/GridLink';
import { ICanvasGridHandlers } from './iCanvasGrid';
import { ResizableBox } from 'react-resizable';
import { IStringProps } from 'iApp';
import {
  useRef,
  useMemo,
  useState,
  ReactNode,
  useEffect,
  useContext,
} from 'react';
import {
  ITowerList,
  IGridLayout,
  ILibraryIcon,
  IGridLayoutProps,
} from 'state/iState';

const DataGrid = () => {
  const { scale, setDragProps, activeTowerID, openGridItemOptions } =
    useContext<ICanvasContext | undefined>(CanvasContext)!;
  const { setTowerList, towerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;

  const [expandTop, setExpandTop] = useState<boolean>(true);

  const canvasTextBoxes: IGridLayoutProps =
    towerList[activeTowerID].layout.data.textBoxes;
  const canvasImages: IGridLayoutProps =
    towerList[activeTowerID].layout.data.images;
  const canvasLinks: IGridLayoutProps =
    towerList[activeTowerID].layout.data.drawings;
  const allTowerdatas = towerList[activeTowerID].layout.data.shelves;

  const textBoxesList: string[] = Object.keys(canvasTextBoxes);
  const dataList: string[] = Object.keys(allTowerdatas);
  const imagesList: string[] = Object.keys(canvasImages);
  const linksList: string[] = Object.keys(canvasLinks);

  const gridRef = useRef(null);

  const dataLayout = Array.from(dataList, (data) => {
    const { shelfGrid } = allTowerdatas[data];
    return { ...shelfGrid, maxH: 40, minH: 10 };
  });

  const layoutOnLoad = useMemo(
    () => [
      {
        i: -1,
        name: 'boundry',
        x: 2000,
        y: 200,
        w: 0,
        h: 0,
        minW: 0,
        minH: 0,
        static: true,
      },
      ...dataLayout,
    ],
    [dataLayout]
  );

  const updateCanvasData = (layout: Layout[]) => {
    let updatedData: { [key: string]: any } = {};
    let offsetY = 0;
    layout.forEach((data: Layout, i: number) => {
      const dataIndex: string = dataList.filter(
        (id) =>
          towerList[activeTowerID].layout.data.shelves[id].shelfGrid.name ===
          data.i
      )[0];
      if (i > 0 && i <= dataList.length) {
        const { '-1': bottom, ...rest } = allTowerdatas[dataIndex].icons;
        const newBottomY = data.h + offsetY;
        offsetY += data.h;
        updatedData[dataIndex] = {
          ...allTowerdatas[dataIndex],
          shelfGrid: {
            ...allTowerdatas[dataIndex].shelfGrid,
            h: data.h,
          },
          icons: {
            '-1': {
              ...bottom,
              y: newBottomY,
            },
            ...rest,
          },
        };
      }
    });
    return updatedData;
  };

  const classes: IStringProps = {
    container: 'relative',
    gridItem:
      'peer group relative h-full flex flex-col bg-stone-100 -ml-[0.5px] -mt-[0.5px] -pb-[1px] border border-stone-400 justify-center',
    link: 'peer relative h-full flex flex-col bg-stone-50 -ml-[0.5px] -mt-[0.5px] pl-2 border border-red-400 justify-center font-bold text-xs text-red-700 rounded rounded-lg',
    buttons:
      'hidden absolute -top-[10px] flex w-full h-[10px] group-hover:flex',
    up: 'fa-solid fa-caret-up flex w-1/2 h-[10px] justify-center items-center text-sm text-stone-500 hover:text-red-600 hover:cursor-default disabled:text-stone-300',
    down: 'fa-solid fa-caret-down flex w-1/2 h-[10px] justify-center items-center text-sm text-stone-500 hover:text-red-600 hover:cursor-default disabled:text-stone-300',
    data: 'relative absolute flex',
    grid: 'relative',
  };

  const images: ReactNode[] = imagesList.map((image: string): ReactNode => {
    return (
      <div
        className={classes.gridItem}
        id={canvasImages[image].name}
        key={canvasImages[image].name}
        data-grid={canvasImages[image]}
        onClick={() => openGridItemOptions(canvasImages[image], 'images')}
        onTouchStart={() => openGridItemOptions(canvasImages[image], 'images')}>
        <GridImages layout={canvasImages[image]} />
      </div>
    );
  });

  const textBoxes: ReactNode[] = textBoxesList.map(
    (box: string): ReactNode => (
      <div
        className={classes.gridItem}
        id={canvasTextBoxes[box].name}
        key={canvasTextBoxes[box].name}
        data-grid={canvasTextBoxes[box]}
        onClick={() => openGridItemOptions(canvasTextBoxes[box], 'textBoxes')}
        onTouchStart={() =>
          openGridItemOptions(canvasTextBoxes[box], 'textBoxes')
        }>
        <GridTextBoxes layout={canvasTextBoxes[box]} />
      </div>
    )
  );

  const links: ReactNode[] = linksList.map(
    (link: string): ReactNode => (
      <div
        className={classes.links}
        id={canvasLinks[link].name}
        key={canvasLinks[link].name}
        data-grid={canvasLinks[link]}
        onClick={() => openGridItemOptions(canvasLinks[link], 'drawings')}
        onTouchStart={() => openGridItemOptions(canvasLinks[link], 'drawings')}>
        <GridLink layout={canvasLinks[link]} />
      </div>
    )
  );

  const data: ReactNode[] = layoutOnLoad.map(
    (box, i): ReactNode =>
      i > 0 && i <= dataList.length ? (
        <div
          id={box.name}
          key={box.name}
          data-grid={box}
          className={classes.gridItem}>
          <div className={classes.buttons}>
            <button
              className={classes.up}
              disabled={box.h === 40}
              onClick={() => {
                const expandRate =
                  towerList[activeTowerID].layout.data.shelves[i - 1].shelfGrid
                    .h > 10
                    ? 20
                    : 10;

                const updatedIcons = Object.keys(
                  towerList[activeTowerID].layout.data.shelves[i - 1].icons
                ).reduce((acc, id) => {
                  return {
                    ...acc,
                    [id]: {
                      ...towerList[activeTowerID].layout.data.shelves[i - 1]
                        .icons[id],
                      y:
                        towerList[activeTowerID].layout.data.shelves[i - 1]
                          .icons[id].y + expandRate,
                    },
                  };
                }, {});

                setTowerList((prev: ITowerList) => ({
                  ...prev,
                  [activeTowerID]: {
                    ...prev[activeTowerID],
                    layout: {
                      ...prev[activeTowerID].layout,
                      data: {
                        ...prev[activeTowerID].layout.data,
                        shelves: {
                          ...prev[activeTowerID].layout.data.shelves,
                          [i - 1]: {
                            ...prev[activeTowerID].layout.data.shelves[i - 1],
                            shelfGrid: {
                              ...prev[activeTowerID].layout.data.shelves[i - 1]
                                .shelfGrid,
                              y:
                                prev[activeTowerID].layout.data.shelves[i - 1]
                                  .shelfGrid.y! - expandRate,
                              h:
                                prev[activeTowerID].layout.data.shelves[i - 1]
                                  .shelfGrid.h! + expandRate,
                            },
                            icons: updatedIcons,
                          },
                        },
                      },
                    },
                  },
                }));
              }}
            />
            <button
              className={classes.down}
              disabled={box.h === 10}
              onClick={() => {
                const dropRate =
                  towerList[activeTowerID].layout.data.shelves[i - 1].shelfGrid
                    .h > 20
                    ? 20
                    : 10;
                const updateIcons = Object.keys(
                  towerList[activeTowerID].layout.data.shelves[i - 1].icons
                ).filter(
                  (id) =>
                    towerList[activeTowerID].layout.data.shelves[i - 1].icons[
                      id
                    ].y <=
                    box.h -
                      towerList[activeTowerID].layout.data.shelves[i - 1].icons[
                        id
                      ].h -
                      dropRate
                );

                const updateSections = Object.keys(
                  towerList[activeTowerID].layout.data.shelves[i - 1].sections
                ).filter(
                  (id) =>
                    towerList[activeTowerID].layout.data.shelves[i - 1]
                      .sections[id].y <=
                    box.h -
                      towerList[activeTowerID].layout.data.shelves[i - 1]
                        .sections[id].h -
                      dropRate
                );

                const updatedIcons = updateIcons.reduce(
                  (acc, id) => ({
                    ...acc,
                    [id]: towerList[activeTowerID].layout.data.shelves[i - 1]
                      .icons[id],
                  }),
                  {}
                );

                const updatedSections = updateSections.reduce(
                  (acc, id) => ({
                    ...acc,
                    [id]: towerList[activeTowerID].layout.data.shelves[i - 1]
                      .sections[id],
                  }),
                  {}
                );

                setTowerList((prev: ITowerList) => ({
                  ...prev,
                  [activeTowerID]: {
                    ...prev[activeTowerID],
                    layout: {
                      ...prev[activeTowerID].layout,
                      data: {
                        ...prev[activeTowerID].layout.data,
                        shelves: {
                          ...prev[activeTowerID].layout.data.shelves,
                          [i - 1]: {
                            ...prev[activeTowerID].layout.data.shelves[i - 1],
                            shelfGrid: {
                              ...prev[activeTowerID].layout.data.shelves[i - 1]
                                .shelfGrid,
                              y:
                                prev[activeTowerID].layout.data.shelves[i - 1]
                                  .shelfGrid.y! + dropRate,
                              h:
                                prev[activeTowerID].layout.data.shelves[i - 1]
                                  .shelfGrid.h! - dropRate,
                            },
                            icons: {
                              '-1': {
                                i: -1,
                                name: '',
                                x: 0,
                                y: dropRate,
                                w: 0,
                                minW: 0,
                                minH: 0,
                                h: 0,
                                static: true,
                                isResizable: false,
                              },
                              ...updatedIcons,
                            },
                            sections: {
                              '-1': {
                                i: -1,
                                name: '',
                                x: 0,
                                y: dropRate,
                                w: 0,
                                minW: 0,
                                minH: 0,
                                h: 0,
                                static: true,
                                isResizable: false,
                              },
                              ...updatedSections,
                            },
                          },
                        },
                      },
                    },
                  },
                }));
              }}
            />
          </div>
          <ResizableBox
            height={box.h * 7.55}
            width={box.w * 7.55}
            className={classes.data}
            onResize={(event) => {
              const { movementY } = event as unknown as MouseEvent;
              const target = event.target as Element;

              setDragProps((prev: ILibraryIcon) => ({
                ...prev,
                dragging: true,
              }));
              if (target.classList.contains('react-resizable-handle-n')) {
                setExpandTop(true);
              }
              if (target.classList.contains('react-resizable-handle-s')) {
                setExpandTop(false);
              }

              setTowerList((prev: ITowerList) => {
                const updatedDataGrid = {
                  ...prev[activeTowerID].layout.data.shelves[i - 1].shelfGrid,
                  h: expandTop
                    ? prev[activeTowerID].layout.data.shelves[i - 1].shelfGrid
                        .h - movementY
                    : prev[activeTowerID].layout.data.shelves[i - 1].shelfGrid
                        .h + movementY,
                  y: expandTop
                    ? prev[activeTowerID].layout.data.shelves[i - 1].shelfGrid
                        .y! + movementY
                    : prev[activeTowerID].layout.data.shelves[i - 1].shelfGrid
                        .y,
                };

                return {
                  ...prev,
                  [activeTowerID]: {
                    ...prev[activeTowerID],
                    layout: {
                      ...prev[activeTowerID].layout,
                      data: {
                        ...prev[activeTowerID].layout.data,
                        shelves: {
                          ...prev[activeTowerID].layout.data.shelves,
                          [i - 1]: {
                            ...prev[activeTowerID].layout.data.shelves[i - 1],
                            shelfGrid: updatedDataGrid,
                          },
                        },
                      },
                    },
                  },
                };
              });
            }}>
            <GridBody
              key={box.name}
              layout={box}
              type='data'
            />
          </ResizableBox>
        </div>
      ) : (
        <div
          id={box.name}
          key={box.name}
          data-grid={box}
          className={classes.gridItem}
        />
      )
  );

  const updateData = (layout: Layout[]): IGridLayout => {
    const updatedLayout: IGridLayout = { ...allTowerdatas };
    const legIndexMap: Record<string, string> = {};
    dataList.forEach((id) => {
      legIndexMap[
        towerList[activeTowerID].layout.data.shelves[id].shelfGrid.name
      ] = id;
    });
    for (let i = 1; i <= dataList.length; i++) {
      const legIndex = legIndexMap[layout[i].i];
      const leg = updatedLayout[legIndex].shelfGrid;
      Object.assign(leg, layout[i], {
        i: allTowerdatas[legIndex].shelfGrid.i,
        name: layout[i].i,
      });
    }
    return updatedLayout;
  };

  const updateTextBoxes = (layout: Layout[]): IGridLayoutProps => {
    const updatedTextBoxes: IGridLayoutProps = {};
    for (let i = 0; i < layout.length; i++) {
      const box = layout[i];
      const textBox = canvasTextBoxes[textBoxesList[i]];
      updatedTextBoxes[i] = {
        ...box,
        i,
        name: box.i,
        text: textBox.text,
        maxH: 30,
        maxW: 30,
        minH: 10,
        minW: 10,
      };
    }
    return updatedTextBoxes;
  };

  const updateImages = (layout: Layout[]) => {
    const updatedImages: Record<string, any> = {};
    for (let i = 0; i < layout.length; i++) {
      const image = layout[i];
      updatedImages[i] = {
        ...image,
        i,
        name: image.i,
        img: canvasImages[imagesList[i]].img,
        maxH: 30,
        maxW: 30,
        minH: 10,
        minW: 10,
      };
    }
    return updatedImages;
  };

  const updateLinks = (layout: Layout[]) => {
    const updatedLinks: Record<string, any> = {};
    for (let i = 0; i < layout.length; i++) {
      const link = layout[i];
      const { i: linkI, name, refID } = canvasLinks[linksList[i]];
      updatedLinks[linkI] = {
        ...link,
        i: linkI,
        name,
        refID,
        isDraggable: true,
        isResizable: true,
      };
    }
    return updatedLinks;
  };

  const handler: ICanvasGridHandlers = {
    onResizeStop: (_, __, next) => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          height: next.h,
        },
      }));
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
        content: '',
        id: '',
        target: '',
        type: '',
      }));
    },
    onLayoutChange: (layout) => {
      setTowerList((prev: ITowerList) => {
        const data = prev[activeTowerID].layout.data;
        const updatedTower = {
          ...data,
          shelves: updateCanvasData(layout),
          textBoxes: updateTextBoxes(
            layout.slice(
              dataList.length + 1,
              dataList.length + textBoxesList.length + 1
            )
          ),
          images: updateImages(
            layout.slice(
              dataList.length + textBoxesList.length + 1,
              dataList.length + textBoxesList.length + imagesList.length + 1
            )
          ),
          drawings: updateLinks(
            layout.slice(
              dataList.length + textBoxesList.length + imagesList.length + 1
            )
          ),
        };
        const updatedLayout = {
          ...prev[activeTowerID].layout,
          data: updatedTower,
        };
        return {
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            layout: updatedLayout,
          },
        };
      });
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
        content: '',
        id: '',
        target: '',
        type: '',
      }));
    },
    onDragStop: (layout) => {
      setTowerList((prev: ITowerList) => {
        const updatedTower = {
          ...prev[activeTowerID].layout.data,
          shelves: updateData(layout),
        };
        const updatedLayout = {
          ...prev[activeTowerID].layout,
          data: updatedTower,
        };
        return {
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            layout: updatedLayout,
          },
        };
      });
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
        content: '',
        id: '',
        target: '',
        type: '',
      }));
    },
    onResize: () => {
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: true,
      }));
    },
  };

  useEffect(
    () =>
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
        content: '',
        id: '',
        target: '',
        type: '',
      })),
    []
  );

  return (
    <div
      id='grid-layout'
      className={classes.container}>
      <GridLayout
        cols={504}
        width={3800}
        margin={[0, 0]}
        autoSize={false}
        rowHeight={7.55}
        innerRef={gridRef}
        isDraggable={true}
        isResizable={true}
        allowOverlap={true}
        layout={layoutOnLoad}
        transformScale={scale}
        useCSSTransforms={true}
        className={classes.grid}
        compactType={'horizontal'}
        draggableHandle='.dragHandle'
        onDragStop={handler.onDragStop}
        onResizeStop={handler.onResizeStop}
        onLayoutChange={handler.onLayoutChange}>
        {data}
        {textBoxes}
        {images}
        {links}
      </GridLayout>
    </div>
  );
};

export default DataGrid;
