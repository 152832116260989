import { IChangeImageStates, IFileSelectComplete } from 'helpers/iHelpers';
import { IDocumentContext, ISettingsContext } from 'state/iContext';
import { DocumentContext, SettingsContext } from 'state/context';
import ImageCropHelpers from 'helpers/imageCropHelpers';
import DocumentInputField from '../DocumentInputField';
import { useContext, useMemo, useState } from 'react';
import { IInputEvent, IStringProps } from 'iApp';
import { IEditFileProps } from 'state/iState';
import ToggleOption from '../ToggleOption';
import noImage from 'assets/no-image.jpg';

const DocHeaderFooter = ({ target }: IStringProps) => {
  const { setActiveFilePanel, editFileProps, setEditFileProps } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;
  const { fileTypes } = useContext<ISettingsContext | undefined>(
    SettingsContext
  )!;
  const { acceptedImageTypes } = fileTypes;

  const [targetInput, setTargetInput] = useState<string>('');

  const { contents } = editFileProps.contents[target];
  const {
    imgSrc,
    setImgSrc,
    setImgSrcExt,
    fileInputRef,
    onFileSelect,
    setFileReader,
    clearToDefault,
    getImageFileExtension,
  } = ImageCropHelpers(noImage);
  const changeImageStates = ({ image }: IChangeImageStates): void => {
    setImgSrc(image);
    setImgSrcExt(getImageFileExtension(image));
  };
  // Additional methods to envoke once a file is selected
  const onFileSelectComplete = ({ e, file }: IFileSelectComplete) => {
    setFileReader({ file, changeImageStates });
    clearToDefault(e);
  };

  const classes: IStringProps = useMemo(
    () => ({
      container: 'flex flex-col w-full h-full',
      box: 'h-1/2 bg-slate-200 rounded rounded-lg m-2',
      title: 'w-full text-center text-lg text-stone-400 font-bold',
      image: 'flex w-full justify-end text-xs text-stone-500 font-semibold',
      label: 'w-[10%] pl-4 text-center',
      inputs: 'w-full h-full',
      inputBox: 'flex items-center',
      checkbox: 'flex w-[10%] justify-center items-center',
      uploaded: 'w-full max-h-[50px] object-contain',
      button:
        'self-end w-1/6 p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
    }),
    []
  );

  const changeHandler = (key: string, value: string) =>
    setEditFileProps((prev: IEditFileProps) => ({
      ...prev,
      contents: {
        ...prev.contents,
        [target]: {
          ...prev.contents[target],
          contents: {
            ...prev.contents[target].contents,
            [key]: {
              ...prev.contents[target].contents[key],
              content: value,
            },
          },
        },
      },
    }));

  const clickHandler = (key: string) =>
    setEditFileProps((prev: IEditFileProps) => ({
      ...prev,
      contents: {
        ...prev.contents,
        [target]: {
          ...prev.contents[target],
          contents: {
            ...prev.contents[target].contents,
            [key]: {
              ...prev.contents[target].contents[key],
              image: !prev.contents[target].contents[key].image,
            },
          },
        },
      },
    }));

  const props = {
    img: {
      id: 'uploaded-thumbnail',
      className: classes.uploaded,
      src: imgSrc,
    },
    input: {
      type: 'file',
      multiple: false,
      ref: fileInputRef!,
      name: 'upload-input',
      id: 'upload-input',
      accept: acceptedImageTypes,
      className: classes.folder,
      onChange: (e: IInputEvent) => onFileSelect({ e, onFileSelectComplete }),
    },
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{target.toUpperCase()}</h1>
      <div className={classes.box}>
        <ToggleOption
          name='Active'
          value={editFileProps.contents[target].active}
          onChange={() =>
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                [target]: {
                  ...prev.contents[target],
                  active: !prev.contents[target].active,
                },
              },
            }))
          }
        />
        <div className={classes.image}>
          <span className={classes.label}>Image</span>
        </div>
        <div className={classes.inputs}>
          <div className={classes.inputBox}>
            {contents['left'].image ? (
              <>
                {editFileProps.contents[target].contents.left.content ? (
                  <>
                    <div className='flex w-full my-0.5 p-0.5 justify-between'>
                      <label
                        className='mx-1 text-stone-500 font-bold'
                        htmlFor='left'>
                        Left:
                      </label>
                      <div className='flex w-1/2'>
                        <img
                          src={
                            editFileProps.contents[target].contents.left.content
                          }
                          className={classes.uploaded}
                          alt='thumbnail'
                        />
                        <button
                          className='text-blue-500 fa-solid fa-xmark mx-4'
                          onClick={() => {
                            setTargetInput('');
                            setEditFileProps((prev: IEditFileProps) => ({
                              ...prev,
                              contents: {
                                ...prev.contents,
                                [target]: {
                                  ...prev.contents[target],
                                  contents: {
                                    ...prev.contents[target].contents,
                                    left: {
                                      ...prev.contents[target].contents.left,
                                      content: '',
                                    },
                                  },
                                },
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='flex w-full my-2 justify-between'>
                    <label
                      htmlFor='left'
                      className='mx-2 font-bold text-stone-500 self-center'>
                      Left
                    </label>
                    <div className='flex w-1/2 items-center justify-center'>
                      {imgSrc && targetInput === 'left' ? (
                        <div className='flex w-1/2'>
                          <img
                            alt='thumbnail'
                            {...props.img}
                          />
                          <button
                            className={classes.button}
                            onClick={() => {
                              setTargetInput('');
                              setEditFileProps((prev: IEditFileProps) => ({
                                ...prev,
                                contents: {
                                  ...prev.contents,
                                  [target]: {
                                    ...prev.contents[target],
                                    contents: {
                                      ...prev.contents[target].contents,
                                      left: {
                                        ...prev.contents[target].contents.left,
                                        content: imgSrc,
                                      },
                                    },
                                  },
                                },
                              }));
                              setImgSrc('');
                            }}>
                            Confirm
                          </button>
                        </div>
                      ) : (
                        <input
                          {...props.input}
                          onClick={() => {
                            setImgSrc('');
                            setTargetInput('left');
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <DocumentInputField
                name='Left'
                value={contents['left'].content}
                onChange={(e: IInputEvent) =>
                  changeHandler('left', e.target.value)
                }
              />
            )}
            <div className={classes.checkbox}>
              <input
                type='checkbox'
                defaultChecked={contents['left'].image}
                onClick={() => clickHandler('left')}
              />
            </div>
          </div>
          <div className={classes.inputBox}>
            {contents['center'].image ? (
              <>
                {editFileProps.contents[target].contents.center.content ? (
                  <>
                    <div className='flex w-full my-0.5 p-0.5 justify-between'>
                      <label
                        className='mx-1 text-stone-500 font-bold'
                        htmlFor='left'>
                        Center:
                      </label>
                      <div className='flex w-1/2'>
                        <img
                          src={
                            editFileProps.contents[target].contents.center
                              .content
                          }
                          className={classes.uploaded}
                          alt='thumbnail'
                        />
                        <button
                          className='text-blue-500 fa-solid fa-xmark mx-4'
                          onClick={() => {
                            setEditFileProps((prev: IEditFileProps) => ({
                              ...prev,
                              contents: {
                                ...prev.contents,
                                [target]: {
                                  ...prev.contents[target],
                                  contents: {
                                    ...prev.contents[target].contents,
                                    center: {
                                      ...prev.contents[target].contents.center,
                                      content: '',
                                    },
                                  },
                                },
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='flex w-full my-2 justify-between'>
                    <label
                      htmlFor='center'
                      className='mx-2 font-bold text-stone-500 self-center'>
                      Center
                    </label>
                    <div className='flex w-1/2 items-center justify-center'>
                      {imgSrc && targetInput === 'center' ? (
                        <div className='flex w-1/2'>
                          <img
                            alt='thumbnail'
                            {...props.img}
                          />
                          <button
                            className={classes.button}
                            onClick={() => {
                              setTargetInput('');
                              setEditFileProps((prev: IEditFileProps) => ({
                                ...prev,
                                contents: {
                                  ...prev.contents,
                                  [target]: {
                                    ...prev.contents[target],
                                    contents: {
                                      ...prev.contents[target].contents,
                                      center: {
                                        ...prev.contents[target].contents
                                          .center,
                                        content: imgSrc,
                                      },
                                    },
                                  },
                                },
                              }));
                              setImgSrc('');
                            }}>
                            Confirm
                          </button>
                        </div>
                      ) : (
                        <input
                          {...props.input}
                          onClick={() => {
                            setImgSrc('');
                            setTargetInput('center');
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <DocumentInputField
                name='Center'
                value={contents['center'].content}
                onChange={(e: IInputEvent) =>
                  changeHandler('center', e.target.value)
                }
              />
            )}
            <div className={classes.checkbox}>
              <input
                type='checkbox'
                defaultChecked={contents['center'].image}
                onClick={() => clickHandler('center')}
              />
            </div>
          </div>
          <div className={classes.inputBox}>
            {contents.right.image ? (
              <>
                {editFileProps.contents[target].contents.right.content ? (
                  <>
                    <div className='flex w-full my-0.5 p-0.5 justify-between'>
                      <label
                        className='mx-1 text-stone-500 font-bold'
                        htmlFor='right'>
                        Right:
                      </label>
                      <div className='flex w-1/2'>
                        <img
                          src={
                            editFileProps.contents[target].contents.right
                              .content
                          }
                          className={classes.uploaded}
                          alt='thumbnail'
                        />
                        <button
                          className='text-blue-500 fa-solid fa-xmark mx-4'
                          onClick={() => {
                            setEditFileProps((prev: IEditFileProps) => ({
                              ...prev,
                              contents: {
                                ...prev.contents,
                                [target]: {
                                  ...prev.contents[target],
                                  contents: {
                                    ...prev.contents[target].contents,
                                    right: {
                                      ...prev.contents[target].contents.right,
                                      content: '',
                                    },
                                  },
                                },
                              },
                            }));
                          }}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <div className='flex w-full my-2 justify-between'>
                    <label
                      htmlFor='right'
                      className='mx-2 font-bold text-stone-500 self-center'>
                      Right
                    </label>
                    <div className='flex w-1/2 items-center justify-center'>
                      {imgSrc && targetInput === 'right' ? (
                        <div className='flex w-1/2'>
                          <img
                            alt='thumbnail'
                            {...props.img}
                          />
                          <button
                            className={classes.button}
                            onClick={() => {
                              setTargetInput('');
                              setEditFileProps((prev: IEditFileProps) => ({
                                ...prev,
                                contents: {
                                  ...prev.contents,
                                  [target]: {
                                    ...prev.contents[target],
                                    contents: {
                                      ...prev.contents[target].contents,
                                      right: {
                                        ...prev.contents[target].contents.right,
                                        content: imgSrc,
                                      },
                                    },
                                  },
                                },
                              }));
                              setImgSrc('');
                            }}>
                            Confirm
                          </button>
                        </div>
                      ) : (
                        <input
                          {...props.input}
                          onClick={() => {
                            setImgSrc('');
                            setTargetInput('right');
                          }}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <DocumentInputField
                name='Right'
                value={contents['right'].content}
                onChange={(e: IInputEvent) =>
                  changeHandler('right', e.target.value)
                }
              />
            )}
            <div className={classes.checkbox}>
              <input
                type='checkbox'
                defaultChecked={contents['right'].image}
                onClick={() => clickHandler('right')}
              />
            </div>
          </div>
        </div>
      </div>
      <button
        className={classes.button}
        onClick={() => setActiveFilePanel('')}>
        Back
      </button>
    </div>
  );
};
export default DocHeaderFooter;
