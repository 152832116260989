import DocInspectionChecklist from './inspectionChecklist/DocInspectionChecklist';
import TableOfContents from './tableOfContents/AddDocTableOfContents';
import DocHeightVerification from './height/DocHeightVerification';
import AddDocumentRooftop from './rooftop/AddDocumentRooftop';
import DocHeaderFooter from './headerFooter/DocHeaderFooter';
import DocDeficiencies from './deficiencies/DocDeficiencies';
import DocInventoryInfo from './inventory/DocInventoryInfo';
import DocVerticality from './verticality/DocVerticality';
import AddDocumentTower from './tower/AddDocumentTower';
import DocGuyInspection from './guy/DocGuyInspection';
import AddDocumentText from './text/AddDocumentText';
import { IDocumentContext } from 'state/iContext';
import { DocumentContext } from 'state/context';
import { useContext, useEffect } from 'react';
import FrontPage from './newPage/FrontPage';
import DocImages from './images/DocImages';
import PanelButtons from './PanelButtons';
import { useMemo } from 'react';

const PDFDocumentPanel = () => {
  const { activeFilePanel, setActiveFilePanel } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;

  const activePanel = useMemo(
    () => ({
      header: <DocHeaderFooter target='header' />,
      footer: <DocHeaderFooter target='footer' />,
      front: <FrontPage />,
      table: <TableOfContents />,
      text: <AddDocumentText />,
      image: <DocImages />,
      tower: <AddDocumentTower />,
      data: <DocInventoryInfo />,
      rooftop: <AddDocumentRooftop />,
      verticality: <DocVerticality />,
      height: <DocHeightVerification />,
      guy: <DocGuyInspection />,
      deficiencies: <DocDeficiencies />,
      checklist: <DocInspectionChecklist />,
    }),
    []
  );

  useEffect((): void => setActiveFilePanel(''), []);

  return (
    <div className='relative flex flex-col w-full h-full'>
      {activeFilePanel ? activePanel[activeFilePanel] : <PanelButtons />}
    </div>
  );
};

export default PDFDocumentPanel;
