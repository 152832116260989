import { IToggleOption } from './iDocumentEditor';
import { IStringProps } from 'iApp';

const ToggleOption = ({ name, onChange, value }: IToggleOption) => {
  const id: string = `${name}-toggle`;

  const classes: IStringProps = {
    container: 'flex w-full my-2 justify-between',
    label: 'mx-2 font-bold text-stone-500',
    input: 'flex w-1/2 items-center justify-center',
  };

  return (
    <div className={classes.container}>
      <label
        className={classes.label}
        htmlFor={id}>
        {name}
      </label>
      <div className={classes.input}>
        <input
          id={id}
          name={id}
          type='checkbox'
          checked={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default ToggleOption;
