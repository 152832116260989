import { userDropdownMenuIcons } from '../MenuIcons';
import profileIcon from 'assets/header/profile.png';
import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import {
  ITabControl,
  ISettingsTabProps,
  ISettingsTabClasses,
} from './iSettings';

const SettingsTabs = ({ activeTab, setActiveTab }: ITabControl) => {
  const { logout } = useAuth0();
  const classes: ISettingsTabClasses = {
    container:
      'flex-col border border-stone-200 w-full md:max-w-[300px] md:w-1/5',
    tab: {
      active: 'flex p-1 border-b border-stone-200 items-center bg-stone-300',
      inactive:
        'flex p-1 border-b border-stone-200 items-center transition ease-in-out delay-100 hover:bg-stone-300',
    },
    icon: {
      img: 'w-4 h-4 m-2 rounded-full bg-slate-400 invert',
      div: 'flex w-4 h-4 m-2',
    },
    logoutClass:
      'flex p-1 border-b border-stone-200 items-center bg-red-200 transition ease-in-out delay-100 hover:bg-red-600 hover:text-white',
  };

  // Highlights active tab
  const isActiveTab = useMemo(
    () =>
      (name: string): string =>
        activeTab[name as keyof typeof activeTab]
          ? classes.tab.active
          : classes.tab.inactive,
    [activeTab, classes.tab]
  );

  // Props to pass to their respective elements
  const props: ISettingsTabProps = {
    account: {
      container: {
        className: isActiveTab('account'),
        onClick: () => setActiveTab({ account: true, settings: false }),
      },
      icon: {
        src: profileIcon,
        className: classes.icon.img,
      },
    },
    settings: {
      className: isActiveTab('settings'),
      onClick: () => setActiveTab({ account: false, settings: true }),
    },
    logout: {
      className: classes.logoutClass,
      onClick: () => logout(),
    },
  };

  return (
    <div className={classes.container}>
      <div {...props.account.container}>
        <img
          alt='user profile icon'
          {...props.account.icon}
        />
        Account
      </div>
      <div {...props.settings}>
        <div className={classes.icon.div}>{userDropdownMenuIcons.Settings}</div>
        Settings
      </div>
      <div {...props.logout}>
        <div className={classes.icon.div}>{userDropdownMenuIcons.Logout}</div>
        Logout
      </div>
    </div>
  );
};

export default SettingsTabs;
