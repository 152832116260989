import { IDocumentContext } from 'state/iContext';
import { IInputEvent, IStringProps } from 'iApp';
import { DocumentContext } from 'state/context';
import { IEditFileProps } from 'state/iState';
import { useContext } from 'react';

const FileName = () => {
  const { editFileProps, setEditFileProps } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;

  const classes: IStringProps = {
    container: 'flex w-full my-2 p-0.5 justify-between',
    label: 'mx-1 text-lg text-stone-500 font-bold',
    input: 'p-0 w-1/2 placeholder:px-2',
  };

  return (
    <div className={classes.container}>
      <label
        className={classes.label}
        htmlFor='file-name'>
        File Name:
      </label>
      <input
        type='text'
        id='file-name'
        name='file-name'
        className={classes.input}
        placeholder='Custom File Name'
        defaultValue={editFileProps.contents.name}
        onChange={(e: IInputEvent) =>
          setEditFileProps((prev: IEditFileProps) => ({
            ...prev,
            contents: {
              ...prev.contents,
              name: e.target.value,
            },
          }))
        }
      />
    </div>
  );
};

export default FileName;
