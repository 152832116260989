import { DocumentContext, CanvasContext, SitesContext } from 'state/context';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useContext, useEffect, useState } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import ViewPDFNav from './ViewPDFNav';
import { IStringProps } from 'iApp';
import {
  IDocumentContext,
  ICanvasContext,
  ISitesContext,
} from 'state/iContext';

const DisplayFile = () => {
  const { activeFile, setActiveFile } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;
  const { towerList } = useContext<ISitesContext | undefined>(SitesContext)!;
  const { activeTowerID } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;

  const [numPages, setNumPages] = useState<number | null>(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const fileInfo = towerList[activeTowerID].documents.files[activeFile];

  const onDocumentLoadSuccess = ({ numPages }): void => {
    setNumPages(numPages);
  };

  const classes: IStringProps = {
    container: 'relative',
    zoom: 'w-screen h-full',
    viewer: 'relative flex flex-col w-full h-fit',
    document:
      'flex flex-col w-fit h-contain justify-center items-center self-center items-center',
    page: 'flex w-full m-1 items-center border border-stone-300 shadow-xl shadow-slate-300',
  };

  useEffect(() => {
    setNumPages(null);
    setPageNumber(1);
  }, []);

  return (
    <div className={classes.container}>
      {fileInfo.file ? (
        <TransformWrapper
          maxScale={10}
          minScale={0.5}
          initialScale={1}
          limitToBounds={true}
          initialPositionX={0}
          initialPositionY={0}>
          {({ resetTransform }) => (
            <>
              <ViewPDFNav
                folder={fileInfo.folder}
                numPages={numPages!}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                resetTransform={resetTransform}
              />
              <TransformComponent
                contentClass={classes.zoom}
                wrapperClass={classes.zoom}>
                <div
                  id='file-viewer'
                  className={classes.viewer}>
                  <Document
                    file={fileInfo.file}
                    onLoadSuccess={onDocumentLoadSuccess}
                    className={classes.document}>
                    <Page
                      className={classes.page}
                      pageNumber={pageNumber}
                    />
                  </Document>
                </div>
              </TransformComponent>
            </>
          )}
        </TransformWrapper>
      ) : (
        <div className='w-full h-full object-contain'>
          <div>
            <ViewPDFNav
              folder={fileInfo.folder}
              numPages={1}
              pageNumber={1}
              setPageNumber={1}
              resetTransform={() => setActiveFile(null)}
            />
          </div>
          <img
            src={fileInfo.img}
            alt='uploaded-image-file'
          />
        </div>
      )}
    </div>
  );
};

export default DisplayFile;
