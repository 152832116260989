import { StyleSheet, View, Page, Text, Image } from '@react-pdf/renderer';
import { IPDFInventory } from '../iDocumentEditor';
import PDFInventoryLine from './PDFInventoryLine';
import PDFFooter from '../headerFooter/PDFFooter';
import PDFHeader from '../headerFooter/PDFHeader';
import { useMemo } from 'react';

const PDFInventory = ({ contents, info }: IPDFInventory) => {
  const styles = useMemo(
    () =>
      StyleSheet.create({
        page: {
          flexDirection: 'row',
          padding: '0.5in',
          flexWrap: 'wrap',
          width: '100%',
          height: '90%',
        },
        container: {
          flexDirection: 'row',
          border: 'solid',
          borderWidth: '1px',
          width: '100%',
          flexWrap: 'wrap',
        },
        column: {
          border: 'solid',
          borderWidth: '1px',
          width: '20%',
          textAlign: 'center',
          fontSize: '15px',
          padding: 4,
          height: '100%',
        },
        asset: {
          border: 'solid',
          borderWidth: '1px',
          width: '40%',
          fontSize: '15px',
          padding: 4,
          alignItems: 'center',
          textAlign: 'center',
          height: '100%',
        },
        title: { fontSize: '15px', marginTop: '20px' },
        inventory: { width: '100%', textAlign: 'center', marginBottom: '10px' },
        table: {
          flexDirection: 'row',
          flexWrap: 'wrap',
          width: '100%',
          marginTop: '5px',
          paddingHorizontal: '20px',
        },
        content: {
          width: '30%',
          flexWrap: 'wrap',
          flexDirection: 'column',
          margin: '5px',
        },
        image: {
          width: '150px',
          height: '200px',
          border: '2px',
          borderColor: 'black',
        },
        label: {
          flex: 1,
          fontSize: '10px',
          marginTop: '2px',
          marginBottom: '10px',
        },
      }),
    []
  );

  const towerAssets = useMemo(
    () =>
      Object.keys(contents.inventory.assets.tower).map((item: string) => (
        <PDFInventoryLine
          info={contents.inventory.assets.tower[item]}
          key={item}
        />
      )),
    [info]
  );

  const rooftopAssets = useMemo(
    () =>
      Object.keys(contents.inventory.assets.rooftop).map((item: string) => (
        <PDFInventoryLine
          info={contents.inventory.assets.rooftop[item]}
          key={item}
        />
      )),
    [info]
  );

  const dataAssets = useMemo(
    () =>
      Object.keys(contents.inventory.assets.data).map((item: string) => (
        <PDFInventoryLine
          info={contents.inventory.assets.data[item]}
          key={item}
        />
      )),
    [info]
  );

  const towerImages = useMemo(
    () =>
      Object.keys(contents.inventory.images.tower).map((id) => (
        <View style={styles.content}>
          <Image
            key={id}
            style={styles.image}
            src={contents.inventory.images.tower[id].image}
          />
          <Text style={styles.label}>
            {contents.inventory.images.tower[id].label}
          </Text>
        </View>
      )),
    [contents.inventory.images.tower]
  );

  const rooftopImages = useMemo(
    () =>
      Object.keys(contents.inventory.images.rooftop).map((id) => (
        <View style={styles.content}>
          <Image
            key={id}
            style={styles.image}
            src={contents.inventory.images.rooftop[id].image}
          />
          <Text style={styles.label}>
            {contents.inventory.images.rooftop[id].label}
          </Text>
        </View>
      )),
    [contents.inventory.images.rooftop]
  );

  const dataImages = useMemo(
    () =>
      Object.keys(contents.inventory.images.data).map((id) => (
        <View style={styles.content}>
          <Image
            key={id}
            style={styles.image}
            src={contents.inventory.images.data[id].image}
          />
          <Text style={styles.label}>
            {contents.inventory.images.data[id].label}
          </Text>
        </View>
      )),
    [contents.inventory.images.data]
  );

  const towerTable = (
    <View>
      <Text style={styles.title}>Tower</Text>
      <View style={styles.container}>
        <Text style={styles.column}>Elev. (ft)</Text>
        <Text style={styles.asset}>Asset / Equipment</Text>
        <Text style={styles.column}>Location</Text>
        <Text style={styles.column}>Properties</Text>
      </View>
      <View>{towerAssets}</View>
      <View style={styles.table}>{towerImages}</View>
    </View>
  );

  const rooftopTable = (
    <View break={towerImages.length > 0}>
      <Text style={styles.title}>Rooftop</Text>
      <View style={styles.container}>
        <Text style={styles.column}>{/***test***/}</Text>
        <Text style={styles.asset}>Asset / Equipment</Text>
        <Text style={styles.column}>{/*Location*/}</Text>
        <Text style={styles.column}>Properties</Text>
      </View>
      <View>{rooftopAssets}</View>
      <View style={styles.table}>{rooftopImages}</View>
    </View>
  );

  const dataTable = (
    <View break={rooftopImages.length > 0}>
      <Text style={styles.title}>Data</Text>
      <View style={styles.container}>
        <Text style={styles.column}>Shelf</Text>
        <Text style={styles.asset}>Asset / Equipment</Text>
        <Text style={styles.column}>Shelter</Text>
        <Text style={styles.column}>Properties</Text>
      </View>
      <View>{dataAssets}</View>
      <View style={styles.table}>{dataImages}</View>
    </View>
  );

  return (
    <Page
      size='A4'
      style={styles.page}>
      {contents.header.active && (
        <PDFHeader contents={contents.header.contents} />
      )}
      <Text style={styles.inventory}>Inventory</Text>
      {towerAssets.length > 0 && <>{towerTable}</>}
      {rooftopAssets.length > 0 && <>{rooftopTable}</>}
      {dataAssets.length > 0 && <>{dataTable}</>}
      {contents.footer.active && (
        <PDFFooter contents={contents.footer.contents} />
      )}
    </Page>
  );
};

export default PDFInventory;
