import { ILibraryContext, IModalContext, IUserContext } from 'state/iContext';
import { LibraryContext, ModalContext, UserContext } from 'state/context';
import { kebabString } from 'helpers/helperFunctions';
import { ILibraryTabClasses } from './iLibrary';
import { useContext, useMemo } from 'react';
import Icons from '../../../../icons/Icons';
import { IStringProps } from 'iApp';

const LibraryTab = ({ name }: IStringProps) => {
  const {
    libraries,
    activeLibrary,
    setActiveIcon,
    toggleLibrary,
    searchFiltered,
  } = useContext<ILibraryContext | undefined>(LibraryContext)!;
  const {
    isModalActive,
    activeModal: { manageLibraries },
  } = useContext<IModalContext | undefined>(ModalContext)!;
  const { searchBar } = useContext<IUserContext | undefined>(UserContext)!;

  // Component classes
  const classes: ILibraryTabClasses = {
    tab: {
      active:
        'sticky top-0 z-20 flex justify-between items-center bg-red-700 text-slate-200 border border-stone-500 p-1',
      inactive:
        'flex z-20 justify-between items-center p-1 odd:bg-slate-100 transition ease-in-out delay-100 hover:bg-stone-300',
    },
    left: 'flex',
    search: {
      active: 'ml-1',
      inactive: 'ml-1 text-red-600',
    },
    arrow: {
      active: 'fa-solid fa-angle-down text-center mr-1',
      inactive: 'fa-solid fa-angle-right text-center mr-1',
    },
  };

  const { tab, search, arrow } = classes;

  // Toggles classes
  const activeName: boolean = activeLibrary === name;
  const activeTab: boolean = activeName && !manageLibraries;
  const activeTabClass: string = activeTab ? tab.active : tab.inactive;
  const activeArrow: string = activeTab ? arrow.active : arrow.inactive;
  const activeSearch: string = activeTab ? search.active : search.inactive;

  // Returns total number of icons that match search bar
  const iconTotal: number = useMemo(
    () =>
      Object.keys(libraries[name]).filter((icon) =>
        searchFiltered.includes(libraries[name][icon].id)
      ).length,
    [libraries, searchFiltered, name]
  );

  // Toggles library visibility
  const clickHandler = (name: string): void => {
    toggleLibrary(name);
    setActiveIcon('');
  };

  return (
    <>
      <div
        id={`${kebabString(name)}-library`}
        onClick={() => clickHandler(name)}
        className={activeTabClass}>
        <div className={classes.left}>
          {name}
          {searchBar && !isModalActive && (
            <div className={activeSearch}>({iconTotal})</div>
          )}
        </div>
        <i className={activeArrow} />
      </div>
      {activeTab && <Icons library={libraries[name]} />}
    </>
  );
};

export default LibraryTab;
