import { ReactNode, useMemo } from 'react';
import FileLine from './FileLine';

const Files = ({ fileIds, fileList }) => {
  const files: ReactNode[] = useMemo(
    () =>
      fileIds.map((file: string): ReactNode => {
        return (
          <FileLine
            id={file}
            key={file}
            file={fileList[file]}
          />
        );
      }),
    [fileIds]
  );
  return <>{files}</>;
};

export default Files;
