import { ILibraryIcon, ITowerList, IGridLayoutProps } from 'state/iState';
import { ITowerLegGridHandlers } from '../../views/grids/iCanvasGrid';
import { useContext, ReactNode, useMemo, useEffect } from 'react';
import { ICanvasContext, ISitesContext } from 'state/iContext';
import { CanvasContext, SitesContext } from 'state/context';
import { nextAvailableKey } from 'helpers/helperFunctions';
import GridLayout, { Layout } from 'react-grid-layout';
import GridAsset from './GridAsset';
import { IStringProps } from 'iApp';

const GridTowerData = ({ id, layout, type }) => {
  const { activeTowerID, dragProps, setDragProps, scale } = useContext<
    ICanvasContext | undefined
  >(CanvasContext)!;
  const { setTowerList, towerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;

  const gridItem: IStringProps = {
    tower: 'legs',
    data: 'shelves',
  };

  const gridIcons: IGridLayoutProps =
    towerList[activeTowerID].layout[type][gridItem[type]][id].icons;
  const nextKey: number = nextAvailableKey(gridIcons, 0)!;

  const height: number =
    type === 'tower'
      ? towerList[activeTowerID].height
      : towerList[activeTowerID].layout.data.shelves[layout[0].i].shelfGrid.h;

  const updateIcons = (layout: Layout[]) => {
    const updatedIcons = {
      ...towerList[activeTowerID].layout[type][gridItem[type]][id].icons,
    };
    layout
      .filter((icon) => icon.i !== '__dropping-elem__')
      .forEach((icon, i) => {
        const resizeInBounds =
          +height - +icon.y < +icon.h ? +height - +icon.y : icon.h;

        i === layout.length - 1
          ? (updatedIcons[-1] = {
              ...towerList[activeTowerID].layout[type][gridItem[type]][id]
                .icons[-1],
            })
          : (updatedIcons[icon.i] = {
              ...towerList[activeTowerID].layout[type][gridItem[type]][id]
                .icons[icon.i],
              i: icon.i,
              h: +resizeInBounds,
              y: icon.y,
            });
      });

    return updatedIcons;
  };

  const handler: ITowerLegGridHandlers = {
    onLayoutChange: (layout: Layout[]) => {
      setTowerList((prev: ITowerList) => ({
        ...prev,
        [activeTowerID]: {
          ...prev[activeTowerID],
          layout: {
            ...prev[activeTowerID].layout,
            [type]: {
              ...prev[activeTowerID].layout.tower,
              [gridItem[type]]: {
                ...prev[activeTowerID].layout[type][gridItem[type]],
                [id]: {
                  ...prev[activeTowerID].layout[type][gridItem[type]][id],
                  icons: updateIcons(layout),
                },
              },
            },
          },
        },
      }));
    },
    onDragStop: (layout: Layout[]) => {
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        id,
        dragging: false,
      }));
    },
    onDrop: async (layout: Layout[], item: Layout) => {
      if (dragProps.id) {
        await setTowerList((prev: ITowerList) => ({
          ...prev,
          [activeTowerID]: {
            ...prev[activeTowerID],
            layout: {
              ...prev[activeTowerID].layout,
              [type]: {
                ...prev[activeTowerID].layout[type],
                [gridItem[type]]: {
                  ...prev[activeTowerID].layout[type][gridItem[type]],
                  [id]: {
                    ...prev[activeTowerID].layout[type][gridItem[type]][id],
                    icons: {
                      ...prev[activeTowerID].layout[type][gridItem[type]][id]
                        .icons!,
                      [nextKey]: {
                        i: nextKey,
                        name: dragProps.id,
                        img: dragProps.content,
                        x: 0,
                        y: item.y - 25,
                        w: dragProps.ports.w,
                        h: dragProps.ports.h,
                        minW: 10,
                        properties: dragProps.properties,
                      },
                    },
                  },
                },
              },
            },
          },
        }));
      }
      setDragProps({
        id: '',
        img: '',
        ports: {
          w: 10,
          h: 5,
        },
        properties: [],
      });
    },
    onMouseUp: () => {
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
      }));
    },
  };

  const openAssetOptions = (iconID) => {
    setDragProps((prev: ILibraryIcon) => ({
      ...prev,
      id: +iconID,
      content:
        towerList[activeTowerID].layout[type][gridItem[type]][id].icons[+iconID]
          .name,
      dragging: true,
      target: id.toString(),
      type: 'asset',
    }));
  };

  const iconsToDisplay: ReactNode[] = useMemo(
    () =>
      Object.keys(gridIcons).map((icon: string, i: number): ReactNode => {
        return (
          <div
            key={+gridIcons[icon].i}
            data-grid={gridIcons[icon]}
            onMouseUp={handler.onMouseUp}
            onTouchStart={openAssetOptions}
            onClick={() => openAssetOptions(icon)}
            className='relative flex flex-nowrap w-full border z-30 border-stone-400 bg-stone-300 justify-center cursor-row-resize'>
            <GridAsset layout={gridIcons[icon]} />
          </div>
        );
      }),
    [id, gridIcons, handler]
  );

  useEffect(
    () =>
      setDragProps((prev: ILibraryIcon) => ({
        ...prev,
        dragging: false,
        content: '',
        id: '',
        target: '',
        type: '',
      })),
    []
  );

  return (
    <GridLayout
      cols={10}
      width={73}
      layout={layout}
      margin={[0, 0]}
      rowHeight={7.55}
      isBounded={true}
      compactType={null}
      isDraggable={true}
      isDroppable={true}
      allowOverlap={false}
      transformScale={scale}
      key={id + '-grid-layout'}
      className='relative absolute z-40 h-full w-full'
      onLayoutChange={handler.onLayoutChange}
      onDragStop={handler.onDragStop}
      onDrop={handler.onDrop}>
      {iconsToDisplay}
    </GridLayout>
  );
};

export default GridTowerData;
