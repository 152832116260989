import { kebabNumber } from 'helpers/helperFunctions';
import InfoWindowLine from './InfoWindowLine';
import { ReactNode } from 'react';
import {
  IMarkerSiteInfoClasses,
  IMarkerSiteInfoProps,
  IInfoWindowLine,
  IMarkerSiteInfo,
} from './iInfoWindow';

const MarkerSiteInfo = ({
  company,
  name,
  code,
  contact,
  siteInfoClasses,
}: IMarkerSiteInfo) => {
  // Props to pass to their respective elements
  const props: IMarkerSiteInfoProps = {
    siteName: {
      title: 'Site Name',
      info: name,
      classes: siteInfoClasses,
    },
    siteCode: {
      title: 'Site Code',
      info: code,
      classes: siteInfoClasses,
    },
    contactName: {
      title: 'Contact Person',
      info: contact.name,
      classes: siteInfoClasses,
    },
    contactPhone: {
      title: 'Phone',
      info: kebabNumber(contact.phone),
      classes: siteInfoClasses,
    },
    contactEmail: {
      title: 'Email',
      info: contact.email,
      classes: siteInfoClasses,
    },
  };

  // Component classes
  const classes: IMarkerSiteInfoClasses = {
    container: 'flex flex-col items-start md:w-full font-medium mt-3',
    title: 'font-bold text-[12px] md:text-sm',
    companyClass:
      'max-w-[200px] font-bold text-sm md:text-base mb-2 text-red-700',
  };

  // Renders individual site details
  const infoLines: ReactNode[] = Object.keys(props).map(
    (line: string): ReactNode => {
      const keyObj: IInfoWindowLine = props[line as keyof typeof props];
      return (
        <InfoWindowLine
          key={props[line as keyof typeof props].title}
          {...keyObj}
        />
      );
    }
  );

  return (
    <div
      id='info-window-site-info'
      className={classes.container}>
      <div className={classes.title}>Site Info</div>
      <div className={classes.companyClass}>{company}</div>
      {infoLines}
    </div>
  );
};

export default MarkerSiteInfo;
