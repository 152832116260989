import DocumentInputField from '../../DocumentInputField';
import { IDocumentTowerInfo } from 'state/iState';
import { IDocumentContext } from 'state/iContext';
import { IInputEvent, IStringProps } from 'iApp';
import { DocumentContext } from 'state/context';
import { ReactNode, useContext } from 'react';

const DocDetailInputs = ({ name }: IStringProps) => {
  const { documentTowerInfo, setDocumentTowerInfo, clearDocumentTowerInfo } =
    useContext<IDocumentContext | undefined>(DocumentContext)!;

  const inputs: ReactNode[] = Object.keys(clearDocumentTowerInfo[name]).map(
    (line) => (
      <DocumentInputField
        key={line}
        name={line}
        value={documentTowerInfo[name][line]}
        onChange={(e: IInputEvent) =>
          setDocumentTowerInfo((prev: IDocumentTowerInfo) => ({
            ...prev,
            [name]: {
              ...prev[name],
              [line]: e.target.value,
            },
          }))
        }
      />
    )
  );

  const classes: IStringProps = {
    container: 'w-full p-2',
    title: 'font-semibold text-stone-500',
    inputs: 'flex flex-col w-full my-2 p-0.5 justify-between',
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>{name}</h1>
      <div className={classes.inputs}>{inputs}</div>
    </div>
  );
};

export default DocDetailInputs;
