import { ISettingsContext } from 'state/iContext';
import { SettingsContext } from 'state/context';
import { IClasses, IResize } from './iResize';
import ResizeButton from './ResizeButton';
import { useContext } from 'react';

const Resize = () => {
  const {
    screenDimensions: { width },
  } = useContext<ISettingsContext | undefined>(SettingsContext)!;

  // Component classes
  const classes: IResize = {
    sm: {
      main: 'block md:hidden cursor-pointer',
      min: 'absolute z-20 -top-2 right-1/2 fa-solid fa-circle-chevron-up text-gray-400 opacity-50 hover:opacity-100 hover:text-red-500 md:-right-2 md:top-1/2',
      max: 'absolute z-20 -top-2 right-1/2 fa-solid fa-circle-chevron-down text-gray-400 opacity-50 hover:opacity-100 hover:text-red-500 md:-right-2 md:top-1/2',
    },
    md: {
      main: 'hidden md:block cursor-pointer',
      min: 'absolute z-20 fa-solid fa-circle-chevron-right text-gray-400 opacity-50 hover:opacity-100 hover:text-red-500 md:-right-2 md:top-1/2',
      max: 'absolute z-20 -top-2 right-1/2 fa-solid fa-circle-chevron-left text-gray-400 opacity-50 hover:opacity-100 hover:text-red-500 md:-right-2 md:top-1/2',
    },
  };

  const size: IClasses = width <= 768 ? classes.sm : classes.md;

  return (
    <ResizeButton
      main={size.main}
      min={size.min}
      max={size.max}
    />
  );
};

export default Resize;
