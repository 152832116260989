import { IStringProps } from 'iApp';
import { useMemo } from 'react';

const DocVerticalityLine = ({ content, isActive, handler }) => {
  const classes: IStringProps = useMemo(
    () => ({
      container: 'relative flex group w-full px-6 items-center',
      edit: 'fa-regular fa-pen-to-square absolute left-1 hidden group-hover:flex',
      borderActive: 'flex w-full border-x border-x-4 border-x-red-600',
      borderInactive: 'flex w-full border-x border-x-4 border-x-white',
      mast: 'flex w-1/5 justify-center',
      subMast:
        'flex w-1/3 border border-stone-500 text-center font-bold justify-center items-center',
      twistPlumb: 'flex w-2/5 justify-center',
      subTwistPlumb:
        'flex w-1/5 border border-stone-500 text-center font-bold justify-center items-center',
      remove:
        'fa-solid fa-circle-minus absolute right-1 hidden group-hover:flex',
    }),
    []
  );

  return (
    <div className={classes.container}>
      <i
        className={classes.edit}
        onClick={handler.edit}
      />
      <div className={isActive ? classes.borderActive : classes.borderInactive}>
        <div className={classes.mast}>
          <div className={classes.subMast}>
            <span>{content.mast.elevation}</span>
          </div>
          <div className={classes.subMast}>
            <span>{content.mast.width}</span>
          </div>
          <div className={classes.subMast}>
            <span>{content.mast.legSize}</span>
          </div>
        </div>
        <div className={classes.twistPlumb}>
          <div className={classes.subTwistPlumb}>
            <span>{content.twist.measured}</span>
          </div>
          <div className={classes.subTwistPlumb}>
            <span>{content.twist.allowable}</span>
          </div>
          <div className={classes.subTwistPlumb}>
            <span>{content.twist.relativeBetween}</span>
          </div>
          <div className={classes.subTwistPlumb}>
            <span>{content.twist.allowableBetween}</span>
          </div>
          <div className={classes.subTwistPlumb}>
            <span>{content.twist.limit ? 'PASS' : 'FAIL'}</span>
          </div>
        </div>
        <div className={classes.twistPlumb}>
          <div className={classes.subTwistPlumb}>
            <span>{content.plumb.measured}</span>
          </div>
          <div className={classes.subTwistPlumb}>
            <span>{content.plumb.allowable}</span>
          </div>
          <div className={classes.subTwistPlumb}>
            <span>{content.plumb.relativeBetween}</span>
          </div>
          <div className={classes.subTwistPlumb}>
            <span>{content.plumb.allowableBetween}</span>
          </div>
          <div className={classes.subTwistPlumb}>
            <span>{content.plumb.limit ? 'PASS' : 'FAIL'}</span>
          </div>
        </div>
      </div>
      <i
        className={classes.remove}
        onClick={handler.remove}
      />
    </div>
  );
};

export default DocVerticalityLine;
