import PDFTableOfContentsLine from './PDFTableOfContentsLine';
import { Page, StyleSheet, Text } from '@react-pdf/renderer';
import { IPDFContents } from '../iDocumentEditor';
import PDFFooter from '../headerFooter/PDFFooter';
import PDFHeader from '../headerFooter/PDFHeader';
import { ReactNode, useMemo } from 'react';

const PDFTableOfContents = ({ contents }: IPDFContents) => {
  const { content } = contents.tableOfContents;

  const styles = useMemo(
    () =>
      StyleSheet.create({
        page: {
          padding: '0.5in',
          height: '100%',
          width: '100%',
          justifyContent: 'flex-start',
        },
        title: {
          width: '100%',
          fontWeight: 'bold',
          textDecoration: 'underline',
          fontSize: '12px',
          marginBottom: '20px',
          textAlign: 'center',
        },
        tableOfContents: {
          height: '90%',
        },
        tableOfContentsLine: {
          fontSize: '13px',
        },
      }),
    []
  );

  const tableContents: ReactNode[] = useMemo(
    () =>
      Object.keys(content).map(
        (id, index): ReactNode => (
          <PDFTableOfContentsLine
            content={content[id]}
            index={index}
            key={id}
          />
        )
      ),
    [content]
  );

  return (
    <Page
      wrap
      size='A4'
      style={styles.page}>
      {contents.header.active && (
        <PDFHeader contents={contents.header.contents} />
      )}
      <Text style={styles.title}>TABLE OF CONTENTS</Text>
      {tableContents}
      {contents.header.active && (
        <PDFFooter contents={contents.footer.contents} />
      )}
    </Page>
  );
};

export default PDFTableOfContents;
