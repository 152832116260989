import { ILibrariesList } from '../iManageLibraries';
import LibraryCheckBox from './LibraryCheckBox';
import { ReactNode, useMemo } from 'react';

const LibrariesList = ({ library, activeLibrary, handler }: ILibrariesList) => {
  // Renders library list items
  const list: ReactNode[] = useMemo(
    () =>
      library.map(
        (name: string): ReactNode => (
          <LibraryCheckBox
            key={name}
            name={name}
            library={activeLibrary}
            handler={() => handler(name)}
          />
        )
      ),
    [library, activeLibrary, handler]
  );
  return <>{list}</>;
};

export default LibrariesList;
