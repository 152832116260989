import { allIcons, company, userJohn } from './developmentData';
import { ISettingsProviderProps } from './iContext';
import { useState, useEffect } from 'react';
import { IStringProps } from 'iApp';
import {
  UserContext,
  CropContext,
  HeaderContext,
  CompanyContext,
  SettingsContext,
} from './context';
import {
  version,
  fileTypes,
  measurements,
  userDropdown,
  headerButtons,
  defaultCropConfig,
  defaultMenuFilters,
} from './state';
import {
  IUser,
  IAppChildren,
  ILibraryList,
  IMenuFilters,
  IHeaderButtons,
  IScreenDimensions,
} from './iState';

const TopStateProvider = ({ children }: IAppChildren) => {
  // User State
  const [user, setUser] = useState<IUser>(userJohn);
  const [favorites, setFavorites] = useState<number[]>(user.favorites);
  const [searchBar, setSearchBar] = useState<string>('');
  const [searchDate, setSearchDate] = useState<string>('');
  const [searchRange, setSearchRange] = useState<string>('');
  const [rangeSearchActive, setRangeSearchActive] = useState<boolean>(false);
  const lowCaseSearch: string = searchBar.toLowerCase().trim();

  // Header States
  const [activeHeaderButton, setActiveHeaderButton] =
    useState<IHeaderButtons>(headerButtons);
  const [menuFilters, setMenuFilters] =
    useState<IMenuFilters>(defaultMenuFilters);
  const [lastActiveView, setLastActiveView] =
    useState<IHeaderButtons>(activeHeaderButton);
  const [activeCanvasTab, setActiveCanvasTab] = useState<string>('');
  const { tower, site, data, drawings, files, settings } = activeHeaderButton;
  // Determines if user is currently on the home page
  const homePage: boolean =
    !tower && !drawings && !site && !data && !files && !settings;

  // Settings States
  const [isSettingsOpen, setIsSettingsOpen] = useState<boolean>(false);
  const [minimize, setMinimize] = useState<boolean>(false);
  const [metric, setMetric] = useState<boolean>(false);
  const [screenDimensions, setScreenDimensions] = useState<IScreenDimensions>({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const scale: string = metric ? 'm' : 'ft';

  // Company States
  const [jobTitles, setJobTitles] = useState<string[]>(company.jobTitles);
  const [companyLibraries, setCompanyLibraries] = useState<ILibraryList>(
    company.libraries
  );
  const [icons, setIcons] = useState<IStringProps[]>({
    ...allIcons.standard,
    ...allIcons[company.name],
  });

  // Crop Image States
  const [isCropActive, setIsCropActive] = useState<boolean>(false);
  const [image, setImage] = useState<string>('');

  // Closes header dropdown windows
  const clearActiveButtons = (): void => {
    setActiveHeaderButton((prev: IHeaderButtons) => ({
      ...prev,
      siteTypes: false,
      account: false,
    }));
  };

  // Toggles min/max site panel size
  const changePanelSize = (search: boolean = false): void =>
    search ? setMinimize(false) : setMinimize((prev: boolean) => !prev);

  // Watches and sets window width into state
  useEffect(() => {
    const resizeWidth = () => {
      setScreenDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', resizeWidth);
  }, []);

  useEffect(() => {
    const { siteTypes, account, settings, ...rest } = activeHeaderButton;
    const canvasTab: string = Object.keys(rest).filter(
      (tab) => activeHeaderButton[tab]
    )[0];
    setActiveCanvasTab(canvasTab);
  }, [activeHeaderButton]);

  // Props to pass to their respective elements
  const providerProps: ISettingsProviderProps = {
    user: {
      user,
      setUser,
      favorites,
      setFavorites,
      searchBar,
      setSearchBar,
      searchDate,
      setSearchDate,
      searchRange,
      setSearchRange,
      rangeSearchActive,
      setRangeSearchActive,
      lowCaseSearch,
    },
    header: {
      homePage,
      menuFilters,
      userDropdown,
      lastActiveView,
      setMenuFilters,
      activeCanvasTab,
      setLastActiveView,
      activeHeaderButton,
      clearActiveButtons,
      setActiveHeaderButton,
    },
    settings: {
      metric,
      setMetric,
      scale,
      fileTypes,
      minimize,
      setMinimize,
      screenDimensions,
      measurements,
      changePanelSize,
      isSettingsOpen,
      setIsSettingsOpen,
      defaultCropConfig,
      version,
    },
    company: {
      icons,
      setIcons,
      company,
      jobTitles,
      setJobTitles,
      companyLibraries,
      setCompanyLibraries,
    },
    crop: {
      isCropActive,
      setIsCropActive,
      image,
      setImage,
    },
  };

  return (
    <CropContext.Provider value={providerProps.crop}>
      <UserContext.Provider value={providerProps.user}>
        <SettingsContext.Provider value={providerProps.settings}>
          <CompanyContext.Provider value={providerProps.company}>
            <HeaderContext.Provider value={providerProps.header}>
              {children}
            </HeaderContext.Provider>
          </CompanyContext.Provider>
        </SettingsContext.Provider>
      </UserContext.Provider>
    </CropContext.Provider>
  );
};

export default TopStateProvider;
