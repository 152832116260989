import { IDocumentContext } from 'state/iContext';
import { DocumentContext } from 'state/context';
import { useContext } from 'react';
import { IStringProps } from 'iApp';

const DocCroppedImage = ({ id, handlers, panel }) => {
  const { editFileProps } = useContext<IDocumentContext | undefined>(
    DocumentContext
  )!;

  const classes: IStringProps = {
    container: 'flex w-full h-[100px] border',
    img: 'w-[100px] h-[100px] object-contain border',
    label: 'p-1 w-full h-full break-words',
    buttons: 'flex flex-col',
    edit: 'flex fa-regular fa-pen-to-square h-1/2 justify-center items-center bg-red-700 text-slate-200 rounded-tr opacity-50 hover:bg-red-600 hover:opacity-100',
    remove:
      'flex fa-regular fa-trash-can h-1/2 justify-center items-center bg-red-700 text-slate-200 rounded-br opacity-50 hover:bg-red-600 hover:opacity-100',
  };

  return (
    <div className={classes.container}>
      <img
        className={classes.img}
        alt={`cropped-image-${id}`}
        src={editFileProps.contents.inventory.images[panel][id].image}
      />
      <div className={classes.label}>
        {editFileProps.contents.inventory.images[panel][id].label}
      </div>
      <div className={classes.buttons}>
        <i
          className={classes.edit}
          onClick={handlers.edit}
        />
        <i
          className={classes.remove}
          onClick={handlers.remove}
        />
      </div>
    </div>
  );
};

export default DocCroppedImage;