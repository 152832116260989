import { ISettingsContext } from 'state/iContext';
import { IInputEvent, IStringProps } from 'iApp';
import { IVerticalityProps } from 'state/iState';
import { SettingsContext } from 'state/context';
import { useContext } from 'react';

const DocVerticalityInputs = ({ inputs, setInputs }) => {
  const { scale } = useContext<ISettingsContext | undefined>(SettingsContext)!;

  const classes: IStringProps = {
    inputs:
      'flex flex-col w-full h-2/5 border rounded rounded-lg bg-slate-200 p-1 m-1',
    section: 'flex w-full justify-center',
    third: 'w-1/3 text-center',
    label: 'text-sm font-bold text-stone-500',
    input: 'p-0.5 border border-stone-500 cursor-text text-center w-full',
    fifth: 'flex flex-col w-1/5 h-[80px] justify-between text-center',
    limit:
      'flex w-full min-h-[30px] border border-stone-500 justify-center items-center bg-white',
  };

  return (
    <div className={classes.inputs}>
      <div className={classes.section}>
        <div className={classes.third}>
          <label
            htmlFor='elev'
            className={classes.label}>{`Mast Elev ${scale}`}</label>
          <input
            name='elev'
            type='number'
            className={classes.input}
            value={inputs.mast.elevation}
            placeholder={`Mast Elev ${scale}`}
            onChange={(e: IInputEvent) =>
              setInputs((prev: IVerticalityProps) => ({
                ...prev,
                mast: {
                  ...prev.mast,
                  elevation: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className={classes.third}>
          <label
            htmlFor='width'
            className={classes.label}>{`Face Width (mm)`}</label>
          <input
            name='width'
            type='number'
            className={classes.input}
            value={inputs.mast.width}
            placeholder={`Face Width (mm)`}
            onChange={(e: IInputEvent) =>
              setInputs((prev: IVerticalityProps) => ({
                ...prev,
                mast: {
                  ...prev.mast,
                  width: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className={classes.third}>
          <label
            htmlFor='size'
            className={classes.label}>{`Leg Size (mm)`}</label>
          <input
            name='size'
            type='number'
            className={classes.input}
            value={inputs.mast.legSize}
            placeholder={`Leg Size (mm)`}
            onChange={(e: IInputEvent) =>
              setInputs((prev: IVerticalityProps) => ({
                ...prev,
                mast: {
                  ...prev.mast,
                  legSize: e.target.value,
                },
              }))
            }
          />
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.fifth}>
          <label
            htmlFor='total-measured-twist'
            className={classes.label}>
            Total Measured Twist
          </label>
          <input
            name='total-measured-twist'
            type='number'
            className={classes.input}
            value={inputs.twist.measured}
            placeholder='Total Measured Twist'
            onChange={(e: IInputEvent) =>
              setInputs((prev: IVerticalityProps) => ({
                ...prev,
                twist: {
                  ...prev.twist,
                  measured: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className={classes.fifth}>
          <label
            htmlFor='total-allowable-twist'
            className={classes.label}>
            Total Allowable Twist
          </label>
          <input
            name='total-allowable-twist'
            type='number'
            className={classes.input}
            value={inputs.twist.allowable}
            placeholder='Total Allowable Twist'
            onChange={(e: IInputEvent) =>
              setInputs((prev: IVerticalityProps) => ({
                ...prev,
                twist: {
                  ...prev.twist,
                  allowable: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className={classes.fifth}>
          <label
            htmlFor='relative-twist'
            className={classes.label}>
            Relative Twist Between Elevations
          </label>
          <input
            name='relative-twist'
            type='number'
            className={classes.input}
            value={inputs.twist.relativeBetween}
            placeholder='Relative Twist Between Elevations'
            onChange={(e: IInputEvent) =>
              setInputs((prev: IVerticalityProps) => ({
                ...prev,
                twist: {
                  ...prev.twist,
                  relativeBetween: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className={classes.fifth}>
          <label
            htmlFor='allowable-twist'
            className={classes.label}>
            Allowable Twist Between Elevations
          </label>
          <input
            name='allowable-twist'
            type='number'
            className={classes.input}
            value={inputs.twist.allowableBetween}
            placeholder='Allowable Twist Between Elevations'
            onChange={(e: IInputEvent) =>
              setInputs((prev: IVerticalityProps) => ({
                ...prev,
                twist: {
                  ...prev.twist,
                  allowableBetween: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className={classes.fifth}>
          <label
            htmlFor='twist-limit'
            className={classes.label}>
            Within S37 Limits
          </label>
          <div className={classes.limit}>
            <input
              type='checkbox'
              id='twist-limit'
              name='twist-limit'
              checked={inputs.twist.limit}
              onChange={(e: IInputEvent) =>
                setInputs((prev: IVerticalityProps) => ({
                  ...prev,
                  twist: {
                    ...prev.twist,
                    limit: !prev.twist.limit,
                  },
                }))
              }
            />
          </div>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.fifth}>
          <label
            htmlFor='total-measured-deflection'
            className={classes.label}>
            Total Measured Deflection
          </label>
          <input
            name='total-measured-deflection'
            type='number'
            className={classes.input}
            value={inputs.plumb.measured}
            placeholder='Total Measured Deflection'
            onChange={(e: IInputEvent) =>
              setInputs((prev: IVerticalityProps) => ({
                ...prev,
                plumb: {
                  ...prev.plumb,
                  measured: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className={classes.fifth}>
          <label
            htmlFor='total-allowable-deflection'
            className={classes.label}>
            Total Allowable Deflection
          </label>
          <input
            name='total-allowable-deflection'
            type='number'
            className={classes.input}
            value={inputs.plumb.allowable}
            placeholder='Total Allowable Deflection'
            onChange={(e: IInputEvent) =>
              setInputs((prev: IVerticalityProps) => ({
                ...prev,
                plumb: {
                  ...prev.plumb,
                  allowable: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className={classes.fifth}>
          <label
            htmlFor='relative-deflection'
            className={classes.label}>
            Relative Deflection Between Elevations
          </label>
          <input
            name='relative-deflection'
            type='number'
            className={classes.input}
            value={inputs.plumb.relativeBetween}
            placeholder='Relative Deflection Between Elevations'
            onChange={(e: IInputEvent) =>
              setInputs((prev: IVerticalityProps) => ({
                ...prev,
                plumb: {
                  ...prev.plumb,
                  relativeBetween: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className={classes.fifth}>
          <label
            htmlFor='allowable-deflection'
            className={classes.label}>
            Allowable Deflection Between Elevations
          </label>
          <input
            name='allowable-deflection'
            type='number'
            className={classes.input}
            value={inputs.plumb.allowableBetween}
            placeholder='Allowable Deflection Between Elevations'
            onChange={(e: IInputEvent) =>
              setInputs((prev: IVerticalityProps) => ({
                ...prev,
                plumb: {
                  ...prev.plumb,
                  allowableBetween: e.target.value,
                },
              }))
            }
          />
        </div>
        <div className={classes.fifth}>
          <label
            htmlFor='deflection-limit'
            className={classes.label}>
            Within S37 Limits
          </label>
          <div className={classes.limit}>
            <input
              type='checkbox'
              id='deflection-limit'
              name='deflection-limit'
              checked={inputs.plumb.limit}
              onChange={(e: IInputEvent) =>
                setInputs((prev: IVerticalityProps) => ({
                  ...prev,
                  plumb: {
                    ...prev.plumb,
                    limit: !prev.plumb.limit,
                  },
                }))
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DocVerticalityInputs;
