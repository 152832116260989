import { IDocInspectionChecklistProps } from '../iDocumentEditor';
import { ReactNode, useContext, useMemo } from 'react';
import { IInspectionChecklist } from 'state/iState';
import { IDocumentContext } from 'state/iContext';
import { IInputEvent, IStringProps } from 'iApp';
import { DocumentContext } from 'state/context';

const DocInspectionChecklistLines = ({
  categoryID,
  category,
  id,
}: IDocInspectionChecklistProps) => {
  const { checklistData, setChecklistData } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;

  const classes: IStringProps = useMemo(
    () => ({
      container: 'flex w-full justify-center',
      inspectionID:
        'w-[5%] border border-stone-500 text-center text-stone-600 text-[15px]',
      title:
        'w-[40%] border border-stone-500 text-start text-stone-600 text-[15px] pl-2',
      checkbox:
        'flex w-[5%] border border-stone-500 text-center text-stone-600 text-[15px] justify-center items-center',
      comments:
        'flex w-[40%] border border-stone-500 text-center text-stone-600 text-[15px] justify-center items-center',
      textarea: 'w-full h-full p-0.5',
    }),
    []
  );

  const onChange = (checkbox: string, category: string, line: string) =>
    setChecklistData((prev: IInspectionChecklist) => ({
      ...prev,
      [category]: {
        ...prev[category],
        [line]: {
          ...prev[category][line],
          grade: prev[category][line].grade === checkbox ? '' : checkbox,
        },
      },
    }));

  const lines = Object.keys(checklistData[category]).map(
    (line: string, i: number): ReactNode => {
      const inspectionID: string = `${categoryID[id]}${i + 1}`;
      return (
        <div
          key={`${category}-${line}`}
          className={classes.container}>
          <div className={classes.inspectionID}>
            <span>{inspectionID}</span>
          </div>
          <div className={classes.title}>
            <span>{checklistData[category][line].title}</span>
          </div>
          <div className={classes.checkbox}>
            <input
              type='checkbox'
              id={`${inspectionID}-S`}
              name={`${inspectionID}-S`}
              checked={checklistData[category][line].grade === 's'}
              onChange={() => onChange('s', category, line)}
            />
          </div>
          <div className={classes.checkbox}>
            <input
              type='checkbox'
              id={`${inspectionID}-U`}
              name={`${inspectionID}-U`}
              checked={checklistData[category][line].grade === 'u'}
              onChange={() => onChange('u', category, line)}
            />
          </div>
          <div className={classes.checkbox}>
            <input
              type='checkbox'
              id={`${inspectionID}-NA`}
              name={`${inspectionID}-NA`}
              checked={checklistData[category][line].grade === 'na'}
              onChange={() => onChange('na', category, line)}
            />
          </div>
          <div className={classes.comments}>
            <textarea
              className={classes.textarea}
              name={`${inspectionID}-comments`}
              defaultValue={checklistData[category][line].comments}
              onChange={(e: IInputEvent) =>
                setChecklistData((prev: IInspectionChecklist) => ({
                  ...prev,
                  [category]: {
                    ...prev[category],
                    [line]: {
                      ...prev[category][line],
                      comments: e.target.value,
                    },
                  },
                }))
              }
            />
          </div>
        </div>
      );
    }
  );

  return <>{lines}</>;
};

export default DocInspectionChecklistLines;
