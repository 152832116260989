import { GoogleMap, InfoWindow, MarkerClusterer } from '@react-google-maps/api';
import TowerDistanceLine from './TowerDistanceLine';
import NewSiteMarker from './markers/NewSiteMarker';
import SiteMarkers from './markers/SiteMarkers';
import { IMapConfig, IMapProps } from './IMap';
import { useContext, useEffect } from 'react';
import MapButtons from './buttons/MapButtons';
import {
  midPoint,
  measurementType,
  haversineDistance,
} from 'helpers/helperFunctions';
import { MapContext, EditContext, SettingsContext } from 'state/context';
import { LatLngLiteral, IMeasurelength } from 'state/iState';
import { IMapContext, IEditContext, ISettingsContext } from 'state/iContext';
import Footer from 'Footer';

const Map = () => {
  const {
    zoom,
    center,
    onLoad,
    mapRef,
    setCenter,
    setInfoWindowID,
    measureEndPoints,
  } = useContext<IMapContext | undefined>(MapContext)!;
  const { isEditPanelOpen, newSite } = useContext<IEditContext | undefined>(
    EditContext
  )!;
  const { metric, measurements } = useContext<ISettingsContext | undefined>(
    SettingsContext
  )!;

  // Displays measurement units
  const measurement: IMeasurelength = measurementType(metric, measurements);
  // Determines distance polyline center
  const measureDistanceMidpoint: LatLngLiteral =
    measureEndPoints.start && measureEndPoints.end
      ? midPoint(measureEndPoints.start, measureEndPoints.end)
      : null;

  // Displays measured distance if end points are true
  const measuredDistance: number | null =
    measureEndPoints.start && measureEndPoints.end
      ? Number(
          haversineDistance(
            measureEndPoints.start,
            measureEndPoints.end,
            measurement.long
          ).toFixed(2)
        )
      : null;

  useEffect(() => {
    setInfoWindowID('');
  }, [isEditPanelOpen, setInfoWindowID]);

  // Map Configuration details
  const mapConfig: IMapConfig = {
    main: 'relative z-0 w-full h-full',
    mapContainerStyle: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      right: 0,
    },
    options: {
      mapId: 'ce884115fb7eda1d',
      allowInfoboxOverflow: true,
      fullscreenControl: false,
      showLocateMeButton: true,
      keyboardShortcuts: true,
      streetViewControl: true,
      zoomControl: false,
      minZoom: 2,
    } as google.maps.MapOptions,
  };
  const { mapContainerStyle, options } = mapConfig;

  const newCenter = (): void => {
    if (mapRef.current) {
      setCenter({
        lat: mapRef.current.center.lat(),
        lng: mapRef.current.center.lng(),
      });
    }
  };

  // Props to pass to their respective elements
  const mapProps: IMapProps = {
    mapContainerStyle,
    zoom,
    center,
    onLoad,
    options,
    minZoom: 3,
    onClick: () => setInfoWindowID(''),
    onZoomChanged: () => {
      if (!isEditPanelOpen) {
        newCenter();
      }
    },
  };

  return (
    <div
      id='map'
      className={mapConfig.main}>
      <GoogleMap {...mapProps}>
        <MapButtons />
        {newSite && <NewSiteMarker />}
        <MarkerClusterer>
          {(clusterer) => <SiteMarkers clusterer={clusterer} />}
        </MarkerClusterer>
        {measureEndPoints.start && measureEndPoints.end && (
          <>
            <TowerDistanceLine />
            <InfoWindow position={measureDistanceMidpoint!}>
              <>
                {measuredDistance} {measurement.long}
              </>
            </InfoWindow>
          </>
        )}
      </GoogleMap>
      <Footer />
    </div>
  );
};

export default Map;
