import { IEditFileProps, IVerticality, IVerticalityProps } from 'state/iState';
import { ReactNode, useContext, useEffect, useState } from 'react';
import { nextAvailableKey } from 'helpers/helperFunctions';
import DocVerticalityInputs from './DocVerticalityInputs';
import DocVerticalityTable from './DocVerticalityTable';
import DocVerticalityLine from './DocVerticalityLine';
import { IDocumentContext } from 'state/iContext';
import { DocumentContext } from 'state/context';
import ToggleOption from '../ToggleOption';
import { IStringProps } from 'iApp';

const DocVerticality = () => {
  const { editFileProps, setEditFileProps, setActiveFilePanel } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;

  const clearInputs: IVerticalityProps = {
    mast: {
      elevation: '',
      width: '',
      legSize: '',
    },
    twist: {
      measured: '',
      allowable: '',
      relativeBetween: '',
      allowableBetween: '',
      limit: false,
    },
    plumb: {
      measured: '',
      allowable: '',
      relativeBetween: '',
      allowableBetween: '',
      limit: false,
    },
  };

  const [inputs, setInputs] = useState<IVerticalityProps>(clearInputs);
  const [activeEdit, setActiveEdit] = useState<string>();

  //TODO: CORRECT SUB SCALE for mm/inches

  const { mast, twist, plumb } = inputs;
  const emptyInputs: boolean =
    !mast.elevation ||
    !mast.width ||
    !mast.legSize ||
    !twist.measured ||
    !twist.allowable ||
    !twist.relativeBetween ||
    !twist.allowableBetween ||
    !plumb.measured ||
    !plumb.allowable ||
    !plumb.relativeBetween ||
    !plumb.allowableBetween

  const verticality: IVerticality = editFileProps.contents.verticality;
  const nextKey: number = nextAvailableKey(verticality.content, 0)!;
  const content = Object.keys(verticality.content);

  const classes: IStringProps = {
    container: 'flex flex-col w-full h-full p-1 text-stone-500 items-center',
    top: 'w-1/2 h-1/6',
    title: 'w-full p-2 text-center text-lg text-stone-400 font-bold',
    buttons: 'flex w-full justify-between p-2 m-2',
    back: 'p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
    update:
      'p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600 disabled:bg-stone-400',
  };

  const lines: ReactNode[] = content.map((id) => {
    const { [+id]: toBeRemoved, ...rest } = verticality.content;
    return (
      <DocVerticalityLine
        key={id}
        isActive={activeEdit === id}
        content={verticality.content[id]}
        handler={{
          edit: () => {
            setActiveEdit(id);
            setInputs(verticality.content[id]);
            if (activeEdit === id) {
              setActiveEdit(undefined);
              setInputs(clearInputs);
            }
          },
          remove: () => {
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                verticality: {
                  ...prev.contents.verticality,
                  content: rest,
                },
              },
            }));
            setActiveEdit(undefined);
            setInputs(clearInputs);
          },
        }}
      />
    );
  });

  useEffect(() => {
    if (!verticality.active) {
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          pages: prev.contents.pages.filter(
            (str: string) => str !== 'verticality'
          ),
        },
      }));
    }
  }, [verticality.active]);

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <h1 className={classes.title}>Verticality</h1>
        <ToggleOption
          name='Active Page'
          value={verticality.active}
          onChange={() => {
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                verticality: {
                  ...prev.contents.verticality,
                  active: !prev.contents.verticality.active,
                },
                pages: [...prev.contents.pages, 'verticality'],
              },
            }));
          }}
        />
      </div>
      {content.length > 0 && <DocVerticalityTable lines={lines} />}
      <DocVerticalityInputs
        setInputs={setInputs}
        inputs={inputs}
      />
      <div className={classes.buttons}>
        <button
          onClick={() => setActiveFilePanel('')}
          className={classes.back}>
          Back
        </button>
        <button
          disabled={emptyInputs}
          onClick={() => {
            setEditFileProps((prev: IEditFileProps) => ({
              ...prev,
              contents: {
                ...prev.contents,
                verticality: {
                  ...prev.contents.verticality,
                  content: {
                    ...prev.contents.verticality.content,
                    [activeEdit ? +activeEdit : nextKey]: inputs,
                  },
                },
              },
            }));
            setInputs(clearInputs);
          }}
          className={classes.update}>
          {activeEdit ? 'Update' : 'Add'}
        </button>
      </div>
    </div>
  );
};

export default DocVerticality;
