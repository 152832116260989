import { ISettingsOptionClasses } from '../../iSettings';
import { ISettingsContext } from 'state/iContext';
import { SettingsContext } from 'state/context';
import { ISelectEvent } from 'iApp';
import { useContext } from 'react';

const Measurement = () => {
  const { metric, setMetric } = useContext<ISettingsContext | undefined>(SettingsContext)!;

  // Classes
  const classes: ISettingsOptionClasses = {
    label: 'font-semibold',
    select: 'rounded-sm border border-slate-400',
  };

  const defaultMeasurement: string = metric ? 'metric' : 'imperial';

  // Toggles Metric/Imperial measurements throughout app
  const measureChangeHandler = (e: ISelectEvent): void => {
    const value: boolean = e.target.value === 'metric' ? true : false;
    setMetric(value);
  };

  return (
    <>
      <span className={classes.label}>Measurements</span>
      <select
        name='measurements'
        id='measurement-toggle'
        className={classes.select}
        onChange={measureChangeHandler}
        defaultValue={defaultMeasurement}>
        <option value='metric'>Metric</option>
        <option value='imperial'>Imperial</option>
      </select>
    </>
  );
};

export default Measurement;
