import { DocumentContext, CanvasContext, SitesContext } from 'state/context';
import DocInventoryImagePanel from './panels/DocInventoryImagePanel';
import { useContext, useEffect, useMemo, useState } from 'react';
import DocInventoryPanel from './panels/DocInventoryPanel';
import { IEditFileProps } from 'state/iState';
import ToggleOption from '../ToggleOption';
import { IStringProps } from 'iApp';
import {
  IDocumentContext,
  ICanvasContext,
  ISitesContext,
} from 'state/iContext';

const DocInventoryInfo = () => {
  const { editFileProps, setEditFileProps, setActiveFilePanel } = useContext<
    IDocumentContext | undefined
  >(DocumentContext)!;
  const { towerList } = useContext<ISitesContext | undefined>(SitesContext)!;
  const { activeTowerID } = useContext<ICanvasContext | undefined>(
    CanvasContext
  )!;

  const [panel, setPanel] = useState<
    'inventory' | 'tower' | 'rooftop' | 'data'
  >('inventory');

  const classes: IStringProps = useMemo(
    () => ({
      container:
        'flex flex-col w-full h-full p-1 text-stone-500 items-center justify-between',
      content: 'flex flex-col w-full h-5/6 items-center',
      title: 'w-full p-2 text-center text-lg text-stone-400 font-bold',
      toggle: 'w-1/2',
      panels: 'w-full h-5/6',
      tabs: 'flex px-2',
      tab: 'p-1 border border-b border-b-0 border-stone-400 rounded-tr rounded-tl rounded-top-xl',
      activeTab:
        'p-1 border border-b border-b-0 border-slate-400 bg-red-700 text-slate-200 rounded-tr rounded-tl rounded-top-xl',
      button:
        'p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
    }),
    []
  );

  useEffect(() => {
    if (
      !editFileProps.contents.data.activePages.inventory &&
      !editFileProps.contents.data.activePages.image
    ) {
      setEditFileProps((prev: IEditFileProps) => ({
        ...prev,
        contents: {
          ...prev.contents,
          pages: prev.contents.pages.filter(
            (str: string) => str !== 'inventory'
          ),
        },
      }));
    }
  }, [editFileProps.contents.data.activePages, setEditFileProps]);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <h1 className={classes.title}>Inventory</h1>
        <div className={classes.toggle}>
          <ToggleOption
            name='Active On PDF'
            value={editFileProps.contents.inventory.active}
            onChange={() =>
              setEditFileProps((prev: IEditFileProps) => ({
                ...prev,
                contents: {
                  ...prev.contents,
                  inventory: {
                    ...prev.contents.inventory,
                    active: !prev.contents.inventory.active,
                  },
                  pages: [...prev.contents.pages, 'inventory'],
                },
              }))
            }
          />
        </div>
        <div className={classes.panels}>
          <div className={classes.tabs}>
            <div
              onClick={() => setPanel('inventory')}
              className={
                panel === 'inventory' ? classes.activeTab : classes.tab
              }>
              Inventory
            </div>
            {Object.keys(towerList[activeTowerID].layout.tower.legs).length >
              0 && (
              <div
                onClick={() => setPanel('tower')}
                className={panel === 'tower' ? classes.activeTab : classes.tab}>
                Tower Images
              </div>
            )}
            {towerList[activeTowerID].layout.site.image && (
              <div
                onClick={() => setPanel('rooftop')}
                className={
                  panel === 'rooftop' ? classes.activeTab : classes.tab
                }>
                Rooftop Images
              </div>
            )}
            <div
              onClick={() => setPanel('data')}
              className={panel === 'data' ? classes.activeTab : classes.tab}>
              data Images
            </div>
          </div>
          {panel === 'inventory' ? (
            <DocInventoryPanel />
          ) : (
            <DocInventoryImagePanel panel={panel} />
          )}
        </div>
      </div>
      <button
        onClick={() => setActiveFilePanel('')}
        className={classes.button}>
        Back
      </button>
    </div>
  );
};

export default DocInventoryInfo;
