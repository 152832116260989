import { IGridItem } from 'components/application/canvas/gridItems/iGridItems';
import GridTowerData from '../items/GridTowerData';
import LeaseSections from '../LeaseSections';
import { ICanvasContext } from 'state/iContext';
import { CanvasContext } from 'state/context';
import PropertyLabel from '../PropertyLabel';
import GridBodyScale from './GridBodyScale';
import DragHandle from '../DragHandle';
import { useContext } from 'react';

const GridBody = ({ layout, type }: IGridItem) => {
  const {
    activeToolBar: { Properties, Lease },
  } = useContext<ICanvasContext | undefined>(CanvasContext)!;

  return (
    <>
      <DragHandle />
      {Properties && (
        <PropertyLabel
          label={layout.name!}
          type='box'
        />
      )}
      <GridTowerData
        layout={[layout]}
        id={layout.i!}
        type={type}
      />
      {Lease && (
        <LeaseSections
          sectionLayout={[layout]}
          id={layout.i!}
          type={type}
        />
      )}
      <GridBodyScale height={layout.h} />
    </>
  );
};

export default GridBody;
