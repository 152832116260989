import { ISitesContext } from 'state/iContext';
import { SitesContext } from 'state/context';
import { useContext } from 'react';
import Tab from './Tab';

const SiteListTabs = () => {
  const { filteredSiteIds, favoriteSites } = useContext<
    ISitesContext | undefined
  >(SitesContext)!;

  return (
    <div
      id='list-tab'
      className='flex w-full md:w-[270px]'>
      <Tab
        id={'all-sites-tab'}
        name={'All Sites'}
        total={filteredSiteIds.length}
        onScreen={filteredSiteIds.length}
      />
      <Tab
        id={'favorites-tab'}
        name={'Favorite Sites'}
        total={favoriteSites.length}
        onScreen={favoriteSites.length}
      />
    </div>
  );
};

export default SiteListTabs;
