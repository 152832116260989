import { CanvasContext, HeaderContext, SettingsContext } from 'state/context';
import { ReactNode, useContext } from 'react';
import EditSection from './EditSection';
import SectionLine from './SectionLine';
import {
  ICanvasContext,
  IHeaderContext,
  ISettingsContext,
} from 'state/iContext';

const SectionBlocks = ({ sections, containers, name, i }) => {
  const { scale } = useContext<ISettingsContext | undefined>(SettingsContext)!;
  const { addItemProps, setAddItemProps } = useContext<
    ICanvasContext | undefined
  >(CanvasContext)!;
  const { activeCanvasTab } = useContext<IHeaderContext | undefined>(
    HeaderContext
  )!;

  const gridItem: { tower: string[]; data: string[] } = {
    tower: ['legs', 'legGrid', scale],
    data: ['shelves', 'shelfGrid', 'U'],
  };

  const sectionBlocks =
    sections.length > 0 ? (
      sections.map((section: string, index: number): ReactNode => {
        const dataFirst: number =
          containers[i][gridItem[activeCanvasTab][1]].h -
          containers[i].sections[index].y -
          containers[i].sections[index].h;

        const dataLast: number =
          containers[i][gridItem[activeCanvasTab][1]].h -
          containers[i].sections[index].y;

        const measurement: string = gridItem[activeCanvasTab][2];

        const data: string = `${dataFirst}${measurement} - ${dataLast}${measurement}`;

        return (
          <div key={`${name}-${section}`}>
            {+addItemProps.content === +containers[i].sections[index].i &&
            +addItemProps.index! ===
              +containers[i][gridItem[activeCanvasTab][1]].i ? (
              <EditSection />
            ) : (
              <SectionLine
                name={section}
                data={data}
                handler={() =>
                  setAddItemProps({
                    content: containers[i].sections[index].i,
                    index: containers[i][gridItem[activeCanvasTab][1]].i,
                    label: containers[i].sections[index].name,
                  })
                }
              />
            )}
          </div>
        );
      })
    ) : (
      <span className='mx-1 font-bold text-stone-400'>
        No Lease Sections To Display
      </span>
    );

  return <>{sectionBlocks}</>;
};

export default SectionBlocks;
