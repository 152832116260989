import { IHeaderContext, ICanvasContext, ISitesContext } from 'state/iContext';
import { HeaderContext, CanvasContext, SitesContext } from 'state/context';
import { IAddItemProps, IGridLayout, ITowerList } from 'state/iState';
import { useState, ReactNode, useContext, useEffect } from 'react';
import { nextAvailableKey } from 'helpers/helperFunctions';
import SaveBox from 'components/buttons/SaveBox';
import { IInputEvent, IStringProps } from 'iApp';

const EditPanel = () => {
  const { setTowerList, towerList } = useContext<ISitesContext | undefined>(
    SitesContext
  )!;
  const {
    clearPanel,
    addItemProps,
    activeTowerID,
    clearAddProps,
    setAddItemProps,
  } = useContext<ICanvasContext | undefined>(CanvasContext)!;
  const {
    activeCanvasTab,
    activeHeaderButton: { data },
  } = useContext<IHeaderContext | undefined>(HeaderContext)!;

  const siteTabActive: boolean = activeCanvasTab === 'site';
  const dataTarget: string[] = data
    ? ['data', 'shelves', 'shelfGrid']
    : ['tower', 'legs', 'legGrid'];

  const itemList =
    towerList[activeTowerID].layout[dataTarget[0]][dataTarget[1]];
  const nextKey: number = nextAvailableKey(itemList, 0)!;
  const keys: string[] = Object.keys(itemList);
  const gridItems: IGridLayout[] = keys.map(
    (key) => itemList[key][dataTarget[2]]
  );

  const itemPositions = Object.keys(itemList).map(
    (item) => itemList[item][dataTarget[2]].x
  );

  const nextPosition = Math.max(...itemPositions) + 35;

  const [modifyType, setModifyType] = useState<string>(
    siteTabActive ? 'remove' : data ? 'rename' : 'add'
  );
  const [target, setTarget] = useState<number>(addItemProps.index! || 0);

  const { [target]: tobeRemoved, ...rest } = itemList;

  const options: ReactNode[] = gridItems.map(
    (leg): ReactNode => (
      <option
        key={leg.i}
        value={leg.i}>
        {leg.name}
      </option>
    )
  );

  const gridHeight = data
    ? itemList[target][dataTarget[2]].h
    : towerList[activeTowerID].height;

  const saveHandler = {
    add: (prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        layout: {
          ...prev[activeTowerID].layout,
          [dataTarget[0]]: {
            ...prev[activeTowerID].layout.tower,
            [dataTarget[1]]: {
              ...prev[activeTowerID].layout[dataTarget[0]][dataTarget[1]],
              [nextKey]: {
                [dataTarget[2]]: {
                  i: nextKey,
                  name: addItemProps.label,
                  x: nextPosition,
                  y: 500,
                  w: 10,
                  h: gridHeight,
                  resizeHandles: ['n', 's'],
                },
                icons: {
                  '-1': {
                    i: -1,
                    name: '',
                    x: 0,
                    y: gridHeight,
                    w: 0,
                    minW: 0,
                    minH: 0,
                    h: 0,
                    static: true,
                    isResizable: false,
                  },
                },
              },
            },
          },
        },
      },
    }),
    rename: (prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        layout: {
          ...prev[activeTowerID].layout,
          [dataTarget[0]]: {
            ...prev[activeTowerID].layout.tower,
            [dataTarget[1]]: {
              ...prev[activeTowerID].layout[dataTarget[0]][dataTarget[1]],
              [target]: {
                ...prev[activeTowerID].layout[dataTarget[0]][dataTarget[1]][
                  target
                ],
                [dataTarget[2]]: {
                  ...prev[activeTowerID].layout[dataTarget[0]][dataTarget[1]][
                    target
                  ][dataTarget[2]],
                  name: addItemProps.label,
                },
              },
            },
          },
        },
      },
    }),
    copy: (prev: ITowerList) => ({
      ...prev,
      [activeTowerID]: {
        ...prev[activeTowerID],
        layout: {
          ...prev[activeTowerID].layout,
          [dataTarget[0]]: {
            ...prev[activeTowerID].layout.tower,
            [dataTarget[1]]: {
              ...prev[activeTowerID].layout[dataTarget[0]][dataTarget[1]],
              [nextKey]: {
                ...prev[activeTowerID].layout[dataTarget[0]][dataTarget[1]][
                  target
                ],
                [dataTarget[2]]: {
                  i: nextKey,
                  name: addItemProps.label,
                  x: nextPosition,
                  y: 500,
                  w: 10,
                  h: gridHeight,
                  resizeHandles: ['n', 's'],
                },
              },
            },
          },
        },
      },
    }),
    remove: (prev: ITowerList) =>
      !siteTabActive
        ? {
            ...prev,
            [activeTowerID]: {
              ...prev[activeTowerID],
              layout: {
                ...prev[activeTowerID].layout,
                [dataTarget[0]]: {
                  ...prev[activeTowerID].layout[dataTarget[0]],
                  [dataTarget[1]]: rest,
                },
              },
            },
          }
        : {
            ...prev,
            [activeTowerID]: {
              ...prev[activeTowerID],
              layout: {
                ...prev[activeTowerID].layout,
                site: {
                  ...prev[activeTowerID].layout.site,
                  image: null,
                  imageGrid: null,
                  assets: {},
                  images: {},
                  textBoxes: {},
                  drawings: {},
                },
              },
            },
          },
  };

  const saveBox = {
    classes: 'absolute flex bottom-5 right-0 w-[150px] justify-around sm:mx-5',
    clickHandlers: {
      save: async () => {
        await setTowerList(saveHandler[modifyType]);
        clearPanel();
      },
      cancel: () => {
        clearPanel();
      },
    },
    disabled:
      // !isLabelUnique(addItemProps.label, labelList) ||
      modifyType.match(/add|rename|copy/)! && !addItemProps.label.trim(),
  };

  useEffect(() => {
    if (modifyType === 'rename') {
      if (addItemProps.index) {
        setTarget(addItemProps.index);
      }
      setAddItemProps({
        content: '',
        label: itemList[target][dataTarget[2]].name,
      });
    }
  }, [modifyType, target, activeTowerID, towerList, setAddItemProps]);

  useEffect(() => {
    if (
      activeCanvasTab === 'site' &&
      towerList[activeTowerID].layout.site.image
    ) {
      setAddItemProps((prev: IAddItemProps) => ({ ...prev, label: 'rooftop' }));
    }
  }, [activeCanvasTab, activeTowerID, towerList, setAddItemProps]);

  useEffect(() => {
    if (!keys.find((str) => str === target.toString())) {
      setTarget(Number(keys[0]));
    }
  }, [modifyType, keys, target]);

  const classes: IStringProps = {
    container: 'w-full h-1/3 p-5',
    card: 'relative w-full h-full p-3 border border-stone-300 bg-slate-200 rounded rounded-md',
    modify: 'flex justify-between items-center py-2',
    label: 'font-bold text-lg text-stone-500',
    select: 'rounded rouned-md',
    section: 'flex justify-between py-2',
  };

  return (
    <div className={classes.container}>
      <div className={classes.card}>
        <div className={classes.modify}>
          <label
            htmlFor='edit-canvas'
            className={classes.label}>
            Modify {siteTabActive ? 'Image' : data ? 'Rack' : 'Leg'}
          </label>
          <select
            id='edit-canvas'
            name='edit-canvas'
            defaultValue={modifyType}
            className={classes.select}
            onChange={(e: IInputEvent) => {
              setAddItemProps(clearAddProps);
              setModifyType(e.target.value);
            }}>
            {!siteTabActive && (
              <>
                <option value='add'>Add</option>
                {options.length > 0 && (
                  <>
                    <option value='rename'>Rename</option>
                    <option value='copy'>Copy</option>
                  </>
                )}
              </>
            )}
            {options.length > 0 && (
              <>
                <option value='remove'>Remove</option>
              </>
            )}
          </select>
        </div>
        {!siteTabActive && modifyType !== 'add' && (
          <div className={classes.section}>
            <label
              htmlFor='target-canvas-item'
              className={classes.label}>
              Target {siteTabActive ? 'Image' : data ? 'Rack' : 'Leg'}
            </label>
            <select
              defaultValue={target}
              id='target-canvas-item'
              name='target-canvas-item'
              className={classes.select}
              onChange={(e: IInputEvent) => setTarget(e.target.value)}>
              {options}
            </select>
          </div>
        )}
        {modifyType.match(/add|rename|copy/) && !siteTabActive && (
          <div className={classes.section}>
            <label
              htmlFor='add-title'
              className={classes.label}>
              {data ? 'Rack' : 'Leg'} Title
            </label>
            <input
              type='text'
              id='add-title'
              name='add-title'
              className={classes.select}
              value={addItemProps.label}
              onChange={(e: IInputEvent) =>
                setAddItemProps((prev: IAddItemProps) => ({
                  ...prev,
                  label: e.target.value,
                }))
              }
            />
          </div>
        )}
        <SaveBox {...saveBox} />
      </div>
    </div>
  );
};

export default EditPanel;
