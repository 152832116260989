const DocImagesList = ({ isEditActive, content, handler }) => {
  return (
    <div
      className={
        isEditActive
          ? 'flex w-full border border-red-700 p-1'
          : 'flex w-full border p-1'
      }>
      <img
        src={content.image}
        className='w-[50px] h-[50px] rounded'
      />
      <div className='w-full self-center px-2 truncate'>{content.label}</div>
      <i
        className='fa-regular fa-pen-to-square text-center self-center text-stone-400 hover:text-red-600 px-1'
        onClick={handler.edit}
      />
      <i
        className='fa-regular fa-trash-can text-center self-center text-stone-400 hover:text-red-700 px-1'
        onClick={handler.remove}
      />
    </div>
  );
};

export default DocImagesList;
