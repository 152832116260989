import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import CanvasGridLines from 'components/application/canvas/CanvasGridLines';
import DataToolBar from 'components/application/canvas/toolbar/DataToolBar';
import { useContext, useMemo, useRef } from 'react';
import { ICanvasContext } from 'state/iContext';
import { CanvasContext } from 'state/context';
import { ILibraryIcon } from 'state/iState';
import html2canvas from 'html2canvas';
import { IStringProps } from 'iApp';

const DocCropImageWindow = ({ updateImage, grid }) => {
  const { dragProps, setDragProps, setScale, clearDragProps } = useContext<
    ICanvasContext | undefined
  >(CanvasContext)!;

  const ref = useRef<HTMLDivElement>(null);

  const classes: IStringProps = useMemo(
    () => ({
      button:
        'p-1 px-2 m-1 border border-stone-400 rounded rouned-lg bg-red-700 text-slate-100 text-sm transition ease-in-out delay-100 hover:bg-red-600',
      zoomable: 'relative flex flex-col h-[350px] w-[300px] mx-5',
      canvas: `relative h-[350px] w-[300px] bg-stone-200`,
      transform: 'w-full h-full',
    }),
    []
  );

  const options = useMemo(
    () => ({
      x: grid === 'data' ? -4400 : -2200,
      y: grid === 'data' ? -7400 : -3750,
    }),
    [grid]
  );

  const onClick = async () => {
    const canvas = await html2canvas(ref.current!),
      data = canvas.toDataURL('image/png');
    updateImage(data);
  };

  return (
    <div className={classes.zoomable}>
      <button
        className={classes.button}
        onClick={onClick}>
        Image Capture
      </button>
      <TransformWrapper
        maxScale={10}
        minScale={0.1}
        initialScale={1}
        limitToBounds={false}
        initialPositionX={options.x}
        initialPositionY={options.y}
        panning={{
          disabled: dragProps.dragging,
          excluded: ['input', 'textarea'],
        }}
        onZoom={(e) => {
          setScale(e.state.scale);
          setDragProps((prev: ILibraryIcon) => ({
            ...prev,
            dragging: false,
          }));
        }}>
        {(util) => (
          <>
            <DataToolBar
              {...util}
              full={false}
            />
            <div
              ref={ref}
              className={classes.canvas}
              onDoubleClick={() => setDragProps(clearDragProps)}>
              <TransformComponent
                contentClass={classes.transform}
                wrapperClass={classes.transform}>
                <CanvasGridLines active={grid} />
              </TransformComponent>
            </div>
          </>
        )}
      </TransformWrapper>
    </div>
  );
};

export default DocCropImageWindow;
