import { IHeaderContext, ICanvasContext } from 'state/iContext';
import { ApplicationTabIcons } from './ApplicationTabIcons';
import { HeaderContext, CanvasContext } from 'state/context';
import { useContext, ReactNode } from 'react';
import { IActiveToolBar, IHeaderButtons } from 'state/iState';
import { ITabClasses } from './iHeaderTabs';

const HeaderTabs = () => {
  const { activeHeaderButton, setActiveHeaderButton } = useContext<
    IHeaderContext | undefined
  >(HeaderContext)!;
  const { setDragProps, clearDragProps, setScale, setActiveToolBar } =
    useContext<ICanvasContext | undefined>(CanvasContext)!;

  const tabs: string[] = ['tower', 'site', 'data', 'drawings', 'files'];

  // Component classes
  const classes: ITabClasses = {
    container: {
      active:
        'flex flex-col justify-end px-4 text-slate-200 bg-red-700 transition ease-in-out delay-100 hover:bg-red-800',
      inactive:
        'flex flex-col justify-end px-4 text-slate-600 transition ease-in-out delay-100 hover:bg-red-700 hover:text-slate-200',
    },
    icon: 'flex flex-col w-auto max-h-[20px] justify-center items-center text-center',
  };

  // Toggles between each header tab
  const clickHandler = (tab: string) => {
    if (!activeHeaderButton[tab as keyof typeof activeHeaderButton]) {
      setActiveToolBar((prev: IActiveToolBar) => ({
        ...prev,
        select: true,
        image: false,
        text: false,
        drawings: false,
        sections: false,
        edit: false,
      }));
      setActiveHeaderButton((prev: IHeaderButtons) => ({
        ...prev,
        tower: false,
        site: false,
        data: false,
        drawings: false,
        files: false,
        [tab]: true,
      }));
      setDragProps(clearDragProps);
      setScale(tab === 'data' ? 2 : 1);
    }
  };

  // Renders each tab to the header
  const headerTabs: ReactNode[] = tabs.map((tab): ReactNode => {
    const key: string = `${tab}-tab`;
    const activeClass: string = activeHeaderButton[
      tab as keyof typeof activeHeaderButton
    ]
      ? classes.container.active
      : classes.container.inactive;

    return (
      <div
        key={key}
        className={activeClass}
        onClick={() => clickHandler(tab)}>
        <div className={classes.icon}>
          {ApplicationTabIcons[tab as keyof typeof ApplicationTabIcons]}
        </div>
        {tab.toUpperCase()}
      </div>
    );
  });

  return <>{headerTabs}</>;
};

export default HeaderTabs;
