import { IMapContext, IEditContext, ISitesContext } from 'state/iContext';
import { MapContext, EditContext, SitesContext } from 'state/context';
import { IEditProps } from '../../editPanel/iEdit';
import { Marker } from '@react-google-maps/api';
import { useContext } from 'react';

const NewSiteMarker = () => {
  const { siteTypes } = useContext<ISitesContext | undefined>(SitesContext)!;
  const { center, getGeoLocation } = useContext<IMapContext | undefined>(
    MapContext
  )!;
  const { editProps, setEditProps } = useContext<IEditContext | undefined>(
    EditContext
  )!;

  return (
    <Marker
      draggable={true}
      position={center}
      icon={{
        url: siteTypes[editProps.type?.site]!,
        scaledSize: new google.maps.Size(25, 25),
      }}
      onDrag={({ latLng }) =>
        setEditProps((prev: IEditProps) => ({
          ...prev,
          location: { lat: latLng!.lat(), lng: latLng!.lng() },
        }))
      }
      onDragEnd={({ latLng }) =>
        getGeoLocation({ lat: latLng!.lat(), lng: latLng!.lng() })!
      }
    />
  );
};

export default NewSiteMarker;
