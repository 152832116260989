import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import {
  MapContext,
  EditContext,
  UserContext,
  SitesContext,
  HeaderContext,
  SettingsContext,
} from './context';
import {
  IGoogleKey,
  ITowerList,
  IAppChildren,
  LatLngLiteral,
  IMeasureEndPoints,
} from './iState';
import {
  IUserContext,
  IHeaderContext,
  ISettingsContext,
  IHomeProviderProps,
} from './iContext';
import {
  mapCenter,
  siteTypes,
  towerTypes,
  stockImages,
  towerColors,
  editPanelProps,
  measureDistanceEndPoints,
} from './state';
import { searchMatch, towerHeight } from 'helpers/helperFunctions';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { IEditProps } from '../components/home/editPanel/iEdit';
import { towers } from './developmentData';
import { IStringProps } from 'iApp';

const HomeStateProvider = ({ children }: IAppChildren) => {
  // Site States
  const [towerList, setTowerList] = useState<ITowerList>(towers);
  const [newSite, setNewSite] = useState<boolean>(false);
  const [favoritesTab, setFavoritesTab] = useState<boolean>(false);
  const [warningClicked, setWarningClicked] = useState<boolean>(false);
  const towerIds: string[] = Object.keys(towerList);

  // Edit States
  const [isEditPanelOpen, setIsEditPanelOpen] = useState<boolean>(false);
  const [imagePanel, setImagePanel] = useState<boolean>(false);
  const [editProps, setEditProps] = useState<IEditProps>(editPanelProps);

  // Map States
  const [center, setCenter] = useState<LatLngLiteral>(mapCenter);
  const [infoWindowID, setInfoWindowID] = useState<number>(-1);
  const [zoom, setZoom] = useState<number>(10);
  const [measureDistance, setMeasureDistance] = useState<boolean>(false);
  const [measureEndPoints, setMeasureEndPoints] = useState<IMeasureEndPoints>(
    measureDistanceEndPoints
  );
  const [stringLocation, setStringLocation] = useState<IStringProps>({
    locality: '',
    political: '',
  });

  const { favorites, lowCaseSearch } = useContext<IUserContext | undefined>(
    UserContext
  )!;
  const { menuFilters } = useContext<IHeaderContext | undefined>(
    HeaderContext
  )!;
  const { metric } = useContext<ISettingsContext | undefined>(SettingsContext)!;

  const googleKey: IGoogleKey = {
    id: 'google-map-api',
    googleMapsApiKey: 'AIzaSyCp8_qlbr1ltbRD6gc5uwhPWorCWZHmsQ4',
  };
  const { isLoaded } = useJsApiLoader(googleKey);
  const mapRef = useRef<GoogleMap>();

  const onLoad = useCallback((map: GoogleMap): void => {
    console.log('Map Reloaded');
    mapRef.current = map;
  }, []);

  // Search bar filtering through site/tower details
  const searchFiltered = useMemo(
    () =>
      towerIds
        .filter((tower): boolean => {
          const { name, code, company, geoCode, contact, comments } =
            towerList[tower];
          const searchInList = [
            name,
            code,
            company,
            geoCode,
            contact.name,
            comments,
          ];
          return searchMatch({ searchInList, lowCaseSearch });
        })
        .map((tower) => Number(tower)),
    [lowCaseSearch, towerIds, towerList]
  );

  // Site type filters
  const siteFilters: string[] = useMemo(
    () =>
      Object.keys(menuFilters).filter(
        (type): boolean => menuFilters[type as keyof typeof menuFilters]
      ),
    [menuFilters]
  );

  // Filtered favorite sites
  const favoriteSites: number[] = useMemo(
    () =>
      searchFiltered.filter(
        (tower): boolean =>
          favorites.includes(tower) &&
          siteFilters.includes(towerList[tower].type.site.toLowerCase())
      ),
    [towerList, favorites, siteFilters, searchFiltered]
  );

  // Filtered list of all available sites
  const filteredSiteIds: number[] = useMemo(
    () =>
      searchFiltered.filter((tower): boolean =>
        siteFilters.includes(towerList[tower].type.site.toLowerCase())
      ),
    [searchFiltered, siteFilters, towerList]
  );

  // Toggles all sites/favorites tab
  const changeSitesTab = (name: string): void => {
    let tab: boolean = false;
    if (name === 'favorites-tab') {
      tab = true;
    }
    setFavoritesTab(tab);
  };

  // Toggles edit panel
  const openEditPanel = (props: IEditProps): void => {
    const height = metric ? towerHeight(metric, props.height!) : props.height;
    setEditProps({
      ...props,
      height,
    });
    setImagePanel(false);
    setIsEditPanelOpen((prev) => !prev);
  };

  // Toggles edit image panel
  const openImagePanel = (): void => setImagePanel((prev) => !prev);

  // Updates details and pans to tower
  const editTower = (props: IEditProps): void => {
    mapRef.current!.panTo(props.location! as LatLngLiteral);
    setTowerList((prev) => ({
      ...prev,
      [props.towerId!]: { ...prev[props.towerId!], ...props! },
    }));
  };

  // Changes lat/long coordinates in edit panel
  const updateCoordinates = (location: LatLngLiteral) =>
    setEditProps((prev) => ({ ...prev, location }));

  // Saves new geolocation of provided lat/long
  const getGeoLocation = (location: LatLngLiteral): void => {
    const geocoder: google.maps.Geocoder = new google.maps.Geocoder();
    geocoder
      .geocode({ location })
      .then(async (res) => {
        const filteredResults: google.maps.GeocoderResult[] =
          res.results.filter((item) => item.types.includes('postal_code'));
        const result: string =
          filteredResults.length > 0
            ? filteredResults[0].formatted_address
            : res.results[0].formatted_address;

        setEditProps((prev) => ({
          ...prev,
          location: location,
          geoCode: result,
        }));
      })
      .catch((e) => console.error('Geocoder failed due to: ' + e));
  };

  // Sets location strings of provided lat/long
  const setGeoLocationStrings = useCallback((location: LatLngLiteral): void => {
    const geocoder: google.maps.Geocoder = new google.maps.Geocoder();
    geocoder
      .geocode({ location })
      .then(async (res) => {
        const localityFilter: google.maps.GeocoderResult[] = res.results.filter(
          (item) => item.types.includes('locality')
        );
        const politialFilter: google.maps.GeocoderResult[] = res.results.filter(
          (item) => item.types.includes('administrative_area_level_1')
        );

        const politial: string =
          politialFilter.length > 0
            ? politialFilter[0].formatted_address
            : res.results[0].formatted_address;
        const locality: string =
          localityFilter.length > 0
            ? localityFilter[0].formatted_address
            : res.results[0].formatted_address;
        setStringLocation((prev: IStringProps) => ({
          ...prev,
          locality: locality.slice(0, locality.indexOf(',')),
          political: politial.slice(0, politial.indexOf(',')),
        }));
      })
      .catch((e) => console.error('Geocoder failed due to: ' + e));
  }, []);

  // Retrieves device location
  const getUserLocation = () => {
    const getCurrentCoords = (position: GeolocationPosition) => {
      const lat: number = position.coords.latitude;
      const lng: number = position.coords.longitude;

      const currentLocation: LatLngLiteral = { lat, lng };

      mapRef.current !== undefined
        ? mapRef.current!.panTo(currentLocation)
        : setCenter(currentLocation);
    };
    navigator.geolocation.getCurrentPosition(getCurrentCoords);
  };

  // Adds/Removes measure tool end points
  const changeMeasureEndPoints = (id: number) => {
    const { start, end } = measureEndPoints;
    let changeEndPoints = (prev: IMeasureEndPoints) => prev;

    if (start === null && end?.towerId !== id) {
      changeEndPoints = (prev: IMeasureEndPoints) => ({
        ...prev,
        start: towerList[id],
      });
    }
    if (start?.towerId === id && end) {
      changeEndPoints = (prev: IMeasureEndPoints) => ({
        ...prev,
        start: null,
      });
    }
    if (end?.towerId === id) {
      changeEndPoints = (prev: IMeasureEndPoints) => ({
        ...prev,
        end: null,
      });
    }
    if (start && end === null) {
      changeEndPoints = (prev: IMeasureEndPoints) => ({
        ...prev,
        end: towerList[id],
      });
    }

    setMeasureEndPoints(changeEndPoints);
  };

  // Clears measure tool endpoints on close
  useEffect((): void => {
    if (!measureDistance) {
      setMeasureEndPoints({ start: null, end: null });
    }
  }, [measureDistance]);

  // Retieves device location on load
  useEffect((): void => {
    getUserLocation();
  }, []);

  // Converts all data to respective measurements units
  useEffect((): void => {
    const heightProp = (height: number) =>
      metric ? towerHeight(metric, height) : height;
    setEditProps((prev: IEditProps) => ({
      ...prev,
      height: heightProp(prev.height!),
    }));
  }, [metric]);

  // Props to pass to their respective elements
  const providerProps: IHomeProviderProps = {
    sites: {
      siteTypes,
      towerTypes,
      towerColors,
      towerList,
      setTowerList,
      favoritesTab,
      favoriteSites,
      changeSitesTab,
      filteredSiteIds,
      warningClicked,
      setWarningClicked
    },
    map: {
      zoom,
      mapRef,
      onLoad,
      setZoom,
      center,
      setCenter,
      infoWindowID,
      setInfoWindowID,
      getGeoLocation,
      setGeoLocationStrings,
      getUserLocation,
      stringLocation,
      setStringLocation,
      updateCoordinates,
      measureEndPoints,
      measureDistance,
      setMeasureDistance,
      setMeasureEndPoints,
      changeMeasureEndPoints,
    },
    edit: {
      newSite,
      setNewSite,
      imagePanel,
      stockImages,
      editTower,
      editProps,
      setEditProps,
      openEditPanel,
      openImagePanel,
      isEditPanelOpen,
    },
  };

  if (!isLoaded) return <div>Map Loading...</div>;

  return (
    <SitesContext.Provider value={providerProps.sites}>
      <EditContext.Provider value={providerProps.edit}>
        <MapContext.Provider value={providerProps.map}>
          {children}
        </MapContext.Provider>
      </EditContext.Provider>
    </SitesContext.Provider>
  );
};

export default HomeStateProvider;
